<template>
  <base-button
    :id="id"
    :disabled="disabled"
    :href="href"
    :icon="icon"
    :min-width="minWidth"
    :size="size"
    :type="type"
    variant="primary"
    @click="$emit('click', $event)"
  >
    <slot />
  </base-button>
</template>

<script>
import BaseButton from "./BaseButton.vue"

export default {
  components: {
    BaseButton
  },
  props: {
    size: {
      type: String,
      default: "m",
      validator: (value) => {
        return ["s", "m", "l", "xl"].indexOf(value) !== -1
      }
    },
    type: {
      type: String,
      default: "button"
    },
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: null
    },
    minWidth: {
      type: Number,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  emits: ["click"]
}
</script>
