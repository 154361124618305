<template>
  <div class="group-list">
    <filters v-if="filtersToggled" />
    <advanced-table
      v-if="areThereAnyGroups"
      :columns="['Group', 'Users', 'Creator', 'Created', '']"
      :current-page="currentPage"
      :data="filteredGroups"
      :last-page="lastPage"
      @load-next-page="changePage(currentPage + 1, 20, true)"
    >
      <template #default="{row}">
        <td>
          {{ row.name }}
        </td>
        <td>
          <div
            class="avatars-stacked same"
            style="height: 38px;"
          >
            <div
              v-for="(author, index) in row.users.slice(0,4)"
              :key="'author-' + author.id"
              class="avatar-wrapper"
              style="height: 38px;width: 19px;"
            >
              <avatar
                :key="index"
                :alt="[author.firstname, author.lastname].join(' ')"
                :avatar="author.avatar"
                :size="38"
                :title="[author.firstname, author.lastname].join(' ')"
                :tooltip="true"
                :user-id="author.uuid"
              />
            </div>
            <div
              v-if="row.users.length > 4"
              class="avatar-wrapper"
            >
              <span
                v-tippy="{animateFill: false}"
                class="avatar avatar-more"
                :content="getMoreAuthorsTooltip(row.users)"
                style="width: 38px; height: 38px;"
              >
                <span class="users-more">
                  +{{ row.users.length - 4 }}
                </span>
              </span>
            </div>
          </div>
        </td>
        <td>
          <avatar
            :alt="[row.user.firstname, row.user.lastname].join(' ')"
            :avatar="row.user.avatar"
            :size="38"
            :title="[row.user.firstname, row.user.lastname].join(' ')"
            :tooltip="true"
            :user-id="row.user.uuid"
          />
        </td>
        <td>
          <span
            v-tippy
            :content="Utils.parseDate(row.created_at, false, 'D MMM YYYY')"
          >{{ Utils.timeAgoFormatter(row.created_at) }}</span>
        </td>
        <td class="action-row">
          <div
            class="dropdown-holder"
            @click.prevent.stop="setDropdown(row.uuid)"
          >
            <context-dropdown
              :min-width="182"
              :options="getRowActions(row)"
              :placement="'bottom-end'"
              :visible="dropdownActive === row.uuid"
              @click="toggleDropdown"
              @hide="hideDropdown"
            />
          </div>
        </td>
      </template>
    </advanced-table>
    <empty-section
      v-else
      :icon="require('../../../images/modal/create-group-infographics.svg')"
    >
      There are no Groups yet. Start by creating new one!
    </empty-section>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Utils from "../../utils"
import ContextDropdown from "../common/dropdown/ContextDropdown"
import GroupModal from "../common/modal/GroupModal"
import Language from "../../i18n/en"
import Filters from "../admin/group/Filters"
import DeleteModal from "../common/modal/DeleteModal"
import { GroupRoutes } from "../../api/routes"
import EmptySection from "../dashboard/components/EmptySection"

export default {
  name: "GroupsList",
  components: {
    ContextDropdown,
    Filters,
    EmptySection
  },
  data () {
    return {
      currentSort: "id",
      currentSortDir: "DESC",
      lastPage: 1,
      currentPage: 1,
      groups: [],
      dropdownActive: false,
      filtersToggled: false
    }
  },
  computed: {
    areThereAnyGroups () {
      return this?.filteredGroups?.length
    },
    filteredGroups () {
      if (this.groups) {
        return this.groups.filter((g) => {
          if (this.selectedFilters && this.selectedFilters.role.length > 0) {
            return g.users.filter(u => this.selectedFilters.role.map(e => e.value).includes(u.role)).length === this.selectedFilters.role.length
          }
          if (this.selectedFilters && this.selectedFilters.group.length > 0) {
            return this.selectedFilters.group.map(e => e.value.uuid).includes(g.uuid)
          }

          return true
        })
      }

      return []
    },
    Utils () {
      return Utils
    },
    ...mapState(["selectedFilters"])
  },
  created () {
    this.fetchData()
  },
  mounted () {
    this.$eventHub.$on("load-groups", this.fetchData)
    this.$eventHub.$on("toggle-filters", (section) => {
      if (section === "group-list-filters") {
        this.filtersToggled = !this.filtersToggled
      }
    })
  },
  methods: {
    setDropdown (uuid) {
      this.dropdownActive = this.dropdownActive ? null : uuid
    },
    toggleDropdown () {
      this.dropdownActive = !this.dropdownActive
    },
    hideDropdown () {
      this.dropdownActive = null
    },
    changePage (page = 1, limit = 20, append = false) {
      this.currentPage = page
      this.fetchData(page, limit, this.currentSort, this.currentSortDir, append)
    },
    fetchData (page = 1, limit = 20, sortBy = this.currentSort, sortWay = this.currentSortDir, append = false) {
      const filters = {
        role: this.selectedFilters && this.selectedFilters.role ? this.selectedFilters.role.map((row) => row.value) : []
      }
      return this.$store.dispatch("loadGroups", { limit, page, orderBy: sortBy, order: sortWay, filters, performCommit: false }).then((resp) => {
        this.groups = append ? this.groups.concat(resp.data.data) : resp.data.data
        this.currentPage = resp.data.meta?.current_page || page
        this.lastPage = resp.data.meta?.last_page || page
      })
    },
    destroy ($event, groupUuid, soft) {
      $event.preventDefault()
      const uri = GroupRoutes.removeGroup(groupUuid)
      return this.$apiClient.delete(uri, { params: { soft: soft === true } }).then(response => {
        this.results = response.data
        this.fetchData()
      })
    },
    openEditGroupModal ($event, groupUuid) {
      $event.preventDefault()

      const group = this.groups.find(group => {
        return group.uuid === groupUuid
      })

      this.$modal.show(GroupModal, {
        title: "Edit Group",
        actionButton: "Edit",
        method: "put",
        endpoint: GroupRoutes.updateGroup(group.uuid),
        preselectedUsers: group.users,
        preselectedGroupName: group.name,
        successMessage: Language.groups.success.edit,
        errorMessage: Language.groups.error.edit,
        success: this.fetchData
      })
    },
    getMoreAuthorsTooltip (users) {
      let text = ""
      users.forEach((row) => {
        text += `<p>${row.name}</p>`
      })

      return `<div class="group-users-tooltip">${text}</div>`
    },
    getRowActions (row) {
      const actions = []
      if (row.permissions.edit) {
        actions.push({
          content: "Edit",
          icon: "edit",
          href: "/groups/" + row.uuid,
          click: ($event) => this.openEditGroupModal($event, row.uuid)
        })
        actions.push({
          content: "Archive",
          icon: "archive",
          click: ($event) => {
            this.$modal.show(DeleteModal, {
              title: "Archive group",
              description: "Are you sure you want to archive this group?",
              actionButton: "Archive",
              successMessage: Language.designs.success.archived,
              errorMessage: Language.designs.error.archived,
              action: () => {
                return this.destroy($event, row.uuid, true)
              }
            })
          }
        })
      }
      if (row.permissions.delete) {
        actions.push({
          content: "Delete",
          icon: "delete",
          click: ($event) => this.destroy($event, row.uuid)
        })
      }
      return actions
    }
  }
}
</script>
