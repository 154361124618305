<template>
  <base-modal
    ref="modal"
    class="analytics-automatic-report-modal"
    :show-close-button="false"
    size="extra-small"
    :title="null"
    @submit="submit"
  >
    <template #modal-header>
&nbsp;
    </template>
    <template #content>
      <h1>Automatic Report Schedule</h1>
      <automatic-report-form
        ref="automatic-report-form"
        :campaign-id="campaignId"
        :report="report"
      />
    </template>
    <template #actions>
      <base-button
        size="xl"
        @click.prevent="modal.close"
      >
        Cancel
      </base-button>
      <primary-button
        size="xl"
        @click.prevent="submit"
      >
        Save
      </primary-button>
    </template>
  </base-modal>
</template>
<script>
import AutomaticReportForm from "../analytics/components/AutomaticReportForm"

export default {
  name: "AutomaticReportModal",
  components: {
    AutomaticReportForm
  },
  props: {
    report: {
      type: Object,
      default: null
    },
    campaignId: {
      type: String,
      required: true
    },
    afterSubmit: {
      type: Function,
      default: () => {
      }
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      }
    }
  },
  mounted () {
    this.modal = this.$refs.modal
  },
  methods: {
    submit () {
      this.$refs["automatic-report-form"].onSubmit()
        .then(() => {
          this.modal.close()
          this.afterSubmit()
        })
    }
  }
}
</script>
