<template>
  <div>
    <label
      v-if="label"
      class="input-label"
      :for="inputId"
      :style="{ 'min-width': labelMinWidth + 'px', 'max-width': labelMaxWidth + 'px'}"
    >
      <span>
        <span v-html="label"></span>
        <icon
          v-if="informationTooltip"
          class="info-tooltip"
          name="info-02"
          :tooltip="informationTooltip"
          :tooltip-max-width="220"
          tooltip-placement="right-start"
        />
      </span>
    </label>
    <base-input
      :id="inputId"
      :disabled="disabled"
      :font-size="fontSize"
      :icon="icon"
      :min-width="minWidth"
      :max-width="maxWidth"
      :move-caret-to-the-end-on-focus="moveCaretToTheEndOnFocus"
      :height="height"
      :placeholder="placeholder"
      :required="required"
      :type="type"
      :min="min"
      :value="value"
      :max="max"
      :maxlength="maxlength"
      :step="step"
      :padding-x="paddingX"
      :padding-y="paddingY"
      :readonly="readonly"
      :separators="separators"
      :input-prefix="inputPrefix"
      :input-prefix-icon="inputPrefixIcon"
      :suffix="suffix"
      :suffix-icon="suffixIcon"
      :error="!validate(value) || errors.length > 0 || error"
      :is-draggable="isDraggable"
      :disable-dragging="disableDragging"
      @active="$emit('active', $event)"
      @blur="$emit('blur', $event)"
      @change="$emit('change', $event)"
      @focus="$emit('focus', $event)"
      @input="emitInput"
      @keydown="$emit('keydown', $event)"
      @keydown.enter.prevent="$emit('enter', $event)"
      @keyup="$emit('keyup', $event)"
      @wheel.passive="$emit('wheel', $event)"
    />
    <div
      v-if="showCharacterCounter"
      class="d-flex justify-content-end align-items-center"
    >
      <span class="character-counter">
        {{ value.length }} / {{ maxlength }}
      </span>
    </div>
  </div>
</template>
<script>
import BaseInput from "./BaseInput"
import Icon from "../Icon"
import { v4 as uuidv4 } from "uuid"

export default {
  name: "TextInput",
  components: {
    BaseInput,
    Icon
  },
  props: {
    id: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: ""
    },
    height: {
      type: Number,
      default: 44
    },
    label: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    moveCaretToTheEndOnFocus: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    paddingX: {
      type: Number,
      default: 20
    },
    paddingY: {
      type: Number,
      default: 12
    },
    minWidth: {
      type: Number,
      default: 260
    },
    maxWidth: {
      type: Number,
      default: null
    },
    labelMinWidth: {
      type: Number,
      default: 260
    },
    labelMaxWidth: {
      type: Number,
      default: null
    },
    fontSize: {
      type: Number,
      default: 16
    },
    readonly: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: () => { return {} }
    },
    validate: {
      type: Function,
      default: () => true
    },
    type: {
      type: String,
      default: "text"
    },
    informationTooltip: {
      type: String,
      default: null
    },
    step: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    maxlength: {
      type: Number,
      default: null
    },
    separators: {
      type: Boolean,
      default: false
    },
    inputPrefix: {
      type: String,
      default: ""
    },
    inputPrefixIcon: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    },
    suffixIcon: {
      type: String,
      default: ""
    },
    isDraggable: {
      type: Boolean,
      default: false
    },
    showCharacterCounter: {
      type: Boolean,
      default: false
    },
    disableDragging: {
      type: Boolean,
      default: false
    }
  },
  emits: ["active", "macroToggleClicked", "input", "keyup", "change", "blur", "focus", "keydown", "enter", "wheel", "update:value"],
  created () {
    this.inputId = uuidv4()
    if (this.id !== null) {
      this.inputId = this.id
    }
  },
  methods: {
    emitInput ($event) {
      this.$emit("input", $event)
      this.$emit("update:value", $event)
    }
  }
}
</script>
