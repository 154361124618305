import {
  AdvancedCard,
  AdvancedTable,
  Avatar,
  Badge,
  BaseButton,
  BaseInput,
  BaseModal,
  BaseTextarea,
  Brand,
  CampaignPopup,
  CampaignRow,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  CheckboxField,
  CircleChart,
  ColorBoxes,
  DangerButton,
  DateField,
  DefaultButton,
  DesignRow,
  FilterEnabler,
  GroupListPopup,
  HeaderPopup,
  Icon,
  InputDropdown,
  LoadingStatus,
  MainHeader,
  ModeSwitch,
  Multiselect,
  NumberField,
  Popup,
  PrimaryButton,
  RadioButton,
  RichSwitch,
  SearchHeader,
  SecondaryButton,
  Sidebar,
  Tab,
  Tabs,
  Tag,
  TagList,
  TemplateCreate,
  TextInput,
  Toggle,
  TooltipTooLong,
  DotTooLong,
  UserListPopup,
  UserRegister,
  CodeEditor
} from "."

/**
 *  Register components globally
 * */
export const components = {
  MainHeader,
  Sidebar,
  Icon,
  DefaultButton,
  Multiselect,
  BaseButton,
  PrimaryButton,
  DangerButton,
  SecondaryButton,
  TooltipTooLong,
  DotTooLong,
  RichSwitch,
  SearchHeader,
  HeaderPopup,
  CampaignPopup,
  UserListPopup,
  GroupListPopup,
  TemplateCreate,
  DesignRow,
  CampaignRow,
  CircleChart,
  Avatar,
  Badge,
  Brand,
  Popup,
  FilterEnabler,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  TagList,
  Tag,
  ColorBoxes,
  InputDropdown,
  BaseInput,
  BaseTextarea,
  TextInput,
  NumberField,
  DateField,
  CheckboxField,
  Checkbox,
  RadioButton,
  Toggle,
  BaseModal,
  UserRegister,
  ModeSwitch,
  LoadingStatus,
  AdvancedCard,
  AdvancedTable,
  Tabs,
  Tab,
  CodeEditor
}

export const registerGlobalComponents = (app) => {
  Object.keys(components).forEach(key => app.component(key, components[key]))
}
