<template>
  <form
    class="edit-user-modal"
    enctype="multipart/form-data"
    method="post"
    @keydown="form.errors.clear($event.target.name)"
    @submit.prevent="onSubmit"
  >
    <div
      v-if="form.success"
      class="alert alert-success"
    >
      User has been successfully modified
    </div>
    <div class="container">
      <div class="row d-flex">
        <div class="col">
          <text-input
            id="firstname"
            v-model:value="firstname"
            :error="form.errors.errors.firstname && form.errors.errors.firstname.length > 0"
            label="User name"
            :max-width="250"
            :min-width="250"
            placeholder="First name"
            @input="clearErrors('firstname')"
          />
        </div>
        <div class="col">
          <text-input
            id="lastname"
            v-model:value="lastname"
            :error="form.errors.errors.lastname && form.errors.errors.lastname.length > 0"
            :max-width="250"
            :min-width="250"
            placeholder="Second name"
            @input="clearErrors('lastname')"
          />
        </div>
      </div>
      <div class="row pt-3">
        <div class="col">
          <text-input
            id="email"
            v-model:value="email"
            :error="errors.email"
            label="User email"
            placeholder="johndoe@abc.com"
            type="email"
          />
          <p
            v-if="errors.email"
            class="text-danger"
          >
            <small>{{ errors.email }}</small>
          </p>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col">
          <input-dropdown
            id="role"
            v-model:value="activeRole"
            :error="form.errors.errors.role && form.errors.errors.role.length > 0"
            label="Choose role"
            :max-width="250"
            :min-width="250"
            :options="roles"
            :placement="'bottom-end'"
            :show-arrow="true"
            @input="clearErrors('role')"
          />
        </div>
        <div class="col">
          <rich-input-dropdown
            id="groups"
            v-model:value="activeGroups"
            :enable-selection="false"
            :is-dropdown="true"
            label="Select Group"
            :max-displayed="3"
            :max-width="250"
            :min-width="250"
            :options="groups"
            placeholder="Search for Group"
            :placement="'bottom-end'"
            :required="false"
            :searchable="false"
            :show-arrow="true"
            track-by="uuid"
          >
            <template #option-preview="data">
              <div class="group-stack">
                <div class="avatars-stacked">
                  <template v-for="(author, index) in data.option.value.users.slice(0,5)">
                    <div
                      v-if="author"
                      :key="'author-' + index"
                      class="avatar-wrapper"
                    >
                      <avatar
                        :alt="[author.firstname, author.lastname].join(' ')"
                        :avatar="author.avatar"
                        :size="26"
                        :title="[author.firstname, author.lastname].join(' ')"
                        :tooltip="true"
                        :user-id="author.uuid"
                      />
                    </div>
                  </template>
                  <div
                    v-if="data.option.value.users.length > 4"
                    class="avatar-wrapper"
                  >
                    <span
                      v-tippy="{animateFill: false}"
                      class="avatar avatar-more"
                      :content="getMoreAuthorsTooltip(data.option.value.users)"
                      style="width: 26px; height: 26px;"
                    >
                      <span class="users-more">
                        +{{ data.option.value.users.length - 4 }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template #option-row="data">
              <div class="group-stack">
                <div class="avatars-stacked">
                  <template v-for="(author, index) in data.option.value.users.slice(0,5)">
                    <div
                      v-if="author"
                      :key="'author-' + index"
                      class="avatar-wrapper"
                    >
                      <avatar
                        :alt="[author.firstname, author.lastname].join(' ')"
                        :avatar="author.avatar"
                        :size="26"
                        :title="[author.firstname, author.lastname].join(' ')"
                        :tooltip="true"
                        :user-id="author.uuid"
                      />
                    </div>
                  </template>
                  <div
                    v-if="data.option.value.users.length > 4"
                    class="avatar-wrapper"
                  >
                    <span
                      v-tippy="{animateFill: false}"
                      class="avatar avatar-more"
                      :content="getMoreAuthorsTooltip(data.option.value.users)"
                      style="width: 26px; height: 26px;"
                    >
                      <span class="users-more">
                        +{{ data.option.value.users.length - 4 }}
                      </span>
                    </span>
                  </div>
                </div>
                <span class="group-name">{{ data.option.title }}</span>
              </div>
            </template>
          </rich-input-dropdown>
        </div>
      </div>
    </div>
    <input
      name="submitted"
      type="hidden"
      value="1"
    />
  </form>
</template>
<script>
import { Form } from "@/classes/form"
import Language from "../../../i18n/en"
import { mapActions, mapState } from "vuex"
import RichInputDropdown from "../../common/dropdown/RichInputDropdown"
import InputDropdown from "../../common/dropdown/InputDropdown"
import Utils from "../../../utils"
import { AdminRoutes } from "../../../api/routes"

export default {
  name: "AdminUserEdit",
  components: {
    InputDropdown,
    RichInputDropdown
  },
  props: {
    submitted: {
      type: Function,
      default: () => {
        return function () {}
      }
    }
  },
  data () {
    return {
      firstname: "",
      lastname: "",
      email: "",
      activeRole: { title: "Designer", value: "user" },
      activeGroups: [],
      form: new Form({
        email: "",
        firstname: "",
        lastname: "",
        group: "",
        role: ""
      }, true)
    }
  },
  computed: {
    errors () {
      const errors = {}

      if (!Utils.validateEmail(this.email)) {
        errors.email = "Invalid email"
      }

      return errors
    },
    roles () {
      return (this.availableRoles || []).filter((r) => {
        return (this.currentUser ? this.currentUser.roles.super_admin : false) ? true : r.value !== "super_admin"
      }).map((r) => {
        return {
          value: r.value,
          title: r.name
        }
      })
    },
    groups () {
      return (this.availableGroups || []).map((g) => {
        return {
          value: g,
          title: g.name,
          uuid: g.uuid
        }
      })
    },
    ...mapState({
      availableRoles: "availableRoles",
      availableGroups: "groups",
      activeUser: "activeUser",
      currentUser: "currentUser"
    })
  },
  mounted () {
    this.loadGroups().then(() => {
      this.activeGroups = this.groups.filter((g) => {
        return this.activeUser.groups.find((ug) => {
          return ug.uuid === g.value.uuid
        })
      })
    })

    this.firstname = this.activeUser.firstname
    this.lastname = this.activeUser.lastname
    this.name = this.activeUser.name
    this.email = this.activeUser.email
    this.activeRole = this.roles.find((r) => r.value === this.activeUser.role)
    this.form = new Form({
      name: this.activeUser.name,
      firstname: this.activeUser.firstname || "",
      lastname: this.activeUser.lastname || "",
      email: this.activeUser.email || "",
      role: this.activeUser.role || "",
      groups: this.activeUser.groups ? this.activeUser.groups : []
    })
  },
  methods: {
    clearErrors (name) {
      this.form.errors.errors[name] = []
    },
    getMoreAuthorsTooltip (users) {
      let text = ""
      users.forEach((row) => {
        text += `<p>${row.name}</p>`
      })

      return `<div class="group-users-tooltip">${text}</div>`
    },
    onSubmit () {
      this.form.email = this.email
      this.form.firstname = this.firstname
      this.form.lastname = this.lastname
      this.form.role = this.activeRole ? this.activeRole.value : null
      this.form.groups = this.activeGroups ? this.activeGroups.map((g) => g.value.uuid) : null

      let error = false
      if (this.errors.email) {
        error = true
      }

      if (!error) {
        return this.form
          .put(AdminRoutes.updateUser(this.activeUser.uuid))
          .then(this.onSuccess)
          .catch(() => {
            this.$toasted.error(Language.users.error.edit)
          })
      }
    },
    onSuccess (response) {
      this.$store.dispatch("loadUsers")
      this.$toasted.success(Language.users.success.edit)
      this.submitted(response)
    },
    ...mapActions(["loadGroups"])
  }
}
</script>
