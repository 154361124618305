export class Routes {
  // DELETE
  static deleteUser = (userUuid) => `/api/user/edit/${userUuid}`

  // GET
  static getBasicLoggedUserInformation = () => "/api/me"
  static getLoggedInUserNotifications = () => "/api/me/notification"
  static approveEmailChange = () => "/api/user/email-approve"
  static verifyEmail = () => "/api/user/email-verify"
  static getAvailableUsers = () => "/api/user"

  // POST
  static saveSelectedDashboard = () => "/api/me/select-dashboard"
  static saveCustomTag = () => "/api/me/add-tag"
  static markNotificationAsRead = (notificationUuid) => `/api/me/notifications/${notificationUuid}/read`
  static requestEmailChange = () => "/api/user/email-request"
  static updateUserData = (userUuid) => `/api/user/edit/${userUuid}`
  static updateUserSettings = (userUuid) => `/api/user/edit/${userUuid}/settings`

  // PUT
  static saveLoggedInUserPaymentData = () => "/api/me/save-payment-data"
  static saveLoggedInUserCompanyData = () => "/api/me/save-company-data"
}
