<template>
  <div class="recent-campaigns">
    <card>
      <card-header>
        <h1 class="campaigns-header">
          Recent Campaigns
        </h1>
      </card-header>
      <card-body v-if="campaigns">
        <template v-if="campaigns.length > 0">
          <table class="table">
            <thead>
              <tr>
                <th>Creator</th>
                <th>Campaign</th>
                <th>Last edit</th>
                <th>Edit by</th>
              </tr>
              <tr>
                <td
                  colspan="4"
                  style="padding:5px 0"
                >
                  <div class="line-through"></div>
                </td>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(campaign, index) in campaigns"
                :key="'campaign-' + index"
              >
                <tr>
                  <td style="width: 120px">
                    <div class="avatars-stacked">
                      <template
                        v-for="(author, indexEditors) in campaign.campaign_editors"
                        :key="'author-' + indexEditors"
                      >
                        <div
                          v-if="author"
                          class="avatar-wrapper"
                        >
                          <avatar
                            :alt="[author.firstname, author.lastname].join(' ')"
                            :avatar="author.avatar"
                            :size="indexEditors === campaign.campaign_editors.length -1 ? 38 : 26"
                            :title="[author.firstname, author.lastname].join(' ')"
                            :tooltip="true"
                            :user-id="author.uuid"
                          />
                        </div>
                      </template>
                    </div>
                  </td>
                  <td>
                    <campaign-row
                      :key="`${campaign.uuid}-${campaign.creative_count}`"
                      :campaign="campaign"
                      :prevent-click="false"
                      :creative-count="campaign.creative_count"
                    />
                  </td>
                  <td>
                    <span
                      v-tippy="{allowHTML:true}"
                      :content="convertLastDate(campaign.last_edit)"
                    >
                      {{ Utils.timeAgoFormatter(campaign.last_edit) }}
                    </span>
                  </td>
                  <td>
                    <div class="avatars-stacked">
                      <template v-for="(author, authorIndex) in campaign.editors">
                        <div
                          v-if="author"
                          :key="'author-' + authorIndex"
                          class="avatar-wrapper"
                        >
                          <avatar
                            :alt="[author.firstname, author.lastname].join(' ')"
                            :avatar="author.avatar"
                            :size="authorIndex === campaign.editors.length - 1 ? 38 : 26"
                            :title="[author.firstname, author.lastname].join(' ')"
                            :tooltip="true"
                            :user-id="author.uuid"
                          />
                        </div>
                      </template>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="4"
                    style="padding:5px 0"
                  >
                    <div class="line-through"></div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="show-more-container">
            <router-link
              class="btn btn-default"
              to="/campaigns"
            >
              Show more
            </router-link>
          </div>
        </template>
        <empty-section
          v-if="campaigns.length === 0"
          :icon="require('../../../../images/dashboard/designer/campaigns-empty-state.svg')"
        >
          Are u ready to create an awesome campaign?
        </empty-section>
      </card-body>
    </card>
  </div>
</template>
<script>
import Utils from "../../../utils"
import EmptySection from "../components/EmptySection"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import utc from "dayjs/plugin/utc"

export default {
  name: "RecentCampaigns",
  components: {
    EmptySection
  },
  data () {
    return {
      campaigns: []
    }
  },
  computed: {
    Utils () {
      return Utils
    }
  },
  mounted () {
    this.loadCampaigns()
  },
  beforeUnmount () {
    window.removeEventListener("intercomTourChanged", this.loadCampaigns)
    this.$eventHub.$off("new-campaign-added", this.loadCampaigns)
  },
  created () {
    window.addEventListener("intercomTourChanged", this.loadCampaigns)
    this.$eventHub.$on("new-campaign-added", this.loadCampaigns)
  },
  methods: {
    loadCampaigns () {
      this.$store.dispatch("loadRecentCampaigns", {performCommit: false}).then((res) => {
        this.campaigns = res.data.data
      })
    },
    convertLastDate (date) {
      dayjs.extend(customParseFormat)
      dayjs.extend(utc)

      date = dayjs.utc(date, "YYYY-MM-DD hh:mm:ss")
      const newDate = dayjs(date.$d).format("DD MMM YYYY")
      const time = dayjs(date.$d).format("hh:mm A")

      return `
        <span class="tippy-date">${newDate}</span>
        <span class="tippy-time">${time}</span>
      `
    }
  }
}
</script>
