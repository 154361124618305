<template>
  <div class="slidecontainer">
    <span
      :key="'fill-overlay' + localValue"
      class="fill-overlay"
      :style="sliderStyles"
    ></span>
    <input
      :id="id"
      ref="slider"
      class="form-control formslider"
      :disabled="disabled"
      :max="max"
      :min="min"
      :name="name"
      :step="step"
      type="range"
      :value="localValue !== null ? localValue : value"
      @change="onChange"
      @input="onInput"
      @keydown.enter.prevent
    />
    <slot name="helpers"></slot>
  </div>
  <div
    v-if="showValue"
    class="container"
  >
    <div class="d-flex justify-content-center align-items-center ">
      <span>{{ valueToDisplay }}</span>
    </div>
  </div>
</template>

<script>
import Utils from "../../../utils"
import throttle from "lodash/throttle"

export default {
  name: "BaseSlider",
  props: {
    id: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10
    },
    value: {
      type: Number,
      default: 5
    },
    shouldWatch: {
      type: Boolean,
      default: false
    },
    shouldSnap: {
      type: Boolean,
      default: true
    },
    showValue: {
      type: Boolean,
      default: false
    },
    customValueToDisplay: {
      type: [Number, String],
      default: null
    }
  },
  emits: ["change", "input", "update:input", "update:value"],
  data () {
    return {
      sliderStyles: null,
      localValue: null,
      unwatch: null
    }
  },
  computed: {
    valueToDisplay () {
      if (this.customValueToDisplay !== null) {
        return this.customValueToDisplay
      }

      return this.localValue !== null ? this.localValue : this.value
    }
  },
  mounted () {
    this.localValue = this.value
    this.sliderStyles = Utils.getRangeSliderWidthCss(Number(this.value), Number(this.max), Number(this.min))
    if (this.shouldWatch) {
      this.unwatch = this.$watch("value", (newVal) => {
        this.throttleUpdateSliderStyles(newVal)
      })
    }
  },
  beforeUnmount () {
    if (this.unwatch) {
      this.unwatch()
    }
  },
  methods: {
    throttleUpdateSliderStyles: throttle(function (newVal) {
      newVal = Utils.clamp(this.min, newVal, this.max)
      this.localValue = newVal
      this.updateSliderStyles(this.$refs.slider, newVal)
    }, 100),
    updateSliderStyles (slider, newVal) {
      if (slider) {
        this.sliderStyles = Utils.getRangeSliderWidthCss(Number(typeof newVal !== "undefined" ? newVal : slider.value), Number(slider.max), Number(slider.min))
      }
    },
    onChange (event) {
      let value = parseFloat(event.target.value)
      this.localValue = null
      this.updateSliderStyles(event.target)

      const center = (this.min + this.max) / 2
      const threshold = 0.06
      const snappingThreshold = Math.abs(1 - center / value)
      if (this.shouldSnap && snappingThreshold < threshold) {
        value = center
        this.$emit("update:value", center)
        this.updateSliderStyles(event.target, center)
      }

      this.$emit("change", value)
    },
    onInput (event) {
      this.localValue = parseFloat(event.target.value)
      this.updateSliderStyles(event.target)
      this.$emit("input", parseFloat(event.target.value))
      this.$emit("update:value", parseFloat(event.target.value))
    }
  }
}
</script>
