import Dashboard from "../views/Dashboard"
import Campaign from "../views/Campaign"
import Users from "../views/admin/Users"
import Analytics from "../views/campaign/Analytics"
import Preferences from "../views/Preferences"
import EditUser from "../views/user/Edit"
import Login from "../components/authorization/Login"

const TITLE_PREFIX = "adtron®"

export default [
  {
    path: "/",
    redirect: { name: "home" }
  },
  {
    path: "/dashboard",
    name: "home",
    component: Dashboard,
    meta: {
      title: `${TITLE_PREFIX} - Dashboard`
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: `${TITLE_PREFIX} - Login`
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../components/authorization/Logout"),
    meta: {
      title: `${TITLE_PREFIX}`
    }
  },
  {
    path: "/password/reset/:token",
    name: "password-reset",
    component: () => import("../components/authorization/PasswordReset"),
    meta: {
      title: `${TITLE_PREFIX} - Reset Password`
    }
  },
  {
    path: "/password/recovery",
    name: "password-recovery",
    component: () => import("../components/authorization/PasswordRecovery"),
    meta: {
      title: `${TITLE_PREFIX} - Recover Password`
    }
  },
  {
    path: "/invites/:code",
    name: "invitation",
    props: true,
    component: () => import("../components/authorization/Invitation"),
    meta: {
      title: `${TITLE_PREFIX} - Accept invitation`
    }
  },
  {
    path: "/campaigns",
    name: "campaign",
    component: Campaign,
    meta: {
      title: `${TITLE_PREFIX} - Campaign`
    }
  },
  {
    path: "/admin/users",
    name: "admin.user",
    component: Users,
    meta: {
      title: `${TITLE_PREFIX} - Users`
    }
  },
  {
    path: "/user/edit",
    name: "user.edit",
    component: EditUser,
    meta: {
      title: `${TITLE_PREFIX} - User`
    }
  },
  {
    path: "/preferences",
    name: "preferences",
    component: Preferences,
    meta: {
      title: `${TITLE_PREFIX} - Preferences`
    }
  },
  {
    path: "/company",
    name: "company",
    component: () => import("../views/Company"),
    meta: {
      title: `${TITLE_PREFIX} - Company`
    }
  },
  {
    path: "/campaign/latest/analytics",
    alias: "/analytics",
    name: "campaign.analytics_latest",
    component: Analytics,
    meta: {
      title: `${TITLE_PREFIX} - Analytics`
    }
  },
  {
    path: "/campaign/:campaignUniqueId/analytics",
    name: "campaign.analytics",
    component: Analytics,
    props: true,
    meta: {
      title: `${TITLE_PREFIX} - Analytics`
    }
  },
  {
    path: "/adtags/:campaignUniqueId",
    name: "campaign.adtags",
    component: () => import("../views/campaign/AdTags"),
    props: true,
    meta: {
      title: `${TITLE_PREFIX} - AdTags`
    }
  },
  {
    path: "/campaign/:campaignUniqueId/details",
    name: "campaign.details",
    component: Campaign,
    props: true,
    meta: {
      title: `${TITLE_PREFIX} - Campaign`
    }
  },
  {
    path: "/designs/:designUniqueId/edit",
    name: "designs.edit",
    component: () => import("../views/designs/Edit"),
    props: true,
    meta: {
      title: `${TITLE_PREFIX} - Designer`
    }
  },
  {
    path: "/showroom/:designUniqueId",
    name: "showroom",
    component: () => import("../views/Showroom"),
    props: true,
    meta: {
      title: `${TITLE_PREFIX} - Showroom`
    }
  },
  {
    path: "/billing",
    name: "billing",
    component: () => import("../views/billing/Dashboard"),
    props: true,
    meta: {
      title: `${TITLE_PREFIX} - Billing`
    }
  },
  {
    path: "/tour",
    name: "tour-entrypoint",
    component: () => import("../views/Tour")
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/NotFound"),
    meta: {
      title: `${TITLE_PREFIX} - Not Found`
    }
  }
]
