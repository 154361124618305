<template>
  <div class="tag-list">
    <slot />
  </div>
</template>
<script>
export default {
  name: "TagList"
}
</script>
