<template>
  <div class="designer-dashboard">
    <recent-creatives />
    <div class="row">
      <div class="col-12 col-lg-9">
        <recent-campaigns />
      </div>
      <div class="col-12 col-lg-3">
        <latest-comments />
      </div>
    </div>
  </div>
</template>

<script>
import LatestComments from "./sections/LatestComments"
import RecentCampaigns from "./sections/RecentCampaigns"
import RecentCreatives from "./sections/RecentCreatives"

export default {
  name: "DesignerDashboard",
  components: {
    RecentCreatives,
    RecentCampaigns,
    LatestComments
  }
}
</script>
