<template>
  <div class="table-wrapper">
    <table class="table advanced-table">
      <template
        v-for="(item, index) in data"
        :key="`head-${item.uuid || index}-${item._active}`"
      >
        <thead
          v-if="index === 0"
          :class="{'no-border':noBorder}"
        >
          <slot name="columns">
            <tr>
              <th
                v-for="(column, indexCol) in columns"
                :key="`col-${column.uuid || indexCol}`"
                class="sortable"
                :style="column.width ? 'width:' + column.width : ''"
              >
                {{ getTitle(column) }}
              </th>
            </tr>
          </slot>
        </thead>
        <tbody v-if="!noBorder">
          <tr>
            <td
              colspan="5"
              style="padding:5px 0"
            >
              <div class="line-through"></div>
            </td>
          </tr>
        </tbody>
        <tbody :class="{active: item._active, hasHover: enableHoverEffect}">
          <tr
            class="row-content"
            @click="rowClicked($event, index)"
          >
            <slot :row="item">
              <td
                v-for="(column, itemIndex) in columns"
                :key="`item-col-${itemIndex}`"
                class="col-content"
              >
                {{ getValue(item, column) }}
              </td>
            </slot>
          </tr>
        </tbody>
        <slot
          name="after"
          :row="item"
        >
        </slot>
      </template>
    </table>
    <div
      v-if="currentPage < lastPage && showLoadMoreButton"
      class="table-footer"
    >
      <secondary-button @click="$emit('load-next-page')">
        <span>Load more</span>
      </secondary-button>
    </div>
  </div>
</template>
<script>

export default {
  name: "AdvancedTable",
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    showLoadMoreButton: {
      type: Boolean,
      default: true
    },
    enableHoverEffect: {
      type: Boolean,
      default: true
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Number,
      default: 1
    },
    lastPage: {
      type: Number,
      default: 1
    }
  },
  emits: ["load-next-page", "click"],
  methods: {
    getName (column) {
      return column?.name || column
    },
    getTitle (column) {
      return column?.title || column
    },
    getValue (item, column) {
      column = this.getName(column)
      if (typeof item[column.toLowerCase()] !== "undefined") {
        return item[column.toLowerCase()]
      }
      return null
    },
    rowClicked ($event, index) {
      if ($event) {
        $event.selectedRow = this.data[index]
        this.$emit("click", $event, this.data[index])
      }
    }
  }
}
</script>
