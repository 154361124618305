import { useStore } from "@/composables/useStore"
import { DOMAIN_WAGAWIN } from "@/constants"
import Utils from "@/utils"

const store = useStore()

const DomainDetection = (to, from, next) => {
  const mockWagawinParameter = Utils.getUrlParameters(location.search).mockWagawin
  const shouldMockWagawin = Number(mockWagawinParameter) === 1

  if (location.hostname.match(/wagawin\.(io|local)/i) || shouldMockWagawin) {
    store.commit("setAccessedDomain", DOMAIN_WAGAWIN)

    document.body.classList.add("theme--wagawin")
    document.body.classList.remove("theme--dark")

    if (to.name !== "showroom" && to.path !== "/404") {
      return next({path: "/404"})
    }
  } else {
    document.body.classList.remove("theme--wagawin")
    document.body.classList.add("theme--dark")
  }

  next()
}

export default DomainDetection
