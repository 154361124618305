<template>
  <div
    class="controls-checkbox"
    :class="{'has-label': label.length > 0}"
  >
    <label :for="id ? id : uuid">
      <input
        :id="id ? id : uuid"
        :checked="value"
        :disabled="disabled"
        :false-value="false"
        :true-value="true"
        type="checkbox"
        :value="value"
        @input="onInput"
      />
      <span class="custom-checkbox">
        <icon
          v-if="value === true || (value===true && disabled)"
          name="marked"
        />
      </span>
      {{ label }}
    </label>
  </div>
</template>
<script>

import { v4 as uuidv4 } from "uuid"

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    }
  },
  emits: ["input", "update:value"],
  created () {
    this.uuid = uuidv4()
  },
  methods: {
    onInput (e) {
      this.$emit("input", e.target.checked, e)
      this.$emit("update:value", e.target.checked, e)
    }
  }
}
</script>
