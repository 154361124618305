<template>
  <div>
    <welcome />
    <manager-dashboard v-if="selectedDashboard.toLowerCase() === 'manager'" />
    <designer-dashboard v-if="selectedDashboard.toLowerCase() === 'designer'" />
  </div>
</template>
<script>
import Welcome from "./sections/Welcome"
import ManagerDashboard from "./ManagerDashboard"
import DesignerDashboard from "./DesignerDashboard"
import { mapState } from "vuex"

export default {
  name: "Dashboard",
  components: {
    Welcome,
    DesignerDashboard,
    ManagerDashboard
  },
  computed: {
    ...mapState(["selectedDashboard"])
  }
}
</script>
