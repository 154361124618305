import { createApp } from "vue"
import App from "./App.vue"

import { registerStore } from "@/store"
import { registerRouter } from "@/router"
import { registerGlobalComponents } from "@/components/_globals"
import { registerGlobalDirectives } from "@/directives/_globals"
import { registerGlobalPlugins } from "@/plugins/plugins"

require("./js/config/intercom")
require("./js/axios")
require("./js/pusher")

const app = createApp(App)

registerStore(app)
registerRouter(app)
registerGlobalDirectives(app)
registerGlobalComponents(app)
registerGlobalPlugins(app)

window.initTitle = document.title
app.mount("#app")

