<template>
  <div class="design-row">
    <div class="creative-info">
      <div
        v-if="showName"
        class="creative-name-row"
      >
        <router-link
          v-if="showLink && !isWagawin"
          class="creative-name"
          :target="linkTarget"
          :to="designEditLink"
          @click="linkClick"
        >
          <tooltip-too-long
            :length="maxNameLength"
            :text="design.name"
            :tooltip-max-width="1000"
          />
        </router-link>
        <a
          v-if="showLink && isWagawin"
          class="creative-name"
          :href="designEditLink"
          @click="linkClick"
        >
          <tooltip-too-long
            :length="maxNameLength"
            :text="design.name"
            :tooltip-max-width="1000"
          />
        </a>
        <span
          v-if="!showLink"
          class="creative-name"
        >
          <tooltip-too-long
            :length="maxNameLength"
            :text="design.name"
            :tooltip-max-width="1000"
          />
        </span>
        <router-link
          v-if="showLinkIcon"
          class="creative-icon-link"
          :target="linkTarget"
          :to="`/designs/${design.unique_id}/edit`"
        >
          <icon name="edit" />
        </router-link>
        <span
          v-if="design.creation_type === getDesignCreationTypeExternal"
          class="creative-external-mark"
        >(external)</span>
      </div>
      <span
        v-if="showId"
        class="creative-id"
      >
        <span>ID:&nbsp;</span>
        <span class="value">{{ design.unique_id }}</span>
      </span>
      <div class="creative-meta">
        <span
          v-if="showCode && code"
          class="creative-code"
        >
          {{ code }}
        </span>
        <span
          v-if="code && showCode"
          class="circle-sep"
        ></span>
        <span
          v-if="showType"
          class="creative-type"
        >
          {{ getUtils.getFriendlyDesignFormat(design.format, design.deviceType, design.ad_product) }}
        </span>
        <span
          v-if="showType && showDimensions"
          class="circle-sep"
        ></span>
        <span
          v-if="showDimensions"
          class="creative-dimensions"
        >
          {{ getUtils.getFriendlyDimensions(design) }}
        </span>
        <span
          v-if="showDimensions && showSize"
          class="circle-sep"
        ></span>
        <span
          v-if="showSize && !isNaN(Number(design.build_size))"
          class="creative-size"
        >
          {{ getUtils.getFriendlySize(design.build_size) }}
        </span>
        <span
          v-if="showSize && showDate"
          class="circle-sep"
        ></span>
        <span
          v-if="showDate"
          v-tippy="{arrow: true}"
          class="creative-date"
          :content="getUtils.parseDate(design.last_edit)"
        >
          {{ getUtils.timeAgoFormatter(design.last_edit) }}
        </span>
      </div>
    </div>
    <div class="action-row">
      <a
        v-if="editAllowed && isDesignActive && design.creation_type === getDesignCreationTypeAdtron"
        class="edit-link input-button"
        :href="editUrl"
        target="_blank"
      >
        <icon name="edit" />
      </a>
      <a
        v-if="showCreateTagButton && editAllowed && isDesignActive"
        class="add-tag input-button"
        href
        @click.prevent.stop="showCreateTagModal"
      >
        <icon name="plus-sign" />
      </a>
      <div
        v-if="showStatus && design.progress"
        class="creative-status"
        style="margin-left:10px"
      >
        <design-status
          :status="design.progress"
          :tooltip="designProgressTooltip"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Utils from "../../../utils"
import DesignStatus from "./DesignStatus"
import {
  AD_FORMATS,
  DESIGN_CREATION_TYPE_ADTRON,
  DESIGN_CREATION_TYPE_EXTERNAL,
  DESIGN_PROGRESS_ERROR, DOMAIN_WAGAWIN,
  PRODUCT_LIVING_ADS
} from "@/constants"
import WagawinUtils from "@/wagawinUtils"
import { mapState } from "vuex"

export default {
  name: "DesignRow",
  components: {
    DesignStatus
  },
  props: {
    design: {
      type: Object,
      required: true
    },
    maxNameLength: {
      type: Number,
      default: 32
    },
    code: {
      type: String,
      default: null
    },
    showLink: {
      type: Boolean,
      default: true
    },
    showLinkIcon: {
      type: Boolean,
      default: false
    },
    linkTarget: {
      type: String,
      default: "_self"
    },
    showId: {
      type: Boolean,
      default: false
    },
    showCode: {
      type: Boolean,
      default: true
    },
    showType: {
      type: Boolean,
      default: true
    },
    showName: {
      type: Boolean,
      default: true
    },
    showDimensions: {
      type: Boolean,
      default: true
    },
    showDate: {
      type: Boolean,
      default: true
    },
    showSize: {
      type: Boolean,
      default: false
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    editAllowed: {
      type: Boolean,
      default: true
    },
    isDesignActive: {
      type: Boolean,
      default: false
    },
    showCreateTagModal: {
      type: Function,
      default: () => {
      }
    },
    showCreateTagButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(["activeDomain"]),
    isWagawin () {
      return this.activeDomain === DOMAIN_WAGAWIN
    },
    designEditLink () {
      if (this.design.ad_product === PRODUCT_LIVING_ADS) {
        return this.isWagawin ? WagawinUtils.replaceWagawinUrlToAdtron(`${window.location.origin}/designs/${this.design.unique_id}/edit?livingAds=${this.design.format}`) : `/designs/${this.design.unique_id}/edit?livingAds=${this.design.format}`
      } else if (this.design.format === AD_FORMATS.vast_interstitial) {
        return this.isWagawin ? WagawinUtils.replaceWagawinUrlToAdtron(`${window.location.origin}/designs/${this.design.unique_id}/edit?vast=${this.design.format}`) : `/designs/${this.design.unique_id}/edit?vast=${this.design.format}`
      } else {
        return this.isWagawin ? WagawinUtils.replaceWagawinUrlToAdtron(`${window.location.origin}/designs/${this.design.unique_id}/edit`) : `/designs/${this.design.unique_id}/edit`
      }
    },
    designProgressTooltip () {
      if (this.design.progress === DESIGN_PROGRESS_ERROR) {
        return "The upload to CDN process has failed.<br>Retrying in 5 minutes."
      }
      return ""
    },
    getDesignCreationTypeAdtron () {
      return DESIGN_CREATION_TYPE_ADTRON
    },
    getDesignCreationTypeExternal () {
      return DESIGN_CREATION_TYPE_EXTERNAL
    },
    getUtils () {
      return Utils
    },
    editUrl () {
      if (this.design.ad_product === PRODUCT_LIVING_ADS) {
        return `/designs/${this.design.unique_id}/edit?livingAds=${this.design.format}`
      } else if (this.design.format === AD_FORMATS.vast_interstitial) {
        return `/designs/${this.design.unique_id}/edit?vast=${this.design.format}`
      }

      return `/designs/${this.design.unique_id}/edit`
    }
  },
  methods: {
    linkClick () {
      if (this.linkTarget === "_blank") {
        window.open(this.designEditLink)
      } else {
        window.location.href = this.designEditLink
      }
    }
  }
}
</script>
