<template>
  <div class="form-group">
    <label :for="id">
      <span v-if="label">{{ label }}</span>
      <span
        v-if="label && required"
        class="required"
      >*</span>
    </label>
    <date-picker
      :id="id"
      :clearable="true"
      :confirm="true"
      :first-day-of-week="1"
      confirm-text="Apply"
      :lang="lang"
      :time-picker-options="timePickerOptions"
      :type="time ? 'datetime' : 'date'"
      :value="form[id]"
      @change="updateForm"
    />
  </div>
</template>
<script>
import DatePicker from "vue-datepicker-next"
import "vue-datepicker-next/index.css"

export default {
  name: "DateField",
  components: { DatePicker },
  props: {
    id: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: [String, Boolean],
      default: false
    },
    time: {
      type: [Boolean],
      default: false
    },
    form: {
      type: [Object],
      default: () => { return new FormData() }
    }
  },
  emits: ["update-form"],
  data () {
    return {
      timePickerOptions: {
        start: "00:00",
        step: "00:15",
        end: "23:45"
      },
      lang: {
        days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        pickers: ["next 7 days", "next 30 days", "previous 7 days", "previous 30 days"],
        placeholder: {
          date: "Select Date",
          dateRange: "Select Date Range"
        }
      }
    }
  },
  methods: {
    updateForm (val) {
      this.$emit("update-form", { id: this.id, val })
    }
  }
}
</script>
