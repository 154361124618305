import debounce from "lodash/debounce"

if (process.env.VUE_APP_INTERCOM_APP_ID) {
  if (window.sessionStorage && window.sessionStorage.getItem("isRunningIntercomTour")) {
    window.isRunningIntercomTour = true
    window.dispatchEvent(new Event("intercomTourChanged"))
  }
  if (typeof window.isRunningIntercomTour === "undefined") {
    window.isRunningIntercomTour = false
  }
  const callback = function (mutationsList) {
    const hasIntercomTree = node => node.classList && node.classList.contains("intercom-namespace") && node.id === "intercom-positioner-tree"
    mutationsList.forEach(debounce(function (mutation) {
      if (mutation.type === "childList") {
        mutation.removedNodes.forEach((node) => {
          if (hasIntercomTree(node) && window.isRunningIntercomTour !== false) {
            window.isRunningIntercomTour = false
            if (window.sessionStorage) {
              window.sessionStorage.removeItem("isRunningIntercomTour")
            }
            document.body.classList.remove("running-intercom-tour")
            window.dispatchEvent(new Event("intercomTourChanged"))
          }
        })
        mutation.addedNodes.forEach((node) => {
          if (hasIntercomTree(node) && window.isRunningIntercomTour !== true) {
            window.isRunningIntercomTour = true
            document.body.classList.add("running-intercom-tour")
            window.dispatchEvent(new Event("intercomTourChanged"))
          }
        })
      }
    }, 500))
  }

  window.intercomTourObserver = new MutationObserver(callback)
  window.intercomTourObserver.observe(document.body, { childList: true })
}
