<template>
  <base-modal
    ref="modal"
    :image="require('../../../images/modal/invite-user-infographics.svg')"
    :show-close-button="true"
    title="Edit User"
    @submit="submit"
  >
    <template #content>
      <admin-user-edit
        ref="edit-user-form"
        :show-button="false"
        :submitted="modal.close"
      />
    </template>
    <template #actions>
      <base-button
        size="xl"
        @click.prevent="modal.close"
      >
        Cancel
      </base-button>
      <primary-button
        size="xl"
        @click.prevent="submit"
      >
        Save
      </primary-button>
    </template>
  </base-modal>
</template>
<script>
import AdminUserEdit from "../admin/user/Edit"

export default {
  name: "EditUserModal",
  components: {
    AdminUserEdit
  },
  data () {
    return {
      modal: {
        close: () => {}
      }
    }
  },
  mounted () {
    this.modal = this.$refs.modal
  },
  methods: {
    submit () {
      this.$refs["edit-user-form"].onSubmit()
    }
  }
}
</script>
