<template>
  <popup class="search-popup groups-popup">
    <card>
      <card-body>
        <h3>Filter matching "{{ query }}"</h3>
        <div class="search-result ">
          <div
            v-for="role in matchingRoles"
            :key="'role_' + role.value"
          >
            <a
              class="search-tag roles"
              href="#"
              @click.prevent.stop="selectFilter(role.name, role.value, 'role')"
            >
              <span
                class="user-badge"
                :class="selectedFilters.role.filter(row => row.value === role.value).length ? 'active' : ''"
              >{{ role.name }}</span>
            </a>
          </div>
          <hr v-if="matchingGroups.length > 0">
          <div
            v-for="group in matchingGroups"
            :key="'group_search_' + group.uuid"
            @click.prevent.stop="selectFilter(group.name, group, 'group')"
          >
            <a
              class="search-tag"
              href="#"
            >
              <span :class="selectedFilters.group.filter(row => row.value.uuid === group.uuid).length ? 'active' : ''">
                <div class="group-stack">
                  <div class="avatars-stacked">
                    <template v-for="(author, index) in group.users.slice(0,5)">
                      <div
                        v-if="author"
                        :key="'author-' + index"
                        class="avatar-wrapper"
                      >
                        <avatar
                          :alt="[author.firstname, author.lastname].join(' ')"
                          :avatar="author.avatar"
                          :size="26"
                          :title="[author.firstname, author.lastname].join(' ')"
                          :tooltip="true"
                          :user-id="author.uuid"
                        />
                      </div>
                    </template>
                    <div
                      v-if="group.users.length > 4"
                      class="avatar-wrapper"
                    >
                      <span
                        v-tippy="{animateFill: false}"
                        class="avatar avatar-more"
                        :content="getMoreAuthorsTooltip(group.users)"
                        style="width: 26px; height: 26px;"
                      >
                        <span class="users-more">
                          +{{ group.users.length - 4 }}
                        </span>
                      </span>
                    </div>
                    <span class="group-name">{{ group.name }}</span>
                  </div>
                </div>
              </span>
            </a>
          </div>
        </div>
      </card-body>
    </card>
  </popup>
</template>

<script>
import Utils from "../../../../utils"
import { mapState } from "vuex"

export default {
  props: {
    query: {
      type: String,
      default: ""
    }
  },
  computed: {
    matchingGroups () {
      return this.groups.filter(e => e.name.match(new RegExp("(.*?)" + this.query + "(.*?)", "i")))
    },
    matchingRoles () {
      return this.availableRoles.filter(role => role.name.match(new RegExp("(.*?)" + this.query + "(.*?)", "i")))
    },
    Utils () {
      return Utils
    },
    ...mapState(["selectedFilters", "availableRoles", "groups"])
  },
  methods: {
    selectFilter (name, value, type) {
      const deleteFilter = type === "role" ? this.selectedFilters.role.find((row) => row.value === value) : this.selectedFilters.group.find((row) => row.value.uuid === value.uuid)
      if (deleteFilter) {
        this.$store.commit("deleteFilter", { type, value })
      } else {
        this.$store.commit("selectFilter", { name, type, value })
      }
    },
    getMoreAuthorsTooltip (users) {
      let text = ""
      users.forEach((row) => {
        text += `<p>${row.name}</p>`
      })

      return `<div class="group-users-tooltip">${text}</div>`
    }
  }
}
</script>
