export class Routes {
  // DELETE
  static deleteTemplate = (templateUuid) => `/api/template/${templateUuid}`

  // GET
  static getTemplates = () => "/api/template"
  static getTemplate = (templateUuid) => `/api/template/${templateUuid}`

  // POST
  static createTemplate = () => "/api/template"
  static createTemplateBasedOnEditor = () => "/api/template/create/preset"
}
