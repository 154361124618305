<template>
  <base-modal
    ref="modal"
    class="modal-auto-height delete-modal text-left"
    :show-close-button="false"
    size="extra-small"
    :title="title"
    @submit="rejectDesign"
  >
    <template #content>
      <p>{{ description }}</p>
    </template>
    <template #actions>
      <base-button
        :min-width="94"
        size="m"
        @click.prevent="modal.close"
      >
        {{ cancelButton }}
      </base-button>
      <danger-button
        :min-width="114"
        size="m"
        @click.prevent="rejectDesign"
      >
        {{ actionButton }}
      </danger-button>
    </template>
  </base-modal>
</template>

<script>
import Language from "../../../i18n/en"
import DangerButton from "../controls/DangerButton"

export default {
  components: {
    DangerButton
  },
  props: {
    title: {
      type: String,
      default: "Reject Creative"
    },
    description: {
      type: String,
      default: "Are you sure that you want to reject this Creative? "
    },
    cancelButton: {
      type: String,
      default: "Cancel"
    },
    actionButton: {
      type: String,
      default: "Reject"
    },
    endpoint: {
      type: String,
      default: null
    },
    action: {
      type: Function,
      default: null,
      validator: (prop) => prop !== null
    },
    successMessage: {
      type: String,
      default: Language.showroom.success.reject
    },
    errorMessage: {
      type: String,
      default: Language.showroom.error.reject
    },
    afterApproval: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      }
    }
  },
  mounted () {
    this.modal = this.$refs.modal
  },
  methods: {
    rejectDesign () {
      if (this.action) {
        return this.action().then(this.onSuccess)
      } else if (this.endpoint === null) {
        return this.onSuccess()
      } else {
        return this.$apiClient.post(this.endpoint)
          .then(this.onSuccess)
          .catch(() => {
            if (this.errorMessage && this.errorMessage.length) {
              this.$toasted.error(this.errorMessage)
            }
          })
      }
    },
    onSuccess () {
      this.modal.close()
      if (this.successMessage && this.successMessage.length) {
        this.$toasted.success(this.successMessage)
      }
      this.afterApproval()
    }
  }
}
</script>
