<template>
  <div class="data-legend">
    <div class="data-legend__name">
      <div
        class="box"
        :style="`background-color: ${color}`"
      ></div>
      {{ title }}
    </div>
    <div class="data-legend__value">
      <span class="value">{{ getFormattedImpressions(value) }}</span>
      <span
        v-if="change !== null && change >= 0"
        class="change"
      >
        <icon name="arrow-green" />
        {{ Math.round(change) }}%
      </span>
      <span
        v-if="change !== null && change < 0"
        class="change"
      >
        <icon name="arrow-red" />
        {{ Math.round(change) }}%
      </span>
    </div>
    <div
      v-if="target !== null"
      class="data-legend__target"
    >
      <span class="value">{{ getFriendlyValue(target) }}</span>
      <span class="subtext">Total Target</span>
    </div>
    <div
      v-if="cpm !== null"
      class="data-legend__cpm"
    >
      <span class="value">{{ cpm }}</span>
      <span class="subtext">CPM</span>
    </div>
  </div>
</template>
<script>

export default {
  name: "DataLegend",
  props: {
    color: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    target: {
      type: Number,
      default: null
    },
    cpm: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: null
    },
    change: {
      type: Number,
      default: null
    }
  },
  methods: {
    getFriendlyValue (value) {
      if (value / 1000000 > 1) {
        return (value / 1000000).toFixed(1) + "M"
      } else if (value / 1000 > 1) {
        return (value / 1000).toFixed(1) + "K"
      }

      return value
    },
    getFormattedImpressions (value) {
      return new Intl.NumberFormat("de-DE").format(value)
    }
  }
}
</script>
