<template>
  <div class="tag">
    <slot />
  </div>
</template>
<script>
export default {
  name: "Tag"
}
</script>
