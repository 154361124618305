<template>
  <section class="recent-creatives">
    <h1>Recent Creatives</h1>
    <section
      v-if="designs"
      class="recent-creatives-list"
    >
      <template v-if="designs.length">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="(design, index) in designs"
              :key="'recent-creatives-design-box-swiper' + index"
              class="swiper-slide"
              :style="slideStyles"
            >
              <design-box
                :auto-wrap="true"
                :design="design"
                :name-max-length="20"
              />
            </div>
          </div>
        </div>
        <div class="swiper-button-prev swiper-button-disabled">
          <icon name="slide-arrow-left" />
        </div>
        <div class="swiper-button-next swiper-button-disabled">
          <icon name="slide-arrow" />
        </div>
      </template>
      <empty-section
        v-if="designs.length === 0"
        :icon="require('../../../../images/dashboard/designer/creatives-empty-state.svg')"
      >
        There are no creatives yet,
        lets start building some kick-ass ads
      </empty-section>
    </section>
  </section>
</template>
<script>
import Swiper from "swiper/bundle"
import EmptySection from "../components/EmptySection"
import DesignBox from "../../common/design/DesignBox"
import { CompanyRoutes } from "../../../api/routes"

export default {
  name: "RecentCreatives",
  components: {
    EmptySection,
    DesignBox
  },
  data () {
    return {
      swiper: null,
      designs: []
    }
  },
  computed: {
    slideStyles () {
      return {
        width: "180px !important"
      }
    }
  },
  mounted () {
    this.updateDesigns()
  },
  created () {
    window.addEventListener("intercomTourChanged", this.updateDesigns)
  },
  beforeUnmount () {
    window.removeEventListener("intercomTourChanged", this.updateDesigns)
  },
  methods: {
    initSwiper () {
      this.swiper = new Swiper(".recent-creatives-list .swiper-container", {
        breakpointsBase: "container",
        spaceBetween: 20,
        speed: 400,
        slidesPerView: "auto",
        allowTouchMove: false,
        preventClicks: false,
        preventClicksPropagation: false,
        centeredSlides: false,
        on: {
          slideChange: this.updateSlidesVisibility,
          resize: this.updateSlidesVisibility,
          afterInit: () => {
            this.$nextTick(() => {
              this.updateSlidesVisibility()
            })
          }
        }, // Add this line
        navigation: {
          nextEl: ".recent-creatives-list .swiper-button-next",
          prevEl: ".recent-creatives-list .swiper-button-prev"
        }
      })
    },
    updateSlidesVisibility () {
      if (!this.swiper) return

      const { width, realIndex, slides } = this.swiper

      const cardWidth = 200
      const fullyVisibleSlidesPerView = Math.floor(width / cardWidth)

      slides.forEach((slide, idx) => {
        slide.style.opacity = this.isSlideFullyVisible(realIndex, fullyVisibleSlidesPerView, idx) ? "1" : "0"
      })
    },
    isSlideFullyVisible (realIndex, fullyVisibleSlidesPerView, slideIndex) {
      return (realIndex + fullyVisibleSlidesPerView) > slideIndex
    },
    updateDesigns () {
      return this.$apiClient.get(CompanyRoutes.getRecentCreatives(), { params: { showcase: window.isRunningIntercomTour } }).then(res => {
        this.designs = res.data.data
        this.$nextTick(() => {
          this.initSwiper()
        })
      })
    }
  }
}
</script>
