<template>
  <div
    class="controls-radio"
    :class="{'has-label': label.length > 0}"
  >
    <label :for="uuid"><input
                         :id="uuid"
                         :checked="value && disabled === false"
                         :disabled="disabled"
                         :false-value="false"
                         :true-value="true"
                         type="checkbox"
                         :value="value"
                         @change="e => $emit('input', e.target.checked, e)"
                         @input="e => $emit('input', e.target.checked, e)"
                       />
      <span class="custom-radio">
      </span>
      {{ label }}
    </label>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid"

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["input"],
  created () {
    this.uuid = uuidv4()
  }
}
</script>
