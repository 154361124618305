<template>
  <div class="rich-switch rounded-full">
    <a
      :key="'first-opt' + firstOption + selectedOption"
      :class="`rounded-full rich-switch__picker ${selectedOption.toLowerCase() === firstOption.toLowerCase() ? 'rich-switch__picker--active' : ''}`"
      href="#"
      @click.prevent="toggleOption(firstOption)"
    >
      <icon
        v-if="firstOptionIcon"
        :name="firstOptionIcon"
      />
      {{ firstOption }}
    </a>
    <a
      :key="'second-opt' + secondOption + selectedOption"
      :class="`rounded-full rich-switch__picker ${selectedOption.toLowerCase() === secondOption.toLowerCase() ? 'rich-switch__picker--active' : ''}`"
      href="#"
      @click.prevent="toggleOption(secondOption)"
    >
      <icon
        v-if="secondOptionIcon"
        :name="secondOptionIcon"
      />
      {{ secondOption }}
    </a>
  </div>
</template>
<script>
export default {
  props: {
    firstOption: {
      type: String,
      default: "First"
    },
    secondOption: {
      type: String,
      default: "Second"
    },
    selectedOption: {
      type: String,
      default: "First"
    },
    firstOptionIcon: {
      type: String,
      default: ""
    },
    secondOptionIcon: {
      type: String,
      default: ""
    }
  },
  emits: ["switch"],
  methods: {
    toggleOption (option) {
      this.$emit("switch", option)
    }
  }
}
</script>
