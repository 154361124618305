<template>
  <div class="analytics--creative-list">
    <advanced-table
      class="border-rounded"
      :columns="['Creative', 'Creative Type', 'Dimensions', '']"
      :current-page="currentPage"
      :data="designs"
      :init-page-size="5"
      :last-page="lastPage"
      @click.stop="selectDesign"
      @load-next-page="$emit('load-next-page')"
    >
      <template #default="{row}">
        <td
          class="creative-thumbnail horizontal"
          :class="getRowClasses(row)"
        >
          <div class="analytics--design-row-cell">
            <img
              v-if="previews[row.uuid] != null"
              :alt="row.name + ' thumbnail'"
              :src="previews[row.uuid]"
              @error="missingThumbnail(row.id)"
            >
            <img
              v-else
              alt="Fallback preview"
              class="missing-thumbnail"
              :src="require('../../../../images/design/design-fallback.svg')"
            >
            <div class="analytics--design-row">
              <design-row
                :design="row"
                :show-date="false"
                :show-details="false"
                :show-dimensions="false"
                :show-id="true"
                :show-link="row.creation_type === DESIGN_CREATION_TYPE_ADTRON && !row.archived"
                :show-size="false"
                :show-type="false"
              />
              <div
                v-if="row.adtags.length > 1"
                class="tracking-count"
                @click.stop="toggleTagList(toggledList === row.uuid ? null : row.uuid)"
              >
                {{ row.adtags.length - 1 }} Tracking Identifiers
                <icon
                  v-if="toggledList === row.uuid"
                  name="arrow_up"
                />
                <icon
                  v-else
                  name="arrow_down"
                />
              </div>
              <div
                v-if="row.adtags.length > 1 && toggledList === row.uuid"
                class="tags-list"
              >
                <a
                  v-for="(tag, idx) in row.adtags.slice(1)"
                  :key="`tag-${idx}`"
                  :class="selectedTag && tag.id === selectedTag.id ? 'active' : ''"
                  href
                  @click.prevent.stop="$emit('select-design-tag', row, tag)"
                >
                  <span>
                    {{ tag.identifier ? tag.identifier : `Ad Tag #${idx + 1}` }}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </td>
        <td
          :class="getRowClasses(row)"
          style="vertical-align: top; padding-top: 30px;"
        >
          {{ Utils.getFriendlyDesignFormat(row.format, row.deviceType, row.ad_product) }}
        </td>
        <td
          :class="getRowClasses(row)"
          style="vertical-align: top; padding-top: 30px;"
        >
          {{ Utils.getFriendlyDimensions(row) }}
        </td>
        <td
          :class="getRowClasses(row)"
          style="vertical-align: top; padding-top: 22px;"
        >
          <div class="d-flex align-items-center">
            <a
              v-if="ACTIVE_DESIGN_STATUSES.includes(row.progress)"
              class="download-button mr-1"
              :href="getPublishHref(row)"
            >
              <icon
                name="publish"
                tooltip="Go to Adtag"
              />
            </a>
            <a
              class="download-button"
              @click="downloadCsv(campaign, row.uuid, startDate, endDate)"
            >
              <icon
                name="download"
                tooltip="Download"
              />
            </a>
          </div>
        </td>
      </template>
    </advanced-table>
  </div>
</template>
<script>
import Utils from "../../../utils"
import { DESIGN_CREATION_TYPE_ADTRON, PRODUCT_DOOH, ACTIVE_DESIGN_STATUSES } from "@/constants"
import { AnalyticsRoutes } from "../../../api/routes"

export default {
  props: {
    selectedDesign: {
      type: Object,
      default: () => {
      }
    },
    selectedTag: {
      type: Object,
      default: () => {
      }
    },
    designs: {
      type: Array,
      default: () => []
    },
    campaign: {
      type: Object,
      required: true
    },
    startDate: {
      type: String,
      default: null
    },
    endDate: {
      type: String,
      default: null
    },
    currentPage: {
      type: Number,
      default: 1
    },
    lastPage: {
      type: Number,
      default: 1
    }
  },
  emits: ["select-design-tag", "load-next-page"],
  data () {
    return {
      toggledList: null,
      previews: {}
    }
  },
  computed: {
    DESIGN_CREATION_TYPE_ADTRON () {
      return DESIGN_CREATION_TYPE_ADTRON
    },
    Utils () {
      return Utils
    },
    ACTIVE_DESIGN_STATUSES () {
      return ACTIVE_DESIGN_STATUSES
    }
  },
  created () {
    const params = Utils.getUrlParameters(location.search)
    this.fakeData = (params && params.fake) || window.isRunningIntercomTour === true ? parseInt(params.fake) : 0
    if (this.designs.length) {
      this.updatePreviews()
    }
  },
  beforeUpdate () {
    this.updatePreviews()
  },
  methods: {
    getPublishHref (design) {
      const url = new URL("/adtags/" + this.campaign.unique_id, window.location.origin)
      url.searchParams.set("token", this.campaign.edit_token)
      url.searchParams.set("design", design.unique_id)

      return url.toString()
    },
    selectDesign (e) {
      this.$emit("select-design-tag", e.selectedRow, null)
    },
    downloadCsv (campaign, uuid, startDate, endDate) {
      this.$apiClient.get(AnalyticsRoutes.getCampaignAnalyticsCsv(campaign.uuid), {
        responseType: "arraybuffer",
        params: {
          design: uuid,
          startDate,
          endDate
        }
      })
        .then((response) => {
          Utils.downloadFileFromResponse(response)
          return response
        })
    },
    getRowClasses (design) {
      const {uuid, ad_product: adProduct} = design
      return {
        selected: this.selectedDesign && this.selectedDesign.uuid === uuid && adProduct !== PRODUCT_DOOH,
        archived: adProduct === PRODUCT_DOOH
      }
    },
    toggleTagList (value) {
      this.toggledList = value
    },
    updatePreviews () {
      this.designs.forEach((design) => {
        this.previews[design.uuid] = design.screenshot
      })
    },
    missingThumbnail (designUuid) {
      this.previews[designUuid] = null
    }
  }
}
</script>
