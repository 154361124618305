<template>
  <div class="create-group-modal">
    <base-modal
      ref="modal"
      :image="require('../../../../images/modal/create-group-infographics.svg')"
      :title="title"
      @submit="createGroup"
    >
      <template #content>
        <div class="container">
          <div class="row">
            <div class="col">
              <text-input
                v-model:value="groupName"
                :error="form.errors.errors.name && form.errors.errors.name.length > 0"
                label="Group name"
                placeholder="Group"
                @input="clearErrorsName"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col">
              <rich-input-dropdown
                v-model:value="selectedUsers"
                icon="search"
                label="Add user"
                :max-displayed="null"
                :max-height="240"
                :options="users"
                :placeholder="!selectedUsers.length ? 'Search user' : ''"
                track-by="id"
              >
                <template #option-preview="data">
                  <avatar
                    :alt="data.option.title"
                    :avatar="data.option.value.avatar"
                    :size="22"
                    :title="data.option.title"
                    :tooltip="false"
                    :user-id="data.option.value.uuid"
                  />
                </template>
                <template #option-row="data">
                  <avatar
                    :alt="data.option.title"
                    :avatar="data.option.value.avatar"
                    :size="28"
                    style="margin-right: 15px; margin-left: 10px;"
                    :title="data.option.title"
                    :tooltip="false"
                    :user-id="data.option.value.uuid"
                  />
                  <span>
                    <tooltip-too-long
                      :length="35"
                      :text="`${data.option.value.firstname} ${data.option.value.lastname}`"
                    />
                  </span>
                </template>
              </rich-input-dropdown>
            </div>
          </div>
        </div>
      </template>
      <template #actions>
        <base-button
          size="xl"
          @click.prevent="modal.close"
        >
          {{ cancelButton }}
        </base-button>
        <primary-button
          :disabled="processing"
          size="xl"
          @click="createGroup"
        >
          {{ actionButton }}
        </primary-button>
      </template>
    </base-modal>
  </div>
</template>

<script>
import RichInputDropdown from "../dropdown/RichInputDropdown"
import Language from "../../../i18n/en"
import { Form } from "@/classes/form"
import { GroupRoutes, UserRoutes } from "../../../api/routes"

export default {
  components: {
    RichInputDropdown
  },
  props: {
    title: {
      type: String,
      default: "Create Group"
    },
    success: {
      type: Function,
      default: () => {}
    },
    cancelButton: {
      type: String,
      default: "Cancel"
    },
    actionButton: {
      type: String,
      default: "Create"
    },
    method: {
      type: String,
      default: "post"
    },
    endpoint: {
      type: String,
      default: GroupRoutes.getGroups()
    },
    preselectedUsers: {
      type: Array,
      default: () => []
    },
    preselectedGroupName: {
      type: String,
      default: ""
    },
    successMessage: {
      type: String,
      default: Language.groups.success.create
    },
    errorMessage: {
      type: String,
      default: Language.groups.error.create
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      },
      groupName: "",
      selectedUsers: [],
      availableUsers: [],
      form: new Form({
        name: "",
        users: []
      }),
      processing: false
    }
  },
  computed: {
    users () {
      return this.availableUsers.map((u) => {
        return {
          id: u.uuid,
          value: u,
          title: [u.firstname, u.lastname].join(" ")
        }
      })
    }
  },
  mounted () {
    this.modal = this.$refs.modal

    this.groupName = this.preselectedGroupName
    this.selectedUsers = this.preselectedUsers.map((u) => {
      return {
        id: u.uuid,
        value: u,
        title: [u.firstname, u.lastname].join(" ")
      }
    })
    this.$apiClient.get(UserRoutes.getAvailableUsers()).then(response => {
      this.availableUsers = response.data.data
    })
  },
  methods: {
    clearErrorsName () {
      this.form.errors.errors.name = []
    },
    createGroup () {
      this.processing = true
      this.form.name = this.groupName
      this.form.users = this.selectedUsers.map(u => u.value.id)

      if (this.method === "put") {
        return this.form
          .put(this.endpoint)
          .then(this.onSuccess)
          .catch(() => {
            this.$toasted.error(this.errorMessage)
          })
          .finally(() => {
            this.processing = false
          })
      }

      return this.form
        .post(this.endpoint)
        .then(this.onSuccess)
        .catch(() => {
          this.$toasted.error(this.errorMessage)
        })
        .finally(() => {
          this.processing = false
        })
    },
    onSuccess () {
      this.modal.close()
      this.$store.dispatch("loadGroups")
      this.$toasted.success(this.successMessage)
      this.success()
    }
  }
}
</script>
