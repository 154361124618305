export class Routes {
  // DELETE
  static deleteTag = (tag) => `/api/campaign/tags/${tag}`
  static deleteCampaign = (campaignUuid) => `/api/campaign/${campaignUuid}`

  // GET
  static getCampaignResources = () => "/api/campaign"
  static getPublicCampaignResources = (campaignUuid) => `/api/campaign/${campaignUuid}/public`
  static clearAdCache = (campaignUuid, designUuid) => `/api/campaign/${campaignUuid}/${designUuid}/purge-cache`
  static getSingleCampaignResource = (campaignUuid) => `/api/campaign/${campaignUuid}`
  static getHostedCampaignsResources = () => "/api/campaign/hosted"

  // PATCH
  static updateCampaignPin = (campaignUuid) => `/api/campaign/${campaignUuid}/pin`

  // POST
  static archiveCampaign = (campaignUuid) => `/api/campaign/${campaignUuid}/archive`
  static unarchiveCampaign = (campaignUuid) => `/api/campaign/${campaignUuid}/unarchive`

  static importDesign = (campaignUuid) => `/api/campaign/${campaignUuid}/import-design`
  static importDesignFromAnotherCompany = (campaignUuid, designUuid) => `/api/campaign/${campaignUuid}/duplicate-from-another-company/${designUuid}`
  static importCampaignDesignsFromAnotherCompany = (campaignUuid, clonedCampaignUniqueId) => `/api/campaign/${campaignUuid}/duplicate-campaign-from-another-company/${clonedCampaignUniqueId}`
  static duplicateCampaign = (campaignUuid) => `/api/campaign/${campaignUuid}/duplicate`
  static createCampaign = () => "/api/campaign"

  // PUT
  static updateCampaign = (campaignUuid) => `/api/campaign/${campaignUuid}`
}
