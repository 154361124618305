<template>
  <div
    :class="'analytics--engagement-layer' + (isActive ? ' analytics--engagement-layer__active' : '')"
    @click="$emit('activateLayer')"
  >
    <span
      v-if="isActive"
      class="analytics--engagement-checkmark"
    >
      <icon name="check" />
    </span>
    <div class="analytics--engagement-layer-line">
      <span
        class="analytics--engagement-layer-color"
        :style="{backgroundColor: color}"
      ></span>
      <span class="analytics--engagement-layer-name">
        <tooltip-too-long
          :length="15"
          :text="title"
        />
      </span>
    </div>
    <div class="analytics--engagement-layer-count">
      {{ Number.isInteger(count) ? Utils.parseNumber(count) : count }}
    </div>
  </div>
</template>
<script>
import Utils from "../../../utils"

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "#c8ef07"
    },
    title: {
      type: String,
      required: true
    },
    count: {
      type: [Number, String],
      required: true
    }
  },
  emits: ["activateLayer"],
  computed: {
    Utils () {
      return Utils
    }
  }
}
</script>
