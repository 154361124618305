import {
  AssetGroupModuleType,
  AssetModuleType,
  BackgroundModuleType,
  BasketModuleType,
  CalendarModuleType,
  Click2MapsModuleType,
  CountdownModuleType,
  DestinationModuleType,
  GestureModuleType,
  GoogleMapsModuleType,
  HotOrNotModuleType,
  ImageSequenceModuleType,
  LightweightSwiperGroupModuleType,
  MatchModuleType,
  MemoryModuleType,
  OfferistaAssetModuleType,
  OfferistaBannerModuleType,
  OfferistaModuleType,
  PanoModuleType,
  ParticleWipeAdModuleType,
  PollModuleType,
  PollSliderModuleType,
  PopupModuleType,
  PuzzleModuleType,
  ShakeModuleType,
  SlidebarModuleType,
  SliderModuleType,
  StoryModuleType,
  SurveyModuleType,
  SurveySliderModuleType,
  SwiperGroupModuleType,
  TimerModuleType,
  TypoModuleType,
  VideoControlsModuleType,
  VideoModuleType,
  VideoStoryModuleType,
  WagawinPrivacyModuleType,
  WagawinVideoPollModuleType,
  WhatsappModuleType,
  WipeAdModuleType,
  VastVideoModuleType,
  CounterModuleType
} from "./components/designer/module_types/types"
import { EffectType } from "./components/designer/effect_types/types"

const FADE_IN = "fade_in"
const FADE_OUT = "fade_out"

const ModuleDataFields = {
  [AssetGroupModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [BackgroundModuleType]: [
    {
      type: "background",
      value: "#000000"
    },
    {
      type: "image",
      value: ""
    },
    {
      type: "clickout",
      value: ""
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [BasketModuleType]: [
    {
      type: "linkedModules",
      value: []
    },
    {
      type: "maxDraggables",
      value: 1
    },
    {
      type: "overlapPercent",
      value: 100
    },
    {
      type: "draggableZoomEnabled",
      value: 0
    },
    {
      type: "draggableZoom",
      value: 1
    },
    {
      type: "timeout",
      value: 10
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [CalendarModuleType]: [
    {
      type: "title",
      value: ""
    },
    {
      type: "description",
      value: ""
    },
    {
      type: "location",
      value: ""
    },
    {
      type: "startDate",
      value: [null]
    },
    {
      type: "endDate",
      value: [null]
    },
    {
      type: "timezone",
      value: "Europe/Berlin"
    },
    {
      type: "reminder",
      value: []
    },
    {
      type: "calendarIcs",
      value: null
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [Click2MapsModuleType]: [
    {
      type: "click2Maps",
      value: {
        type: "keyword",
        keyword: ""
      }
    },
    {
      type: "dataBindingId",
      value: ""
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [DestinationModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "data",
      value: null
    },
    {
      type: "dataBindingId",
      value: ""
    },
    {
      type: "latitudeVariable",
      value: null
    },
    {
      type: "longitudeVariable",
      value: null
    },
    {
      type: "locationColumns",
      value: [
        {
          title: "Street",
          type: "street",
          value: null
        },
        {
          title: "Number",
          type: "number",
          value: null
        },
        {
          title: "Zip code",
          type: "zip",
          value: null
        },
        {
          title: "City",
          type: "city",
          value: null
        }
      ]
    },
    {
      type: "filterColumns",
      value: null
    },
    // We have 4 location mechanism
    // * native – JS geolocation API, fallback to IP service
    // * dealerId – display single row from the CSV based on a column value (window.dealerId specifies the column value to match and is provided by the ad server ex. Permodo)
    // * latLong – similar to native but skips the JS geolocation part – the lat/long is provided by the ad server (ex. Permodo)
    // * zipCode – match the location by zip code (one column contains the zip code, we match it by the zip code range) is provided by the ad server (ex. Permodo)
    {
      type: "locationMechanism",
      value: "native" // native –> JS geolocation API, fallback to IP service. dealerId – display single row from the CSV based on a column value
    },
    {
      type: "dealerId",
      value: null
    },
    {
      type: "dealerIdFallback",
      value: null
    },
    // Contains data-binding variable which contains the name of the column
    {
      type: "zipCodeVariable",
      value: null
    },
    // Contains data-binding variable which contains the name of the column
    {
      type: "zipCodeFallback",
      value: null
    },
    {
      type: "previewData",
      value: true
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [GestureModuleType]: [
    {
      type: "gesture",
      value: ""
    },
    {
      type: "gestureColor",
      value: "#fff"
    },
    {
      type: "gestureType",
      value: "swipeIcon"
    },
    {
      type: "playInitialAnimation",
      value: 1
    },
    {
      type: "initialAnimationDuration",
      value: 3000
    },
    {
      type: "initialAnimationDelay",
      value: 0
    },
    {
      type: "initialAnimationLoop",
      value: false
    },
    {
      type: "initialAnimationLoopDelay",
      value: 0
    },
    {
      type: "image",
      value: ""
    },
    {
      type: "gestureAnimation",
      value: "swipe"
    },
    {
      type: "gestureBackground",
      value: 0
    },
    {
      type: "gestureBackgroundColor",
      value: "#00000080"
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [PanoModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "panoMode",
      value: "horizontal"
    },
    {
      type: "ratioX",
      value: 3
    },
    {
      type: "ratioY",
      value: 1
    },
    {
      type: "playInitialAnimation",
      value: 1
    },
    {
      type: "initialAnimationDuration",
      value: 1000
    },
    {
      type: "initialAnimationDelay",
      value: 0
    },
    {
      type: "initialAnimationLoop",
      value: false
    },
    {
      type: "initialAnimationLoopDelay",
      value: 0
    },
    {
      type: "startPosition",
      value: "center"
    },
    {
      type: "image",
      value: ""
    },
    {
      type: "controlsDisabled",
      value: []
    },
    {
      type: "controlsPosition",
      value: "bottom"
    },
    {
      type: "gyroNotificationTheme",
      value: "dark"
    },
    {
      type: "showScrollbars",
      value: 1
    },
    {
      type: "deviceMotionSpeed",
      value: 50
    },
    {
      type: "panoScaleMode",
      value: "no-scale"
    },
    {
      type: "videoLoop",
      value: 0
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [ParticleWipeAdModuleType]: [
    {
      type: "particle",
      value: "",
      previewDimensions: false
    },
    {
      type: "particleCount",
      value: "1",
      previewDimensions: false
    },
    {
      type: "particleDensity",
      value: 50
    },
    {
      type: "brush",
      value: "",
      previewDimensions: false
    },
    {
      type: "brushCount",
      value: "1",
      previewDimensions: false
    },
    {
      type: "wipePercentage",
      value: 80
    },
    {
      type: "wipeTimeout",
      value: 30
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [PopupModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "popupSelector",
      value: null
    },
    {
      type: "popupOpenTrigger",
      value: "#ad-content"
    },
    {
      type: "popupCloseTrigger",
      value: null
    },
    {
      type: "entryAnimation",
      value: FADE_IN
    },
    {
      type: "exitAnimation",
      value: FADE_OUT
    },
    {
      type: "animationDurations",
      value: {
        entry: 1,
        exit: 1
      }
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [ShakeModuleType]: [
    {
      type: "shakeCounts",
      value: 4
    },
    {
      type: "sensitivity",
      value: 0
    },
    {
      type: "gyroNotificationTheme",
      value: "dark"
    },
    {
      type: "timeout",
      value: 10
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [SlidebarModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [], // Default group,
          []
        ]
      }
    },
    {
      type: "direction",
      value: "horizontal"
    },
    {
      type: "initialArrowsAnimationEnabled",
      value: "1"
    },
    {
      type: "startPosition",
      value: 50
    },
    {
      type: "backOpenSpace",
      value: 10
    },
    {
      type: "frontOpenSpace",
      value: 10
    },
    {
      type: "playInitialAnimation",
      value: 1
    },
    {
      type: "initialAnimationDuration",
      value: 1000
    },
    {
      type: "initialAnimationDelay",
      value: 0
    },
    {
      type: "initialAnimationLoop",
      value: false
    },
    {
      type: "initialAnimationLoopDelay",
      value: 0
    },
    {
      type: "arrowPosition",
      value: 0
    },
    {
      type: "arrowSize",
      value: 50
    },
    {
      type: "arrowColor",
      value: "#000"
    },
    {
      type: "circleColor",
      value: "#fff"
    },
    {
      type: "arrow",
      value: ""
    },
    {
      type: "lineColor",
      value: "#ffffff"
    },
    {
      type: "lineWidth",
      value: 5
    },
    {
      type: "parallaxEffect",
      value: 0
    },
    {
      type: "isGradualDarkeningEnabled",
      value: 0
    },
    {
      type: "gradualDarkeningValue",
      value: 4
    },
    {
      type: "timeout",
      value: 15
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [SwiperGroupModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "data",
      value: null
    },
    {
      type: "dataBindingId",
      value: ""
    },
    {
      type: "slideEffect",
      value: "normal"
    },
    {
      type: "slideDirection",
      value: "horizontal"
    },
    {
      type: "slideDirectionLocks",
      value: []
    },
    {
      type: "transitionSpeed",
      value: 300
    },
    {
      type: "initialSlide",
      value: 1
    },
    {
      type: "linkedGalleries",
      value: []
    },
    {
      type: "linkedGalleriesDelay",
      value: 0
    },
    {
      type: "playInitialAnimation",
      value: 1
    },
    {
      type: "initialAnimationMode",
      value: "normal"
    },
    {
      type: "initialAnimationDuration",
      value: 2000
    },
    {
      type: "initialAnimationDelay",
      value: 0
    },
    {
      type: "initialAnimationLoop",
      value: false
    },
    {
      type: "initialAnimationLoopDelay",
      value: 0
    },
    {
      type: "controlsDisabled",
      value: []
    },
    {
      type: "arrowColor",
      value: "#ffffff"
    },
    {
      type: "arrowLeft",
      value: ""
    },
    {
      type: "arrowRight",
      value: ""
    },
    {
      type: "arrowsPosition",
      value: 0
    },
    {
      type: "arrowsPositionX",
      value: 0
    },
    {
      type: "arrowSize",
      value: 25
    },
    {
      type: "indicatorColor",
      value: "#ffffff"
    },
    {
      type: "indicatorActiveColor",
      value: "#d4ff00"
    },
    {
      type: "indicatorImage",
      value: ""
    },
    {
      type: "indicatorActiveImage",
      value: ""
    },
    {
      type: "indicatorsPositionVertical",
      value: 10
    },
    {
      type: "indicatorsPositionHorizontal",
      value: "center"
    },
    {
      type: "indicatorSize",
      value: 10
    },
    {
      type: "autoplay",
      value: 0
    },
    {
      type: "autoplayDelay",
      value: 0
    },
    {
      type: "autoplayProgress",
      value: 0
    },
    {
      type: "clickout",
      value: ""
    },
    {
      type: "progressColor",
      value: "#ffffff"
    },
    {
      type: "loopSlides",
      value: 1
    },
    {
      type: "shuffleMode",
      value: 0
    },
    {
      type: "shuffleSlidesLimit",
      value: null
    },
    {
      type: "cubeShadow",
      value: 0
    },
    {
      type: "panoramaDepth",
      value: 50
    },
    {
      type: "carouselDepth",
      value: 50
    },
    {
      type: "carouselDistance",
      value: 50
    },
    {
      type: "shouldReplayAnimations",
      value: 0
    },
    {
      type: "slideOverflow",
      value: 0
    },
    {
      type: "scroll2Slide",
      value: 0
    },
    {
      type: "addAnimationDelay",
      value: 1
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [LightweightSwiperGroupModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "controlsDisabled",
      value: []
    },
    {
      type: "slideEffect",
      value: "normal"
    },
    {
      type: "slideDirection",
      value: "horizontal"
    },
    {
      type: "slideDirectionLocks",
      value: []
    },
    {
      type: "initialSlide",
      value: 1
    },
    {
      type: "arrowColor",
      value: "#ffffff"
    },
    {
      type: "arrowLeft",
      value: ""
    },
    {
      type: "arrowRight",
      value: ""
    },
    {
      type: "arrowsPosition",
      value: 0
    },
    {
      type: "arrowsPositionX",
      value: 0
    },
    {
      type: "arrowSize",
      value: 25
    },
    {
      type: "indicatorColor",
      value: "#ffffff"
    },
    {
      type: "indicatorActiveColor",
      value: "#d4ff00"
    },
    {
      type: "indicatorImage",
      value: ""
    },
    {
      type: "indicatorActiveImage",
      value: ""
    },
    {
      type: "indicatorsPositionVertical",
      value: 10
    },
    {
      type: "indicatorsPositionHorizontal",
      value: "center"
    },
    {
      type: "indicatorSize",
      value: 10
    },
    {
      type: "autoplay",
      value: 0
    },
    {
      type: "autoplayDelay",
      value: 0
    },
    {
      type: "autoplayProgress",
      value: 0
    },
    {
      type: "clickout",
      value: ""
    },
    {
      type: "progressColor",
      value: "#ffffff"
    },
    {
      type: "loopSlides",
      value: 1
    },
    {
      type: "shuffleMode",
      value: 0
    },
    {
      type: "panoramaDepth",
      value: 50
    },
    {
      type: "carouselDepth",
      value: 50
    },
    {
      type: "carouselDistance",
      value: 50
    },
    {
      type: "slideOverflow",
      value: 0
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [VideoModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "video",
      value: "",
      previewDimensions: false
    },
    {
      type: "autoplay",
      value: 1
    },
    {
      type: "videoLoop",
      value: 0
    },
    {
      type: "playMuted",
      value: 1
    },
    {
      type: "automaticHeight",
      value: 1
    },
    {
      type: "clickout",
      value: ""
    },
    {
      type: "customPoster",
      value: null
    },
    {
      type: "posterBackgroundSize",
      value: "contain"
    },
    {
      type: "posterBackgroundPosition",
      value: "center center"
    },
    {
      type: "backgroundColor",
      value: "#000000"
    },
    {
      type: "background",
      value: null
    },
    {
      type: "controlsDisabled",
      value: ["pause"]
    },
    {
      type: "objectFit",
      value: "contain"
    },
    {
      type: "objectPosition",
      value: "center center"
    },
    {
      type: "controlsAlignment",
      value: "Spread"
    },
    {
      type: "progressBarColor",
      value: "#ffffff"
    },
    {
      type: "videoControlsColor",
      value: "#000000"
    },
    {
      type: "videoControlsBackgroundColor",
      value: "#ffffff"
    },
    {
      type: "customPlayButton",
      value: null
    },
    {
      type: "customFullscreenButton",
      value: null
    },
    {
      type: "customVolumeButton",
      value: null
    },
    {
      type: "customMuteButton",
      value: null
    },
    {
      type: "customPauseButton",
      value: null
    },
    {
      type: "customReplayButton",
      value: null
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [VideoControlsModuleType]: [
    {
      type: "linkedModule",
      value: ""
    }
  ],
  [VideoStoryModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "video",
      value: "",
      previewDimensions: false
    },
    {
      type: "clickout",
      value: ""
    },
    {
      type: "controlsDisabled",
      value: [
        "play",
        "pause",
        "replay",
        "full",
        "progress"
      ]
    },
    {
      type: "objectFit",
      value: "contain"
    },
    {
      type: "objectPosition",
      value: "center center"
    },
    {
      type: "automaticHeight",
      value: 1
    },
    {
      type: "playMuted",
      value: 1
    },
    {
      type: "customVolumeButton",
      value: null
    },
    {
      type: "customMuteButton",
      value: null
    },
    {
      type: "videoTimestamps",
      value: [1000, 2000]
    },
    {
      type: "controlsAlignment",
      value: "Center"
    },
    {
      type: "backgroundColor",
      value: "#000000"
    },
    {
      type: "videoControlsColor",
      value: "#000000"
    },
    {
      type: "videoControlsBackgroundColor",
      value: "#ffffff"
    },
    {
      type: "activeProgressBarsColor",
      value: "#ffffff"
    },
    {
      type: "inactiveProgressBarsColor",
      value: "#FFFFFF80"
    },
    {
      type: "progressBarsHeight",
      value: 4
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [StoryModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "clickout",
      value: ""
    },
    {
      type: "shouldReplayAnimations",
      value: 0
    },
    {
      type: "scenesLength",
      value: [3000]
    },
    {
      type: "activeProgressBarsColor",
      value: "#ffffff"
    },
    {
      type: "inactiveProgressBarsColor",
      value: "#FFFFFF80"
    },
    {
      type: "progressBarsHeight",
      value: 4
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [MatchModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [], // Default group
          [] // Default group
        ]
      }
    },
    {
      type: "swipeDirection",
      value: "horizontal"
    },
    {
      type: "swipeEffect",
      value: "tinder"
    },
    {
      type: "playInitialAnimation",
      value: 1
    },
    {
      type: "shuffleMode",
      value: 0
    },
    {
      type: "initialAnimationDuration",
      value: 2000
    },
    {
      type: "initialAnimationDelay",
      value: 0
    },
    {
      type: "initialAnimationLoop",
      value: false
    },
    {
      type: "initialAnimationLoopDelay",
      value: 0
    },
    {
      type: "matchButtonAssetId",
      value: ""
    },
    {
      type: "noMatchButtonAssetId",
      value: ""
    },
    {
      type: "buttonsPosition",
      value: 9
    },
    {
      type: "buttonsPositionX",
      value: 8
    },
    {
      type: "timeout",
      value: 30
    },
    {
      type: "clickout",
      value: ""
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [WhatsappModuleType]: [
    {
      type: "shareText",
      value: ""
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [WipeAdModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "background",
      value: ""
    },
    {
      type: "eraser",
      value: "",
      previewDimensions: false
    },
    {
      type: "eraserBg",
      value: ""
    },
    {
      type: "wipePercentage",
      value: 80
    },
    {
      type: "brushSize",
      value: 64
    },
    {
      type: "drawFriction",
      value: 40
    },
    {
      type: "displayEraser",
      value: 0
    },
    {
      type: "wipeTimeout",
      value: 30
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [AssetModuleType]: [
    {
      type: "image",
      value: ""
    },
    {
      type: "clickout",
      value: ""
    },
    {
      type: "constraints",
      value: []
    },
    {
      type: "csvBinding",
      value: ""
    }
  ],

  [TypoModuleType]: [
    {
      type: "typoText",
      value: null
    }, {
      type: "fontFamily",
      value: "Open Sans"
    }, {
      type: "fontStyle",
      value: "regular"
    }, {
      type: "fontSize",
      value: 5
    }, {
      type: "letterSpacing",
      value: 0
    }, {
      type: "lineHeight",
      value: 1.2
    }, {
      type: "color",
      value: "#000"
    }, {
      type: "borderStyle",
      value: "none"
    }, {
      type: "clickout",
      value: ""
    }, {
      type: "textTransform",
      value: "normal"
    }, {
      type: "textDecoration",
      value: "none"
    }, {
      type: "backgroundColor",
      value: "#00000000"
    }, {
      type: "textAlign",
      value: "center"
    }, {
      type: "textAlignVertical",
      value: "center"
    }, {
      type: "dataBindingId",
      value: null
    }, {
      type: "fontUnit",
      value: "vw"
    }, {
      type: "typerEffect",
      value: 0
    },
    {
      type: "typerEffectBasedOnWord",
      value: 0
    },
    {
      type: "typerEffectSpeed",
      value: 50
    },
    {
      type: "constraints",
      value: []
    },
    {
      name: "Is custom font selected",
      type: "isCustomFontSelected",
      value: 0
    },
    {
      type: "csvBinding",
      value: ""
    }
  ],

  [PollModuleType]: [
    {
      type: "clickout",
      value: ""
    },
    {
      type: "showPollResults",
      value: 1
    },
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [PollSliderModuleType]: [
    {
      type: "playInitialAnimation",
      value: 1
    },
    {
      type: "initialAnimationDuration",
      value: 2000
    },
    {
      type: "initialAnimationDelay",
      value: 0
    },
    {
      type: "initialAnimationLoop",
      value: false
    },
    {
      type: "initialAnimationLoopDelay",
      value: 0
    },
    {
      type: "sliderThumbSnappingEnabled",
      value: 1
    },
    {
      type: "sliderThumbMovingAfterReleaseDisabled",
      value: 1
    },
    {
      type: "sliderBackgroundImage",
      value: ""
    },
    {
      type: "sliderPadding",
      value: 0
    },
    {
      type: "sliderThumbImage",
      value: ""
    },
    {
      type: "sliderThumbAlignment",
      value: "center center"
    },
    {
      type: "sliderThumbPercentage",
      value: 100
    },
    {
      type: "pollAwsUuid",
      value: ""
    },
    {
      type: "timeout",
      value: 10
    }
  ],

  [SurveyModuleType]: [
    {
      type: "circleWidth",
      value: 80
    },
    {
      type: "circleColor",
      value: "#ffffff"
    },
    {
      type: "arrow",
      value: null
    },
    {
      type: "barWidth",
      value: 10
    },
    {
      type: "barColor",
      value: "#ffffff"
    },
    {
      type: "showVoteConfirmation",
      value: 0
    },
    {
      type: "showVoteCount",
      value: 0
    },
    {
      type: "showPercentage",
      value: 0
    },
    {
      type: "allowRevoting",
      value: 0
    },
    {
      type: "showSeats",
      value: 0
    },
    {
      type: "seatsColors",
      value: ["#ff7300", "#ffb376", "#fcd6b4"]
    },
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "surveyAwsUuid",
      value: ""
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [SurveySliderModuleType]: [
    {
      type: "playInitialAnimation",
      value: 1
    },
    {
      type: "initialAnimationDuration",
      value: 2000
    },
    {
      type: "initialAnimationDelay",
      value: 0
    },
    {
      type: "initialAnimationLoop",
      value: false
    },
    {
      type: "initialAnimationLoopDelay",
      value: 0
    },
    {
      type: "sliderThumbPercentage",
      value: 20
    },
    {
      type: "sliderThumbImage",
      value: ""
    }
  ],

  [OfferistaModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "offeristaType",
      value: ""
    },
    {
      type: "rowsCount",
      value: 0
    },
    {
      type: "brochureId",
      value: ""
    },
    {
      type: "dataBindingId",
      value: ""
    },
    {
      type: "bannerDataBindingId",
      value: ""
    },
    {
      type: "flyerDataBindingId",
      value: ""
    },
    {
      type: "bannerVisibility",
      value: 0
    },
    {
      type: "initialProducts",
      value: []
    },
    {
      type: "isLoadMoreProductsEnabled",
      value: 0
    },
    {
      type: "loadMoreProducts",
      value: []
    },
    {
      type: "isFilteringEnabled",
      value: 0
    },
    {
      type: "selectedCategories",
      value: []
    },
    {
      type: "categoriesProducts",
      value: []
    },
    {
      type: "isAutofillEnabled",
      value: 0
    },
    {
      type: "shouldTrackGalleryEvents",
      value: 0
    },
    {
      type: "shouldTrackProductEvents",
      value: 0
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [OfferistaAssetModuleType]: [
    {
      type: "image",
      value: ""
    },
    {
      type: "clickout",
      value: ""
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [OfferistaBannerModuleType]: [
    {
      type: "clickout",
      value: ""
    },
    {
      type: "bannerType",
      value: "bottom"
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [EffectType]: [
    {
      type: "autoplay",
      value: 1
    },
    {
      type: "type",
      value: "rain"
    },
    {
      type: "particleSpeed",
      value: [1, 1]
    },
    {
      type: "particleAmount",
      value: [25, 25]
    },
    {
      type: "image",
      value: null
    },
    {
      type: "imageCount",
      value: 1
    },
    {
      type: "particleSize",
      value: [100, 100]
    },
    {
      type: "particleColor",
      value: "#ffffff"
    },
    {
      type: "particleDelay",
      value: 0
    },
    {
      type: "randomMovement",
      value: 1
    },
    {
      type: "particleRotation",
      value: [100, 100]
    },
    {
      type: "straightMovement",
      value: 0
    },
    {
      type: "particleDirection",
      value: "none"
    },
    {
      type: "particleOpacity",
      value: 1
    },
    {
      type: "randomParticleOpacity",
      value: 1
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [CountdownModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "countdownDate",
      value: null
    }
  ],
  [HotOrNotModuleType]: [
    {
      type: "type",
      value: "single"
    },
    {
      type: "cards",
      value: [null, null, null, null]
    },
    {
      type: "cardsClickouts",
      value: [null, null, null, null]
    },
    {
      type: "endCards",
      value: [null, null, null, null]
    },
    {
      type: "endCardsClickouts",
      value: [null, null, null, null]
    },
    {
      type: "defaultEndcard",
      value: null
    },
    {
      type: "defaultEndcardClickout",
      value: null
    },
    {
      type: "cardBorderRadius",
      value: 0
    },
    {
      type: "cardBoxShadow",
      value: "0px 10px 20px 0px rgba(0,0,0,0.75)"
    },
    {
      type: "closeButtonBackgroundColor",
      value: "#a3a3a3"
    },
    {
      type: "closeButtonIconColor",
      value: "#ffffff"
    },
    {
      type: "paginationBulletColor",
      value: "#d1d5db"
    },
    {
      type: "paginationBulletActiveColor",
      value: "#000000"
    },
    {
      type: "arrowColor",
      value: "#000000"
    },
    {
      type: "progressTextColor",
      value: "#000000"
    },
    {
      type: "progressBackgroundColor",
      value: "#d1d5db"
    },
    {
      type: "progressBarColor",
      value: "#000"
    },
    {
      type: "hotButtonType",
      value: "text"
    },
    {
      type: "hotButtonText",
      value: "HOT"
    },
    {
      type: "hotButtonAsset",
      value: null
    },
    {
      type: "hotButtonTextColor",
      value: "#ffffff"
    },
    {
      type: "hotButtonBackgroundColor",
      value: "#000000"
    },
    {
      type: "hotButtonBorderRadius",
      value: 0
    },
    {
      type: "hotButtonBorderColor",
      value: "#000000"
    },
    {
      type: "hotButtonBorderWidth",
      value: 1
    },
    {
      type: "hotAndNotButtonBoxShadow",
      value: "0px 0px 0px 0px rgba(0,0,0,1.0)"
    },
    {
      type: "notButtonType",
      value: "text"
    },
    {
      type: "notAndHotButtonTextFont",
      value: "Open Sans"
    },
    {
      type: "notButtonText",
      value: "NOT"
    },
    {
      type: "notButtonAsset",
      value: null
    },
    {
      type: "notButtonTextColor",
      value: "#000000"
    },
    {
      type: "notButtonBackgroundColor",
      value: "#ffffff"
    },
    {
      type: "notButtonBorderRadius",
      value: 0
    },
    {
      type: "notButtonBorderColor",
      value: "#000000"
    },
    {
      type: "notButtonBorderWidth",
      value: 1
    },
    {
      type: "ctaButtonType",
      value: "text"
    },
    {
      type: "ctaButtonText",
      value: "Click here"
    },
    {
      type: "ctaButtonAsset",
      value: null
    },
    {
      type: "ctaButtonClickout",
      value: null
    },
    {
      type: "ctaButtonTextFont",
      value: "Open Sans"
    },
    {
      type: "ctaButtonTextColor",
      value: "#ffffff"
    },
    {
      type: "ctaButtonBackgroundColor",
      value: "#191919"
    },
    {
      type: "ctaButtonBorderRadius",
      value: 0
    },
    {
      type: "ctaButtonBorderColor",
      value: "#000000"
    },
    {
      type: "ctaButtonBorderWidth",
      value: 1
    },
    {
      type: "ctaButtonBoxShadow",
      value: "0px 0px 0px 0px rgba(0,0,0,1.0)"
    },
    {
      type: "random",
      value: 1
    },
    {
      type: "showOverlayCheckmark",
      value: 1
    },
    {
      type: "footerStyle",
      value: "light"
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [WagawinPrivacyModuleType]: [
    {
      type: "modulePosition",
      value: "flex-end"
    },
    {
      type: "logoColor",
      value: "#6b7280"
    },
    {
      type: "backgroundColor",
      value: "#ffffff00"
    },
    {
      type: "winnieLink",
      value: "https://www.wagawin.com/privacy-en"
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [WagawinVideoPollModuleType]: [
    {
      type: "wagawinVideoPollAwsUuid",
      value: ""
    },
    {
      type: "videoPollMode",
      value: "duringVideo"
    },
    {
      type: "videoAssetId",
      previewDimensions: false,
      value: null
    },
    {
      type: "videoPollTextFont",
      value: "Open Sans"
    },
    {
      type: "videoControlsColor",
      value: "#FFFFFF"
    },
    {
      type: "backgroundColor",
      value: "#FFFFFF"
    },
    {
      type: "backgroundLinearGradient",
      value: "linear-gradient(180deg, #000000 0%, #000000 50%)"
    },
    {
      type: "timebarForegroundColor",
      value: "#FFFFFF"
    },
    {
      type: "timebarBackgroundColor",
      value: "#FFFFFF40"
    },
    {
      type: "closeButtonBackgroundColor",
      value: "#808080"
    },
    {
      type: "closeButtonIconColor",
      value: "#FFFFFF"
    },
    {
      type: "closeButtonBorderRadius",
      value: 0
    },
    {
      type: "endcardAssetId",
      value: null
    },
    {
      type: "questionText",
      value: "Enter your question here"
    },
    {
      type: "questionTextColor",
      value: "#FFFFFF"
    },
    {
      type: "questionBackgroundColor",
      value: "#3169D4"
    },
    {
      type: "questionAnswersOpenBackgroundColor",
      value: "#a3a3a3"
    },
    {
      type: "questionAnswersOpenFontColor",
      value: "#ffffff"
    },
    {
      type: "answers",
      value: ["Answer 1", "Answer 2", "", ""]
    },
    {
      type: "answerTextColor",
      value: "#FFFFFF"
    },
    {
      type: "answerTextColorHover",
      value: "#0653b6"
    },
    {
      type: "answerBackgroundColor",
      value: "#0653b6"
    },
    {
      type: "answerBackgroundColorHover",
      value: "#FFFFFF"
    },
    {
      type: "answerOverlayBackgroundColor",
      value: "#FFFFFF8C"
    },
    {
      type: "answerOverlayBackgroundBlur",
      value: 10
    },
    {
      type: "answerBorderColor",
      value: "#000000"
    },
    {
      type: "answerBorderWidth",
      value: 0
    },
    {
      type: "answersBorderRadius",
      value: 0
    },
    {
      type: "randomAnswersOrder",
      value: 0
    },
    {
      type: "showAnswersPercentages",
      value: 0
    },
    {
      type: "percentageBackgroundColor",
      value: "#d4d4d4"
    },
    {
      type: "selectedAnswerBackgroundColor",
      value: "#2563EBFF"
    },
    {
      type: "answersDisplayDuration",
      value: 7
    },
    {
      type: "answersFontSize",
      value: 40
    },
    {
      type: "ctaButtonType",
      value: "text"
    },
    {
      type: "ctaButtonText",
      value: "Answer now"
    },
    {
      type: "ctaButtonAsset",
      value: null
    },
    {
      type: "ctaButtonTextFont",
      value: "Open Sans"
    },
    {
      type: "ctaButtonTextColor",
      value: "#ffffff"
    },
    {
      type: "ctaButtonBackgroundColor",
      value: "#0653b6"
    },
    {
      type: "ctaButtonBorderRadius",
      value: 0
    },
    {
      type: "ctaButtonBorderColor",
      value: "#000000"
    },
    {
      type: "ctaButtonBorderWidth",
      value: 0
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [VastVideoModuleType]: [
    {
      type: "videoAssetId",
      previewDimensions: false,
      value: null
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [GoogleMapsModuleType]: [
    {
      type: "dataBindingId",
      value: ""
    },
    {
      type: "googleMapsSourceType",
      value: "keyword"
    },
    {
      type: "data",
      value: null
    },
    {
      type: "keyword",
      value: ""
    },
    {
      type: "locationMechanism",
      value: "address"
    },
    {
      type: "titleVariable",
      value: null
    },
    {
      type: "cityVariable",
      value: null
    },
    {
      type: "zipCodeVariable",
      value: null
    },
    {
      type: "streetVariable",
      value: null
    },
    {
      type: "numberVariable",
      value: null
    },
    {
      type: "latitudeVariable",
      value: null
    },
    {
      type: "longitudeVariable",
      value: null
    },
    {
      type: "pinAssetId",
      value: null
    },
    {
      type: "activePinAssetId",
      value: null
    },
    {
      type: "currentPositionMarkerColor",
      value: "#ff4646"
    },
    {
      type: "customExpandedPinTextEnabled",
      value: 0
    },
    {
      type: "customExpandedPinText",
      value: ""
    },
    {
      type: "theme",
      value: "default"
    },
    {
      type: "allowMapTypeControl",
      value: 0
    },
    {
      type: "allowFullscreenControl",
      value: 0
    },
    {
      type: "allowStreetViewControl",
      value: 0
    },
    {
      type: "allowNavigationControl",
      value: 0
    },
    {
      type: "shouldCenterCurrentPosition",
      value: 1
    },
    {
      type: "customCenterPosition",
      value: ""
    },
    {
      type: "clickOverlayLabel",
      value: ""
    },
    {
      type: "searchAgainLabel",
      value: "Search in this area"
    },
    {
      type: "searchResultsLimit",
      value: null
    },
    {
      type: "initialZoomLevel",
      value: 12
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [TimerModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "shouldCountUp",
      value: 0
    },
    {
      type: "timerValue",
      value: 10
    },
    {
      type: "timerText",
      value: null
    },
    {
      type: "timerAutoplay",
      value: 0
    },
    {
      type: "timerType",
      value: "default"
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [CounterModuleType]: [
    {
      type: "variableName",
      value: ""
    },
    {
      type: "initialValue",
      value: 0
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [PuzzleModuleType]: [
    {
      type: "assetId",
      value: null
    },
    {
      type: "playInitialAnimation",
      value: 1
    },
    {
      type: "showTeaserImage",
      value: 1
    },
    {
      type: "teaserDuration",
      value: 2000
    },
    {
      type: "rowsNumber",
      value: 4
    },
    {
      type: "columnsNumber",
      value: 4
    },
    {
      type: "behavior",
      value: "swap"
    },
    {
      type: "transition",
      value: "scale"
    },
    {
      type: "puzzleDifficulty",
      value: 0
    },
    {
      type: "timeout",
      value: 30
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [MemoryModuleType]: [
    {
      type: "columnsNumber",
      value: 4
    },
    {
      type: "gridGap",
      value: "2.5%"
    },
    {
      type: "cardImageSizing",
      value: "contain"
    },
    {
      type: "cardImagePosition",
      value: "center center"
    },
    {
      type: "playInitialAnimation",
      value: 1
    },
    {
      type: "initialAnimationDuration",
      value: 2000
    },
    {
      type: "initialAnimationDelay",
      value: 0
    },
    {
      type: "initialAnimationLoop",
      value: false
    },
    {
      type: "initialAnimationLoopDelay",
      value: 0
    },
    {
      type: "teaserAnimation",
      value: "dropInTeaser"
    },
    {
      type: "randomInitialRotation",
      value: 0
    },
    {
      type: "hideCardsAfterMatch",
      value: 0
    },
    {
      type: "cardShadow",
      value: 1
    },
    {
      type: "timeout",
      value: 30
    },
    {
      type: "evenCardAssetFront",

      value: null
    },
    {
      type: "oddCardAssetFront",
      value: null
    },
    {
      type: "useDefaultMatchIndicators",
      value: 1
    },
    {
      type: "matchIndicatorImage",
      value: null
    },
    {
      type: "noMatchIndicatorImage",
      value: null
    },
    {
      type: "backCardsAssets",
      value: [null, null, null, null]
    },
    {
      type: "backCardsAssetsPair",
      value: [null, null, null, null]
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [SliderModuleType]: [
    {
      type: "sliderDirection",
      value: "horizontal"
    },
    {
      type: "linkedModule",
      value: ""
    },
    {
      type: "initialSliderPosition",
      value: "start"
    },
    {
      type: "playInitialAnimation",
      value: 1
    },
    {
      type: "initialAnimationDuration",
      value: 2000
    },
    {
      type: "initialAnimationDelay",
      value: 0
    },
    {
      type: "initialAnimationLoop",
      value: false
    },
    {
      type: "initialAnimationLoopDelay",
      value: 0
    },
    {
      type: "randomInitialRotation",
      value: 0
    },
    {
      type: "sliderThumbMovingAfterReleaseDisabled",
      value: 0
    },
    {
      type: "sliderBackgroundImage",
      value: ""
    },
    {
      type: "sliderPadding",
      value: 0
    },
    {
      type: "sliderThumbImage",
      value: ""
    },
    {
      type: "sliderThumbVerticalAlignment",
      value: "center center"
    },
    {
      type: "sliderThumbPercentage",
      value: 100
    },
    {
      type: "constraints",
      value: []
    }
  ],
  [ImageSequenceModuleType]: [
    {
      type: "folderPath",
      value: ""
    },
    {
      type: "backgroundSize",
      value: "contain"
    },
    {
      type: "backgroundPosition",
      value: "center center"
    },
    {
      type: "direction",
      value: "forward"
    },
    {
      type: "yoyo",
      value: 0
    },
    {
      type: "autoplay",
      value: 1
    },
    {
      type: "isLoopEnabled",
      value: 0
    },
    {
      type: "repeatCount",
      value: 1
    },
    {
      type: "loopDelay",
      value: 0
    },
    {
      type: "fps",
      value: 30
    },
    {
      type: "clickout",
      value: ""
    },
    {
      type: "constraints",
      value: []
    }
  ]
}

export { ModuleDataFields }
