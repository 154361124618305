<template>
  <ul
    class="adt-dropdown"
    :style="getDropdownStyle()"
  >
    <li
      v-for="(option, index) in options"
      :key="'option-' + index + '_' + uuid"
      @click="handleClick(option, $event)"
    >
      <component
        :is="option.href && dynamicHref(option.href) ? 'router-link' : 'a'"
        :class="option.class ? option.class : ''"
        :href="option.href ? option.href : null"
        :to="option.href ? option.href : null"
      >
        <i v-if="option.icon">
          <inline-svg
            :data-icon="option.icon"
            :src="require(`../../../../images/icons/${option.icon}.svg`)"
            :style="iconStyle"
          />
        </i>
        {{ option.content }}
      </component>
    </li>
  </ul>
</template>

<script>
import Utils from "../../../utils"
import { v4 as uuidv4 } from "uuid"
import InlineSvg from "vue-inline-svg"

export default {
  components: {
    InlineSvg
  },
  props: {
    minWidth: {
      type: Number,
      default: 182
    },
    options: {
      type: Array,
      default: () => []
    },
    margin: {
      type: Array,
      default: () => [0, 0, 0, 0]
    },
    iconStyle: {
      type: Object,
      default: null
    },
    tip: {
      type: Object,
      default: null
    }
  },
  emits: ["hide", "click"],
  created () {
    this.uuid = uuidv4()
  },
  methods: {
    dynamicHref (href) {
      return Utils.checkRouteIsDynamic(href)
    },
    getDropdownStyle () {
      return {
        minWidth: this.minWidth + "px",
        margin: this.margin.map(e => e + "px").join(" "),
        textAlign: "initial"
      }
    },
    handleClick (option, e) {
      if (this.tip?.value) {
        this.tip.value._tippy.hide()
      }

      if (typeof option.click === "function") {
        option.click(e)
      }
      if (!option.href) {
        e.preventDefault()
      }
      this.$emit("click", e)
    }
  }
}
</script>
