<template>
  <div class="analytics--devices">
    <div class="analytics--chart-section">
      <div
        v-if="Object.keys(devices).length > 0"
        class="device-chart-wrapper"
      >
        <span class="analytics--chart-title">Device Distribution</span>
        <div class="analytics--chart-row">
          <div class="analytics--canvas-wrapper">
            <canvas ref="device-chart"></canvas>
          </div>
          <div class="analytics--legend">
            <div class="analytics--legend-align">
              <div
                v-for="(value, key) in devices"
                :key="`device-${key}`"
                class="analytics--legend-row"
              >
                <span
                  class="analytics--legend-box"
                  :style="getDeviceBoxColorStyle(key, value)"
                ></span>
                <span class="analytics--legend-text">{{ Utils.ucfirst(key) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="pie-error-message"
      >
        <h3 class="table-error-message">
          Not enough device data available
        </h3>
      </div>
    </div>
    <div class="analytics--chart-section">
      <div
        v-if="Object.keys(browsers).length > 0"
        class="platform-chart-wrapper"
      >
        <span class="analytics--chart-title">Browser Distribution</span>
        <div class="analytics--chart-row">
          <div class="analytics--canvas-wrapper">
            <canvas ref="browser-chart"></canvas>
          </div>
          <div class="analytics--legend">
            <div class="analytics--legend-align">
              <div
                v-for="(value, key) in browsers"
                :key="`browser-${key}`"
                class="analytics--legend-row"
              >
                <span
                  class="analytics--legend-box"
                  :style="getBrowserBoxColorStyle(key, value)"
                ></span>
                <span class="analytics--legend-text">{{ Utils.ucfirst(key) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="pie-error-message"
      >
        <h3 class="table-error-message">
          Not enough browser data available
        </h3>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js/auto"
import Utils from "../../../utils"
import chartJsTooltip from "../../../classes/chartJsTooltip"
import { AnalyticsRoutes } from "../../../api/routes"

export default {
  props: {
    designUuid: {
      type: String,
      required: true
    },
    tagUuid: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      devices: {},
      browsers: {},
      deviceChart: null
    }
  },
  computed: {
    Utils () {
      return Utils
    },
    availableBrowserColors () {
      return [
        "#6b0fe5",
        "#ba72fc",
        "#ff7c82",
        "#ffc351",
        "#2bd99a",
        "#55d8ff",
        "#f87335"
      ]
    },
    availableDeviceColors () {
      return [
        "#e59b0f",
        "#4791fe",
        "#34d17e",
        "#dc2ccf"
      ]
    }
  },
  created () {
    const params = Utils.getUrlParameters(location.search)
    this.fakeData = (params && params.fake) || window.isRunningIntercomTour === true ? parseInt(params.fake) : 0
  },
  mounted () {
    this.updateCharts()
  },
  beforeUnmount () {
    if (this.deviceChart) {
      this.deviceChart.destroy()
    }
    if (this.browserChart) {
      this.browserChart.destroy()
    }
  },
  methods: {
    updateCharts () {
      const params = Utils.getUrlParameters(location.search)
      this.fakeData = (params && params.fake) || window.isRunningIntercomTour === true ? parseInt(params.fake) : 0
      this.getChartData().then(() => {
        this.createDeviceChart()
        this.createBrowserChart()
      })
    },
    getDeviceBoxColorStyle (key) {
      const idx = Object.keys(this.devices).indexOf(key)
      return { backgroundColor: this.availableDeviceColors[idx % this.availableDeviceColors.length] }
    },
    getBrowserBoxColorStyle (key) {
      const idx = Object.keys(this.browsers).indexOf(key)
      return { backgroundColor: this.availableBrowserColors[idx % this.availableBrowserColors.length] }
    },
    getChartData () {
      return this.$apiClient.get(AnalyticsRoutes.getChartDataDevices(), {
        params: {
          fake: this.fakeData,
          start: this.startDate,
          end: this.endDate,
          tagId: this.tagUuid,
          design: this.designUuid
        }
      }).then((resp) => {
        this.fetchedData = resp.data
        this.devices = this.fetchedData.device
        this.browsers = this.fetchedData.browser
      })
    },
    createDeviceChart () {
      if (!Object.keys(this.devices).length) {
        return
      }

      const getChartData = () => {
        return {
          datasets: [{
            data: Object.values(this.devices),
            backgroundColor: Object.keys(this.devices).map((e, idx) => {
              return this.availableDeviceColors[idx % this.availableDeviceColors.length]
            }),
            borderWidth: 2,
            borderColor: "#222529"
          }],
          labels: Object.keys(this.devices)
        }
      }
      if (this.deviceChart) {
        this.deviceChart.data = getChartData()
        this.deviceChart.update()
      } else {
        const ctx = this.$refs["device-chart"].getContext("2d")
        this.deviceChart = new Chart(ctx, {
          type: "pie",
          data: getChartData(),
          options: {
            rotation: 0.25 * Math.PI,
            aspectRatio: 1,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                enabled: false,
                external: chartJsTooltip,
                caretSize: 8,
                bodyFont: {
                  size: 14,
                  family: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\""
                },
                bodyColor: "#d6dae0",
                padding: 14,
                yAlign: "bottom",
                xAlign: "center",
                callbacks: {
                  label (context) {
                    let sum = 0
                    context.dataset.data.forEach((v) => {
                      sum += v
                    })
                    const value = context.dataset.data[context.dataIndex] || ""
                    const text = context.label || ""
                    const label = " " + Math.round(((value / sum) * 100)) + `% ${text} - ${Utils.parseNumber(value)}`

                    return label
                  }
                }
              }
            }
          }
        })
      }
    },
    createBrowserChart () {
      if (!Object.keys(this.browsers).length) {
        return
      }

      const getChartData = () => {
        return {
          datasets: [{
            data: Object.values(this.browsers),
            backgroundColor: Object.keys(this.browsers).map((e, idx) => {
              return this.availableBrowserColors[idx % this.availableBrowserColors.length]
            }),
            borderWidth: 2,
            borderColor: "#222529"
          }],
          labels: Object.keys(this.browsers)
        }
      }
      if (this.browserChart) {
        this.browserChart.data = getChartData()
        this.browserChart.update()
      } else {
        const ctx = this.$refs["browser-chart"].getContext("2d")
        this.browserChart = new Chart(ctx, {
          type: "pie",
          data: getChartData(),
          options: {
            animation: {
              duration: 0
            },
            hover: {
              animationDuration: 0
            },
            responsiveAnimationDuration: 0,
            rotation: 0.25 * Math.PI,
            aspectRatio: 1,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                enabled: false,
                external: chartJsTooltip,
                caretSize: 8,
                bodyFont: {
                  size: 14,
                  family: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\""
                },
                padding: 14,
                yAlign: "bottom",
                xAlign: "center",
                callbacks: {
                  label (context) {
                    let sum = 0
                    context.dataset.data.forEach((v) => {
                      sum += v
                    })
                    const value = context.dataset.data[context.dataIndex] || ""
                    const text = context.label || ""
                    const label = " " + Math.round(((value / sum) * 100)) + `% ${text} - ${Utils.parseNumber(value)}`

                    return label
                  }
                }
              }
            }
          }
        })
      }
    }
  }
}
</script>
