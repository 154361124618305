<template>
  <context-dropdown
    :min-width="182"
    :options="getAvailableOptions()"
    :placement="'top-end'"
  >
    <div
      v-if="user"
      class="current-user rounded-full"
    >
      <div class="dots">
        <icon
          name="profile"
        />
      </div>
      <avatar
        :alt="[user.firstname, user.lastname].join(' ')"
        :avatar="user.avatar"
        :size="24"
        :title="[user.firstname, user.lastname].join(' ')"
        :user-id="user.uuid"
      />
      <avatar
        v-if="user && (user.roles.super_admin || user.company_count > 1)"
        :alt="user.active_company.name"
        :custom-src="user.active_company.logo_src"
        :size="24"
        :title="user.active_company.name"
        :user-id="user.uuid"
      />
    </div>
  </context-dropdown>
</template>

<script>
import { mapActions, mapState } from "vuex"
import ContextDropdown from "../common/dropdown/ContextDropdown"

export default {
  components: {
    ContextDropdown
  },
  data () {
    return {
      dropdownActive: false
    }
  },
  computed: {
    ...mapState({
      user: "currentUser"
    })
  },
  beforeMount () {
    this.loadCurrentUser()
  },
  methods: {
    ...mapActions(["loadCurrentUser"]),
    toggleDropdown () {
      this.dropdownActive = !this.dropdownActive
    },
    getAvailableOptions () {
      const options = [
        {
          content: "Edit Profile",
          href: "/user/edit",
          icon: "edit"
        }
      ]

      if (this.user && (this.user.roles.super_admin || this.user.company_count > 1)) {
        options.push({
          content: "Change company",
          icon: "change-company",
          href: "/company"
        })
      }

      options.push({
        content: "Logout",
        icon: "logout",
        href: "/logout"
      })

      return options
    }
  }
}
</script>
