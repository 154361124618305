<template>
  <popup class="search-popup">
    <card class="card-sm">
      <card-body>
        <h1>Campaign matching "{{ query }}"</h1>
        <div class="search-result">
          <div v-if="data.campaigns"></div>
          <router-link
            v-for="campaign in data.campaigns"
            :key="campaign.uuid"
            class="campaign-row row"
            :to="`/campaign/${campaign.unique_id}/details`"
          >
            <brand
              :brand="campaign.brand"
              class="campaign-brand"
              :size="32"
            />
            <div class="campaign-details">
              <span class="campaign-name">{{ campaign.name }}</span>
              <span class="text-muted">{{ getCreativeCount(campaign) }}</span>
            </div>
          </router-link>
          <hr
            v-if="data.designs && data.designs.length"
            class="sep"
          />
          <router-link
            v-for="design in data.designs"
            :key="design.uuid"
            class="creative-row row"
            :to="`/designs/${design.unique_id}/edit`"
          >
            <img
              :alt="design.name"
              class="creative-screenshot"
              :src="design.screenshot ? design.screenshot : utils.fallbackImage()"
            />
            <div class="creative-info">
              <span class="creative-name">{{ design.name }}</span>
              <div class="creative-meta">
                <span class="creative-type">
                  {{ utils.getFriendlyDimensions(design) }}
                </span>
                <span class="circle-sep"></span>
                <span
                  v-tippy
                  class="creative-date"
                  :content="utils.parseDate(design.last_edit)"
                >
                  {{ utils.timeAgoFormatter(design.last_edit) }}
                </span>
              </div>
            </div>
          </router-link>
          <hr
            v-if="data.campaigns && data.campaigns.length"
            class="sep"
          />
          <router-link
            v-for="campaign in data.campaigns"
            :key="campaign.uuid + '-report'"
            class="report-row row"
            :to="`/campaign/${campaign.uuid}/analytics`"
          >
            <icon
              name="reports"
              style="margin-right: 14px"
            />
            <div class="report-details">
              <span class="campaign-name">{{ campaign.name }}</span>
              <span class="text-muted">Report for {{ campaign.name }}</span>
            </div>
          </router-link>
        </div>
      </card-body>
    </card>
  </popup>
</template>

<script>
import Utils from "../../../../utils"
import Icon from "../../Icon"

export default {
  components: {
    Icon
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    query: {
      type: String,
      default: ""
    }
  },
  computed: {
    utils () {
      return Utils
    }
  },
  methods: {
    getCreativeCount (campaign) {
      const count = campaign.creative_count
      if (count === 1) {
        return count + " creative"
      }

      return count + " creatives"
    }
  }
}
</script>
