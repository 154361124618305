import axios from "axios"

export default class ApiClient {
  baseUrl = process.env.VUE_APP_API_URL

  async get (route, options = {}) {
    return axios.get(this.baseUrl + route, options)
  }

  async post (route, options = {}, config = {}) {
    return axios.post(this.baseUrl + route, options, config)
  }

  async put (route, options = {}) {
    return axios.put(this.baseUrl + route, options)
  }

  async patch (route, options = {}) {
    return axios.patch(this.baseUrl + route, options)
  }

  async delete (route, options = {}) {
    return axios.delete(this.baseUrl + route, options)
  }
}
