<template>
  <div class="design-box">
    <span
      :class="['creative-preview', {screenshot: !!design.screenshot}]"
      :style="design.screenshot ? `background-image: url(${design.screenshot})` : 'background-image: url(' + require('../../../../images/templates/template-default.svg') + ');'"
    >
      <div class="overlay">
        <a
          v-if="displayShowroom"
          class="preview"
          :href="design.progress === DESIGN_PROGRESS_DESIGN_INIT ? '#' : getShowroomHref(design)"
          :target="design.progress === DESIGN_PROGRESS_DESIGN_INIT ? '' : '_blank'"
        >
          <icon
            :disabled="design.progress === DESIGN_PROGRESS_DESIGN_INIT"
            name="preview"
            :tooltip="design.progress === DESIGN_PROGRESS_DESIGN_INIT ? 'Preview is not ready yet' : ''"
          />
        </a>
        <div
          v-if="displayEdit"
          class="edit"
        >
          <default-button
            :href="editUrl"
            target="_blank"
          >
            Edit Creative
          </default-button>
        </div>
        <a
          v-if="displayEdit"
          class="overlay-link"
          :href="editUrl"
          target="_blank"
        ></a>
      </div>
      <div
        v-if="displaySizing"
        class="creative-sizing"
      >
        <div
          v-if="design.build_size > 0"
          class="box"
        >{{ Utils.getFriendlySize(design.build_size) }}</div>
        <div class="box">{{ Utils.getFriendlyDimensions(design) }}</div>
      </div>
    </span>
    <h3>
      <tooltip-too-long
        v-if="autoWrap"
        :length="nameMaxLength"
        :text="design.name"
        :tooltip-max-width="1000"
      />
      <span v-if="!autoWrap">{{ design.name }}</span>
    </h3>
    <div
      v-if="displayMeta"
      class="creative-meta"
    >
      <span class="creative-type">
        {{ Utils.getFriendlyDesignFormat(design.format, design.deviceType, design.ad_product) }}
      </span>
      <span
        v-if="displayDate"
        class="circle-sep"
      ></span>
      <span
        v-if="displayDate"
        v-tippy="{allowHTML:true}"
        class="creative-date"
        :content="convertLastDate(design.last_edit)"
      >
        {{ Utils.timeAgoFormatter(design.last_edit, true) }}
      </span>
    </div>
    <div class="action">
      <slot name="action" />
    </div>
  </div>
</template>
<script>
import Utils from "../../../utils"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import {
  AD_FORMATS,
  DESIGN_PROGRESS_DESIGN_INIT,
  PRODUCT_LIVING_ADS
} from "@/constants"
import WagawinUtils from "@/wagawinUtils"
import { mapState } from "vuex"

export default {
  props: {
    design: {
      type: Object,
      required: true
    },
    displayMeta: {
      type: Boolean,
      default: true
    },
    displayDate: {
      type: Boolean,
      default: true
    },
    autoWrap: {
      type: Boolean,
      default: false
    },
    displaySizing: {
      type: Boolean,
      default: true
    },
    displayShowroom: {
      type: Boolean,
      default: true
    },
    displayEdit: {
      type: Boolean,
      default: true
    },
    nameMaxLength: {
      type: Number,
      default: 20
    }
  },
  computed: {
    ...mapState(["availableFeatures"]),
    editUrl () {
      if (this.design.showcase) {
        return "/designs/showcase/edit"
      }

      if (this.design.ad_product === PRODUCT_LIVING_ADS) {
        return `/designs/${this.design.unique_id}/edit?livingAds=${this.design.format}`
      } else if (this.design.format === AD_FORMATS.vast_interstitial) {
        return `/designs/${this.design.unique_id}/edit?vast=${this.design.format}`
      }

      return `/designs/${this.design.unique_id}/edit`
    },
    DESIGN_PROGRESS_DESIGN_INIT () {
      return DESIGN_PROGRESS_DESIGN_INIT
    },
    Utils () {
      return Utils
    }
  },
  methods: {
    convertLastDate (date) {
      dayjs.extend(customParseFormat)
      const newDate = dayjs(date, "YYYY-MM-DD, hh:mm:ss").format("DD MMM YYYY")
      const time = dayjs(date, "YYYY-MM-DD, hh:mm:ss").format("hh:mm A")
      return `
        <span class="tippy-date">${newDate}</span>
        <span class="tippy-time">${time}</span>
      `
    },
    getShowroomHref ({unique_id: designUniqueId, preview_token: designToken}) {
      if (this.availableFeatures.includes("gr:wagawinFeatures")) {
        const adtronUrl = `${window.location.origin}/showroom/${designUniqueId}?token=${designToken}`
        return WagawinUtils.replaceAdtronUrlToWagawin(adtronUrl)
      }
      return `/showroom/${designUniqueId}?token=${designToken}`
    }
  }
}
</script>
