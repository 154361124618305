<template>
  <div class="row pt-3">
    <box-selection
      :model-value="adFormatId"
      :options="options"
      @update:model-value="updateModelValue"
    />
  </div>
</template>

<script>
import Utils from "@/utils.js"
import BoxSelection from "@/components/common/box/BoxSelection.vue"

export default {
  components: {
    BoxSelection
  },
  props: {
    modelValue: {
      type: Object,
      default: null
    }
  },
  emits: ["input", "update:modelValue"],
  computed: {
    adFormatId () {
      return this?.modelValue.id
    },
    options () {
      return Utils.getLivingAdsFormats()
    }
  },
  methods: {
    updateModelValue (optionId) {
      const entry = this.options.find(o => o.id === optionId)
      this.$emit("input", entry)
      this.$emit("update:modelValue", entry)
    }

  }
}
</script>
