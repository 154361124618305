<template>
  <button
    :id="id"
    :class="['button', 'button-' + size, 'button-' + variant, (disabled ? 'disabled' : '')]"
    :style="{minWidth: minWidth + 'px'}"
    :type="type"
    @click="clicked"
  >
    <i
      v-if="icon"
      :style="iconStyles"
    >
      <inline-svg :src="require(`../../../../images/icons/${icon}.svg`)" />
    </i>
    <component
      :is="dynamicHref ? 'router-link' : 'a'"
      v-if="href"
      :href="href"
      :target="target"
      :to="href"
    >
      <slot />
    </component>
    <template v-if="!href">
      <slot />
    </template>
  </button>
</template>

<script>
import InlineSvg from "vue-inline-svg"
import Utils from "../../../utils"

export default {
  components: {
    InlineSvg
  },
  props: {
    size: {
      type: String,
      default: "m",
      validator: (value) => {
        return ["custom", "s", "m", "l", "xl"].indexOf(value) !== -1
      }
    },
    type: {
      type: String,
      default: "button"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: "default",
      validator: (value) => {
        return ["primary", "secondary", "default", "danger"].indexOf(value) !== -1
      }
    },
    target: {
      type: String,
      default: "_self"
    },
    icon: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    minWidth: {
      type: Number,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  emits: ["click"],
  computed: {
    dynamicHref () {
      return Utils.checkRouteIsDynamic(this.href)
    },
    iconStyles () {
      if (this.icon && !this.href) {
        return { marginRight: "7px" }
      }
      return null
    }
  },
  methods: {
    clicked (e) {
      if (!this.disabled) {
        this.$emit("click", e)
      }
    }
  }
}
</script>
