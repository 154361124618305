<template>
  <div class="row tags-row">
    <div class="header-row d-flex justify-content-between align-items-center">
      <h2>Choose your Tags</h2>
      <div class="tag-input">
        <div
          v-if="inputActive"
          v-click-outside="onClickOutside"
          class="tag-input__wrapper"
        >
          <base-input
            v-model:value="newTagName"
            :border-radius="8"
            :focus-input-on-mount="true"
            :height="44"
            :max-width="275"
            :maxlength="50"
            :min-width="275"
            @keyup.enter.stop="addPressed"
          />
        </div>
        <secondary-button
          ref="addNewTagButton"
          icon="add"
          size="m"
          @click="addPressed"
        >
          Add New Tag
        </secondary-button>
      </div>
    </div>
    <h4>
      In this section you can choose tags that describe your Campaign the best. Use them to filter your Campaign in the
      Campaign Manager
    </h4>
    <div class="container">
      <div class="row pt-3">
        <div class="col">
          <div class="adt-tags">
            <tags-list
              ref="tagsList"
              :active-tags="activeTags"
              :tags="tagsValue"
              @activate="activateTag"
              @delete-tag="deleteTag"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "../../form/BaseInput"
import { mapActions, mapState } from "vuex"
import Language from "../../../../i18n/en"
import TagsList from "../../tags/TagsList"
import { DesignRoutes } from "../../../../api/routes"

export default {
  name: "ChooseYourTags",
  components: {
    BaseInput,
    TagsList
  },
  props: {
    tags: {
      type: Array,
      default: () => {
      }
    }
  },
  emits: ["input"],
  data () {
    return {
      newTagName: "",
      inputActive: false,
      activeTags: [],
      tagInputOffset: 0,
      tagsValue: [
        // Predefined and constants tags for everyone
        "Technology",
        "Game Design",
        "Fashion & Styling",
        "Automotives",
        "Artificial Intelligence",
        "Engineering"
      ]
    }
  },
  computed: {
    ...mapState({ companyTags: "companyTags" })
  },
  mounted () {
    this.activeTags = this.tags
    this.tagsValue = [...this.tagsValue, ...this.companyTags].filter((value, index, self) => self.indexOf(value) === index)
    this.fetchCompanyTags().then(() => {
      this.tagsValue = [...this.tagsValue, ...this.companyTags].filter((value, index, self) => self.indexOf(value) === index)
    })
  },
  methods: {
    onClickOutside (ev) {
      // If add new tag button is clicked, don't set inputActive to false
      if (this.$refs.addNewTagButton && this.$refs.addNewTagButton.$el === ev.target) {
        return
      }

      this.inputActive = false
    },
    deleteTag (tag) {
      this.$apiClient.delete(DesignRoutes.deleteCampaignTag(encodeURIComponent(tag))).then(() => {
        this.$toasted.success(Language.campaign.success.delete_tag)
        if (this.tagsValue.indexOf(tag) !== -1) {
          this.tagsValue.splice(this.tagsValue.indexOf(tag), 1)
        }
        if (this.activeTags.indexOf(tag) !== -1) {
          this.activeTags.splice(this.activeTags.indexOf(tag), 1)
        }
        if (this.companyTags.indexOf(tag) !== -1) {
          this.companyTags.splice(this.companyTags.indexOf(tag), 1)
        }
      }).catch(() => {
        this.$toasted.error(Language.campaign.error.delete_tag)
      })
    },
    activateTag (tagName) {
      if (this.activeTags.indexOf(tagName) !== -1) {
        this.activeTags = this.activeTags.filter(tag => tagName !== tag)
      } else {
        this.activeTags.push(tagName)
      }
      this.$emit("input", this.activeTags)
    },
    updateTags (tags) {
      this.$emit("input", tags)
    },
    addPressed () {
      if (this.inputActive === false) {
        this.inputActive = true
      } else {
        this.addNewTagToUser()
        this.inputActive = false
      }
    },
    addNewTagToUser () {
      if (this.newTagName.trim().length > 0) {
        this.addCompanyTag(this.newTagName.trim())
          .then(resp => {
            const tagName = resp.data.data.tag.name
            if (this.tagsValue.indexOf(tagName) === -1) {
              this.tagsValue.push(tagName)
            }
            if (this.activeTags.indexOf(tagName) === -1) {
              this.activeTags.push(tagName)
            }
          })
          .catch(err => {
            if (err.response?.status === 400) {
              this.$toasted.error(err.response.data.message)
            }
          })
        this.newTagName = ""
      }
    },
    ...mapActions(["fetchCompanyTags", "addCompanyTag"])
  }
}
</script>
