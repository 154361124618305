<template>
  <div class="view-container">
    <sidebar />
    <div
      class="app-container"
      @click="$eventHub.$emit('hide-search', $event)"
    >
      <main-header />
      <div class="content">
        <div class="section-title campaign-list-title content-inner">
          <h2>Campaign Manager</h2>
          <filter-enabler @change="$eventHub.$emit('toggle-filters', 'campaign-list-filters')" />
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 content-inner">
              <transition
                appear
                mode="out-in"
                name="fadeIn"
              >
                <campaign-list :preselect="campaignId" />
              </transition>
            </div>
          </div>
        </div>
        <loading-status />
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex"
import CampaignList from "../components/campaign/CampaignList"

export default {
  components: {
    CampaignList
  },
  computed: {
    campaignId () {
      return this.$route.params.campaignUniqueId || null
    }
  },
  watch: {
    campaignId (val) {
      if (val) {
        this.clearFilters()
      }
    }
  },
  methods: {
    ...mapMutations(["clearFilters"])
  }
}
</script>
