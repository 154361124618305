<template>
  <div
    ref="tip"
    class="context-dropdown"
  >
    <slot>
      <icon
        name="dots"
      />
    </slot>
  </div>
</template>
<script setup>
import { h, ref } from "vue"
import { useTippy } from "vue-tippy"
import "tippy.js/dist/tippy.css"
import ContextDropdownList from "./ContextDropdownList.vue"

const props = defineProps({
  minWidth: {
    type: Number,
    default: 182
  },
  options: {
    type: Array,
    default: () => []
  },
  placement: {
    type: String,
    default: "bottom"
  },
  margin: {
    type: Array,
    default: () => [0, 0, 0, 0]
  },
  iconStyle: {
    type: Object,
    default: null
  }
})

const tip = ref(null)

useTippy(tip, {
  content: h(ContextDropdownList, {
    options: props.options,
    minWidth: props.minWidth,
    margin: props.margin,
    iconStyle: props.iconStyle,
    placement: props.placement,
    tip
  }),
  arrow: false,
  trigger: "click",
  interactive: true,
  placement: props.placement
})
</script>
