<template>
  <search
    popup-component="header-popup"
    @change="search"
  />
</template>

<script>
import Search from "./Search"

export default {
  components: {
    Search
  },
  data () {
    return {
      data: []
    }
  },
  methods: {
    search (value) {
      this.$store.dispatch("loadSearchResult", { query: value })
    }
  }
}
</script>
