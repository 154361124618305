export const BRAND_SIZE = 42
export const AVATAR_SIZE = 34

export class ThumbnailResolver {
  static resolve (name, size) {
    return process.env.VUE_APP_CDN + "/upload/thumbs/" + size + "/thumbnail_" + name + ".webp"
  }

  static isEnabled () {
    return parseInt(process.env.VUE_APP_CDN_ENABLED) === 1
  }
}
