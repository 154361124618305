<template>
  <div ref="tip"></div>
</template>
<script setup>
import { h, onBeforeUnmount, onMounted, ref, computed } from "vue"
import { useTippy } from "vue-tippy"
import "tippy.js/dist/tippy.css"
import MacroHintList from "@/components/common/macro/MacroHintList.vue"
import Utils from "@/utils"

const props = defineProps({
  macros: {
    type: Array,
    default: () => []
  }
})
const emits = defineEmits(["cancel", "key", "input"])

const activeOptionIndex = ref(null)

const activeIndex = computed({
  get () {
    return Utils.clamp(0, activeOptionIndex.value, props.macros.length - 1)
  },
  set (val) {
    activeOptionIndex.value = Math.max(0, val)
  }
})

function chooseMacro (macro) {
  emits("input", macro)
}

function scrollToElement () {
  const liElement = document.querySelector(`[data-macro="${activeIndex.value}"]`)
  if (liElement) {
    liElement.scrollIntoView()
  }
}

function keyup (e) {
  switch (e.key) {
    case "ArrowUp":
      activeIndex.value = activeIndex.value - 1
      scrollToElement()
      e.preventDefault()
      break
    case "ArrowDown":
      activeIndex.value++
      scrollToElement()
      e.preventDefault()
      break
    case "Enter":
      chooseMacro(props.macros[activeIndex.value])
      e.preventDefault()
      break
    case "Esc":
      emits("cancel")
      break
    default:
      if (props.macros.length > 0) {
        emits("key", e)
      }
      break
  }
}

onMounted(() => {
  document.addEventListener("keyup", keyup)
})
onBeforeUnmount(() => {
  document.removeEventListener("keyup", keyup)
})

const tip = ref(null)

useTippy(tip, {
  content: h(MacroHintList, {
    macros: props.macros,
    tip,
    activeIndex: activeIndex.value,
    chooseMacro
  }),
  arrow: false,
  trigger: "click",
  interactive: true,
  showOnCreate: true,
  placement: "bottom"
})

// how to check if value is a boolean or an object with enabled property
// const isBoolean = typeof props.value === "boolean"
// const isEnabled = isBoolean ? props.value : props.value.enabled

</script>

