<template>
  <base-modal
    id="importModal"
    ref="modal"
    class="modal-auto-height text-left"
    :show-close-button="true"
    size="extra-small"
    title="Import"
  >
    <template #content>
      <div>
        <div class="form-group">
          <input-dropdown
            :value="activeOption"
            label="Import"
            :options="availableOptions"
            @update:value="updateActiveOption"
          />
        </div>

        <template
          v-if="activeOption.value === 'import_design'"
        >
          <div class="form-group">
            <text-input
              id="designId"
              v-model:value="designId"
              label="Unique ID of a design"
              name="designId"
              placeholder="1A2B2CD"
              type="text"
              @keydown.enter.prevent="$event.target && $event.target.blur"
            />
          </div>
        </template>

        <template
          v-else-if="activeOption.value === 'import_designs_from_campaign'"
        >
          <div class="form-group">
            <text-input
              id="designId"
              v-model:value="campaignId"
              label="Unique ID of a campaign"
              name="campaignId"
              type="text"
              placeholder="1A2B2CD"
              @keydown.enter.prevent="$event.target && $event.target.blur"
            />
          </div>
        </template>

        <template
          v-else-if="activeOption.value === 'import_design_from_a_different_instance'"
        >
          <div class="form-group">
            <input-dropdown
              v-model:value="activeInstance"
              label="External site URL"
              :options="availableInstances"
            />
          </div>
          <div class="form-group">
            <text-input
              id="designId"
              v-model:value="designId"
              label="Unique ID of a design"
              name="designId"
              placeholder="1A2B2CD"
              type="text"
              @keydown.enter.prevent="$event.target && $event.target.blur"
            />
          </div>
          <div class="form-group">
            <text-input
              id="apiLogin"
              v-model:value="email"
              placeholder="mail@example.com"
              label="E-mail to external site (will not be stored)"
              name="apiLogin"
              type="text"
              @keydown.enter.prevent="$event.target && $event.target.blur"
            />
          </div>
          <div class="form-group">
            <text-input
              id="apiPassword"
              v-model:value="password"
              label="Password for external site (will not be stored)"
              name="apiPassword"
              type="password"
              @keydown.enter.prevent="$event.target && $event.target.blur"
            />
          </div>
        </template>
      </div>
    </template>
    <template #actions>
      <base-button
        :min-width="114"
        size="m"
        @click.prevent="modal.close"
      >
        Cancel
      </base-button>
      <primary-button
        :disabled="!isFormValid"
        :min-width="114"
        size="m"
        @click.prevent="submitForm"
      >
        Import
      </primary-button>
    </template>
  </base-modal>
</template>
<script>
import Language from "../../i18n/en"
import InputDropdown from "@/components/common/dropdown/InputDropdown.vue"
import { mapState } from "vuex"

export default {
  components: { InputDropdown },
  props: {
    campaignUniqueId: {
      type: String,
      default: null
    },
    callback: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      },
      activeOption: { title: "Design", value: "import_design" },
      options: [
        { title: "Design", value: "import_design" },
        { title: "Designs from the campaign", value: "import_designs_from_campaign" },
        { title: "Design from a different instance", value: "import_design_from_a_different_instance" }
      ],
      activeInstance: { title: "https://lab.adtron.io", value: "https://lab.adtron.io" },
      availableInstances: [
        { title: "https://dev.adtron.network", value: "https://dev.adtron.network" },
        { title: "https://test.adtron.network", value: "https://test.adtron.network" },
        { title: "https://lab.adtron.io", value: "https://lab.adtron.io" }
      ],
      email: "",
      password: "",
      designId: "",
      campaignId: ""
    }
  },
  computed: {
    availableOptions () {
      if (this.currentUser.roles.super_admin) {
        return this.options
      }

      return this.options.filter(option => option.value !== "import_design_from_a_different_instance")
    },
    ...mapState(["currentUser"]),
    isFormValid () {
      if (this.activeOption.value === "import_design") {
        return this.designId.length >= 7
      } else if (this.activeOption.value === "import_designs_from_campaign") {
        return this.campaignId.length >= 7
      } else if (this.activeOption.value === "import_design_from_a_different_instance") {
        return this.designId.length >= 7 && this.email.length && this.password.length
      }

      return false
    }
  },
  mounted () {
    this.modal = this.$refs.modal
  },
  methods: {
    resetFormData () {
      this.email = ""
      this.password = ""
      this.designId = ""
      this.campaignId = ""
    },
    updateActiveOption ({value}) {
      this.resetFormData()
      this.activeOption = this.availableOptions.find(option => option.value === value)
    },
    submitForm () {
      if (!this.isFormValid) return

      if (this.activeOption.value === "import_design") {
        this.queueImportDesign()
      } else if (this.activeOption.value === "import_designs_from_campaign") {
        this.queueImportDesignsFromCampaign()
      } else if (this.activeOption.value === "import_design_from_a_different_instance") {
        this.queueImportDesignFromAnotherInstance()
      }
    },
    queueImportDesignsFromCampaign () {
      this.$toasted.success(Language.designs.success.import_campaign_designs_from_another_company_started)
      const promise = this.$store.dispatch("importCampaignDesignsFromAnotherCompany", {
        campaignUniqueId: this.campaignUniqueId,
        clonedCampaignUniqueId: this.campaignId
      }).then(
        response => {
          this.$toasted.success(Language.designs.success.import_designs_finished)

          if (this.callback) {
            this.callback(response)
          }
        },
        err => {
          if ([419].includes(err.response.status)) return
          if (err.response.status === 400) {
            this.$toasted.error(err.response.data.message)
          } else {
            this.$toasted.error(Language.designs.error.import_failed)
          }
        }
      )
      this.modal.close()

      return promise
    },
    queueImportDesignFromAnotherInstance () {
      this.$toasted.success(Language.designs.success.import_started)
      const promise = this.$store.dispatch("importDesign", {
        campaignUniqueId: this.campaignUniqueId,
        designUniqueId: this.designId,
        apiUrl: this.activeInstance.value,
        email: this.email,
        password: this.password
      }).then(
        response => {
          this.$toasted.success(Language.designs.success.import_design_finished)

          if (this.callback) {
            this.callback(response)
          }
        },
        err => {
          if ([419].includes(err.response.status)) return
          if (err.response.status === 400) {
            this.$toasted.error(err.response.data.message)
          } else {
            this.$toasted.error(Language.designs.error.import_failed)
          }
        }
      )
      this.modal.close()

      return promise
    },
    queueImportDesign () {
      this.$toasted.success(
        Language.designs.success.import_started
      )
      const promise = this.$store
        .dispatch("importDesignFromAnotherCompany", {
          campaignUniqueId: this.campaignUniqueId,
          designUniqueId: this.designId
        })
        .then(
          (response) => {
            this.$toasted.success(Language.designs.success.import_design_finished)

            if (this.callback) {
              this.callback(response)
            }
          },
          (err) => {
            if ([419].includes(err.response.status)) return
            if (err.response.status === 400) {
              this.$toasted.error(err.response.data.message)
            } else {
              this.$toasted.error(Language.designs.error.import_failed)
            }
          }
        )
      this.modal.close()

      return promise
    }
  }
}
</script>
