<template>
  <div
    class="sizing-entry"
    :class="{active}"
    @click.prevent="$emit('click', $event)"
  >
    <div class="device-preview">
      <inline-svg :src="require(`../../../../images/deviceSizings/${type}.svg`)" />
    </div>
    <div
      class="info-wrapper"
      style="display:flex;flex-direction: column; align-items:start"
    >
      <div
        v-if="title"
        class="sizing-title"
      >
        {{ title }}
      </div>
      <div
        v-if="sizing[0] && sizing[1]"
        class="sizing-value"
      >
        {{ sizing.join(' x ') }}
      </div>
    </div>
  </div>
</template>
<script>
import InlineSvg from "vue-inline-svg"

export default {
  components: {
    InlineSvg
  },
  props: {
    title: {
      type: String,
      default: null
    },
    type: {
      type: String,
      required: true
    },
    sizing: {
      type: Array,
      default: () => [null, null]
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"]
}
</script>
