<template>
  <div
    class="controls-switch"
    :class="{'has-label': label.length > 0}"
    @click.prevent.stop="toggle"
  >
    <label :for="uuid">
      <input
        :id="uuid"
        ref="toggle"
        :checked="value && disabled === false"
        :disabled="disabled"
        :false-value="falseValue"
        :true-value="trueValue"
        type="checkbox"
        :value="value"
        @input="emitInput"
      />
      <span class="custom-switch-new"></span>
      <span
        v-if="tooltip && tooltip.length"
        v-tippy
        class="ml-1 mr-auto"
        :content="tooltip"
      >
        <icon :name="icon" />
      </span>
      {{ label }}
    </label>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid"

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    tooltip: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    trueValue: {
      type: [Boolean, String, Number],
      default: true
    },
    falseValue: {
      type: [Boolean, String, Number],
      default: false
    }
  },
  emits: ["input", "update:value"],
  created () {
    this.uuid = uuidv4()
  },
  methods: {
    toggle () {
      const event = new Event("input", {
        target: this.$refs.toggle.$el,
        bubbles: true,
        cancelable: true
      })
      this.$emit("input", !this.value, event)
      this.$emit("update:value", !this.value, event)
    },
    emitInput (val) {
      this.$emit("input", val)
      this.$emit("update:value", val)
    }
  }
}
</script>
