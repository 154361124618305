<template>
  <div class="user-groups-list">
    <h1 v-if="activeTab === 'users'">
      User Management
    </h1>
    <h1 v-if="activeTab === 'groups'">
      Group Management
    </h1>
    <tabs
      class="clean no-bg tabs--aligned"
      :init-active-tab="'users'"
      @change-tab="changeActiveTab"
    >
      <template #header>
        <nav class="top-actions">
          <template v-if="activeTab === 'users'">
            <primary-button @click.prevent="showInviteModal">
              <icon name="add" />
              Invite User
            </primary-button>
            <filter-enabler @change="emitToggleFilters( 'user-list-filters')" />
          </template>
          <template v-else>
            <primary-button @click.prevent="showGroupModal">
              <icon name="add" />
              Create Group
            </primary-button>
            <filter-enabler @change="emitToggleFilters('group-list-filters')" />
          </template>
        </nav>
      </template>
      <template #content>
        <tab
          :is-active="activeTab==='users'"
          title="Users"
        >
          <transition
            appear
            mode="out-in"
            name="fadeIn"
          >
            <admin-user-list />
          </transition>
        </tab>
        <tab
          :is-active="activeTab==='groups'"
          title="Groups"
        >
          <transition
            appear
            mode="out-in"
            name="fadeIn"
          >
            <groups-list />
          </transition>
        </tab>
      </template>
    </tabs>
  </div>
</template>
<script>
import InviteModal from "../common/modal/InviteModal"
import GroupModal from "../common/modal/GroupModal"
import GroupsList from "../groups/List"
import AdminUserList from "../admin/user/List"

export default {
  name: "UsersGroupsList",
  components: {
    GroupsList,
    AdminUserList
  },
  data () {
    return {
      activeTab: "users"
    }
  },
  methods: {
    emitToggleFilters (val) {
      this.$eventHub.$emit("toggle-filters", val)
    },
    changeActiveTab (tab) {
      this.activeTab = tab.title.toLowerCase()
    },
    showInviteModal () {
      this.$modal.show(InviteModal, {
        success () {
          this.$eventHub.$emit("load-users")
        }
      })
    },
    showGroupModal () {
      this.$modal.show(GroupModal, {
        success () {
          this.$eventHub.$emit("load-groups")
        }
      })
    }
  }
}
</script>
