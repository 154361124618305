export const addAsset = (uuid, filename, options = {}, imageOptions = {}) => {
  window.postMessage({
    type: "addModule",
    params: {
      uuid,
      moduleName: filename,
      type: "assetModule",
      ...options
    }
  }, "*")
  window.postMessage({
    type: "setModuleImageByName",
    params: {
      filename,
      moduleId: uuid,
      ...imageOptions
    }
  }, "*")
}
