<template>
  <div class="layout-clean view-auth-login">
    <div class="main">
      <div class="content">
        <div class="authorization-page login-page">
          <div class="images">
            <img
              class="login-image"
              :src="require('../../../images/authorization/login/pic-main.svg')"
            />
            <div class="login-elements">
              <div class="svg-element play"></div>
              <div class="svg-element like"></div>
              <div class="svg-element pic"></div>
              <div class="svg-element comment"></div>
            </div>
          </div>
          <div class="authorization-page-right-container">
            <div class="authorization-page-right">
              <div class="logo">
                <inline-svg :src="require('../../../images/logo-adtron-white.svg')" />
              </div>
              <div class="heading">
                <h3>Hello</h3>
                <h2>Welcome Back</h2>
                <p>Don't have an account? <a href="https://adtron.io">Learn more</a></p>
              </div>
              <div class="authorization-form">
                <div class="inputs">
                  <text-input
                    id="email"
                    v-model:value="emailVal"
                    :error="hasInvalidEmail"
                    label="Email address"
                    :min-width="300"
                    :placeholder="hasInvalidEmail ? errorMessage : emailPlaceholder"
                    :required="true"
                    type="email"
                    @keyup="verifyEmail($event)"
                    @keyup.enter="submit"
                  />
                  <text-input
                    id="password"
                    v-model:value="passwordVal"
                    :error="hasInvalidPassword"
                    label="Password"
                    :min-width="300"
                    :placeholder="hasInvalidPassword ? errorMessage : passwordPlaceholder"
                    :required="true"
                    type="password"
                    @keyup.enter="submit"
                  />
                </div>
                <div class="notes">
                  <checkbox
                    id="remember_me"
                    v-model:value="form.remember"
                    label="Keep me logged in"
                  />
                  <a href="/password/recovery">Forgot your password?</a>
                </div>
                <primary-button
                  id="submit"
                  :min-width="135"
                  size="xl"
                  @click="submit"
                >
                  Login
                </primary-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InlineSvg from "vue-inline-svg"
import { Form } from "@/classes/form"
import Language from "../../i18n/en"
import Utils from "../../utils"
import debounce from "lodash/debounce"
import { SecurityRoutes } from "../../api/routes"

export default {
  components: {
    InlineSvg
  },
  data () {
    return {
      errorMessage: "",
      hasInvalidEmail: false,
      hasInvalidPassword: false,
      form: new Form({
        email: "",
        password: "",
        remember: false
      }, true),
      wasAutofilled: false
    }
  },
  computed: {
    passwordVal: {
      get () {
        return this.form.password
      },
      set (val) {
        this.hasInvalidPassword = false
        this.form.password = val
        this.wasAutofilled = false
      }
    },
    emailVal: {
      get () {
        return this.form.email
      },
      set (val) {
        this.hasInvalidEmail = false
        this.form.email = val
        this.wasAutofilled = false
      }
    },
    emailPlaceholder () {
      return this.wasAutofilled ? "" : "Enter your email address"
    },
    passwordPlaceholder () {
      return this.wasAutofilled ? "" : "Enter your password"
    },
    Utils () {
      return Utils
    }
  },
  mounted () {
    setTimeout(() => {
      if (document.querySelector("input:-webkit-autofill")) {
        this.wasAutofilled = true
      }
    }, 100)
  },
  methods: {
    verifyEmail: debounce(function (e) {
      if (!e.target.value.length) {
        this.hasInvalidEmail = false
        return
      }

      this.hasInvalidEmail = !Utils.validateEmail(e.target.value)
    }, 500),
    submit () {
      if (this.form.email.length === 0) {
        this.$toasted.error(Language.login.error.email_empty)
        return
      }

      if (this.form.password.length === 0) {
        this.hasInvalidPassword = true
        this.$toasted.error(Language.login.error.password_empty)
        return
      }

      return this.form
        .post(SecurityRoutes.login())
        .then(this.onSuccess)
        .catch(this.onError)
    },
    onSuccess () {
      this.$toasted.success(Language.login.success.login)
      setTimeout(() => {
        window.location.href = "/company?autoRedirect=1"
      }, 1500)
    },
    onError () {
      const errorMessage = Object.values(Object.values(this.form.errors)[0])[0] || ""
      if (errorMessage.length > 0 && errorMessage === "Invalid credentials") {
        this.passwordVal = ""
        this.hasInvalidPassword = true
        this.errorMessage = errorMessage
      }
    }
  }
}
</script>
