export const LAB_WAGAWIN_DOMAIN = "wagawin.io"
export const BETA_WAGAWIN_DOMAIN = "beta.wagawin.io"
export const TEST_WAGAWIN_DOMAIN = "test.wagawin.io"
export const DEV_WAGAWIN_DOMAIN = "dev.wagawin.io"

export const wagawinVideoPollElegant = [
  {
    type: "videoPollTextFont",
    value: "Work Sans"
  },
  {
    type: "videoControlsColor",
    value: "#FFFFFF"
  },
  {
    type: "backgroundColor",
    value: "#FFFFFF"
  },
  {
    type: "backgroundLinearGradient",
    value: "linear-gradient(180deg, #000000 0%, #000000 50%)"
  },
  {
    type: "timebarForegroundColor",
    value: "#FFFFFF"
  },
  {
    type: "timebarBackgroundColor",
    value: "#FFFFFF40"
  },
  {
    type: "closeButtonBackgroundColor",
    value: "#d1d5db"
  },
  {
    type: "closeButtonIconColor",
    value: "#000000"
  },
  {
    type: "closeButtonBorderRadius",
    value: 0
  },
  {
    type: "questionTextColor",
    value: "#d1d5db"
  },
  {
    type: "questionBackgroundColor",
    value: "#000000"
  },
  {
    type: "questionAnswersOpenBackgroundColor",
    value: "#d1d5db"
  },
  {
    type: "questionAnswersOpenFontColor",
    value: "#000000"
  },
  {
    type: "answerTextColor",
    value: "#000000"
  },
  {
    type: "answerBackgroundColor",
    value: "#ffffff"
  },
  {
    type: "answerBorderColor",
    value: "#000000"
  },
  {
    type: "answerBorderWidth",
    value: 1
  },
  {
    type: "answersBorderRadius",
    value: 0
  },
  {
    type: "answerOverlayBackgroundColor",
    value: "#FFFFFF80"
  },
  {
    type: "answerOverlayBackgroundBlur",
    value: 5
  },
  {
    type: "percentageBackgroundColor",
    value: "#d1d5db"
  },
  {
    type: "selectedAnswerBackgroundColor",
    value: "#1d4ed8"
  },
  {
    type: "answersFontSize",
    value: 40
  },
  {
    type: "ctaButtonTextFont",
    value: "Work Sans"
  },
  {
    type: "ctaButtonTextColor",
    value: "#ffffff"
  },
  {
    type: "ctaButtonBackgroundColor",
    value: "#1a1a1a"
  },
  {
    type: "ctaButtonBorderRadius",
    value: 0
  },
  {
    type: "ctaButtonBorderColor",
    value: "#000000"
  },
  {
    type: "ctaButtonBorderWidth",
    value: 0
  }
]

export const wagawinVideoPollBrutalist = [
  {
    type: "videoPollTextFont",
    value: "Roboto Mono"
  },
  {
    type: "videoControlsColor",
    value: "#FFFFFF"
  },
  {
    type: "backgroundColor",
    value: "#FFFFFF"
  },
  {
    type: "backgroundLinearGradient",
    value: "linear-gradient(180deg, #000000 0%, #000000 50%)"
  },
  {
    type: "timebarForegroundColor",
    value: "#FFFFFF"
  },
  {
    type: "timebarBackgroundColor",
    value: "#FFFFFF40"
  },
  {
    type: "closeButtonBackgroundColor",
    value: "#d4d4d4"
  },
  {
    type: "closeButtonIconColor",
    value: "#000000"
  },
  {
    type: "closeButtonBorderRadius",
    value: 0
  },
  {
    type: "questionTextColor",
    value: "#FFFFFF"
  },
  {
    type: "questionBackgroundColor",
    value: "#e10a75"
  },
  {
    type: "questionAnswersOpenBackgroundColor",
    value: "#000000"
  },
  {
    type: "questionAnswersOpenFontColor",
    value: "#e10a75"
  },
  {
    type: "answerTextColor",
    value: "#ffffff"
  },
  {
    type: "answerBackgroundColor",
    value: "#000000"
  },
  {
    type: "answerBorderColor",
    value: "#000000"
  },
  {
    type: "answerBorderWidth",
    value: 1
  },
  {
    type: "answersBorderRadius",
    value: 0
  },
  {
    type: "answerOverlayBackgroundColor",
    value: "#FFFFFFFF"
  },
  {
    type: "answerOverlayBackgroundBlur",
    value: 5
  },
  {
    type: "percentageBackgroundColor",
    value: "#e10a75"
  },
  {
    type: "selectedAnswerBackgroundColor",
    value: "#f9a8d4"
  },
  {
    type: "answersFontSize",
    value: 40
  },
  {
    type: "ctaButtonTextFont",
    value: "Roboto Mono"
  },
  {
    type: "ctaButtonTextColor",
    value: "#ffffff"
  },
  {
    type: "ctaButtonBackgroundColor",
    value: "#e10a75"
  },
  {
    type: "ctaButtonBorderRadius",
    value: 0
  },
  {
    type: "ctaButtonBorderColor",
    value: "#ffffff"
  },
  {
    type: "ctaButtonBorderWidth",
    value: 0
  }
]

export const wagawinVideoPollPlayful = [
  {
    type: "videoPollTextFont",
    value: "Poppins"
  },
  {
    type: "videoControlsColor",
    value: "#FFFFFF"
  },
  {
    type: "backgroundColor",
    value: "#FFFFFF"
  },
  {
    type: "backgroundLinearGradient",
    value: "linear-gradient(180deg, #000000 0%, #000000 50%)"
  },
  {
    type: "timebarForegroundColor",
    value: "#FFFFFF"
  },
  {
    type: "timebarBackgroundColor",
    value: "#FFFFFF40"
  },
  {
    type: "closeButtonBackgroundColor",
    value: "#d4d4d4"
  },
  {
    type: "closeButtonIconColor",
    value: "#000000"
  },
  {
    type: "closeButtonBorderRadius",
    value: 100
  },
  {
    type: "questionTextColor",
    value: "#ffffff"
  },
  {
    type: "questionBackgroundColor",
    value: "#CD9504"
  },
  {
    type: "questionAnswersOpenBackgroundColor",
    value: "#a3a3a3"
  },
  {
    type: "questionAnswersOpenFontColor",
    value: "#ffffff"
  },
  {
    type: "answerTextColor",
    value: "#000000"
  },
  {
    type: "answerBackgroundColor",
    value: "#ffffff"
  },
  {
    type: "answerBorderColor",
    value: "#000000"
  },
  {
    type: "answerBorderWidth",
    value: 0
  },
  {
    type: "answersBorderRadius",
    value: 25
  },
  {
    type: "answerOverlayBackgroundColor",
    value: "#FFFFFFF80"
  },
  {
    type: "answerOverlayBackgroundBlur",
    value: 5
  },
  {
    type: "percentageBackgroundColor",
    value: "#d4d4d4"
  },
  {
    type: "selectedAnswerBackgroundColor",
    value: "fbbf24"
  },
  {
    type: "answersFontSize",
    value: 40
  },
  {
    type: "ctaButtonTextFont",
    value: "Poppins"
  },
  {
    type: "ctaButtonTextColor",
    value: "#ffffff"
  },
  {
    type: "ctaButtonBackgroundColor",
    value: "#fbbf24"
  },
  {
    type: "ctaButtonBorderRadius",
    value: 100
  },
  {
    type: "ctaButtonBorderColor",
    value: "#000000"
  },
  {
    type: "ctaButtonBorderWidth",
    value: 0
  }
]

export const wagawinHotOrNotElegant = [
  {
    type: "cardBorderRadius",
    value: 0
  },
  {
    type: "cardBoxShadow",
    value: "0px 5px 20px -7px rgba(0,0,0,0.75)"
  },
  {
    type: "closeButtonBackgroundColor",
    value: "#a3a3a3"
  },
  {
    type: "closeButtonIconColor",
    value: "#ffffff"
  },
  {
    type: "paginationBulletColor",
    value: "#d1d5db"
  },
  {
    type: "paginationBulletActiveColor",
    value: "#000000"
  },
  {
    type: "arrowColor",
    value: "#9ca3af"
  },
  {
    type: "progressTextColor",
    value: "#000000"
  },
  {
    type: "progressBackgroundColor",
    value: "#d1d5db"
  },
  {
    type: "progressBarColor",
    value: "#000000"
  },
  {
    type: "hotButtonTextColor",
    value: "#ffffff"
  },
  {
    type: "hotButtonBackgroundColor",
    value: "#000000"
  },
  {
    type: "hotButtonBorderRadius",
    value: 0
  },
  {
    type: "hotButtonBorderColor",
    value: "#000000"
  },
  {
    type: "hotButtonBorderWidth",
    value: 3
  },
  {
    type: "hotAndNotButtonBoxShadow",
    value: "0px 0px 0px 0px rgba(0,0,0,1.0)"
  },
  {
    type: "notAndHotButtonTextFont",
    value: "Work Sans"
  },
  {
    type: "notButtonTextColor",
    value: "#000000"
  },
  {
    type: "notButtonBackgroundColor",
    value: "#ffffff"
  },
  {
    type: "notButtonBorderRadius",
    value: 0
  },
  {
    type: "notButtonBorderColor",
    value: "#000000"
  },
  {
    type: "notButtonBorderWidth",
    value: 3
  },
  {
    type: "ctaButtonTextFont",
    value: "Work Sans"
  },
  {
    type: "ctaButtonTextColor",
    value: "#ffffff"
  },
  {
    type: "ctaButtonBackgroundColor",
    value: "#000000"
  },
  {
    type: "ctaButtonBorderRadius",
    value: 0
  },
  {
    type: "ctaButtonBorderColor",
    value: "#000000"
  },
  {
    type: "ctaButtonBorderWidth",
    value: 0
  },
  {
    type: "ctaButtonBoxShadow",
    value: "0px 0px 0px 0px rgba(0,0,0,1.0)"
  }
]

export const wagawinHotOrNotBrutalist = [
  {
    type: "cardBorderRadius",
    value: 0
  },
  {
    type: "cardBoxShadow",
    value: "0px 10px 20px 0px rgba(0,0,0,0.75)"
  },
  {
    type: "closeButtonBackgroundColor",
    value: "#a3a3a3"
  },
  {
    type: "closeButtonIconColor",
    value: "#ffffff"
  },
  {
    type: "paginationBulletColor",
    value: "#d1d5db"
  },
  {
    type: "paginationBulletActiveColor",
    value: "#000000"
  },
  {
    type: "arrowColor",
    value: "#9ca3af"
  },
  {
    type: "progressTextColor",
    value: "#000000"
  },
  {
    type: "progressBackgroundColor",
    value: "#000000"
  },
  {
    type: "progressBarColor",
    value: "#e10a75"
  },
  {
    type: "hotButtonTextColor",
    value: "#fcd34d"
  },
  {
    type: "hotButtonBackgroundColor",
    value: "#e10a75"
  },
  {
    type: "hotButtonBorderRadius",
    value: 0
  },
  {
    type: "hotButtonBorderColor",
    value: "#000000"
  },
  {
    type: "hotButtonBorderWidth",
    value: 1
  },
  {
    type: "hotAndNotButtonBoxShadow",
    value: "6px 6px 0px 0px rgba(0,0,0,1.0)"
  },
  {
    type: "notAndHotButtonTextFont",
    value: "Roboto Mono"
  },
  {
    type: "notButtonTextColor",
    value: "#fcd34d"
  },
  {
    type: "notButtonBackgroundColor",
    value: "#000000"
  },
  {
    type: "notButtonBorderRadius",
    value: 0
  },
  {
    type: "notButtonBorderColor",
    value: "#000000"
  },
  {
    type: "notButtonBorderWidth",
    value: 3
  },
  {
    type: "ctaButtonTextFont",
    value: "Roboto Mono"
  },
  {
    type: "ctaButtonTextColor",
    value: "#000000"
  },
  {
    type: "ctaButtonBackgroundColor",
    value: "#ffffff"
  },
  {
    type: "ctaButtonBorderRadius",
    value: 0
  },
  {
    type: "ctaButtonBorderColor",
    value: "#000000"
  },
  {
    type: "ctaButtonBorderWidth",
    value: 1
  },
  {
    type: "ctaButtonBoxShadow",
    value: "2px 6px 0px 0px rgba(0,0,0,1.0)"
  }
]

export const wagawinHotOrNotPlayful = [
  {
    type: "cardBorderRadius",
    value: 10
  },
  {
    type: "cardBoxShadow",
    value: "0px 5px 20px -7px rgba(251,191,36,1.0)"
  },
  {
    type: "closeButtonBackgroundColor",
    value: "#a3a3a3"
  },
  {
    type: "closeButtonIconColor",
    value: "#ffffff"
  },
  {
    type: "paginationBulletColor",
    value: "#d1d5db"
  },
  {
    type: "paginationBulletActiveColor",
    value: "#000000"
  },
  {
    type: "arrowColor",
    value: "#9ca3af"
  },
  {
    type: "progressTextColor",
    value: "#000000"
  },
  {
    type: "progressBackgroundColor",
    value: "#d1d5db"
  },
  {
    type: "progressBarColor",
    value: "#fbbf24"
  },
  {
    type: "hotButtonTextColor",
    value: "#fbbf24"
  },
  {
    type: "hotButtonBackgroundColor",
    value: "#000000"
  },
  {
    type: "hotButtonBorderRadius",
    value: 100
  },
  {
    type: "hotButtonBorderColor",
    value: "#fbbf24"
  },
  {
    type: "hotButtonBorderWidth",
    value: 0
  },
  {
    type: "hotAndNotButtonBoxShadow",
    value: "0px 0px 0px 0px rgba(255,255,255,1.0)"
  },
  {
    type: "notAndHotButtonTextFont",
    value: "Poppins"
  },
  {
    type: "notButtonTextColor",
    value: "#000000"
  },
  {
    type: "notButtonBackgroundColor",
    value: "#fbbf24"
  },
  {
    type: "notButtonBorderRadius",
    value: 100
  },
  {
    type: "notButtonBorderColor",
    value: "#fbbf24"
  },
  {
    type: "notButtonBorderWidth",
    value: 0
  },
  {
    type: "ctaButtonTextFont",
    value: "Poppins"
  },
  {
    type: "ctaButtonTextColor",
    value: "#000000"
  },
  {
    type: "ctaButtonBackgroundColor",
    value: "#fbbf24"
  },
  {
    type: "ctaButtonBorderRadius",
    value: 100
  },
  {
    type: "ctaButtonBorderColor",
    value: "#fbbf24"
  },
  {
    type: "ctaButtonBorderWidth",
    value: 0
  },
  {
    type: "ctaButtonBoxShadow",
    value: "0px 0px 0px 0px rgba(255,255,255,1.0)"
  }
]

export const wagawinVideoPollPresetStyles = {
  elegant: wagawinVideoPollElegant,
  brutalist: wagawinVideoPollBrutalist,
  playful: wagawinVideoPollPlayful
}

export const wagawinHotOrNotPresetStyles = {
  elegant: wagawinHotOrNotElegant,
  brutalist: wagawinHotOrNotBrutalist,
  playful: wagawinHotOrNotPlayful
}
