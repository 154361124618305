import { v4 as uuidv4 } from "uuid"
import {
  GestureModuleType,
  LightweightSwiperGroupModuleType,
  SurveySliderModuleType,
  SwiperGroupModuleType,
  VideoControlsModuleType,
  VideoModuleType,
  VideoStoryModuleType
} from "@/components/designer/module_types/types"
import Utils from "../utils"
import { EventManager } from "./eventManager"
import { MIN_VIDEO_CONTROLS_HEIGHT } from "@/constants"

export class VariantManager {
  static get VARIANT_S () {
    return "s"
  }

  static get VARIANT_M () {
    return "m"
  }

  static get VARIANT_L () {
    return "l"
  }

  static get BASE_VARIANTS () {
    return {
      S: { name: "S (320x480)", dimensions: [320, 480] },
      M: { name: "M (375x667)", dimensions: [375, 667] },
      L: { name: "L (414x896)", dimensions: [414, 896] }
    }
  }

  /* * Returns default variant
   * @return {array<Object>} defaultVariant
  */
  static get DEFAULT_VARIANT () {
    return this.BASE_VARIANTS.S
  }

  /* * Returns base variant
   * @param {object} variant
   * @param {array} variants
   */
  static getBaseVariant ({ variant, variants }) {
    const sortedVariants = this.getSortedVariants({ variants, order: "desc" })
    const filteredVariants = sortedVariants.filter(v => !(v.dimensions[0] === variant.dimensions[0] && v.dimensions[1] === variant.dimensions[1]))
    const baseVariant = filteredVariants.find(v => v.dimensions[0] <= variant.dimensions[0] && v.dimensions[1] <= variant.dimensions[1])

    return baseVariant || sortedVariants[0]
  }

  /**
     * Returns sorted variants
   * @return {variant[]} sortedVariants
   * @param {variant} variant
     * @param {string} order
     */
  static getSortedVariants ({ variants, order = "asc" }) {
    const sortedVariants = Utils.cloneDeep(variants).sort((v1, v2) => v1.dimensions[1] - v2.dimensions[1] || v1.dimensions[0] - v2.dimensions[0])

    if (order === "asc") {
      return sortedVariants
    } else {
      return sortedVariants.reverse()
    }
  }

  /**
     * Returns active variant width
   * @param {design} design
     */
  static getActiveVariantWidth (design) {
    let width = this.BASE_VARIANTS.S.dimensions[0]
    const activeVariant = design.variants.find(v => v.active === true)

    if (activeVariant) {
      width = activeVariant.dimensions[0]
    }

    return width
  }

  /**
   * Returns active variant height
   * @param {design} design
   */
  static getActiveVariantHeight (design) {
    let height = this.BASE_VARIANTS.S.dimensions[1]
    const activeVariant = design.variants.find(v => v.active === true)

    if (activeVariant) {
      height = activeVariant.dimensions[1]
    }

    return height
  }

  /**
     * Returns true if given variant has the same dimensions as the default one
   * @param {variant} variant
     */
  static isDefaultVariant (variant) {
    return variant.dimensions.join("x") === this.DEFAULT_VARIANT.dimensions.join("x")
  }

  /**
   * Returns true if given variant is a base variant
   * @param {variant} variant
   * @param {array} availableVariants
   */
  static isBaseVariant (variant, availableVariants) {
    const baseVariantsDimensionsStrings = availableVariants.map(v => {
      if (variant.deviceOrientation === "landscape") {
        const landscapeDimensions = [v.dimensions[1], v.dimensions[0]]
        return landscapeDimensions.join("x")
      }
      return v.dimensions.join("x")
    })

    return baseVariantsDimensionsStrings.includes(variant.dimensions.join("x"))
  }

  /**
   * Returns synchronized variants
   * 1. Find active and inactive variants
   * 2. Get uuids of active variant scenes
   * 3. Loop through every inactive variant
   * 3.1 Check if any of scenes were removed
   * 3.2 We loop through activeVariantScenesUuids to check if new scenes were added,
   * if yes, we clone them, and clear the scene.modules property, since modules will be synchronized in the next steps
   * 3.3 Deep clone updated scenes, to remove reactive objects
   * 3.4 Sort scenes to be in the same order between all variants
   * @return {variant[]} synchronized variants
   * @param {variant[]} variants
   */
  static synchronizeScenesBetweenVariants (variants) {
    variants = Utils.cloneDeep(variants)

    const activeVariant = variants.find(v => v.active === true)
    const inactiveVariants = variants.filter(v => v.active === false)

    const activeVariantScenesUuids = activeVariant.scenes.map(s => s.uuid)

    inactiveVariants.forEach(v => {
      // Filter out removed scenes
      const filteredScenes = v.scenes.filter(s => activeVariantScenesUuids.includes(s.uuid))

      // Add missing scenes
      const missingScenes = []
      const variantScenesUuids = filteredScenes.map(s => s.uuid)
      activeVariantScenesUuids.forEach(uuid => {
        if (!variantScenesUuids.includes(uuid)) {
          const missingScene = activeVariant.scenes.find(s => s.uuid === uuid)
          const clonedMissingScene = Utils.cloneDeep(missingScene)
          clonedMissingScene.modules = []
          missingScenes.push(clonedMissingScene)
        }
      })
      const updatedScenes = [...filteredScenes, ...missingScenes]

      // Deep clone updated scenes
      const clonedScenes = Utils.cloneDeep(updatedScenes)

      // Sort scenes
      v.scenes = clonedScenes.sort((s1, s2) => {
        return activeVariant.scenes.findIndex(s => s.uuid === s1.uuid) - activeVariant.scenes.findIndex(s => s.uuid === s2.uuid)
      })
    })

    return variants
  }

  /**
   * Synchronizes modules between variants
   * 1. Find active and inactive variants
   * 2. Get active variant modules uuids
   * 3. Loop through inactive variants
   * 3.1 Filter inactive variant modules by checking if module exists in active variant modules
   * 3.2 Make a diff of active and inactive variant modules - check which modules needs to be added
   * 3.3 Deep clone missing modules to remove reactive properties
   * 3.4 Loop through cloned missing modules and clean timeline/active properties
   * 3.5 Find root modules of cloned missing modules (modules that do not have its parent in the missing modules array)
   * 3.6 Loop through root modules and resize it and its modules accordingly
   * 3.7 Loop through all modules and sync data of modules between variants
   * 3.8 Sort modules to be in the same order as in the active variant
   * 3.9 Assign modules to correct scenes
   * 4. Return variants with synchronized modules
   * @return {variant[]} variants with synchronized modules
   * @param {variant[]} variants
   * @param {asset[]} assets
   */
  static synchronizeModulesBetweenVariants (variants, assets) {
    variants = Utils.cloneDeep(variants)

    const activeVariant = variants.find(v => v.active === true)
    const inactiveVariants = variants.filter(v => v.active === false)
    const sortedVariants = VariantManager.getSortedVariants({ variants, order: "asc" })

    const activeVariantModules = Utils.getAllModules(activeVariant.scenes)
    const activeVariantModulesUuids = activeVariantModules.map(m => m.uuid)

    inactiveVariants.forEach(variant => {
      const variantModules = Utils.getAllModules(variant.scenes)

      // Filter out removed modules
      const modules = Utils.cloneDeep(variantModules.filter(m => activeVariantModulesUuids.includes(m.uuid)))

      // Add missing modules
      const variantModulesUuids = modules.map(m => m.uuid)
      const missingModules = []

      activeVariantModulesUuids.forEach(uuid => {
        if (!variantModulesUuids.includes(uuid)) {
          missingModules.push(activeVariantModules.find(m => m.uuid === uuid))
        }
      })

      // Clone deep missing modules
      const clonedModules = Utils.cloneDeep(missingModules)

      // Clean cloned modules
      clonedModules.forEach(module => {
        module.timeline = []
        module.active = false

        let defaultVariant = sortedVariants.find(s => s.dimensions[0] === 320 && s.dimensions[1] === 480)

        if (!defaultVariant) {
          defaultVariant = sortedVariants[0]
        }

        if (defaultVariant && !defaultVariant.active) {
          if (module.type !== VideoControlsModuleType) {
            module.preview.hidden = true
          }
        }

        module.wasMissing = true
        modules.push(module)
      })

      // Transform cloned modules
      const clonedModulesUuids = clonedModules.map(m => m.uuid)
      const rootModules = clonedModules.filter(m => clonedModulesUuids.includes(m.parentModuleId) === false)

      rootModules.forEach(module => {
        const ratioX = variant.dimensions[0] / activeVariant.dimensions[0]
        const ratioY = variant.dimensions[1] / activeVariant.dimensions[1]

        let parentContainerWidth
        let parentContainerHeight

        const parentModule = Utils.getAllModules(variant.scenes).find(m => m.uuid === module.parentModuleId)

        if (parentModule) {
          parentContainerWidth = parentModule.width
          parentContainerHeight = parentModule.height
        } else {
          parentContainerWidth = variant.dimensions[0]
          parentContainerHeight = variant.dimensions[1]
        }

        this.setUpModuleTransformationsForNewDesignVariant(
          module,
          modules,
          assets,
          ratioX,
          ratioY,
          parentContainerWidth,
          parentContainerHeight
        )
      })

      // Sync modules data
      modules.forEach(updatedModule => {
        updatedModule.preview.active = false

        const activeVariantModule = activeVariantModules.find(m => m.uuid === updatedModule.uuid)
        const preservedModule = Utils.cloneDeep(updatedModule)
        updatedModule.data = Utils.cloneDeep(activeVariantModule.data)

        switch (updatedModule.type) {
          case SwiperGroupModuleType:
          case LightweightSwiperGroupModuleType:
            const arrowsPosition = Number(Utils.getModuleDataValue(preservedModule, "arrowsPosition", 0))
            const arrowsPositionX = Number(Utils.getModuleDataValue(preservedModule, "arrowsPositionX", 0))
            Utils.setModuleDataValue(updatedModule, "arrowsPosition", arrowsPosition)
            Utils.setModuleDataValue(updatedModule, "arrowsPositionX", arrowsPositionX)
            break
          default:
            break
        }

        updatedModule.styles = Utils.cloneDeep(activeVariantModule.styles)
        updatedModule.clickable = activeVariantModule.clickable
        updatedModule.sceneId = activeVariantModule.sceneId
        updatedModule.name = activeVariantModule.name
        updatedModule.preview.zIndex = activeVariantModule.preview.zIndex
        if (updatedModule.parentModuleId !== activeVariantModule.parentModuleId) {
          updatedModule.parentModuleId = activeVariantModule.parentModuleId
          const parentModule = modules.find(m => m.uuid === activeVariantModule.parentModuleId)
          const parentModuleWidth = parentModule ? parentModule.preview.width : variant.dimensions[0]
          const parentModuleHeight = parentModule ? parentModule.preview.height : variant.dimensions[1]
          updatedModule.preview.percentWidth = Utils.calculateRelativeValue(updatedModule.preview.width, parentModuleWidth)
          updatedModule.preview.percentHeight = Utils.calculateRelativeValue(updatedModule.preview.height, parentModuleHeight)
        }
        if (updatedModule.type === VideoControlsModuleType && updatedModule.wasMissing) {
          const parentModule = modules.find(m => m.uuid === activeVariantModule.parentModuleId)
          const transform = Utils.parseTransform(updatedModule.preview.transform)
          const translateY = (Utils.translateToFloat(transform.translateY) / parentModule.height)

          updatedModule.preview.height = MIN_VIDEO_CONTROLS_HEIGHT
          updatedModule.preview.percentHeight = Utils.calculateRelativeValue(updatedModule.preview.height, parentModule.preview.height)

          transform.translateY = (translateY * updatedModule.preview.height).toFixed(2) + "px"
          updatedModule.preview.transform = Utils.encodeTransform(transform)
        }
      })

      // Sort modules to be in the same order as in the active variant
      modules.sort((a, b) => activeVariantModulesUuids.indexOf(a.uuid) - activeVariantModulesUuids.indexOf(b.uuid))

      // Assign modules to correct scene
      variant.scenes.forEach(scene => {
        scene.modules = modules.filter(m => m.sceneId === scene.uuid)
      })
    })

    return variants
  }

  /**
   * Sets up scenes for new variant
   * @param {scene[]} newScenes
   * @param {variant} variant
   * @param {variant} activeVariant
   * @param {asset[]} assets
   */
  static setUpScenesForNewDesignVariant (
    newScenes,
    variant,
    activeVariant,
    assets
  ) {
    newScenes = Utils.cloneDeep(newScenes)
    newScenes.forEach(scene => {
      const rootModules = scene.modules.filter(m => m.parentModuleId === null)

      const ratioX = variant.dimensions[0] / activeVariant.dimensions[0]
      const ratioY = variant.dimensions[1] / activeVariant.dimensions[1]

      const parentContainerWidth = variant.dimensions[0]
      const parentContainerHeight = variant.dimensions[1]

      rootModules
        .forEach((module) => {
          module.preview.active = false
          this.setUpModuleTransformationsForNewDesignVariant(
            module,
            scene.modules,
            assets,
            ratioX,
            ratioY,
            parentContainerWidth,
            parentContainerHeight
          )
        })
    })

    return newScenes
  }

  /**
   * Sets up modules transformation for new design variant
   * @param {module} module
   * @param {module[]} modules
   * @param {asset[]} assets
   * @param {number} ratioX
   * @param {number} ratioY
   * @param {number} parentContainerWidth
   * @param {number} parentContainerHeight
   * @returns
   */
  static setUpModuleTransformationsForNewDesignVariant (
    module,
    modules,
    assets,
    ratioX = 1,
    ratioY = 1,
    parentContainerWidth,
    parentContainerHeight
  ) {
    const initialModuleWidth = module.preview.width
    const initialModuleHeight = module.preview.height
    const initialWidthToHeightRatio = initialModuleWidth / initialModuleHeight

    module.preview.width = module.preview.width * ratioX
    module.preview.height = module.preview.height * ratioY

    if ([VideoModuleType, VideoStoryModuleType].includes(module.type) && Number(Utils.getModuleDataValue(module, "automaticHeight", 0)) === 1) {
      const assetId = Utils.getModuleDataValue(module, "video", null)
      const asset = assets.find(a => a.id === assetId)
      if (!asset) return
      const ratio = asset.width / asset.height
      module.preview.height = module.preview.width / ratio
    }
    if ([GestureModuleType, SurveySliderModuleType].includes(module.type)) {
      const preview = {
        percentHeight: `${module.preview.width / parentContainerHeight * 100}`,
        height: module.preview.width
      }
      module.preview = { ...module.preview, ...preview }
    }

    if (module.keepRatio) {
      const newHeight = module.preview.width / initialWidthToHeightRatio
      const preview = {
        percentHeight: `${newHeight / parentContainerHeight * 100}`,
        height: newHeight
      }
      module.preview = { ...module.preview, ...preview }
    }

    const oldTransformProperties = Utils.parseTransform(
      module.preview.transform
    )
    const newTransformProperties = {
      translateX:
        Number(oldTransformProperties.translateX.split("px")[0]) * ratioX + "px",
      translateY:
        Number(oldTransformProperties.translateY.split("px")[0]) * ratioY + "px"
    }

    Utils.updateModuleTransformations(module, newTransformProperties)
    module.initialPreview = Utils.cloneDeep(module.preview)

    const moduleRatioX = module.preview.width / initialModuleWidth
    const moduleRatioY = module.preview.height / initialModuleHeight

    const childModules = modules.filter(m => m.parentModuleId === module.uuid)

    childModules.forEach(childModule => {
      this.setUpModuleTransformationsForNewDesignVariant(
        childModule,
        modules,
        assets,
        moduleRatioX,
        moduleRatioY,
        module.preview.width,
        module.preview.height
      )
    })
  }

  /**
   * @param events
   * @param modulesUuids
   * @param variant
   * @returns {array}
   */
  static getNewVariantEvents ({ events, variant, variants }) {
    const baseVariant = VariantManager.getBaseVariant({ variant, variants })

    const eventsWithActiveVariant = events.filter((e) => e.design_variant_uuid === baseVariant.uuid)
    const modulesUuids = Utils.getAllModules(variant.scenes).map(m => m.uuid)

    const ratioW = variant.dimensions[0] / baseVariant.dimensions[0]
    const ratioH = variant.dimensions[1] / baseVariant.dimensions[1]
    const mainEvents = eventsWithActiveVariant.filter(e => {
      // empty timelinesheet
      if ([null, undefined].includes(e.actionTarget) && e.action.hasOwnProperty(EventManager.ACTION_PLAY_TIMELINE_SHEET)) return true

      return modulesUuids.includes(e.actionTarget) && (e.action.hasOwnProperty(EventManager.ACTION_PLAY_ANIMATION) || e.action.hasOwnProperty(EventManager.ACTION_PLAY_CUSTOM_ANIMATION)) && e.trigger.startsWith("with:") === false
    })

    const relatedEvents = []

    const updatedMainEvents = mainEvents.map(e => {
      const oldEventUuid = e.uuid
      const newEventUuid = uuidv4()

      let eventRelatedEvents = eventsWithActiveVariant.filter(r => r.trigger.startsWith(`with:${oldEventUuid}`))
      eventRelatedEvents = eventRelatedEvents.map(relatedEvent => {
        const trigger = relatedEvent.trigger.split(":")
        trigger[1] = newEventUuid

        relatedEvent.trigger = trigger.join(":")
        relatedEvent.design_variant_uuid = variant.uuid

        return relatedEvent
      })

      relatedEvents.push(...eventRelatedEvents)

      e.uuid = newEventUuid
      e.design_variant_uuid = variant.uuid

      return e
    })

    return [...updatedMainEvents, ...relatedEvents].map((e) => {
      if (e.action.hasOwnProperty(EventManager.ACTION_PLAY_ANIMATION) || e.action.hasOwnProperty(EventManager.ACTION_PLAY_CUSTOM_ANIMATION)) {
        const actionKey = Object.keys(e.action)[0]
        const animationFrom = e.action[actionKey].from
        const animationTo = e.action[actionKey].to
        if (animationFrom) {
          e.action[actionKey].from.x = e.action[actionKey].from.x * ratioW
          e.action[actionKey].from.y = e.action[actionKey].from.y * ratioH
        }
        if (animationTo) {
          e.action[actionKey].to.x = e.action[actionKey].to.x * ratioW
          e.action[actionKey].to.y = e.action[actionKey].to.y * ratioH
        }
      }

      return e
    })
  }

  /**
   * Returns new prepared variant
   * @returns {variant}
   * @param {variant} newVariant
   * @param {variant[]} variants
   * @param {scene[]} newScenes
   * @param {asset[]} assets
   */
  static getNewVariant ({ newVariant, variants, assets }) {
    const baseVariant = VariantManager.getBaseVariant({ variant: newVariant, variants })
    const newScenes = VariantManager.setUpScenesForNewDesignVariant(
      Utils.cloneDeep(baseVariant.scenes),
      newVariant,
      baseVariant,
      assets
    )

    return {
      uuid: uuidv4(),
      dimensions: newVariant.dimensions,
      active: true,
      scenes: newScenes,
      name: newVariant.name,
      deviceOrientation: baseVariant.deviceOrientation,
      deviceOrientationLock: true,
      deviceDimensionsLock: false
    }
  }

  /**
   * Saves scenes to active variant
   * @returns {variant[]}
   * @param {variant[]} variants
   * @param {scene[]} scenes
   */
  static saveScenesToActiveVariant (variants, scenes) {
    const sortedVariants = VariantManager.getSortedVariants({ variants, order: "asc" })
    const activeVariant = variants.find(v => v.active === true) || sortedVariants[0]
    activeVariant.scenes = Utils.cloneDeep(scenes)

    return variants
  }

  /**
   * Returns synchronized variants
   * @returns {variant[]}
   * @param {variant[]} variants
   * @param {scene[]} scenes
   * @param {asset[]} assets
   */
  static synchronizeVariants (variants, assets, scenes) {
    const clonedVariants = Utils.cloneDeep(variants)
    const variantsWithSavedScenes = this.saveScenesToActiveVariant(clonedVariants, scenes)
    const variantsWithSyncedScenes = this.synchronizeScenesBetweenVariants(variantsWithSavedScenes)
    return this.synchronizeModulesBetweenVariants(variantsWithSyncedScenes, assets)
  }

  /**
   * Returns synchronized variants
   * @returns {variant}
   * @param baseVariantDimensions
   * @param {variant} variant
   * @param {asset[]} assets
   */
  static updateVariantDimensionsToMatchDevice (baseVariantDimensions, variant, assets) {
    const [width, height] = baseVariantDimensions
    const clonedVariant = Utils.cloneDeep(variant)
    clonedVariant.scenes.forEach((scene) => {
      scene.modules = this.resizeModulesToMatchVariant(scene.modules, assets, [width, height], variant.dimensions)
    })
    clonedVariant.name = width + "x" + height
    clonedVariant.dimensions = [width, height]
    return clonedVariant
  }

  static resizeModulesToMatchVariant (modules, assets, variantDimensions, activeVariantDimensions) {
    const rootModules = modules.filter(m => m.parentModuleId === null)

    const ratioX = variantDimensions[0] / activeVariantDimensions[0]
    const ratioY = variantDimensions[1] / activeVariantDimensions[1]

    const parentContainerWidth = variantDimensions[0]
    const parentContainerHeight = variantDimensions[1]

    rootModules.forEach(module => {
      this.setUpModuleTransformationsForNewDesignVariant(
        module,
        modules,
        assets,
        ratioX,
        ratioY,
        parentContainerWidth,
        parentContainerHeight
      )
    })

    return modules
  }
}
