<template>
  <div class="heading-with-icon">
    <h3>{{ text }}</h3>
    <icon
      v-if="title && title.length"
      class="info-tooltip"
      name="info"
      :tooltip="title"
      :tooltip-max-width="220"
      tooltip-placement="right-start"
    />
  </div>
</template>
<script>

export default {
  props: {
    text: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: null
    }
  }
}
</script>
