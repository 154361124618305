<template>
  <div class="form-group form-group-inline custom-control custom-switch">
    <label :for="id">
      <span v-if="label">{{ label }}</span>
      <span
        v-if="label && required"
        class="required"
      >*</span>
    </label>
    <input
      :id="id"
      class="form-control"
      :class="{ 'is-invalid': errors.has(id) }"
      name="name"
      :placeholder="placeholder"
      type="checkbox"
      :value="form[id]"
      @input="updateForm"
    />
    <span class="custom-switch-element"></span>
    <div
      v-if="errors.has(id)"
      class="invalid-feedback"
      v-text="errors.get(id)"
    />
  </div>
</template>
<script>
import { Errors } from "@/classes/errors"

export default {
  name: "TextField",
  props: {
    id: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    label: {
      type: [String, Boolean],
      default: false
    },
    errors: {
      type: [Object],
      default: () => { return new Errors() }
    },
    form: {
      type: [Object],
      default: () => { return new FormData() }
    }
  },
  emits: ["update-form"],
  methods: {
    updateForm (val) {
      this.$emit("update-form", { id: this.id, val })
    }
  }
}
</script>
