<template>
  <div class="form-group form-group-inline custom-control custom-switch">
    <label for="helper-relative1">Dark mode
      <input
        id="helper-relative1"
        v-model="darkModeActive"
        disabled="disabled"
        name="darkmode"
        true-value="true"
        type="checkbox"
        @change="switchMode"
      >
      <span class="custom-switch-element" />
    </label>
  </div>
</template>
<script>
export default {
  name: "Modeswitcher",
  props: {
    darkMode: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      darkModeActive: this.darkMode || false
    }
  },
  methods: {
    switchMode () {
      const body = document.body
      body.classList.toggle("theme--dark")
      body.classList.toggle("theme--default")
    }
  }
}
</script>
