import mitt from "mitt"
// Global event bus to emit payload between components
const emitter = mitt()
const $eventHub = {
  $on: emitter.on,
  $off: emitter.off,
  $emit: emitter.emit
}

export const useEventHub = () => {
  return $eventHub
}
