import {
  AssetGroupModuleType,
  AssetModuleType,
  BackgroundModuleType,
  BasketModuleType,
  CalendarModuleType,
  Click2MapsModuleType,
  CountdownModuleType,
  CounterModuleType,
  DestinationModuleType,
  GestureModuleType,
  ImageSequenceModuleType,
  LightweightSwiperGroupModuleType,
  MatchModuleType,
  MemoryModuleType,
  OfferistaModuleType,
  PanoModuleType,
  ParticleWipeAdModuleType,
  PollModuleType,
  PollSliderModuleType,
  PopupModuleType,
  PuzzleModuleType,
  ShakeModuleType,
  SlidebarModuleType,
  SliderModuleType,
  StoryModuleType,
  SurveyModuleType,
  SurveySliderModuleType,
  SwiperGroupModuleType,
  TimerModuleType,
  TypoModuleType,
  VideoControlsModuleType,
  VideoModuleType,
  VideoStoryModuleType,
  WhatsappModuleType,
  WipeAdModuleType
} from "./components/designer/module_types/types"
import { EffectType } from "@/components/designer/effect_types/types"

export const TimelinePropertyHeight = 49

// DESIGN PROGRESS
export const DESIGN_PROGRESS_DESIGN_INIT = "design_init"
export const DESIGN_PROGRESS_DESIGN = "design"
export const DESIGN_PROGRESS_REVIEW = "review"
export const DESIGN_PROGRESS_ERROR = "error"
export const DESIGN_PROGRESS_READY_PENDING = "ready_pending"
export const DESIGN_PROGRESS_READY = "ready"
export const DESIGN_PROGRESS_LIVE = "live"
export const DESIGN_PROGRESS_DELIVERED = "delivered"

export const ACTIVE_DESIGN_STATUSES = [DESIGN_PROGRESS_READY, DESIGN_PROGRESS_READY_PENDING, DESIGN_PROGRESS_LIVE, DESIGN_PROGRESS_DELIVERED, DESIGN_PROGRESS_ERROR]

export const DESIGN_CREATION_TYPE_ADTRON = "adtron"
export const DESIGN_CREATION_TYPE_EXTERNAL = "external"

export const PRODUCT_DOOH = "dooh"
export const PRODUCT_LIVING_ADS = "living-ads"
export const PRODUCT_VAST = "vast"

export const PRODUCT_RICH_MEDIA = "rich-media"

export const DEVICE_SIZING_TYPE_RESPONSIVE = "responsive"

export const DEVICE_TYPE_DESKTOP = "desktop"

// MODULES
export const AVAILABLE_GESTURE_MODULES = [
  SwiperGroupModuleType,
  PanoModuleType,
  WipeAdModuleType,
  ParticleWipeAdModuleType,
  ShakeModuleType,
  LightweightSwiperGroupModuleType,
  MatchModuleType
]

export const NON_DUPLICABLE_MODULES = [
  PanoModuleType,
  BasketModuleType,
  ShakeModuleType,
  DestinationModuleType,
  SlidebarModuleType,
  ParticleWipeAdModuleType,
  PollSliderModuleType,
  SurveySliderModuleType,
  OfferistaModuleType,
  VideoStoryModuleType,
  EffectType,
  MatchModuleType
]

export const AVAILABLE_ONLY_IN_ROOT_SCOPE_MODULES = [ShakeModuleType, CounterModuleType]

export const AVAILABLE_ONLY_ONCE_PER_CREATIVE = [
  ShakeModuleType,
  PollModuleType,
  SurveyModuleType,
  VideoStoryModuleType,
  StoryModuleType,
  OfferistaModuleType
]

export const AVAILABLE_ONLY_ONCE_PER_SCENE = [
  PanoModuleType
]

export const AD_FORMATS = {
  interstitial: "interstitial",
  interstitial_320x568: "interstitial_320x568",
  halfPage: "half_page",
  interscroller: "interscroller",
  leaderboard: "leaderboard",
  fullPage: "full_page",
  largeBanner: "large_banner",
  square: "square",
  smallSquare: "small_square",
  custom_responsive: "custom_responsive",
  custom_fixed: "custom_fixed",
  infoscreen: "pv_infoscreen",
  mrec: "mrec",
  roadside_horizontal: "pv_roadside_horizontal",
  roadside_vertical: "pv_roadside_vertical",
  station: "pv_station",
  mall_vertical: "pv_mall_vertical",
  mall_horizontal: "pv_mall_horizontal",
  city_tower: "pv_city_tower",
  city: "pv_city",
  desktop_square: "desktop_square",
  desktop_small_square: "desktop_small_square",
  desktop_billboard: "desktop_billboard",
  desktop_halfpage: "desktop_halfpage",
  desktop_wide_skyscraper: "desktop_wide_skyscraper",
  desktop_leaderboard: "desktop_leaderboard",
  desktop_medium_banner: "desktop_medium_banner",
  desktop_dynamic_sitebar: "desktop_dynamic_sitebar",
  desktop_dynamic_sidebar: "desktop_dynamic_sidebar",
  living_ads_hot_or_not: "living_ads_hot_or_not",
  living_ads_video_poll: "living_ads_video_poll",
  living_ads_tree: "living_ads_tree",
  living_ads_video_quiz: "living_ads_video_quiz",
  living_ads_chat: "living_ads_chat",
  living_ads_emotion: "living_ads_emotion",
  expandable_banner_small: "expandable_banner_small",
  expandable_banner_medium: "expandable_banner_medium",
  expandable_banner_large: "expandable_banner_large",
  vast_interstitial: "vast_interstitial",
  vast_csv_gallery: "vast_csv_gallery"
}

export const RICH_MEDIA_AD_FORMATS = [
  AD_FORMATS.interstitial,
  AD_FORMATS.interstitial_320x568,
  AD_FORMATS.halfPage,
  AD_FORMATS.interscroller,
  AD_FORMATS.leaderboard,
  AD_FORMATS.fullPage,
  AD_FORMATS.largeBanner,
  AD_FORMATS.square,
  AD_FORMATS.smallSquare,
  AD_FORMATS.mrec,
  AD_FORMATS.desktop_square,
  AD_FORMATS.desktop_small_square,
  AD_FORMATS.desktop_billboard,
  AD_FORMATS.desktop_halfpage,
  AD_FORMATS.desktop_wide_skyscraper,
  AD_FORMATS.desktop_leaderboard,
  AD_FORMATS.desktop_medium_banner,
  AD_FORMATS.desktop_dynamic_sitebar,
  AD_FORMATS.desktop_dynamic_sidebar,
  AD_FORMATS.expandable_banner_small,
  AD_FORMATS.expandable_banner_medium,
  AD_FORMATS.expandable_banner_large,
  AD_FORMATS.custom_responsive,
  AD_FORMATS.custom_fixed
]

export const DESKTOP_FORMATS = [
  AD_FORMATS.desktop_square,
  AD_FORMATS.desktop_small_square,
  AD_FORMATS.desktop_billboard,
  AD_FORMATS.desktop_halfpage,
  AD_FORMATS.desktop_wide_skyscraper,
  AD_FORMATS.desktop_leaderboard,
  AD_FORMATS.desktop_medium_banner,
  AD_FORMATS.desktop_dynamic_sitebar,
  AD_FORMATS.desktop_dynamic_sidebar
]

export const MOBILE_FORMATS = [
  AD_FORMATS.interstitial,
  AD_FORMATS.interstitial_320x568,
  AD_FORMATS.halfPage,
  AD_FORMATS.interscroller,
  AD_FORMATS.leaderboard,
  AD_FORMATS.fullPage,
  AD_FORMATS.largeBanner,
  AD_FORMATS.square,
  AD_FORMATS.smallSquare,
  AD_FORMATS.mrec,
  AD_FORMATS.expandable_banner_small,
  AD_FORMATS.expandable_banner_medium,
  AD_FORMATS.expandable_banner_large
]

export const DOOH_AD_FORMATS = [
  AD_FORMATS.infoscreen,
  AD_FORMATS.roadside_horizontal,
  AD_FORMATS.roadside_vertical,
  AD_FORMATS.station,
  AD_FORMATS.mall_vertical,
  AD_FORMATS.mall_horizontal,
  AD_FORMATS.city_tower,
  AD_FORMATS.city
]

export const LIVING_ADS_FORMAT = [
  AD_FORMATS.living_ads_hot_or_not,
  AD_FORMATS.living_ads_video_poll,
  AD_FORMATS.living_ads_tree,
  AD_FORMATS.living_ads_video_quiz,
  AD_FORMATS.living_ads_chat,
  AD_FORMATS.living_ads_emotion
]

export const VAST_ADS_FORMAT = [
  AD_FORMATS.vast_interstitial
]

export const EXPANDABLE_BANNER_FORMATS = [
  AD_FORMATS.expandable_banner_small,
  AD_FORMATS.expandable_banner_medium,
  AD_FORMATS.expandable_banner_large
]

export const FIXED_AD_FORMATS = [
  AD_FORMATS.halfPage,
  AD_FORMATS.leaderboard,
  AD_FORMATS.fullPage,
  AD_FORMATS.largeBanner,
  AD_FORMATS.square,
  AD_FORMATS.smallSquare,
  AD_FORMATS.mrec,
  AD_FORMATS.custom_fixed
]

export const MAX_COMMENT_LENGTH = 500

export const MIN_VIDEO_CONTROLS_HEIGHT = 30
export const MAX_VIDEO_CONTROLS_HEIGHT = 48

export const AVAILABLE_MODULES = [
  {
    title: "Background",
    type: "backgroundModule",
    icon: "module-background"
  },
  {
    title: "Typo",
    type: "typoModule",
    icon: "module-typo"
  },
  {
    title: "Asset",
    type: "assetModule",
    icon: "module-asset"
  },
  {
    title: "Gesture",
    type: "gestureModule",
    icon: "module-gesture"
  },
  {
    title: "Gallery",
    type: "swiperGroupModule",
    icon: "module-gallery"
  },
  {
    title: "Lightweight Gallery",
    type: "lightweightSwiperGroupModule",
    icon: "module-gallery"
  },
  {
    title: "Image Sequence",
    type: "imageSequenceModule",
    icon: "module-image-sequence"
  },
  {
    title: "Match",
    type: "matchModule",
    icon: "module-match",
    isNew: true
  },
  {
    title: "Pano",
    type: "panoModule",
    icon: "module-pano"
  },
  {
    title: "Slidebar",
    type: "slidebarModule",
    icon: "module-slidebar"
  },
  {
    title: "Wipe",
    type: "wipeAdModule",
    icon: "module-wipe"
  },
  {
    title: "Particle Wipe",
    type: "particleWipeAdModule",
    icon: "module-particle-wipe"
  },
  {
    title: "Shake",
    type: "shakeModule",
    icon: "module-shake"
  },
  {
    title: "Countdown",
    type: "countdownModule",
    icon: "module-countdown"
  },
  {
    title: "Story",
    type: "storyModule",
    icon: "module-story"
  },
  {
    title: "Video Story",
    type: "videoStoryModule",
    icon: "module-video-story"
  },
  {
    title: "Poll",
    type: "pollModule",
    icon: "module-poll"
  },
  {
    title: "Survey",
    type: "surveyModule",
    icon: "module-survey"
  },
  {
    title: "WhatsApp",
    type: "whatsappModule",
    icon: "module-whatsapp"
  },
  {
    title: "Calendar",
    type: "calendarModule",
    icon: "module-calendar"
  },
  {
    title: "Popup",
    type: "popupModule",
    icon: "module-popup"
  },
  {
    title: "Particle",
    type: "effectModule",
    icon: "module-particle"
  },
  {
    title: "Google Maps",
    type: "googleMapsModule",
    icon: "module-google-maps",
    isNew: true
  },
  {
    title: "Destination",
    type: "destinationModule",
    icon: "module-destination"
  },
  {
    title: "Click2Maps",
    type: "click2MapsModule",
    icon: "module-click-2-maps"
  },
  {
    title: "Offerista",
    type: "offeristaModule",
    icon: "module-offerista"
  },
  {
    title: "Memory",
    type: "memoryModule",
    icon: "module-memory",
    isNew: true
  },
  {
    title: "Puzzle",
    type: "puzzleModule",
    icon: "module-puzzle",
    isNew: true
  },
  {
    title: "Basket",
    type: "basketModule",
    icon: "module-basket"
  },
  {
    title: "Timer",
    type: "timerModule",
    icon: "module-timer",
    isNew: true
  },
  {
    title: "Counter",
    type: "counterModule",
    icon: "module-counter",
    isNew: true
  },
  {
    title: "Hot or Not",
    type: "hotOrNotModule",
    icon: "module-countdown"
  },
  {
    title: "Privacy",
    type: "wagawinPrivacyModule",
    icon: "module-wagawin-privacy"
  },
  {
    title: "Video Poll",
    type: "wagawinVideoPollModule",
    icon: "module-countdown"
  },
  {
    title: "Vast video",
    type: "vastVideo",
    icon: "module-countdown"
  },
  {
    title: "Slider",
    type: "sliderModule",
    icon: "module-slider",
    isNew: true
  }
]

export const DOMAIN_WAGAWIN = "wagawin"

export const ADTRON_CDN_URL = "cdn.adtron.io"

export const LAB_ADTRON_DOMAIN = "lab.adtron.io"
export const BETA_ADTRON_DOMAIN = "beta.adtron.network"
export const TEST_ADTRON_DOMAIN = "test.adtron.network"
export const DEV_ADTRON_DOMAIN = "dev.adtron.network"

export const baseModules = [
  SliderModuleType,
  VideoModuleType,
  BackgroundModuleType,
  AssetModuleType,
  GestureModuleType,
  TypoModuleType,
  PanoModuleType,
  EffectType,
  SwiperGroupModuleType,
  DestinationModuleType,
  CalendarModuleType,
  AssetGroupModuleType,
  WipeAdModuleType,
  ParticleWipeAdModuleType,
  PopupModuleType,
  SlidebarModuleType,
  WhatsappModuleType,
  BasketModuleType,
  Click2MapsModuleType,
  ShakeModuleType,
  VideoControlsModuleType,
  PollModuleType,
  SurveyModuleType,
  VideoStoryModuleType,
  StoryModuleType,
  LightweightSwiperGroupModuleType,
  CountdownModuleType,
  SurveySliderModuleType,
  TimerModuleType,
  PuzzleModuleType,
  MemoryModuleType,
  ImageSequenceModuleType,
  MatchModuleType
]

export const EDITOR_SCENES_NUMBER_LIMIT = 15

export const AD_FORMATS_WITH_PHONE_MOCKUP = [
  AD_FORMATS.living_ads_hot_or_not,
  AD_FORMATS.living_ads_video_poll,
  AD_FORMATS.interscroller,
  AD_FORMATS.interstitial_320x568,
  AD_FORMATS.interstitial,
  AD_FORMATS.expandable_banner_small,
  AD_FORMATS.expandable_banner_medium,
  AD_FORMATS.expandable_banner_large,
  ...MOBILE_FORMATS
]

export const MODULES_WITH_INITIAL_ANIMATION = [
  MemoryModuleType,
  SwiperGroupModuleType,
  PanoModuleType,
  GestureModuleType,
  SlidebarModuleType,
  SliderModuleType,
  MatchModuleType,
  SurveySliderModuleType,
  PollSliderModuleType
]

export const TIMELINE_SPECIAL_GROUPING_MODULES = [
  SwiperGroupModuleType,
  LightweightSwiperGroupModuleType,
  SlidebarModuleType,
  StoryModuleType,
  MatchModuleType
]
