<template>
  <div class="templates-list">
    <transition
      appear
      mode="out-in"
      name="fadeIn"
    >
      <template v-if="areThereAnyTemplates">
        <div
          v-if="view === 'grid'"
          class="box-selection"
        >
          <design-box
            v-for="template in templates"
            :key="'template-' + template.name"
            :auto-wrap="true"
            :design="template"
            :display-date="false"
            :display-edit="false"
            :display-meta="true"
            :display-showroom="false"
            :display-sizing="true"
            :name-max-length="18"
          >
            <template #action>
              <icon
                name="delete"
                tooltip="Delete"
                @click="destroy(template.uuid, template.name)"
              />
            </template>
          </design-box>
          <div
            v-if="view === 'grid' && currentPage < lastPage"
            key="brand-list-load-more"
            style="display: flex; align-items: center; padding-bottom: 20px;"
          >
            <secondary-button @click="nextPage">
              <span>Load more</span>
            </secondary-button>
          </div>
        </div>
        <advanced-table
          v-else
          :columns="['Templates', 'Size', '']"
          :current-page="currentPage"
          :data="templates"
          :last-page="lastPage"
          @load-next-page="nextPage"
        >
          <template #default="{row}">
            <td>
              <div class="template-info">
                <img
                  :alt="row.name + ' preview'"
                  class="template-preview"
                  :src="row.screenshot"
                  @error="fallbackImg"
                >
                <div class="template-details">
                  <p class="template-name">
                    {{ row.name }}
                  </p>
                  <p class="template-type">
                    {{ getTemplateDescription(row) }}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <span>
                {{ Utils.getFriendlyDimensions(row) }}
              </span>
            </td>
            <td class="action-row">
              <div
                class="dropdown-holder"
                @click="destroy(row.uuid, row.name)"
              >
                <icon
                  name="delete"
                  tooltip="Delete"
                />
              </div>
            </td>
          </template>
        </advanced-table>
      </template>
      <empty-section
        v-else
        :icon="require('../../../images/dashboard/designer/campaigns-empty-state.svg')"
      >
        There are no Templates yet. Start by creating new one!
      </empty-section>
    </transition>
  </div>
</template>
<script>import DesignBox from "../common/design/DesignBox"
import Icon from "../common/Icon"
import Utils from "../../utils"
import DeleteModal from "../common/modal/DeleteModal"
import { TemplateRoutes } from "../../api/routes"
import EmptySection from "../dashboard/components/EmptySection"
import dayjs from "dayjs"

export default {
  components: {
    DesignBox,
    Icon,
    EmptySection
  },
  props: {
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dropdownActive: null,
      templates: [],
      currentPage: 1,
      lastPage: 1
    }
  },
  computed: {
    areThereAnyTemplates () {
      return this.templates && this.templates.length > 0
    },
    Utils () {
      return Utils
    }
  },
  mounted () {
    this.fetchTemplates()
  },
  methods: {
    getTemplateDescription (row) {
      const date = dayjs(row.created_at).format("DD MMM YYYY")

      return `Created on ${date} by ${row.user.firstname} ${row.user.lastname}`
    },
    changePage (newPage, limit = 20, appendData = false) {
      this.currentPage = newPage
      this.fetchTemplates(newPage, limit, appendData)
    },
    nextPage () {
      this.changePage(this.currentPage + 1, 20, true)
    },
    fetchTemplates (page = 1, limit = 20, appendData = false) {
      const filters = {
        page,
        limit
      }

      return this.$apiClient.get(TemplateRoutes.getTemplates() + "?" + Utils.parseUrlFilter(filters))
        .then(res => {
          this.templates = appendData ? this.templates.concat(res?.data?.data || []) : res?.data?.data || []
          this.currentPage = res?.data?.meta?.current_page || 1
          this.lastPage = res?.data?.meta?.last_page || 1
        })
    },
    fallbackImg (e) {
      e.target.src = require("../../../images/templates/template-default.svg")
    },
    destroy (templateUuid, templateName) {
      this.$modal.show(DeleteModal, {
        endpoint: TemplateRoutes.deleteTemplate(templateUuid),
        title: `Delete Template ${templateName}?`,
        afterDelete: this.fetchTemplates
      })
    }
  }
}
</script>
