export class Routes {
  // DELETE
  static deleteAutomaticReport = (campaignId) => `/api/campaign/${campaignId}/analytics/automatic-report`

  // GET
  static getComprehensiveReport = () => "/api/campaign-report/export"
  static getOverviewChartData = () => "/api/analytics/chart-data-overview"
  static getChartData = () => "/api/analytics/chart-data"
  static getChartDataGeneral = () => "/api/analytics/chart-data-general"
  static getChartDataEngagement = () => "/api/analytics/chart-data-engagement"
  static getChartDataDevices = () => "/api/analytics/chart-data-device"
  static getCampaignAnalyticsXslx = (campaignUuid) => `/api/campaign/${campaignUuid}/analytics/xlsx`
  static getCampaignAnalyticsCsv = (campaignUuid) => `/api/campaign/${campaignUuid}/analytics/csv`
  static getAutomaticReport = (campaignId) => `/api/campaign/${campaignId}/analytics/automatic-report`

  // POST
  static saveAutomaticReport = (campaignId) => `/api/campaign/${campaignId}/analytics/automatic-report`
}
