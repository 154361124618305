<template>
  <div class="analytics--creative-overview">
    <div class="analytics--creative-overview-bar">
      <h2>Creative overview</h2>
    </div>
    <analytics-stat-cards
      :analytics="designAnalytics"
      :campaign="campaign"
      class="analytics--creative-overview-cards"
      :show-bar="false"
    />
    <div
      v-if="design && tag"
      class="analytics--chart-tabs-wrapper"
    >
      <card>
        <card-body>
          <tabs
            ref="tabsEngagement"
            class="padded-cards"
            init-active-tab="engagement"
            @change-tab="changeActiveTab"
          >
            <template #content>
              <tab
                :is-active="activeTab==='engagement'"
                title="Engagement"
              >
                <transition
                  appear
                  mode="out-in"
                  name="fadeIn"
                >
                  <engagement
                    :design="design"
                    :end-date="endDate"
                    :start-date="startDate"
                    :tag-uuid="tag.uuid"
                  />
                </transition>
              </tab>
              <tab
                :is-active="activeTab==='devices'"
                style="min-height: 440px"
                title="Devices"
              >
                <devices
                  :design-uuid="design.uuid"
                  :end-date="endDate"
                  :start-date="startDate"
                  :tag-uuid="tag.uuid"
                />
              </tab>
            </template>
          </tabs>
        </card-body>
      </card>
    </div>
  </div>
</template>
<script>
import Engagement from "./Engagement"
import Devices from "./Devices"
import AnalyticsStatCards from "./AnalyticsStatCards"

export default {
  components: {
    AnalyticsStatCards,
    Engagement,
    Devices
  },
  props: {
    design: {
      type: Object,
      default: () => {
      }
    },
    tag: {
      type: Object,
      default: () => {
      }
    },
    campaign: {
      type: Object,
      required: true
    },
    designAnalytics: {
      type: Object,
      default: () => {
      }
    },
    startDate: {
      type: String,
      default: ""
    },
    endDate: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      activeTab: "engagement"
    }
  },
  methods: {
    changeActiveTab (tab) {
      this.activeTab = tab.title.toLowerCase()
    }
  }
}
</script>
