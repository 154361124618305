<template>
  <span
    v-tippy="{arrow: true}"
    :class="`design-progress design-progress__${status}`"
    :content="tooltip"
    @show="showTooltip"
  >
    {{ Utils.ucfirst(getStatusText(status)) }}
  </span>
</template>
<script>
import Utils from "../../../utils"
import {
  DESIGN_PROGRESS_DELIVERED,
  DESIGN_PROGRESS_DESIGN,
  DESIGN_PROGRESS_DESIGN_INIT,
  DESIGN_PROGRESS_ERROR,
  DESIGN_PROGRESS_LIVE,
  DESIGN_PROGRESS_READY,
  DESIGN_PROGRESS_READY_PENDING,
  DESIGN_PROGRESS_REVIEW
} from "@/constants"

export default {
  props: {
    status: {
      type: String,
      validator: (type) => [DESIGN_PROGRESS_DESIGN_INIT, DESIGN_PROGRESS_DESIGN, DESIGN_PROGRESS_REVIEW, DESIGN_PROGRESS_DELIVERED, DESIGN_PROGRESS_LIVE, DESIGN_PROGRESS_READY, DESIGN_PROGRESS_READY_PENDING, DESIGN_PROGRESS_ERROR].includes(type),
      default: DESIGN_PROGRESS_DESIGN
    },
    tooltip: {
      type: String,
      default: ""
    }
  },
  computed: {
    Utils () {
      return Utils
    }
  },
  methods: {
    showTooltip () {
      return this.tooltip.length > 0
    },
    getStatusText (status) {
      switch (status) {
        case DESIGN_PROGRESS_READY_PENDING:
          return DESIGN_PROGRESS_READY
        case DESIGN_PROGRESS_DESIGN_INIT:
          return DESIGN_PROGRESS_DESIGN
        default:
          return status
      }
    }
  }
}
</script>
