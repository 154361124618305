<template>
  <a
    class="icon-button filters-icon"
    href="#"
    @click.prevent="$emit('change')"
  >
    <icon name="filter" />
  </a>
</template>
<script>

export default {
  emits: ["change"]
}
</script>
