import Multiselect from "vue-multiselect/dist/vue-multiselect.common.js"
export { default as CodeEditor } from "simple-code-editor"

/** Header **/
export { default as MainHeader } from "./header/Header"

/** Sidebar **/
export { default as Sidebar } from "./sidebar/Sidebar"

/** Common components **/
export { default as Icon } from "./common/Icon.vue"
export { default as DefaultButton } from "./common/controls/DefaultButton"
export { default as BaseButton } from "./common/controls/BaseButton"
export { default as DangerButton } from "./common/controls/DangerButton"
export { default as PrimaryButton } from "./common/controls/PrimaryButton"
export { default as SecondaryButton } from "./common/controls/SecondaryButton"
export { default as TooltipTooLong } from "./common/TooltipTooLong"
export { default as DotTooLong } from "./common/DotTooLong.vue"
export { default as RichSwitch } from "./common/controls/RichSwitch"
export { default as SearchHeader } from "./common/search/SearchHeader"
export { default as HeaderPopup } from "./common/search/popups/HeaderPopup"
export { default as CampaignPopup } from "./common/search/popups/CampaignPopup"
export { default as UserListPopup } from "./common/search/popups/UserListPopup"
export { default as GroupListPopup } from "./common/search/popups/GroupListPopup"
export { default as TemplateCreate } from "./template/Create.vue"
export { default as DesignRow } from "./common/design/DesignRow.vue"
export { default as CampaignRow } from "./common/campaign/CampaignRow.vue"
export { default as CircleChart } from "./common/CircleChart.vue"
export { default as Avatar } from "./common/Avatar.vue"
export { default as Badge } from "./common/Badge.vue"
export { default as Brand } from "./common/Brand.vue"
export { default as Popup } from "./common/Popup.vue"
export { default as FilterEnabler } from "./common/FilterEnabler.vue"
export { default as Card } from "./common/card/Card.vue"
export { default as CardBody } from "./common/card/CardBody.vue"
export { default as CardHeader } from "./common/card/CardHeader.vue"
export { default as CardFooter } from "./common/card/CardFooter.vue"
export { default as TagList } from "./common/TagList.vue"
export { default as Tag } from "./common/Tag.vue"
export { default as ColorBoxes } from "./common/colorbox/ColorBoxes.vue"
export { default as InputDropdown } from "./common/dropdown/InputDropdown.vue"

/** Form components **/
export { default as BaseInput } from "./common/form/BaseInput.vue"
export { default as BaseTextarea } from "./common/form/Textarea.vue"
export { default as TextInput } from "./common/form/TextInput.vue"
export { default as NumberField } from "./common/form/Number.vue"
export { default as DateField } from "./common/form/Date.vue"
export { default as CheckboxField } from "./common/form/Checkbox.vue"
export { default as Checkbox } from "./common/Checkbox"
export { default as RadioButton } from "./common/RadioButton"
export { default as Toggle } from "./common/Toggle"

/** Modal related **/
export { default as BaseModal } from "./common/modal/BaseModal"

/** User related **/
export { default as UserRegister } from "./user/Register.vue"

// Other
export { default as ModeSwitch } from "./ModeSwitch.vue"
export { default as LoadingStatus } from "./LoadingStatus.vue"

// Base Components
export { default as AdvancedCard } from "./base/AdvancedCard.vue"
export { default as AdvancedTable } from "./base/AdvancedTable.vue"
export { default as Tabs } from "./base/Tabs.vue"
export { default as Tab } from "./base/Tab.vue"

// // Vue Multiselect
const multiselect = {
  extends: Multiselect,
  mixins: [
    {
      props: {
        deselectLabel: {
          type: String,
          default: "Remove"
        },
        selectLabel: {
          type: String,
          default: "Select"
        },
        alwaysActive: {
          type: Boolean,
          default: false
        }
      },
      methods: {
        deactivate () {
          if (this.alwaysActive) {
            this.activate()
            return
          }
          /* istanbul ignore else */
          if (!this.isOpen) return

          this.isOpen = false
          /* istanbul ignore else  */
          if (this.searchable) {
            if (typeof this.$refs.search !== "undefined") this.$refs.search.blur()
          } else if (typeof this.$el !== "undefined") this.$el.blur()
          if (!this.preserveSearch) this.search = ""
          this.$emit("close", this.getValue(), this.id)
        }
      },
      mounted () {
        this.isOpen = this.alwaysActive
      }
    }
  ]
}

export { multiselect as Multiselect }

