<template>
  <form
    enctype="multipart/form-data"
    method="post"
    @keydown="form.errors.clear($event.target.name)"
    @submit.prevent="onSubmit"
  >
    <div class="panel-body">
      <div class="form-group">
        <text-input
          id="name"
          v-model:value="form.name"
          :class="{ 'is-invalid': form.errors.has('name') }"
          :label="null"
          name="name"
          placeholder="Title"
          type="text"
        />
        <div
          v-if="form.errors.has('name')"
          class="invalid-feedback"
          v-text="form.errors.get('name')"
        />
      </div>
    </div>
    <input
      v-model="form.configuration"
      name="configuration"
      type="hidden"
    />
    <input
      name="submitted"
      type="hidden"
      value="1"
    />
    <slot name="actions">
      <primary-button
        :disabled="form.errors.any()"
        :min-width="114"
        size="m"
        type="submit"
      >
        Create
      </primary-button>
    </slot>
  </form>
</template>
<script>
import { Form } from "@/classes/form"
import Language from "../../i18n/en"
import { mapActions, mapMutations, mapState } from "vuex"
import { TemplateRoutes } from "../../api/routes"

export default {
  name: "TemplateCreate",
  emits: ["submit"],
  data () {
    return {
      form: new Form({
        name: "",
        preview_image: null,
        configuration: {},
        success: false,
        format: null
      }),
      query: "",
      results: []
    }
  },
  computed: {
    ...mapState(["scenes", "assets", "activeDesign", "events"])
  },
  methods: {
    ...mapActions(["loadDesignWithoutSetting"]),
    ...mapMutations(["setScreenshotImage"]),
    onSubmit () {
      const design = {
        deviceDimensions: this.activeDesign.deviceDimensions,
        deviceOrientation: this.activeDesign.deviceOrientation,
        deviceType: this.activeDesign.deviceType,
        deviceSizing: this.activeDesign.deviceSizing,
        deviceOrientationLock: this.activeDesign.deviceOrientationLock,
        fallbackColor: this.activeDesign.fallbackColor,
        fallbackEnabled: this.activeDesign.fallbackEnabled,
        fallbackImage: this.activeDesign.fallbackImage,
        spinnerColor: this.activeDesign.spinnerColor,
        globalClickout: this.activeDesign.globalClickout,
        savedColors: this.activeDesign.savedColors,
        type: this.activeDesign.type,
        format: this.activeDesign.format
      }
      return this.loadDesignWithoutSetting({ uuid: this.activeDesign.unique_id }).then((res) => {
        const screenshot = res.data.data.screenshot
        this.setScreenshotImage(screenshot)
        this.form.configuration = JSON.stringify({
          scenes: this.scenes,
          assets: this.assets,
          events: this.events,
          design,
          variants: this.activeDesign.variants
        })
        this.form.preview_image = screenshot
        this.form.format = design.format
        if (this.name) {
          return true
        }
        return this.form
          .post(TemplateRoutes.createTemplate())
          .then(this.onSuccess)
          .catch(() => {
            this.$toasted.error(Language.templates.error.create)
          })
      })
    },
    onSuccess () {
      this.$emit("submit")
      this.$toasted.success(Language.templates.success.create)
    }
  }
}
</script>
