import CommonToast from "../components/common/toast/CommonToast.vue"
import { useToast as useToastification } from "vue-toastification"

export const useToast = () => {
  const toast = useToastification()

  return {
    success: (content) => {
      toast({
        component: CommonToast,
        props: {
          title: "Success",
          content,
          icon: "success-notification"
        }
      }, {
        toastClassName: "Vue-Toastification__toast--success"
      })
    },
    error: (content) => {
      toast({
        component: CommonToast,
        props: {
          title: "Error",
          content,
          icon: "error-notification"
        }
      }, {
        toastClassName: "Vue-Toastification__toast--error"
      })
    },
    warning: (content) => {
      toast({
        component: CommonToast,
        props: {
          title: "Warning",
          content,
          icon: "warning-notification"
        }
      }, {
        toastClassName: "Vue-Toastification__toast--warning"
      })
    },
    info: (content) => {
      toast({
        component: CommonToast,
        props: {
          title: "Info",
          content,
          icon: "info-notification"
        }
      }, {
        toastClassName: "Vue-Toastification__toast--info"
      })
    }
  }
}
