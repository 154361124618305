<template>
  <div
    class="upload"
  >
    <label
      v-if="label"
      class="input-label"
    >
      {{ label }}
    </label>
    <div
      class="upload-row"
      :class="{'drag-enter': dragEnter}"
    >
      <div
        v-if="uploading === false && file"
        class="preview"
      >
        <div class="dz-preview dz-file-preview">
          <div class="dz-details">
            <img
              alt="Brand icon"
              :src="file.imagepath"
            />
            <div class="dz-filename">
              <span>{{ file.friendly_name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        ref="dropzone"
        class="drop-zone"
      ></div>
      <div class="preview"></div>
      <template v-if="file === null">
        Drag and drop or &nbsp;<a href="#">browse</a>
      </template>
      <div style="position: absolute;top:5px;right:10px; display:flex;column-gap: 10px;">
        <a
          v-if="file"
          href
          @click.prevent="swapModuleImage"
        >
          <icon name="swap" />
        </a>
        <a
          v-if="file"
          href
          @click.prevent.stop="removeImage"
        >
          <icon name="delete" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Language from "../../../i18n/en"
import Dropzone from "dropzone"
import { FileRoutes } from "../../../api/routes"
import Icon from "@/components/common/Icon.vue"

export default {
  components: { Icon },
  props: {
    label: {
      type: String,
      default: null
    },
    file: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: "brand"
    },
    companyId: {
      type: Number,
      default: null
    }
  },
  emits: ["uploaded", "remove-file"],
  data () {
    return {
      dragEnter: false,
      uploading: false,
      dropzoneConfig: {
        url: process.env.VUE_APP_API_URL + FileRoutes.uploadFile(),
        uploadMultiple: false,
        withCredentials: true,
        hiddenInputContainer: "body",
        previewsContainer: ".preview",
        clickable: true,
        acceptedFiles: "image/jpeg,image/png",
        drop: () => {
          this.dragEnter = false
        },
        dragend: () => {
          this.dragEnter = false
        },
        dragenter: () => {
          this.dragEnter = true
        },
        dragleave: () => {
          this.dragEnter = false
        },
        success: (file, res) => {
          this.uploading = false
          this.$emit("uploaded", res.data)
        },
        complete: (file) => {
          this.dropzone.removeFile(file)
        },
        params: () => {
          return this.companyId
            ? { type: this.type, companyId: this.companyId }
            : { type: this.type }
        },
        headers: {
          "X-CSRF-TOKEN": document.head.querySelector("meta[name=\"csrf-token\"]") ? document.head.querySelector("meta[name=\"csrf-token\"]").content : ""
        },
        error: (e) => {
          let message

          switch (e.xhr.status) {
            case 400:
              try {
                message = JSON.parse(e.xhr.response).message
              } catch (err) {
                message = e.xhr.response
              }
              this.$toasted.error(message)
              break
            case 413:
              this.$toasted.error(Language.file_too_big)
              break
            case 422:
              try {
                message = JSON.parse(e.xhr.response).errors.file
              } catch (err) {
                message = e.xhr.response
              }
              this.$toasted.error(message + "<br/>")
              break
            default:
              this.$toasted.error(Language.assets.error.not_uploaded)
              break
          }
        },
        previewTemplate: this.dropzoneTemplate(),
        dictDefaultMessage: ""
      }

    }
  },
  mounted () {
    this.dropzone = new Dropzone(this.$refs.dropzone, this.dropzoneConfig)

    this.dropzone.on("addedfile", () => {
      this.uploading = true
    })
  },
  beforeUnmount () {
    this.dropzone.destroy()
    this.dropzone = null
  },
  methods: {
    dropzoneTemplate () {
      return `
<div class="dz-preview dz-file-preview">
  <div class="dz-details">
    <img data-dz-thumbnail />
    <div class="dz-filename"><span data-dz-name></span></div>
  </div>
</div>`
    },
    removeImage () {
      this.$emit("remove-file")
    },
    swapModuleImage () {
      this.dropzone.hiddenFileInput.click()
    }
  }
}
</script>
