import { ModuleDataFields as BaseModuleDataFields } from "./moduleDataFields"
import { SwiperGroupModuleType } from "./components/designer/module_types/types"

const ModuleDataFields = {
  ...BaseModuleDataFields,
  [SwiperGroupModuleType]: [
    {
      type: "group",
      value: {
        activeGroupIndex: 0,
        groups: [
          [] // Default group
        ]
      }
    },
    {
      type: "data",
      value: null
    },
    {
      type: "dataBindingId",
      value: ""
    },
    {
      type: "slideEffect",
      value: "normal"
    },
    {
      type: "slideDirection",
      value: "horizontal"
    },
    {
      type: "slideDirectionLocks",
      value: []
    },
    {
      type: "transitionSpeed",
      value: 300
    },
    {
      type: "initialSlide",
      value: 1
    },
    {
      type: "playInitialAnimation",
      value: 0
    },
    {
      type: "controlsDisabled",
      value: ["arrows", "indicator"]
    },
    {
      type: "arrowColor",
      value: "#ffffff"
    },
    {
      type: "arrowLeft",
      value: ""
    },
    {
      type: "arrowRight",
      value: ""
    },
    {
      type: "arrowsPosition",
      value: 0
    },
    {
      type: "arrowsPositionX",
      value: 0
    },
    {
      type: "arrowSize",
      value: 25
    },
    {
      type: "indicatorColor",
      value: "#ffffff"
    },
    {
      type: "indicatorActiveColor",
      value: "#d4ff00"
    },
    {
      type: "indicatorImage",
      value: ""
    },
    {
      type: "indicatorActiveImage",
      value: ""
    },
    {
      type: "indicatorsPositionVertical",
      value: 10
    },
    {
      type: "indicatorsPositionHorizontal",
      value: "center"
    },
    {
      type: "indicatorSize",
      value: 10
    },
    {
      type: "autoplay",
      value: 1
    },
    {
      type: "autoplayDelay",
      value: 1
    },
    {
      type: "autoplayProgress",
      value: 0
    },
    {
      type: "clickout",
      value: ""
    },
    {
      type: "progressColor",
      value: "#ffffff"
    },
    {
      type: "loopSlides",
      value: 1
    },
    {
      type: "shuffleMode",
      value: 0
    },
    {
      type: "shuffleSlidesLimit",
      value: null
    },
    {
      type: "cubeShadow",
      value: 0
    },
    {
      type: "panoramaDepth",
      value: 50
    },
    {
      type: "carouselDepth",
      value: 50
    },
    {
      type: "carouselDistance",
      value: 50
    },
    {
      type: "shouldReplayAnimations",
      value: 0
    },
    {
      type: "slideOverflow",
      value: 0
    },
    {
      type: "addAnimationDelay",
      value: 1
    },
    {
      type: "constraints",
      value: []
    }
  ]
}

export { ModuleDataFields }
