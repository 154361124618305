import Language from "./i18n/en"
import { useToast } from "./composables/useToast"

const toasted = useToast()

window.axios = require("axios")

window.axios.defaults.withCredentials = true
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL || window.location.protocol + "//" + window.location.host + "/"
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
window.axios.defaults.headers.common.Accept = "application/json"

window.axios.interceptors.response.use((response) => response, (error) => {
  const response = error.response.data
  const regexSafe = /(\/invites\/(.*?)|\/password\/reset|\/login|\/showroom(.*?)|\/adtags(.*))/i
  if (response && response.hasOwnProperty("type")) {
    let message = response.errors && Array.isArray(response.errors) ? response.errors.join("<br>") : ""
    if (response.errors.length === 0) {
      message = Language.request_generic_error
    }
    switch (error.type) {
      case "info":
        toasted.info(message)
        break
      case "error":
      default:
        toasted.error(message)
        break
    }
  } else if (response) {
    switch (error.response.status) {
      case 419:
        toasted.error(Language.expired_session)
        break
      case 413:
        toasted.error(Language.file_too_big)
        break
      case 422:
        break
      case 403:
        toasted.error(Language.wrong_permissions)
        break
      case 404:
      case 400:
        let message
        if (Array.isArray(error.response.data.errors)) {
          message = response.errors.join("<br>")
        } else {
          message = error.response.data
        }
        if (message === "Company is not selected" && !regexSafe.test(window.location.pathname) && window.location.pathname !== "/company") {
          window.location.href = "/company?redirectAfter=" + encodeURI(window.location.pathname)
        }

        // these errors are handled by the proper endpoints
        break
      case 429:
        if (error.response.data.errors) {
          if (Array.isArray(error.response.data.errors)) {
            error.response.data.errors.forEach(e => toasted.error(e + "<br/>"))
          } else {
            Object.values(error.response.data.errors).forEach(e => toasted.error(e + "<br/>"))
          }
        } else {
          toasted.error(Language.quota_exceeded)
        }
        break
      case 401:
        if (!regexSafe.test(window.location.pathname)) {
          window.location.href = "/login"
        }
        break
      default:
        toasted.error(Language.request_generic_error)
        break
    }
  }
  return Promise.reject(error) // We have to reject/resolve Promise anyway, otherwise it will stay pending
})
