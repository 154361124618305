<template>
  <div>
    <label
      v-if="label"
      class="input-label"
    >
      <span>
        {{ label }}
        <icon
          v-if="informationTooltip"
          class="info-tooltip"
          name="info"
          :tooltip="informationTooltip"
          :tooltip-max-width="220"
          tooltip-placement="right-start"
        />
      </span>
    </label>
    <div class="functional-daterangepicker">
      <date-picker
        ref="datepicker"
        v-model:value="calendarData"
        :append-to-body="false"
        :editable="false"
        :multiple="true"
        :disabled-dates="disabledDates"
        range
        separator=" - "
        :show-hour="false"
        :open="calendarActive"
        :show-minute="false"
        :show-second="false"
        :type="type"
        @close="closeCalendar"
        @open="openCalendar"
      >
        <template #input="props">
          <base-input
            :max-width="maxWidth"
            :active="calendarActive"
            :min-width="minWidth"
            :icon="icon"
            :readonly="isTypeable"
            title="Selected dates"
            type="text"
            :value="parseDate(props)"
            @click="calendarActive=true"
          />
        </template>
      </date-picker>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue-datepicker-next"
import "vue-datepicker-next/index.css"
import Icon from "@/components/common/Icon.vue"
import dayjs from "dayjs"

export default {
  name: "DateRangePicker",
  components: {
    Icon,
    DatePicker
  },
  props: {
    disabledDates: {
      type: [Array, Object, Function],
      default: () => []
    },
    icon: {
      type: String,
      default: "calendar"
    },
    minWidth: {
      type: Number,
      default: 250
    },
    maxWidth: {
      type: Number,
      default: null
    },
    isTypeable: {
      type: Boolean,
      default: false
    },
    informationTooltip: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    dateStart: {
      type: String,
      default: ""
    },
    dateEnd: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "date"
    }
  },
  emits: ["change"],
  data () {
    return {
      calendarActive: false,
      calendarDataRaw: null
    }
  },
  computed: {
    calendarData: {
      get () {
        return this.calendarDataRaw
      },
      set (val) {
        this.calendarDataRaw = val
        this.$emit("change", val)
        this.calendarActive = false
      }
    }
  },
  mounted () {
    if (this.dateStart && this.dateEnd) {
      this.calendarDataRaw = [
        dayjs(this.dateStart, "DD/MM/YYYY").toDate(),
        dayjs(this.dateEnd, "DD/MM/YYYY").toDate()
      ]
    }
  },
  methods: {
    parseDate (props) {
      return props.value
    },
    openCalendar () {
      this.calendarActive = true
    },
    closeCalendar () {
      this.calendarActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
.functional-daterangepicker {
  .vfc-single-input,
  .vfc-multiple-input input {
    text-align: left;

    &:first-child {
      padding: 12px 20px !important;
    }
  }

  :deep(.mx-datepicker-range .mx-datepicker-main) {
    left: 0 !important;
  }

  :deep(.mx-datepicker-range) {
    .mx-input-wrapper .base-input input {
      padding: 12px 20px !important;
    }

    .mx-calendar-header {
      color: $grey_12;
    }
  }

  :deep(.base-input.active > input ~ .input-icon) {
    background-color: $grey_5;
    opacity: 1;
  }

  :deep(.input-icon) {
    width: 36px;
    height: 32px;
    border-radius: 5px;
    position: absolute;
    right: 6px !important;
    top: 6px !important;
    left: initial !important;
    cursor: pointer;
    background-color: transparent;
    transition: 0.2s ease-in-out;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:active {
      background-color: $grey_5;
    }

    .icon {
      position: relative;
      top: auto;
      left: auto;
    }
  }
}

:deep(.mx-datepicker-main) {
  background-color: $grey_3;
  color: $grey_12;
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

  .vfc-calendars {
    flex-wrap: nowrap;
  }

  .vfc-dayNames {
    span {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: $grey_9;
    }
  }

  .vfc-week .vfc-day {
    height: 36px;
    line-height: 28px;
    padding-right: 6px;
    justify-content: center;

    &:not(.vfc-marked) {
      .vfc-base-start,
      .vfc-base-end {
        width: 80% !important;
        left: auto !important;
      }

      &:first-child {
        .vfc-base-end {
          border-radius: 18px !important;
        }
      }
    }

    &:first-child {
      span.vfc-span-day.vfc-marked:before {
        border-top-left-radius: 18px;
        border-bottom-left-radius: 18px;
      }
    }

    &:last-child {
      span.vfc-span-day.vfc-marked:before {
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
      }

      color: $grey_12;
    }

    .vfc-base-start {
      border-top-left-radius: 18px !important;
      border-bottom-left-radius: 18px !important;
    }

    .vfc-base-end {
      border-top-right-radius: 18px !important;
      border-bottom-right-radius: 18px !important;
    }

    .vfc-base-start,
    .vfc-base-end {
      background-color: rgba(162, 193, 0, 0.14);
    }

    span.vfc-span-day {
      border: solid 1.5px transparent;
      width: 32px;
      height: 32px;
      line-height: 28px;

      &.vfc-hover:hover,
      &.vfc-hovered {
        background: inherit;
        color: #fff;
      }

      &.vfc-today {
        font-weight: bold;
        color: $green_2;
        background: inherit;

        &:hover {
          background: $grey_3;
        }
      }

      &.vfc-marked {
        background: inherit;

        &:before {
          background-color: rgba(162, 193, 0, 0.14);
        }

        &:hover {
          background: $grey_3;
        }
      }

      &.vfc-start-marked,
      &.vfc-end-marked {
        border-radius: 50%;
        border-color: $green_2;
        background-color: $grey_3;
      }

      &.vfc-hide {
        font-weight: normal;
        color: $grey_7;
      }
    }
  }

  .vfc-navigation-buttons,
  .vfc-separately-navigation-buttons {
    margin-top: 0;
  }

  .vfc-top-date {
    margin: 30px 25px 30px 25px;

    &,
    > span {
      font-family: $font-family-base;
      opacity: 0.8;
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      text-align: center;
      text-transform: uppercase;
      color: $white;
    }
  }

  .vfc-cursor-pointer {
    background: $grey_6;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 22px 25px;

    .vfc-arrow-left {
      margin: 0 0 0 2px;
    }

    .vfc-arrow-right {
      margin: 0 2px 0 0;
    }

    .vfc-arrow-left,
    .vfc-arrow-right {
      font-size: 10px;
      border-color: #fff;
      opacity: 0.8;
      width: 8px;
      height: 8px;
      padding: 0;
    }
  }
}

</style>
