import { BETA_ADTRON_DOMAIN, DEV_ADTRON_DOMAIN, LAB_ADTRON_DOMAIN, TEST_ADTRON_DOMAIN } from "@/constants"
import { BETA_WAGAWIN_DOMAIN, DEV_WAGAWIN_DOMAIN, LAB_WAGAWIN_DOMAIN, TEST_WAGAWIN_DOMAIN } from "@/wagawinConstants"

export default class WagawinUtils {
  /**
   * Replaces adtron url to wagawins one.
   * @returns {string} replacedUrl
   * @param {string} url
   */
  static replaceAdtronUrlToWagawin (url) {
    if (url.includes(LAB_ADTRON_DOMAIN)) {
      return url.replace(LAB_ADTRON_DOMAIN, LAB_WAGAWIN_DOMAIN)
    }

    if (url.includes(BETA_ADTRON_DOMAIN)) {
      return url.replace(BETA_ADTRON_DOMAIN, BETA_WAGAWIN_DOMAIN)
    }

    if (url.includes(TEST_ADTRON_DOMAIN)) {
      return url.replace(TEST_ADTRON_DOMAIN, TEST_WAGAWIN_DOMAIN)
    }

    if (url.includes(DEV_ADTRON_DOMAIN)) {
      return url.replace(DEV_ADTRON_DOMAIN, DEV_WAGAWIN_DOMAIN)
    }

    return url
  }

  /**
   * Replaces wagawin url to adtrons one.
   * @returns {string} replacedUrl
   * @param {string} url
   */
  static replaceWagawinUrlToAdtron (url) {
    if (url.includes(LAB_WAGAWIN_DOMAIN)) {
      return url.replace(LAB_WAGAWIN_DOMAIN, LAB_ADTRON_DOMAIN)
    }

    if (url.includes(BETA_WAGAWIN_DOMAIN)) {
      return url.replace(BETA_WAGAWIN_DOMAIN, BETA_ADTRON_DOMAIN)
    }

    if (url.includes(TEST_WAGAWIN_DOMAIN)) {
      return url.replace(TEST_WAGAWIN_DOMAIN, TEST_ADTRON_DOMAIN)
    }

    if (url.includes(DEV_WAGAWIN_DOMAIN)) {
      return url.replace(DEV_WAGAWIN_DOMAIN, DEV_ADTRON_DOMAIN)
    }

    return url
  }
}
