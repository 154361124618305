<template>
  <header
    v-if="currentUser"
    class="main-header"
  >
    <search-header v-if="currentUser.active_company" />
    <div
      v-if="!currentUser.active_company"
      class="search"
    >
&nbsp;
    </div>
    <div class="center-content">
      <rich-switch
        v-if="currentUser.active_company && isHome && (currentUser.roles.manager || currentUser.roles.admin || currentUser.roles.super_admin)"
        first-option="Designer"
        second-option="Manager"
        :selected-option="selectedDashboard"
        @switch="updateDashboard"
      />
      <slot />
    </div>
    <div class="user-content">
      <primary-button
        v-if="!!currentUser.active_company"
        :disabled="currentUser.roles.manager === false && currentUser.roles.admin === false && currentUser.roles.super_admin === false"
        :icon="'add'"
        @click="showCampaignWizard"
      >
        Create Campaign
      </primary-button>
      <notifications />
      <current-user />
    </div>
  </header>
</template>

<script>
import Notifications from "./notifications/Notifications"
import CurrentUser from "./CurrentUser"
import CampaignModal from "../common/modal/campaign_modal/CampaignModal"
import SearchHeader from "../common/search/SearchHeader"
import { mapState } from "vuex"

export default {
  components: {
    Notifications,
    CurrentUser,
    SearchHeader
  },
  props: {
    isHome: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(["currentUser", "selectedDashboard"])
  },
  methods: {
    updateDashboard (val) {
      this.$store.commit("setSelectedDashboard", val.toLowerCase())
    },
    showCampaignWizard (options) {
      this.$modal.show(CampaignModal, Object.assign({}, { openCampaign: this.showCampaignWizard }, options))
    }
  }
}
</script>
