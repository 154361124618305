export class Routes {
  // DELETE
  static removeGroup = (groupUuid) => `/api/group/${groupUuid}`

  // GET
  static getGroups = () => "/api/group"

  // PUT
  static updateGroup = (groupUuid) => `/api/group/${groupUuid}`

}
