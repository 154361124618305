<template>
  <div
    class="base-input"
    :class="[
      inputActive && isInputInvalid === false ? 'active' : '',
      disabled ? 'disabled' : '',
      required ? 'required' : '',
      isInputInvalid ? 'error' : '',
    ]"
    :style="getContainerStyle()"
    @click.prevent="hidePlaceholder"
  >
    <div
      v-if="inputPrefix && inputPrefix.length"
      class="input-prefix"
    >
      {{ inputPrefix }}
    </div>
    <div
      v-if="inputPrefixIcon && inputPrefixIcon.length"
      class="input-prefix"
    >
      <icon :name="inputPrefixIcon" />
    </div>
    <input
      :id="labelId"
      :ref="'baseinput_' + id"
      :data-radius="borderRadius + 'px'"
      :disabled="disabled"
      :readonly="readonly"
      :required="required"
      :min="min"
      :step="step"
      :max="max"
      :style="getInputStyle()"
      :maxlength="maxlength"
      :type="type && separators === false ? type === 'password' && displayPassword === true ? 'text' : type : 'text'"
      autocomplete="off"
      :value="separators ? useSeparators(value) : value"
      @blur="blurEvent"
      @change="changeEvent"
      @animationstart="animationStartEvent"
      @click.prevent.stop="$emit('click', $event)"
      @focus="focusEvent"
      @input="inputEvent"
      @keydown.enter.prevent="$emit('keydown.enter', $event)"
      @keyup="keyUpEvent"
      @mousedown="mouseDownEvent"
      @wheel.passive="wheelEvent"
    >
    <div class="input-icon">
      <icon
        v-if="icon"
        :name="icon"
      />
    </div>
    <div
      v-if="suffix && suffix.length"
      class="input-suffix"
    >
      {{ suffix }}
    </div>
    <div
      v-if="suffixIcon && suffixIcon.length"
      class="input-suffix"
    >
      <icon :name="suffixIcon" />
    </div>
    <div
      v-if="type === 'password'"
      class="input-password"
      @click="toggleDisplayPassword"
    >
      <icon
        v-if="displayPassword === true"
        key="visible"
        name="eye-visible"
      />
      <icon
        v-if="displayPassword === false"
        key="hidden"
        name="eye-hidden"
      />
    </div>
    <label
      v-if="placeholder && (typeof value !== 'undefined' && (typeof value === 'string' && value.length === 0 && autofilled === false))"
      class="placeholder"
      :class="{'with-icon': !!icon}"
      :for="id ? null : labelId"
      :style="getPlaceholderStyle()"
    >{{ placeholder }}</label>
  </div>
</template>

<script>

import { v4 as uuidv4 } from "uuid"

export default {
  props: {
    id: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: ""
    },
    placeholder: {
      type: String,
      default: null
    },
    height: {
      type: Number,
      default: 44
    },
    borderRadius: {
      type: Number,
      default: 8
    },
    fontSize: {
      type: Number,
      default: 16
    },
    icon: {
      type: String,
      default: null
    },
    paddingX: {
      type: Number,
      default: 20
    },
    paddingY: {
      type: Number,
      default: 12
    },
    minWidth: {
      type: Number,
      default: 260
    },
    maxWidth: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    moveCaretToTheEndOnFocus: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: null
    },
    type: {
      type: String,
      default: "text",
      validator (value) {
        return ["text", "range", "number", "email", "password", "url", "file"].indexOf(value) !== -1
      }
    },
    separators: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    maxlength: {
      type: Number,
      default: null
    },
    inputPrefix: {
      type: String,
      default: ""
    },
    inputPrefixIcon: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    },
    suffixIcon: {
      type: String,
      default: ""
    },
    isDraggable: {
      type: Boolean,
      default: false
    },
    disableDragging: {
      type: Boolean,
      default: false
    },
    focusInputOnMount: {
      type: Boolean,
      default: false
    }
  },
  emits: ["active", "macroToggleClicked", "input", "keyup", "change", "blur", "focus", "click", "keydown.enter", "wheel", "update:value"],
  data () {
    return {
      inputActiveValue: false,
      isInvalid: false,
      placeholderVisible: true,
      displayPassword: false,
      autofilled: false,
      draggableInput: {}
    }
  },
  computed: {
    inputActive: {
      get () {
        return this.inputActiveValue || this.active
      },
      set (val) {
        this.$emit("active", val)
        this.inputActiveValue = val
      }
    },
    isInputInvalid () {
      return this.error || this.isInvalid
    }
  },
  beforeMount () {
    this.labelId = this.id ? this.id : uuidv4()
  },
  mounted () {
    if (this.focusInputOnMount) {
      this.$refs["baseinput_" + this.id]?.focus()
    }
    this.placeholderVisible = this.value && this.value.length === 0
  },
  methods: {
    toggleDisplayPassword () {
      this.displayPassword = !this.displayPassword
    },
    hidePlaceholder () {
      this.placeholderVisible = false
    },
    getLimitedValue (value) {
      if (this.max !== null) {
        value = Math.min(this.max, value)
      }
      if (this.min !== null) {
        value = Math.max(this.min, value)
      }

      return parseFloat(value.toFixed(2))
    },
    mouseDownEvent (e) {
      if (this.disableDragging) {
        return
      }

      if (this.type === "number" || (this.isDraggable && !isNaN(parseFloat(this.value)))) {
        this.draggableInput.snapshot = parseFloat(this.value || 0)
        this.draggableInput.startPositionX = e.pageX
        document.addEventListener("mousemove", this.onMouseMove)
        document.addEventListener("mouseup", this.onMouseUp)
        document.body.classList.add("ew-resize")
      }
    },
    onMouseMove (e) {
      let diff = this.draggableInput.startPositionX - e.pageX

      if (this.step !== null) {
        diff *= this.step
      }
      let value = this.draggableInput.snapshot - diff

      if (this.type === "number") {
        value = this.getLimitedValue(value)
      }

      this.$emit("input", value, e)
      this.$emit("change", value, e)
    },
    onMouseUp () {
      document.removeEventListener("mousemove", this.onMouseMove)
      document.removeEventListener("mouseup", this.onMouseUp)
      document.body.classList.remove("ew-resize")
    },
    useSeparators (val) {
      if (val && isFinite(parseFloat(val))) {
        return parseFloat(val).toLocaleString("de-DE")
      }
      return val
    },
    keyUpEvent (e) {
      this.$emit("keyup", e)
      if (e.key === "Enter" || e.key === "Escape") {
        this.inputActive = false
        e.target.blur()
      }
    },
    changeEvent (e) {
      this.isInvalid = this.required && e.target.value.length === 0
      let value = this.separators ? String(e.target.value).replace(/\s|,|\./g, "") : e.target.value

      if (this.type === "number" && isNaN(value)) {
        value = this.min !== null ? this.min : 0
        e.target.value = value
      }

      if (this.max !== null || this.min !== null) {
        value = this.getLimitedValue(value)
        e.target.value = value
      }

      this.$emit("change", value, e)
    },
    blurEvent (e) {
      this.inputActive = false
      this.$emit("blur", e)
    },
    wheelEvent (e) {
      this.$emit("wheel", e)
    },
    animationStartEvent (e) {
      switch (e.animationName) {
        case "onAutoFillStart":
          this.autofilled = true
          break
        case "onAutoFillCancel":
          this.autofilled = false
          break
      }
    },
    focusEvent (e) {
      this.inputActive = true
      this.$emit("focus", e)

      const input = this.$refs["baseinput_" + this.id]
      if (this.moveCaretToTheEndOnFocus && input) {
        const end = this.value.length

        setTimeout(() => {
          input.setSelectionRange(end, end)
          input.scrollLeft = input.scrollWidth
        }, 50)
      }
    },
    inputEvent (e) {
      this.isInvalid = this.required && e.target.value.length === 0
      let value = this.separators ? e.target.value.replace(/\s|,|\./g, "") : e.target.value

      if (this.type === "number" && isNaN(value)) {
        value = this.min !== null ? this.min : 0
      }

      if (this.max !== null || this.min !== null) {
        value = this.getLimitedValue(value)
      }

      this.$emit("input", value, e)
      this.$emit("update:value", value, e)
    },
    getContainerStyle () {
      return {
        height: this.height + "px",
        minWidth: this.minWidth + "px",
        maxWidth: this.maxWidth !== null ? this.maxWidth + "px" : null
      }
    },
    getInputStyle () {
      return {
        borderRadius: this.borderRadius + "px",
        fontSize: this.fontSize + "px",
        padding: [this.paddingY + "px", this.paddingX + "px", this.paddingY + "px", (this.paddingX + (this.icon && !this.inputActive ? 20 : 0)) + "px"].join(" ")
      }
    },
    getPlaceholderStyle () {
      return {
        top: this.paddingY + "px",
        left: this.paddingX + "px",
        fontSize: this.fontSize + "px"
      }
    }
  }
}
</script>
