<template>
  <div>
    <div
      v-tippy
      class="notifications"
      content="Notifications"
      @click.prevent.stop="togglePopup"
    >
      <icon
        v-if="newNotificationsPending === true"
        name="bell-bullet"
      />
      <icon
        v-if="newNotificationsPending === false"
        name="bell"
      />
    </div>
    <notifications-popup
      v-if="showNotificationsPopup"
      :notifications="notifications"
    />
  </div>
</template>

<script>
import NotificationsPopup from "./NotificationsPopup"
import { mapActions, mapState } from "vuex"
import Icon from "../../common/Icon"

export default {
  components: {
    NotificationsPopup,
    Icon
  },
  emits: ["toggle"],
  data () {
    return {
      showNotificationsPopup: false,
      newNotificationsPending: false
    }
  },
  computed: {
    ...mapState({
      user: "currentUser",
      notifications: "notifications"
    })
  },
  mounted () {
    this.$eventHub.$on("hide-search", () => {
      if (this.showNotificationsPopup) {
        this.showNotificationsPopup = false
        this.$emit("toggle", this.showNotificationsPopup)
      }
    })
    this.loadCurrentUser().then(() => {
      this.loadNotifications()
      this.setupNotificationWsConnection({
        userUuid: this.user.uuid,
        callback: (notification) => {
          this.addNotification(notification)
          if (this.showNotificationsPopup === false) {
            this.newNotificationsPending = true
          }
        }
      })
    })
  },
  beforeUnmount () {
    this.destroyNotificationWsConnection({ userUuid: this.user.uuid })
  },
  methods: {
    togglePopup () {
      this.showNotificationsPopup = !this.showNotificationsPopup
      this.$emit("toggle", this.showNotificationsPopup)
      this.newNotificationsPending = false
    },
    ...mapActions(["loadCurrentUser", "loadNotifications", "addNotification", "setupNotificationWsConnection", "destroyNotificationWsConnection"])
  }
}
</script>
