<template>
  <div
    class="single-box"
    :class="{disabled, active}"
    @click.prevent="$emit('click', $event)"
  >
    <icon :name="icon" />
    <h3>{{ title }}</h3>
  </div>
</template>
<script>

export default {
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"]
}
</script>
