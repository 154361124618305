<template>
  <div
    class="card card-pane card-collapsible"
    :class="{ disabled: disabled }"
  >
    <div
      class="card-header"
      @click="collapse"
    >
      <button
        class="btn btn-collapse-link"
        :class="(disabled || (isCollapsed === null ? collapsed : isCollapsed)) ? 'collapsed' : ''"
        type="button"
      >
        <icon
          v-if="icon"
          class="title-icon"
          :name="icon"
        />
        <span :style="{margin: titleMargins.join(' ')}">{{ title }}</span>
        <span
          v-if="tooltip && tooltip.length"
          v-tippy="{placement: 'left'}"
          class="info-icon"
          :content="tooltip"
        >
          <icon name="info" />
        </span>
        <icon
          v-if="!(disabled || (isCollapsed === null ? collapsed : isCollapsed))"
          :name="!arrowReverse ? 'arrow_up' : 'arrow_down'"
        />
        <icon
          v-if="(disabled || (isCollapsed === null ? collapsed : isCollapsed))"
          :name="!arrowReverse ? 'arrow_down' : 'arrow_up'"
        />
      </button>
    </div>
    <div
      v-if="!(disabled || (isCollapsed === null ? collapsed : isCollapsed))"
      class="collapse"
      :class="(disabled || (isCollapsed === null ? collapsed : isCollapsed)) ? 'collapsed' : 'show'"
      :style="cardStyle"
    >
      <div
        class="card-body p-0"
        :style="cardStyle"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "AdvancedCard",
  props: {
    collapsible: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    tooltip: {
      type: String,
      default: ""
    },
    arrowReverse: {
      type: Boolean,
      default: false
    },
    initCollapsed: {
      type: Boolean,
      default: false
    },
    isCollapsed: {
      type: Boolean,
      default: null
    },
    cardStyle: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    titleMargins: {
      type: Array,
      default: () => []
    }
  },
  emits: {
    collapse: (isCollapsed) => {
      return typeof isCollapsed === "boolean"
    }
  },
  data () {
    return {
      collapsed: this.initCollapsed
    }
  },
  watch: {
    isCollapsed (val) {
      this.collapsed = Boolean(val)
    }
  },
  methods: {
    collapse () {
      if (this.collapsible && this.isCollapsed === null) {
        this.collapsed = !this.collapsed
      }
      this.$emit("collapse", this.collapsed)
    }
  }
}
</script>
