<template>
  <div>
    <label
      v-if="label"
      class="input-label"
    >
      {{ label }}
    </label>
    <div class="color-boxes">
      <color-box
        v-for="color in availableColors"
        :key="color"
        :active="color === value"
        :color="color"
        @click="chooseColor(color)"
      />
    </div>
  </div>
</template>
<script>
import ColorBox from "./ColorBox"

export default {
  components: { ColorBox },
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: "#ffffff"
    },
    availableColors: {
      type: Array,
      default: () => []
    }
  },
  emits: ["input", "update:value"],
  methods: {
    chooseColor (color) {
      this.$emit("input", color)
      this.$emit("update:value", color)
    }
  }
}
</script>
