<template>
  <div class="view-container">
    <sidebar />
    <div
      class="app-container"
      @click="$eventHub.$emit('hide-search', $event)"
    >
      <main-header />
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-12 content-inner">
              <admin-users-groups-list />
            </div>
          </div>
        </div>
        <loading-status />
      </div>
    </div>
  </div>
</template>
<script>
import AdminUsersGroupsList from "../../components/admin/UsersGroupsList"

export default {
  components: {
    AdminUsersGroupsList
  }
}
</script>
