<template>
  <div class="card">
    <slot />
  </div>
</template>
<script>
export default {
  name: "Card"
}
</script>
