export class Routes {
  // GET
  static logout = () => "/api/logout"

  // POST
  static login = () => "/api/login"
  static register = () => "/api/register"
  static refreshUserToken = () => "/api/refresh"
  static sendRecoverPasswordEmail = () => "/api/password/email"
  static resetPassword = () => "/api/password/reset"
}
