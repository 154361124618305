<template>
  <div class="analytics--stat-card">
    <span
      v-if="showBar"
      class="analytics--stat-card-line"
      :style="getStyles"
    ></span>
    <span class="analytics--stat-card-content">
      <span class="analytics--stat-card-count">{{
        Number.isInteger(count) ? parseFloat(count).toLocaleString("de-DE") : count
      }}</span>
      <span class="analytics--stat-card-sub">{{ title }}</span>
    </span>
    <icon
      v-if="icon"
      :name="icon"
    />
  </div>
</template>
<script>
import Icon from "../../common/Icon"
import Utils from "../../../utils"

export default {
  components: {
    Icon
  },
  props: {
    brand: {
      type: Object,
      default: () => {}
    },
    count: {
      type: [Number, String],
      required: true
    },
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: null
    },
    showBar: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    Utils () {
      return Utils
    },
    getStyles () {
      return {
        background: this.brand ? this.brand.color : "#4892ff"
      }
    }
  }
}
</script>
