<template>
  <div class="container">
    <div class="row d-flex">
      <div class="col">
        <div
          class="form-group"
          style="max-width: 260px;"
        >
          <date-range-picker
            ref="reporting-period-datepicker"
            :calendar-count="1"
            :disabled-dates="disabledDateBeforeToday"
            icon="calendar"
            label="Reporting period"
            :max-width="260"
            :min-width="260"
            @change="changeTimeRange"
          />
        </div>
      </div>
      <div class="col">
        <div
          class="form-group"
          style="max-width: 110px;"
        >
          <label
            class="input-label"
            for="date-start"
          >Reporting time</label>
          <div class="base-input">
            <date-picker
              id="reporting-time"
              :clearable="false"
              :append-to-body="false"
              :confirm="false"
              :first-day-of-week="1"
              :lang="lang"
              :show-second="false"
              :time-picker-options="timePickerOptions"
              confirm-text="Apply"
              type="time"
              :value="reportingTime"
              @change="updateReportingTime"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex">
      <div class="col">
        <label class="input-label">Reporting days</label>
        <div class="d-flex justify-content-between mb-3">
          <selectable-circle
            :active="isDayActive(1)"
            @click="() => toggleDay(1)"
          >
            Mo
          </selectable-circle>
          <selectable-circle
            :active="reportingDays[2]"
            @click="() => toggleDay(2)"
          >
            Tu
          </selectable-circle>
          <selectable-circle
            :active="reportingDays[3]"
            @click="() => toggleDay(3)"
          >
            We
          </selectable-circle>
          <selectable-circle
            :active="reportingDays[4]"
            @click="() => toggleDay(4)"
          >
            Th
          </selectable-circle>
          <selectable-circle
            :active="reportingDays[5]"
            @click="() => toggleDay(5)"
          >
            Fr
          </selectable-circle>
          <selectable-circle
            :active="reportingDays[6]"
            @click="() => toggleDay(6)"
          >
            Sa
          </selectable-circle>
          <selectable-circle
            :active="reportingDays[7]"
            @click="() => toggleDay(7)"
          >
            Su
          </selectable-circle>
        </div>
      </div>
    </div>
    <div class="row d-flex">
      <div class="col">
        <div
          class="form-group"
          style="max-width: 380px;"
        >
          <label
            class="input-label"
            for="date-start"
          >Analytics start date</label>
          <div class="base-input">
            <date-picker
              id="date-start"
              :append-to-body="false"
              :clearable="false"
              :confirm="false"
              confirm-text="Apply"
              :first-day-of-week="1"
              :lang="lang"
              type="date"
              :value="dateStart"
              @change="(d) => updateDateStart(d)"
            >
              <template #icon-calendar>
                <icon name="calendar" />
              </template>
            </date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex">
      <div class="col">
        <div
          class="form-group"
          style="max-width: 380px;"
        >
          <text-input
            v-model:value="form.emails"
            label="E-mails (use comma as a separator)"
            :max-width="380"
            :min-width="380"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from "@/classes/form"
import Language from "../../../i18n/en"
import { Routes as AnalyticsRoutes } from "../../../api/routes/analytics"
import dayjs from "dayjs"
import DatePicker from "vue-datepicker-next"
import "vue-datepicker-next/index.css"
import DateRangePicker from "../../common/DateRangePicker"
import { v4 as uuidv4 } from "uuid"
import SelectableCircle from "../../common/controls/SelectableCircle"
import { mapState } from "vuex"

export default {
  name: "AutomaticReportForm",
  components: {
    SelectableCircle,
    DateRangePicker,
    DatePicker
  },
  props: {
    campaignId: {
      type: String,
      required: true
    },
    report: {
      type: Object,
      default: null
    }
  },
  emits: ["click"],
  data () {
    return {
      form: new Form({
        uuid: "",
        date_start: dayjs().date(1).format("YYYY-MM-DD"),
        reporting_period_start: dayjs().date(1).format("YYYY-MM-DD"),
        reporting_period_end: dayjs().add(14, "day").date(1).format("YYYY-MM-DD"),
        reporting_time: "00:00",
        reporting_days: "1",
        emails: ""
      })
    }
  },
  computed: {
    ...mapState(["currentUser"]),
    reportingDays: {
      get () {
        const days = {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false
        }

        this.form.reporting_days.split(",").forEach((d) => {
          days[d] = true
        })

        return days
      }
    },
    reportingTime: {
      get () {
        const h = this.form.reporting_time ? this.form.reporting_time.split(":")[0] : 0
        const m = this.form.reporting_time ? this.form.reporting_time.split(":")[1] : 0

        return dayjs().hour(h).minute(m).second(0).toDate()
      }
    },
    dateStart: {
      get () {
        return dayjs(this.form.date_start).toDate()
      }
    },
    reportingStartDate: {
      get () {
        return dayjs(this.form.reporting_period_start).toDate()
      }
    },
    timePickerOptions () {
      return {
        start: "00:00",
        step: "00:15",
        end: "23:45",
        format: "HH:mm"
      }
    },
    lang () {
      return {
        days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        pickers: ["next 7 days", "next 30 days", "previous 7 days", "previous 30 days"],
        placeholder: {
          date: "Select Date",
          dateRange: "Select Date Range"
        }
      }
    }
  },
  mounted () {
    this.form.uuid = uuidv4()
    this.form.emails = this.currentUser.email

    if (this.report) {
      this.form.uuid = this.report.uuid
      this.form.date_start = this.report.date_start
      this.form.reporting_period_start = this.report.reporting_period_start
      this.form.reporting_period_end = this.report.reporting_period_end
      this.form.reporting_time = this.report.reporting_time
      this.form.reporting_days = this.report.reporting_days
      this.form.emails = this.report.emails
    }
  },
  methods: {
    disabledDateBeforeToday (date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date < today
    },
    isDayActive (day) {
      return this.reportingDays[day]
    },
    toggleDay (day) {
      const days = this.reportingDays
      days[day] = !days[day]

      const parsedDays = []
      for (const [key, value] of Object.entries(days)) {
        if (value && key.length) {
          parsedDays.push(key)
        }
      }

      this.form.reporting_days = parsedDays.join(",")
      this.$forceUpdate()
    },
    updateReportingTime (date) {
      this.form.reporting_time = dayjs(date).format("HH:mm")
    },
    updateDateStart (date) {
      this.form.date_start = dayjs(date).format("YYYY-MM-DD")
    },
    changeTimeRange (data) {
      if (data.length) {
        const start = data[0].toLocaleDateString("af")
        const end = data[1].toLocaleDateString("af")

        this.form.reporting_period_start = start
        this.form.reporting_period_end = end

        this.$refs["reporting-period-datepicker"].closeCalendar()
      }
    },
    onSubmit () {
      return this.form
        .put(AnalyticsRoutes.saveAutomaticReport(this.campaignId))
        .then(this.onSuccess)
        .catch(e => {
          this.$toasted.error(Language.analytics.error.automatic_report_saved)
          this.$toasted.error(Object.values(e).join("\n"))
        })
    },
    onSuccess () {
      this.$modal.hide("AutomaticReportModal")
      this.$toasted.success(Language.analytics.success.automatic_report_saved)
    }
  }
}
</script>
