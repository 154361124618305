<template>
  <inline-svg
    v-if="!usePng"
    :key="[tooltip, name, folder].join('-')"
    v-tippy="vTippy"
    :class="classes"
    :content="tooltip"
    :src="iconSrc"
    @click="click"
    @show="showTooltip"
  />
  <div
    v-else
    class="img-container"
  >
    <img
      :key="[tooltip, name, folder].join('-')"
      v-tippy="vTippy"
      :alt="name"
      class="image-main"
      :class="classes"
      :content="tooltip"
      :src="iconSrc"
      :srcset="iconSrcSet"
      @click="click"
      @show="showTooltip"
    />
    <img
      v-if="hover"
      :key="[tooltip, name, folder, 'hover'].join('-')"
      v-tippy="vTippy"
      :alt="name"
      class="image-hover"
      :class="classes"
      :content="tooltip"
      :src="iconHoverSrc"
      :srcset="iconHoverSrcSet"
      @click="click"
      @show="showTooltip"
    />
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg"

export default {
  components: {
    InlineSvg
  },
  props: {
    hover: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ""
    },
    tooltipPlacement: {
      type: String,
      default: "top"
    },
    tooltipMaxWidth: {
      type: Number,
      default: 350
    },
    folder: {
      type: String,
      default: "icons/"
    },
    name: {
      type: String,
      required: true
    },
    fallback: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    usePng: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"],
  computed: {
    classes () {
      return [
        "icon",
        this.name + "-icon",
        this.name + this.folder.replace(/\//i, "-") + "icon",
        this.disabled ? "disabled" : "",
        this.hover ? "" : "single-img"
      ]
    },
    vTippy () {
      return { arrow: true, placement: this.tooltipPlacement, maxWidth: this.tooltipMaxWidth, allowHTML: true }
    },
    fileExtension () {
      return this.usePng ? "png" : "svg"
    },
    iconSrc () {
      try {
        return require(`../../../images/${this.folder}${this.name}.${this.fileExtension}`)
      } catch (e) {
        try {
          return require(`../../../images/${this.folder}${this.fallback}.${this.fileExtension}`)
        } catch (e) {
          return require("../../../images/fallback.png")
        }
      }
    },
    iconHoverSrc () {
      if (!this.hover) {
        return null
      }

      try {
        return require(`../../../images/${this.folder}${this.name}-hover.${this.fileExtension}`)
      } catch (e) {
        return require(`../../../images/${this.folder}${this.fallback}.${this.fileExtension}`)
      }
    },
    iconHoverSrcSet () {
      try {
        const x1 = require(`../../../images/${this.folder}${this.name}-hover.${this.fileExtension}`)
        const x2 = require(`../../../images/${this.folder}${this.name}-hover@2x.${this.fileExtension}`)
        const x3 = require(`../../../images/${this.folder}${this.name}-hover@3x.${this.fileExtension}`)

        return `${x1} 1x, ${x2} 2x, ${x3} 3x`
      } catch (e) {
        try {
          return require(`../../../images/${this.folder}${this.fallback}.${this.fileExtension}`)
        } catch (e) {
          return require("../../../images/fallback.png")
        }
      }
    },
    iconSrcSet () {
      if (!this.usePng) {
        try {
          return require(`../../../images/${this.folder}${this.name}.${this.fileExtension}`)
        } catch (e) {
          return require("../../../images/fallback.png")
        }
      }

      try {
        const x1 = require(`../../../images/${this.folder}${this.name}.${this.fileExtension}`)
        const x2 = require(`../../../images/${this.folder}${this.name}@2x.${this.fileExtension}`)
        const x3 = require(`../../../images/${this.folder}${this.name}@3x.${this.fileExtension}`)
        return `${x1} 1x, ${x2} 2x, ${x3} 3x`
      } catch (e) {
        try {
          return require(`../../../images/${this.folder}${this.fallback}.${this.fileExtension}`)
        } catch (e) {
          return require("../../../images/fallback.png")
        }
      }
    }
  },
  methods: {
    showTooltip () {
      return this.tooltip.length > 0
    },
    click (e) {
      if (this.disabled) {
        e.preventDefault()
        e.stopPropagation()
      } else {
        this.$emit("click", e)
      }
    }
  }
}
</script>
