<template>
  <div
    v-if="role && roleName"
    class="user-badge"
  >
    {{ roleName }}
  </div>
</template>
<script>

import { mapState } from "vuex"

export default {
  props: {
    role: {
      type: String,
      default: null
    }
  },
  computed: {
    roleName () {
      return this.role ? this.availableRoles.find((r) => r.value === this.role).name : null
    },
    ...mapState(["availableRoles"])
  }
}
</script>
