import cloneDeep from "lodash/cloneDeep"

class UndoRedoHistory {
  init (store) {
    this.store = store
    this.history = []
    this.currentIndex = -1
    this.maxLength = 100
  }

  clear () {
    this.history = []
    this.currentIndex = -1
  }

  addState (state) {
    if (this.currentIndex + 1 < this.history.length) {
      this.history.splice(this.currentIndex + 1)
    }
    this.history.push(state)
    if (this.history.length > this.maxLength) {
      this.history.splice(0, this.history.length - this.maxLength)
    } else {
      this.currentIndex++
    }
  }

  undo () {
    const prevState = this.history[this.currentIndex - 1]
    let result
    if (prevState) {
      if (this.store.state.deletedAssetsId !== prevState.deletedAssetsId) {
        const p = []
        cloneDeep(this.store.state.deletedAssetsId).forEach((id) => {
          p.push(this.store.dispatch("restoreAsset", { assetId: id, designId: this.store.state.activeDesign.uuid }))
        })
        result = Promise
          .all(p)
          .then(() => {
            this.store.commit("setDeletedAssets", [])
            this.store.commit("setAssets", prevState.assets)
            this.store.dispatch("loadAssets", { uuid: this.store.state.activeDesign.uuid })
          })
      }
      this.store.replaceState(cloneDeep(prevState))
      this.currentIndex--
    }
    return result
  }

  redo () {
    const nextState = this.history[this.currentIndex + 1]
    let result
    if (nextState) {
      if (nextState.deletedAssetsId && this.store.state.deletedAssetsId !== nextState.deletedAssetsId) {
        const p = []
        cloneDeep(nextState.deletedAssetsId).forEach((id) => {
          p.push(this.store.dispatch("deleteAsset", id))
        })
        result = Promise
          .all(p)
          .then(() => {
            this.store.commit("setDeletedAssets", nextState.deletedAssetsId)
            this.store.commit("setAssets", nextState.assets)
            this.store.dispatch("loadAssets", { uuid: this.store.state.activeDesign.uuid })
          })
      }
      this.store.replaceState(cloneDeep(nextState))
      this.currentIndex++
    }
    return result
  }

  replace (state) {
    const nextState = cloneDeep(state)
    if (nextState) {
      this.store.replaceState(cloneDeep(nextState))
    }
  }
}

const undoRedoHistory = new UndoRedoHistory()
const undoRedoPlugin = store => {
  // initialize and save the starting stage
  undoRedoHistory.init(store)

  store.subscribe((mutation, state) => {
    // save only saveState mutations
    if (mutation.type === "saveState") {
      undoRedoHistory.addState(cloneDeep(state))
    }
  })
}

export { undoRedoPlugin, undoRedoHistory }
