<template>
  <aside
    class="main-sidebar"
    :class="{collapsed: collapsed}"
  >
    <div
      v-if="!!activeCompany"
      class="collapse-box rounded-full"
      @click="collapse"
    >
      <icon
        name="arrow"
        :size="8"
      />
    </div>
    <div
      class="logo-container"
      :style="{backgroundColor: !!activeCompany? '#222529' : '#181a1d'}"
    >
      <div :style="logoContainerOptions">
        <router-link
          v-if="activeCompany"
          to="/"
        >
          <inline-svg
            v-if="collapsed"
            :src="require('../../../images/logo-adtron-icon.svg')"
          />
          <inline-svg
            v-else
            :src="require('../../../images/logo-adtron-white.svg')"
          />
        </router-link>
        <router-link
          v-else
          to="/company?redirectAfter=home"
        >
          <inline-svg :src="require('../../../images/logo-adtron-white.svg')" />
        </router-link>
      </div>
    </div>
    <ul class="options">
      <li
        v-for="(option, i) in listOptions"
        :key="'option-' + option.id"
        class="option"
        :class="{'active': activeOption === option.id, 'pre-active': listOptions.indexOf(listOptions.find(o => o.id === activeOption)) - 1 === i}"
      >
        <div
          :key="option.id"
          class="option-inner"
        >
          <router-link :to="option.children ? '#' : option.link">
            <i>
              <icon :name="option.id" />
            </i>
            <h3>
              {{ option.name }}
            </h3>
          </router-link>
        </div>
        <ul class="children">
          <li
            v-for="child in option.children"
            :key="'child-' +child.id"
          >
            {{ child.name }}
          </li>
        </ul>
      </li>
      <li
        v-if="listOptions.length && listOptions[listOptions.length - 1].id === activeOption"
        class="option"
      >
      </li>
    </ul>
    <div
      v-if="canDisplayBuildNodeStatus"
      class="build-node-status"
    >
      <div
        v-tippy="{arrow: true, content: buildNodeStatusTooltip, allowHTML: true}"
        :class="'build-node-status__' + (buildNodeStatus.connected ? 'active' : 'inactive')"
      ></div>
    </div>
  </aside>
</template>

<script>
import InlineSvg from "vue-inline-svg"
import { mapState } from "vuex"
import { AdminRoutes } from "@/api/routes"
import { useApiClient } from "@/composables/useApiClient"

const $apiClient = useApiClient()

export default {
  components: {
    InlineSvg
  },
  data () {
    return {
      collapsed: false,
      options: [
        {
          id: "dashboard",
          name: "Dashboard",
          link: "/dashboard",
          authorizator: () => Boolean(this.activeCompany)
        },
        {
          id: "campaigns",
          name: "Campaigns",
          link: "/campaigns",
          authorizator: () => Boolean(this.activeCompany)
        },
        {
          id: "users",
          name: "Users",
          link: "/admin/users",
          authorizator: () => {
            return Boolean(this.currentUser) && (this.currentUser.roles.admin === true || this.currentUser.roles.super_admin === true || this.currentUser.roles.manager === true) && Boolean(this.activeCompany)
          }
        },
        {
          id: "analytics",
          name: "Analytics",
          link: "/analytics",
          authorizator: () => {
            return Boolean(this.currentUser) && (this.currentUser.roles.admin === true || this.currentUser.roles.super_admin === true || this.currentUser.roles.manager === true) && Boolean(this.activeCompany)
          }
        },
        {
          id: "billing",
          name: "Billing",
          link: "/billing",
          authorizator: () => {
            return Boolean(this.currentUser) && (this.currentUser.roles.admin === true || this.currentUser.roles.super_admin) && Boolean(this.activeCompany)
          }
        },
        {
          id: "preferences",
          name: "Preferences",
          link: "/preferences",
          authorizator: () => Boolean(this.activeCompany)
        }
      ],
      activeOption: "",
      initializeBuildNodeStatusTimeout: null
    }
  },
  computed: {
    canDisplayBuildNodeStatus () {
      return this.currentUser && this.currentUser.roles.super_admin === true
    },
    buildNodeStatusTooltip () {
      const status = this.buildNodeStatus.connected ? "Active" : "Inactive"

      return `Build node status: <strong>${status}</strong><br /> Connected nodes: ${this.buildNodeStatus.count}`
    },
    activeCompany () {
      return this.currentUser && this.currentUser.active_company
    },
    logoContainerOptions () {
      if (!this.activeCompany) {
        return { backgroundColor: "#181a1d" }
      }

      if (this.options.indexOf(this.options.find(e => e.id === this.activeOption)) === 0) {
        return {
          "border-radius": "0 0 8px 0px"
        }
      }
      return null
    },
    listOptions () {
      return this.options.filter(e => e.authorizator())
    },
    ...mapState(["currentUser", "buildNodeStatus"])
  },
  created () {
    this.collapsed = window.localStorage.getItem("sidebar_collapsed") === "true"
    this.updateBodyClass()
  },
  mounted () {
    const currentOption = this.options.find(o => {
      if (window.location.pathname.match(/campaign\/[\w\d]+\/analytics/i) && o.link === "/analytics") {
        return true
      }

      if (window.location.pathname.match(/campaign\/[\w\d]+\/details/i) && o.link === "/campaigns") {
        return true
      }

      return o.link === window.location.pathname
    })

    this.activeOption = currentOption ? currentOption.id : null

    this.initializeBuildNodeStatus()
  },
  beforeUnmount () {
    if (this.buildNodeUpdateInterval) {
      clearInterval(this.buildNodeUpdateInterval)
    }
    if (this.initializeBuildNodeStatusTimeout) {
      clearTimeout(this.initializeBuildNodeStatusTimeout)
    }
  },
  methods: {
    initializeBuildNodeStatus () {
      if (this.canDisplayBuildNodeStatus) {
        this.updateBuildNodeStatus()
        this.buildNodeUpdateInterval = setInterval(this.updateBuildNodeStatus, 60 * 1000)
      } else if (this.currentUser === null) {
        this.initializeBuildNodeStatusTimeout = setTimeout(()=>{
          this.initializeBuildNodeStatus()
        }, 2000)
      }
    },
    updateBuildNodeStatus () {
      const URL = `${AdminRoutes.getBuildNodeStatus()}`
      return $apiClient.get(URL).then(response => {
        this.$store.commit("setBuildNodeStatus", response.data.data)
        return response
      })
    },
    collapse () {
      this.collapsed = !this.collapsed
      window.localStorage.setItem("sidebar_collapsed", this.collapsed)
      this.updateBodyClass()
    },
    updateBodyClass () {
      if (!this.collapsed) {
        document.body.classList.remove("sidebar-hidden")
      } else {
        document.body.classList.add("sidebar-hidden")
      }
    }
  }
}
</script>
