<template>
  <a
    :class="`pin-button ${active ? 'active' : ''}`"
    href="#"
    @click.prevent.stop="$emit('pin')"
  >
    <icon
      v-if="!active"
      :key="$props['key'] + 'bookmark'"
      name="bookmark"
      tooltip="Pin to Top"
    />
    <icon
      v-if="active"
      :key="$props['key'] + 'bookmark-active'"
      name="bookmark-active"
      tooltip="Unpin"
    />
  </a>
</template>
<script>
import Icon from "./Icon"

export default {
  name: "PinButton",
  components: {
    Icon
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  emits: ["pin"]
}
</script>
