<template>
  <div class="campaign-list-filters">
    <search
      popup-component="group-list-popup"
    />
    <tag-list class="selected-filters">
      <tag
        v-for="(filter, index) in mappedSelectedFilters"
        :key="index"
      >
        {{ filter.name }} <a
          href="#"
          @click.prevent="removeFilter(filter.type, filter.value)"
        ><icon name="close" /></a>
      </tag>
    </tag-list>
  </div>
</template>

<script>
import Search from "../../common/search/Search"
import { mapState } from "vuex"

export default {
  name: "GroupListFilters",
  components: {
    Search
  },
  computed: {
    ...mapState(["selectedFilters"]),
    mappedSelectedFilters () {
      const filters = []
      this.selectedFilters.role.forEach((role) => filters.push({ type: "role", value: role.value, name: role.name }))
      this.selectedFilters.group.forEach((group) => filters.push({ type: "group", value: group.value, name: group.name }))

      return filters
    }
  },
  methods: {
    removeFilter (type, value) {
      this.$store.commit("deleteFilter", { type, value })
    }
  }
}
</script>
