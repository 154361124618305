import Utils from "../utils"
import { PRODUCT_DOOH } from "@/constants"

const crypto = require("crypto-browserify")

export default {
  getPresetColors: state => [...state.savedColors, ...state.defaultPalette],
  isDooh: state => state.activeDesign?.ad_product === PRODUCT_DOOH,
  getModules: state => Utils.getActiveSceneModules(state.activeScene, state.scenes),
  getLoadingStatus: state => state.loading,
  getDesigns: state => state.designs,
  getTemplates: state => state.templates,
  getGroups: state => state.groups,
  getUsers: state => state.users,
  getReports: state => state.reports,
  getInvoices: state => state.invoices,
  getCampaigns: state => state.campaigns,
  getCompanies: state => state.companies,
  getRecordingTime: state => state.recordingTime,
  getCustomFonts: state => {
    const usedFriendlyNames = []
    return state.assets
      .filter(row => row.filetype === "font")
      .map(row => {
        let friendlyname = row.friendly_name.replace(
          /\.(ttf|otf|woff|woff2)/i,
          ""
        )
        const similarNames = usedFriendlyNames.filter(m => m.includes(friendlyname))
        if (similarNames.length > 0) {
          friendlyname = `${friendlyname}(${similarNames.length})`
        }
        usedFriendlyNames.push(friendlyname)
        return { family: friendlyname, id: row.filename }
      })
  },
  getSettingsContext: state => {
    return { context: state.settingsContext, object: state.settingsObject }
  },
  getSelectedModule: (state, getters) => {
    return getters.getModules.find(m => m.uuid === state.selectedModule)
  },
  getCurrentStateHash: state => {
    const watch = (({
      name,
      packageId,
      id,
      scenes,
      events,
      assets
    }) => ({ name, packageId, id, scenes, events, assets }))(state)
    const hash = crypto
      .createHash("md5")
      .update(JSON.stringify(Utils.getUnifiedState(watch)))
      .digest("hex")
    return hash
  },
  getAllModules: state => Utils.getAllModules(state.scenes),
  getCurrentGroupModule: state => {
    return state.currentScope
      ? Utils.getActiveSceneModules(state.activeScene, state.scenes).find(
        e => e.uuid === state.currentScope
      )
      : null
  },
  isExcluded: (state, getters) => moduleType => {
    return Utils.isExcludedModule({
      moduleType,
      currentScope: state.currentScope,
      sceneModules: getters.getModules,
      allModules: getters.getAllModules,
      activeDesign: state.activeDesign,
      activeScene: state.activeScene,
      state
    })
  },
  getModuleByUuid: (state, getters) => moduleUuid => {
    return getters.getAllModules.find(m => m.uuid === moduleUuid)
  },
  isCustomAnimationBeingRecorded: (state) => {
    return Boolean(state.recordingModule)
  },
  getActiveVariantEvents: state => {
    if (state.activeDesign?.variants) {
      const activeVariant = state.activeDesign.variants.find(v => v.active === true)
      return state.events.filter(e => [null, activeVariant?.uuid].includes(e.design_variant_uuid))
    }
    return state.events
  },
  getAvailableVariants: state => {
    return state.activeDesign?.availableVariants || []
  },
  layersPaneScenes: state => {
    return state.scenes.map(s => {
      const modules = s.modules.map(m => {
        return {
          uuid: m.uuid,
          parentModuleId: m.parentModuleId,
          type: m.type,
          sceneId: m.sceneId,
          name: m.name,
          data: m.data,
          preview: {
            locked: m.preview.locked,
            active: m.preview.active,
            hidden: m.preview.hidden
          }
        }
      })

      return {
        uuid: s.uuid,
        name: s.name,
        hidden: s.hidden,
        active: s.active,
        modules
      }
    })
  }
}
