export default function (context) {
  // Tooltip Element
  let tooltipEl = document.getElementById("chartjs-tooltip")

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div")
    tooltipEl.id = "chartjs-tooltip"
    tooltipEl.innerHTML = "<table></table>"
    tooltipEl.style.backgroundColor = "rgba(12,13,15,0.8)"
    tooltipEl.style.border = "solid 1px #2e3236;"
    tooltipEl.style.borderRadius = "9px"
    tooltipEl.style.color = "#FFF"
    document.body.appendChild(tooltipEl)
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform")
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign)
  } else {
    tooltipEl.classList.add("no-transform")
  }

  function getBody (bodyItem) {
    return bodyItem.lines
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || []
    const bodyLines = tooltipModel.body.map(getBody)

    let innerHtml = "<thead>"

    titleLines.forEach(function (title) {
      innerHtml += "<tr><th>" + title + "</th></tr>"
    })
    innerHtml += "</thead><tbody>"

    bodyLines.forEach(function (body, i) {
      const colors = tooltipModel.labelColors[i]
      let style = "background:" + colors.backgroundColor
      style += "; width: 15px"
      style += "; height: 15px"
      style += "; top: 2px"
      style += "; display: inline-flex"
      style += "; position: absolute"
      const div = `<div style="${style}"></div>`
      const wrapperDivStyles = "position:relative;width:15px;height:15px;display:inline-flex;justify-content:center;align-items:center;"
      innerHtml += `<tr><td><div style=${wrapperDivStyles}>${div}</div>${body}</td></tr>`
    })
    innerHtml += "</tbody>"

    const tableRoot = tooltipEl.querySelector("table")
    tableRoot.innerHTML = innerHtml
  }

  // `this` will be the overall tooltip
  const position = context.chart.canvas.getBoundingClientRect()

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1
  tooltipEl.style.position = "absolute"
  tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX + "px"
  tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + "px"
  tooltipEl.style.font = tooltipModel.options.bodyFont.size + "px " + tooltipModel.options.bodyFont.family
  tooltipEl.style.padding = tooltipModel.options.padding + "px " + tooltipModel.options.padding + "px"
  tooltipEl.style.pointerEvents = "none"
}
