<template>
  <div class="data-traffic">
    <card>
      <card-body>
        <div class="header">
          <h1>Impressions</h1>
          <!--          <plan-picker v-if="currentUser" :current-plan="currentUser.pricing_package" :can-upgrade="true"></plan-picker>-->
          <chart-date-picker
            :date="currentDateString"
            :next-disabled="canSelectNextMonth()"
            @next="changeDate(1, 'month')"
            @prev="changeDate(-1, 'month')"
          />
        </div>
        <div class="body">
          <div class="legend">
            <data-legend
              :change="impressionChange"
              color="#a2c100"
              :target="impressionsTarget"
              title="IMPRESSIONS"
              :value="impressions"
            />
          </div>
          <canvas
            ref="traffic-canvas"
            class="chart"
            style="height: 300px; width: 1200px;"
          ></canvas>
        </div>
      </card-body>
    </card>
  </div>
</template>
<script>
import DataLegend from "../components/DataLegend"
import ChartDatePicker from "../components/ChartDatePicker"
import Chart from "chart.js/auto"
import dayjs from "dayjs"
import { AnalyticsRoutes } from "../../../api/routes"
import { nextTick } from "vue"

export default {
  name: "Traffic",
  components: {
    "data-legend": DataLegend,
    "chart-date-picker": ChartDatePicker
  },
  data () {
    return {
      chart: null,
      impressions: 0,
      impressionsTarget: 0,
      impressionChange: 0,
      chartData: {},
      currentDate: dayjs(),
      currentDateString: ""
    }
  },
  created () {
    window.addEventListener("intercomTourChanged", this.updateChart)
  },
  mounted () {
    this.initChartData()
    this.updateChart()
    this.$store.dispatch("loadCurrentUser")
  },
  beforeUnmount () {
    if (this.chart) {
      this.chart.destroy()
    }
    window.removeEventListener("intercomTourChanged", this.updateChart)
  },
  methods: {
    updateChart () {
      return this.getChartData().then(() => {
        this.createChart(this.$refs["traffic-canvas"], this.chartData.config)
      })
    },
    canSelectNextMonth () {
      return this.currentDate.clone().add(1, "month") > dayjs()
    },
    changeDate (value, type) {
      this.currentDate = dayjs(this.currentDate.format("YYYY-MM-DD")).add(value, type)
      this.currentDateString = this.currentDate.format("MMM YYYY")
      return this.getChartData().then(() => {
        this.createChart(this.$refs["traffic-canvas"], this.chartData.config)
      })
    },
    initChartData () {
      const startY = 0
      const stopY = 300
      const ctx = this.$refs["traffic-canvas"].getContext("2d")
      const gradientFill = ctx.createLinearGradient(0, startY, 0, stopY)
      gradientFill.addColorStop(0, "rgba(162, 193, 0, 0.1)")
      gradientFill.addColorStop(1, "rgba(162, 193, 0, 0)")

      this.currentDate = dayjs()
      this.currentDateString = this.currentDate.format("MMM YYYY")
      this.chartData = {
        config: {
          data: {
            labels: [],
            datasets: [
              {
                backgroundColor: gradientFill, // "#a2c100",
                borderWidth: 3,
                pointRadius: 5,
                pointHoverRadius: 6,
                pointHoverBorderWidth: 3,
                borderColor: "#a2c100",
                pointBorderColor: "#222529",
                pointHoverBorderColor: "#222529",
                pointBackgroundColor: "#a2c100",
                pointHoverBackgroundColor: "#a2c100",
                fill: true,
                data: []
              }
            ]
          },
          options: {
            animation: {
              duration: 0
            },
            plugins: {
              legend: false
            },
            scales: {
              y: {
                ticks: {
                  callback (value) {
                    if (value >= 1000000) {
                      value = Number(value / 1000000).toFixed(1) + "M"
                    } else if (value > 1000) {
                      value = Number(value / 1000).toFixed(1) + "K"
                    }
                    return value
                  }
                },
                gridLines: {
                  color: "rgba(216, 216, 216, 0.06)"
                }
              },
              x: {
                gridLines: {
                  display: false
                }
              }
            },
            tooltip: {
              callbacks: {
                title (tooltipItem, data) {
                  return data.labels[tooltipItem[0].index]
                },
                label (tooltipItem) {
                  let label = tooltipItem.yLabel
                  if (label >= 1000000) {
                    label = Number(label / 1000000).toFixed(1) + "M"
                  } else if (label > 1000) {
                    label = Number(label / 1000).toFixed(1) + "K"
                  }
                  return label
                }
              }
            }
          }
        }
      }
    },
    getChartData () {
      return this.$apiClient.get(AnalyticsRoutes.getChartData(), { params: { fake: window.isRunningIntercomTour ? 1 : 0, date: this.currentDate.format("YYYY-MM-DD") } }).then((resp) => {
        this.chartData.config.data.labels = []
        this.chartData.config.data.datasets[0].data = []

        const data = resp.data
        this.impressions = data.impressions
        this.impressionsTarget = null
        this.impressionChange = data.impressionChange

        if (data.chart) {
          data.chart.forEach((row) => {
            this.chartData.config.data.labels.push(dayjs(row[0]).format("MMM DD"))
            this.chartData.config.data.datasets[0].data.push(row[1])
          })
        }

        nextTick(() => this.$eventHub.$emit("update-circle-charts"))
      })
    },
    createChart (ctx, data) {
      if (this.chart) {
        this.chart.destroy()
        this.$refs["traffic-canvas"].width = 1200
        this.$refs["traffic-canvas"].height = 300
      }
      this.chart = new Chart(ctx, {
        type: "line",
        data: data.data,
        options: data.options
      })
    }
  }
}
</script>
