<template>
  <div class="analytics--engagement">
    <div class="analytics--engagement-campaign-picker">
      <input-dropdown
        ref="module-dropdown"
        v-model:value="selectedModule"
        :max-width="315"
        :min-width="315"
        :options="parsedAvailableModules"
        :placement="'bottom-end'"
        placeholder=""
        track-by="uuid"
        @input="changeSelectedModule"
      >
        <template #single-option="{ option }">
          <icon
            v-if="option.icon"
            :name="option.icon"
          />
          <tooltip-too-long
            :length="25"
            :text="option.title"
            class="analytics--engagement-dropdown-tooltip"
          />
        </template>
      </input-dropdown>
    </div>
    <div class="analytics--engagement-data-row">
      <div class="analytics--engagement-summary">
        <div>
          <icon :name="getIconType()" />
          <span class="analytics--engagement-count">{{ getTotalCount() }}</span>
        </div>
        <span class="analytics--engagement-sub">{{ getTotalName() }}</span>
      </div>
      <div class="analytics--engagement-layers">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <data-layer
              v-for="(layer, idx) in availableLayers"
              :key="'layer-' + idx"
              :color="layer.color"
              :count="layer.sum"
              :is-active="layer.active"
              :title="layer.name"
              @activate-layer="selectDataLayer(layer)"
            />
          </div>
        </div>
        <div class="swiper-button-prev swiper-button-disabled"></div>
        <div class="swiper-button-next swiper-button-disabled"></div>
      </div>
    </div>
    <canvas
      ref="engagement-canvas"
      class="chart"
      style="height: 300px; width: 1200px;"
    ></canvas>
  </div>
</template>
<script>
import dayjs from "dayjs"
import Icon from "../../common/Icon"
import Utils from "../../../utils"
import DataLayer from "./DataLayer"
import Swiper from "swiper"
import InputDropdown from "../../common/dropdown/InputDropdown"
import {
  AssetModuleType,
  BackgroundModuleType,
  OfferistaModuleType,
  TypoModuleType
} from "../../designer/module_types/types"
import { v4 as uuidv4 } from "uuid"
import { AnalyticsRoutes } from "../../../api/routes"
import { EventManager } from "@/classes/eventManager"
import Chart from "chart.js/auto"
import { AD_FORMATS, AVAILABLE_MODULES } from "@/constants"

export default {
  components: {
    Icon,
    DataLayer,
    InputDropdown
  },
  props: {
    design: {
      type: Object,
      required: true
    },
    tagUuid: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      selectedModule: null,
      availableModules: [],
      availableLayers: [],
      chart: null,
      fetchedData: null,
      chartData: {},
      selectedPeriod: "day" // single unit on chart - < 30 we use days, < 180 we use weeks, otherwise months
    }
  },
  computed: {
    parsedAvailableModules () {
      return this.availableModules.map((m) => (
        {
          title: m.name + " Events",
          icon: this.getIconName(m),
          module: m,
          uuid: m.uuid
        }
      ))
    },
    availableColors () {
      return [
        "#dc2ccf",
        "#3e5aef",
        "#a2c100",
        "#e59b0f",
        "#55D8FF",
        "#4892FF",
        "#6B0FE5",
        "#BD74FF",
        "#FF7E83",
        "#F87335",
        "#FFC351",
        "#34D17E",
        "#2CD344"
      ]
    }
  },
  created () {
    const params = Utils.getUrlParameters(location.search)
    this.fakeData = (params && params.fake) || window.isRunningIntercomTour === true ? parseInt(params.fake) : 0
    window.addEventListener("intercomTourChanged", this.updateChartData)
  },
  mounted () {
    this.initSwiper()
    this.initChartData()
    this.updateChartData()
  },
  beforeUnmount () {
    this.chart?.destroy()
    window.removeEventListener("intercomTourChanged", this.updateChartData)
  },
  methods: {
    getIconName (module) {
      return module.designUuid ? Utils.getModuleIconName(module.type) : "custom-event"
    },
    updateChartData () {
      const params = Utils.getUrlParameters(location.search)
      this.fakeData = (params && params.fake) || window.isRunningIntercomTour === true ? parseInt(params.fake) : 0
      this.getChartData().then(() => {
        this.createChart(this.$refs["engagement-canvas"], this.chartData.config)
      })
    },
    setDataChangingModule (row) {
      // update available Layers
      this.availableLayers = []
      row.labels.forEach((label, idx) => {
        this.availableLayers.push({
          name: label,
          color: this.getColorForLayer(idx),
          sum: 0,
          active: true
        })
      })

      this.$nextTick(() => {
        this.initChartLayers()

        this.chartData.config.data.labels = []
        Object.values(row.data).forEach((datarow) => {
          this.chartData.config.data.labels.push(dayjs(datarow[0]).format("MMM DD"))
          datarow.slice(1).forEach((value, idx) => {
            this.chartData.config.data.datasets[idx].data.push(value)
            this.availableLayers[idx].sum += value
          })
        })

        this.initSwiper()
        this.createChart(this.$refs["engagement-canvas"], this.chartData.config)
      })
    },
    changeSelectedModule (selectedModule) {
      const module = selectedModule.module

      const data = this.fetchedData
      if (data.chart) {
        for (const moduleId in data.chart) {
          if (["global_clickouts", "Impressions", "Scenes", "#vast_video"].includes(moduleId)) {
            if (data.chart.hasOwnProperty(moduleId) && moduleId === module.moduleId) {
              const row = data.chart[moduleId]
              this.setDataChangingModule(row)
              break
            }
          }
          if (this.design.creation_type === "adtron") {
            if (data.chart.hasOwnProperty(moduleId) && (moduleId === "#" + module.htmlId || moduleId === "#offerista")) {
              const row = data.chart[moduleId]
              this.setDataChangingModule(row)
              break
            }
          } else if (data.chart.hasOwnProperty(moduleId) && moduleId === module.moduleId) {
            const row = data.chart[moduleId]
            this.setDataChangingModule(row)
            break
          }
        }
      }
    },
    getIconType () {
      return Utils.getModuleIcon(this.selectedModule ? this.selectedModule.module.type : "")
    },
    getTotalCount () {
      let sum = 0

      if (this.selectedModule?.title === "Impressions Events") {
        const displays = this.availableLayers.find(layer => layer.name === "Displays")
        return Utils.parseNumber(displays ? displays.sum : sum)
      }

      this.availableLayers.forEach((layer) => {
        if (layer.active) {
          sum += layer.sum
        }
      })
      return Utils.parseNumber(sum)
    },
    getTotalName () {
      return this.selectedModule ? "Total " + Utils.getFriendlyModuleType(this.selectedModule.module.type) + " Events" : "Total Events"
    },
    getColorForLayer (idx) {
      return this.availableColors[idx % this.availableColors.length]
    },
    selectDataLayer (layer) {
      this.availableLayers.map((l) => {
        if (l.name === layer.name) {
          l.active = !l.active
        }
        return l
      })
      this.initChartLayers()
      this.updateSlides(this.availableLayers)
      this.createChart(this.$refs["engagement-canvas"], this.chartData.config)
    },
    setChartLayers (dataSets, moduleId) {
      this.chartData.config.data.labels = []
      const row = this.fetchedData.chart[moduleId]
      Object.values(row.data).forEach((datarow) => {
        this.chartData.config.data.labels.push(dayjs(datarow[0]).format("MMM DD"))
        let idx = 0
        datarow.slice(1).forEach((value, rowIdx) => {
          if (this.availableLayers[rowIdx] && this.availableLayers[rowIdx].active) {
            dataSets[idx].data.push(value)
            idx++
          }
        })
      })
    },
    initChartLayers () {
      const startY = 0
      const stopY = 300
      const ctx = this.$refs["engagement-canvas"].getContext("2d")
      const gradientStop = "rgba(162, 193, 0, 0)"
      const dataSets = []
      this.availableLayers.filter((l) => l.active).forEach((layer) => {
        const color = layer.color
        const rgb = Utils.hexToRgb(color)
        rgb.a = 0.1

        const gradientFill = ctx.createLinearGradient(0, startY, 0, stopY)
        gradientFill.addColorStop(0, `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`)
        gradientFill.addColorStop(1, gradientStop)

        dataSets.push({
          backgroundColor: gradientFill,
          borderWidth: 3,
          pointRadius: 5,
          pointHoverRadius: 6,
          pointHoverBorderWidth: 3,
          borderColor: color,
          pointBorderColor: "#222529",
          pointHoverBorderColor: "#222529",
          pointBackgroundColor: color,
          pointHoverBackgroundColor: color,
          fill: true,
          data: []
        })
      })

      if (this.fetchedData && this.fetchedData.chart) {
        for (const moduleId in this.fetchedData.chart) {
          if (this.fetchedData.chart.hasOwnProperty(moduleId)) {
            if (["global_clickouts", "Impressions", "Scenes", "#vast_video"].includes(moduleId)) {
              const currentModuleId = this.selectedModule && this.selectedModule.module ? this.selectedModule.module.moduleId : null
              if (currentModuleId === moduleId) {
                this.setChartLayers(dataSets, moduleId)
              }
              continue
            }
            if (this.design.creation_type === "adtron") {
              const currentModuleId = this.selectedModule && this.selectedModule.module ? "#" + this.selectedModule.module.htmlId : null
              if (currentModuleId === moduleId) {
                this.setChartLayers(dataSets, moduleId)
              }
            } else {
              const currentModuleId = this.selectedModule && this.selectedModule.module ? this.selectedModule.module.moduleId : null
              if (currentModuleId === moduleId) {
                this.setChartLayers(dataSets, moduleId)
              }
            }
          }
        }
      }

      this.chartData.config.data.datasets = dataSets
    },
    initChartData () {
      this.chartData = {
        config: {
          data: {
            labels: [],
            datasets: []
          },
          options: {
            animation: {
              duration: 0
            },
            hover: {
              animationDuration: 0
            },
            responsiveAnimationDuration: 0,
            plugins: {
              legend: false,
              tooltip: {
                callbacks: {
                  title (context) {
                    return context[0].label
                  },
                  label (context) {
                    let label = context.formattedValue
                    if (label >= 1000000) {
                      label = Number(label / 1000000).toFixed(1) + "M"
                    } else if (label > 1000) {
                      label = Number(label / 1000).toFixed(1) + "K"
                    }
                    return label
                  }
                }
              }
            },
            scales: {
              y: {
                ticks: {
                  callback (value) {
                    if (value >= 1000000) {
                      value = Number(value / 1000000).toFixed(1) + "M"
                    } else if (value > 1000) {
                      value = Number(value / 1000).toFixed(1) + "K"
                    }
                    return value
                  }
                },
                grid: {
                  color: "rgba(216, 216, 216, 0.06)"
                }
              },
              x: {
                grid: {
                  display: false
                }
              }
            }
          }
        }
      }
      this.initChartLayers()
    },
    updateAvailableLayers (row) {
      row.labels.forEach((label, idx) => {
        this.availableLayers.push({
          name: label,
          color: this.getColorForLayer(idx),
          sum: 0,
          active: true
        })
      })

      this.initChartLayers()
      this.initSwiper()

      this.chartData.config.data.labels = []

      Object.values(row.data).forEach((datarow) => {
        this.chartData.config.data.labels.push(dayjs(datarow[0]).format("MMM DD"))
        datarow.slice(1).forEach((value, idx) => {
          this.chartData.config.data.datasets[idx].data.push(value)
          this.availableLayers[idx].sum += value
        })
      })
    },
    parseFakeData (data) {
      if (!data.chart) {
        return
      }

      for (const moduleId in data.chart) {
        if (["global_clickouts", "Impressions", "Scenes"].includes(moduleId) && this.design.format === AD_FORMATS.vast_interstitial) {
          continue
        }

        if (!data.chart.hasOwnProperty(moduleId)) {
          continue
        }

        const row = data.chart[moduleId]
        if (["global_clickouts", "Impressions", "Scenes", "#vast_video"].includes(moduleId)) {
          let moduleName, moduleType
          switch (moduleId) {
            case "global_clickouts":
              moduleName = "Global clickouts"
              moduleType = "global_clickouts"
              break
            case "#vast_video":
              moduleName = "VAST"
              moduleType = "VAST"
              break
            case "Impressions":
              moduleName = "Impressions"
              moduleType = "impressions"
              break
            case "Scenes":
              moduleName = "Scenes"
              moduleType = "scenes"
              break
          }

          const module = {
            moduleId,
            name: moduleName,
            type: moduleType,
            uuid: uuidv4()
          }

          this.availableModules.push(module)
          if (!this.selectedModule) {
            this.selectedModule = this.parsedAvailableModules.find((m) => m.module.moduleId === moduleId)
          }

          // update available Layers
          if (this.selectedModule.module.moduleId === moduleId) {
            this.updateAvailableLayers(row)
          }
          continue
        }

        // find module in design
        if (this.design.creation_type === "adtron") {
          const parsedModuleType = moduleId.split("-")[2]
          const module = AVAILABLE_MODULES.find((m) => m.type.toLowerCase() === parsedModuleType.toLowerCase())
          if (!module) {
            continue
          }

          const preparedModule = {
            moduleId,
            htmlId: moduleId.replaceAll("#", ""),
            name: module.title,
            type: module.type,
            uuid: uuidv4()
          }
          this.availableModules.push(preparedModule)

          if (!this.selectedModule) {
            this.selectedModule = this.parsedAvailableModules.find((m) => m.module.uuid === preparedModule.uuid)
          }

          // update available Layers
          if (this.selectedModule.module.uuid === preparedModule.uuid) {
            this.updateAvailableLayers(row)
          }
        }
      }
    },
    getChartData () {
      return this.$apiClient.get(AnalyticsRoutes.getChartDataEngagement(), {
        params: {
          period: this.selectedPeriod,
          fake: this.fakeData,
          start: this.startDate,
          end: this.endDate,
          tagId: this.tagUuid,
          design: this.design.uuid
        }
      }).then((resp) => {
        this.chartData.config.data.labels = []
        const data = resp.data
        this.fetchedData = data
        this.availableModules = []
        this.availableLayers = []
        this.selectedModule = null

        if (data.isFakeData) {
          this.parseFakeData(data)
          return
        }

        if (data.chart) {
          for (const moduleId in data.chart) {
            if (["global_clickouts", "Impressions", "Scenes"].includes(moduleId) && this.design.format === AD_FORMATS.vast_interstitial) {
              continue
            }

            if (data.chart.hasOwnProperty(moduleId)) {
              const row = data.chart[moduleId]
              if (["global_clickouts", "Impressions", "Scenes", "#vast_video"].includes(moduleId)) {
                let moduleName, moduleType
                switch (moduleId) {
                  case "global_clickouts":
                    moduleName = "Global clickouts"
                    moduleType = "global_clickouts"
                    break
                  case "#vast_video":
                    moduleName = "VAST"
                    moduleType = "VAST"
                    break
                  case "Impressions":
                    moduleName = "Impressions"
                    moduleType = "impressions"
                    break
                  case "Scenes":
                    moduleName = "Scenes"
                    moduleType = "scenes"
                    break
                }
                const module = {
                  moduleId,
                  name: moduleName,
                  type: moduleType,
                  uuid: uuidv4()
                }

                this.availableModules.push(module)
                if (!this.selectedModule) {
                  this.selectedModule = this.parsedAvailableModules.find((m) => m.module.moduleId === moduleId)
                }

                // update available Layers
                if (this.selectedModule.module.moduleId === moduleId) {
                  this.updateAvailableLayers(row)
                }
                continue
              }
              // find module in design
              if (this.design.creation_type === "adtron") {
                const modules = Utils.getAllModules(this.design.variants[0].scenes)
                let module = null
                if (moduleId === "#offerista") {
                  module = Utils.getModuleByType(OfferistaModuleType, modules)
                } else {
                  module = Utils.getModuleByHtmlId(moduleId.replace("#", ""), modules)
                }
                if (!module) {
                  continue
                }
                if ([AssetModuleType, BackgroundModuleType, TypoModuleType].indexOf(module.type) !== -1) {
                  let hasClickout = false
                  let hasClickInteraction = false
                  module.data.forEach((d) => {
                    if (d.type === "clickout" && d.value && String(d.value).length) {
                      hasClickout = true
                    }
                  })

                  // if we have any CLICK interaction, allow to show this module
                  this.design.events.forEach((ev) => {
                    if (ev.trigger === EventManager.TRIGGER_CLICK && ev.target === module.uuid) {
                      hasClickInteraction = true
                    }
                  })

                  if (!hasClickout && !hasClickInteraction) {
                    continue
                  }
                }

                this.availableModules.push(module)
                if (!this.selectedModule) {
                  this.selectedModule = this.parsedAvailableModules.find((m) => m.module.uuid === module.uuid)
                }

                // update available Layers
                if (this.selectedModule.module.uuid === module.uuid) {
                  this.updateAvailableLayers(row)
                }
              } else {
                const events = this.design.external_tag_config.events
                if (events.hasOwnProperty(moduleId.replace("#", ""))) {
                  const module = {
                    moduleId,
                    name: moduleId,
                    type: "custom",
                    uuid: uuidv4()
                  }

                  this.availableModules.push(module)
                  if (!this.selectedModule) {
                    this.selectedModule = this.parsedAvailableModules.find((m) => m.module.moduleId === moduleId)
                  }

                  // update available Layers
                  if (this.selectedModule.module.moduleId === moduleId) {
                    this.updateAvailableLayers(row)
                  }
                }
              }
            }
          }
        }
      })
    },
    initSwiper () {
      if (this.swiper) {
        this.swiper.destroy(true, true)
      }

      this.swiper = new Swiper(".analytics--engagement-layers .swiper-container", {
        speed: 400,
        slidesPerView: 4,
        loop: false,
        allowTouchMove: false,
        width: 660,
        virtual: {
          slides: this.availableLayers,
          renderExternal (data) {
            this.virtualData = data
          }
        },
        navigation: {
          nextEl: ".analytics--engagement-layers .swiper-button-next",
          prevEl: ".analytics--engagement-layers .swiper-button-prev"
        }
      })
    },
    updateSlides (newSlides) {
      if (this.swiper && this.swiper.virtual) {
        this.swiper.virtual.slides = []
        this.swiper.virtual.update(true)

        this.swiper.virtual.slides = newSlides
        this.swiper.virtual.update(true)
      } else {
        this.initSwiper()
      }
    },
    createChart (ctx, data) {
      if (this.chart) {
        this.chart.destroy()
        if (this.$refs["engagement-canvas"]) {
          this.$refs["engagement-canvas"].width = 1200
          this.$refs["engagement-canvas"].height = 300
        }
      }
      this.chart = new Chart(ctx, {
        type: "line",
        data: data.data,
        options: data.options
      })
    }
  }
}
</script>
