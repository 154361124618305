<template>
  <base-modal
    ref="modal"
    class="modal-auto-height delete-modal text-left"
    :show-close-button="false"
    size="extra-small"
    :title="title"
    @submit="deleteEntity"
  >
    <template #content>
      <p v-html="description"></p>
    </template>
    <template #actions>
      <base-button
        :min-width="94"
        size="m"
        @click.prevent="modal.close"
      >
        {{ cancelButton }}
      </base-button>
      <danger-button
        :min-width="114"
        size="m"
        @click.prevent="deleteEntity"
      >
        {{ actionButton }}
      </danger-button>
    </template>
  </base-modal>
</template>

<script>
import DangerButton from "../controls/DangerButton"
import Language from "../../../i18n/en"

export default {
  components: {
    DangerButton
  },
  props: {
    title: {
      type: String,
      default: "Delete entity?"
    },
    description: {
      type: String,
      default: "Are you sure you want to delete?"
    },
    cancelButton: {
      type: String,
      default: "Cancel"
    },
    actionButton: {
      type: String,
      default: "Delete"
    },
    endpoint: {
      type: String,
      default: null
    },
    successMessage: {
      type: String,
      default: Language.adtags.success.delete
    },
    errorMessage: {
      type: String,
      default: Language.adtags.error.delete
    },
    afterDelete: {
      type: Function,
      default: () => {}
    },
    apiMethod: {
      type: String,
      default: "delete"
    },
    action: {
      type: Function,
      default: null,
      validator: (prop) => prop !== null
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      }
    }
  },
  mounted () {
    this.modal = this.$refs.modal
  },
  methods: {
    deleteEntity () {
      if (this.action) {
        return this.action(this.modal)
          .then(this.onSuccess)
          .catch(() => {
            if (this.errorMessage !== null) {
              this.$toasted.error(this.errorMessage)
            }
          })
      } else {
        return this.$apiClient[this.apiMethod](this.endpoint)
          .then(this.onSuccess)
          .catch(() => {
            if (this.errorMessage !== null) {
              this.$toasted.error(this.errorMessage)
            }
          })
      }
    },
    onSuccess () {
      this.modal.close()
      if (this.successMessage !== null) {
        this.$toasted.success(this.successMessage)
      }
      this.afterDelete()
    }
  }
}
</script>
