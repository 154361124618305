<template>
  <div class="tags adt-tags">
    <div
      v-for="tag in tags"
      :key="'tag' + tag"
      class="tag adt-tag"
      :class="{ 'active': activeTags.indexOf(tag) !== -1}"
      @click.prevent="$emit('activate', tag)"
    >
      <tooltip-too-long
        :length="32"
        :text="tag.substr(0, 50)"
        :tooltip-max-width="450"
      />
      <icon
        v-if="canDeleteTag && defaultTags.indexOf(tag) === -1"
        name="close"
        @click.prevent.stop="deleteTag(tag)"
      />
    </div>
  </div>
</template>
<script>
import Icon from "../Icon"
import { mapState } from "vuex"

export default {
  components: {
    Icon
  },
  props: {
    activeTags: {
      type: Array,
      default: () => []
    },
    tags: {
      type: Array,
      default: () => []
    }
  },
  emits: ["activate", "delete-tag"],
  data () {
    return {
      // Predefined and constants tags for everyone
      defaultTags: [
        "Technology",
        "Game Design",
        "Fashion & Styling",
        "Automotives",
        "Artificial Intelligence",
        "Engineering"
      ]
    }
  },
  computed: {
    canDeleteTag () {
      return this.currentUser.roles.admin || this.currentUser.roles.super_admin
    },
    ...mapState(["currentUser"])
  },
  methods: {
    deleteTag (tag) {
      this.$emit("delete-tag", tag)
    }
  }
}
</script>
