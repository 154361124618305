import Echo from "laravel-echo"

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

const pusherKey = document.head.querySelector("meta[name=\"pusher-key\"]")
if (pusherKey?.content?.length > 0) {
  window.Pusher = require("pusher-js")
  window.Pusher.Runtime.createXHR = function () {
    const xhr = new XMLHttpRequest()
    xhr.withCredentials = true
    return xhr
  }
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: pusherKey.content,
    authEndpoint: process.env.VUE_APP_API_URL + "/broadcasting/auth",
    wsHost: process.env.VUE_APP_API_HOST,
    wssPort: process.env.VUE_APP_API_PORT,
    disableStats: true,
    encrypted: true
  })
} else {
  console.error("Pusher key is missing (meta name: pusher-key)")
}
