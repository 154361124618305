<template>
  <div
    v-if="currentUser"
    class="welcome-text"
  >
    <span class="text-white">
      Hello, {{ currentUser.firstname }}
    </span>
    <span class="text-muted">
      Welcome back!
    </span>
    <badge :role="currentUser.role" />
  </div>
</template>
<script>
import { mapState } from "vuex"

export default {
  name: "Welcome",
  computed: {
    ...mapState(["currentUser"])
  },
  mounted () {
    this.$store.dispatch("loadCurrentUser")
  }
}
</script>
