<template>
  <div>
    <label
      v-if="label"
      class="input-label"
      :class="optional ? 'input-label-optional' : ''"
      :for="uuid"
    >
      <span>
        <tooltip-too-long
          :length="40"
          :text="label"
        />
        <icon
          v-if="informationTooltip"
          class="info-tooltip"
          name="info"
          :tooltip="informationTooltip"
          :tooltip-max-width="220"
          tooltip-placement="right-start"
        />
      </span>
    </label>
    <multiselect
      :id="uuid"
      :key="uuid+dropdownKey"
      ref="multiselect"
      class="adt-dropdown multiselect"
      :class="[
        optional ? 'adt-dropdown-optional' : '',
        error ? 'error' : '',
        enableSelection === false ? 'disable-selection' : '',
        options.length === 0 ? 'no-options':''
      ]"
      :deselect-label="deselectLabel"
      :disabled="!enableSelection"
      :loading="isLoading"
      :model-value="value"
      :options="sortedOptions"
      :multiple="multiple"
      :placeholder="placeholder"
      :searchable="searchable"
      :style="{
        minWidth: minWidth + 'px',
        maxWidth: maxWidth ? maxWidth + 'px' : null
      }"
      :allow-empty="optional"
      :tag-placeholder="tagPlaceholder"
      :label="trackLabel"
      :taggable="taggable"
      :show-labels="false"
      :track-by="trackBy"
      :open-direction="openDirection"
      @close="updateKey"
      @input="emitInput"
      @remove="$emit('remove', $event)"
      @search-change="$emit('search-change', $event)"
      @select="$emit('select', $event)"
      @tag="$emit('tag', $event)"
      @update:model-value="emitInput"
    >
      <template #noOptions="{ option }">
        <slot
          name="noOptions"
          :option="option"
        >
          {{ taggable ? 'Type to add new entity' : 'No options available' }}
        </slot>
      </template>
      <template #singleLabel="{ option }">
        <slot
          name="single-option"
          :option="option"
        >
          {{ option.title ? option.title : option.name }}
        </slot>
        <div class="dropdown-arrow-container">
          <icon
            v-if="showArrow"
            name="dropdown-arrow"
          />
        </div>
      </template>
      <template #placeholder>
        <tooltip-too-long
          :length="25"
          :text="placeholder"
        />
        <div class="dropdown-arrow-container">
          <icon
            v-if="showArrow"
            name="dropdown-arrow"
          />
        </div>
      </template>
      <template #option="props">
        <slot
          name="option-row-icon"
          :option="props.option"
        >
          <icon
            v-if="props.option && props.option.icon"
            :name="props.option.icon"
          />
        </slot>
        <div class="option__desc">
          <span class="option__title">
            <slot
              name="option-row"
              :option="props.option"
            >
              {{ props.option.title ? props.option.title : props.option.name }}
            </slot>
          </span>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Icon from "../Icon"
import Utils from "../../../utils"

import { v4 as uuidv4 } from "uuid"

export default {
  components: {
    Icon
  },
  props: {
    searchable: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    optional: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Object, Number],
      default: null
    },
    minWidth: {
      type: Number,
      default: 260
    },
    maxWidth: {
      type: Number,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: "Select"
    },
    tagPlaceholder: {
      type: String,
      default: "Add new"
    },
    error: {
      type: Boolean,
      default: false
    },
    informationTooltip: {
      type: String,
      default: null
    },
    enableSelection: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    trackLabel: {
      type: String,
      default: "name"
    },
    trackBy: {
      type: String,
      default: "title"
    },
    deselectLabel: {
      type: String,
      default: "Can't remove this value"
    },
    openDirection: {
      type: String,
      default: null
    }
  },
  emits: ["search-change", "select", "tag", "remove", "input", "update:value"],
  data () {
    return {
      dropdownKey: 1
    }
  },
  computed: {
    sortedOptions () {
      let selectedOption
      let sortedOptions = this.options

      if (Utils.isObject(this.value)) {
        selectedOption = this.options.find(opt => Utils.deepEqual(opt, this.value))
        if (selectedOption) {
          const filteredOptions = this.options.filter(opt => !Utils.deepEqual(opt, this.value))
          filteredOptions.unshift(selectedOption)
          sortedOptions = filteredOptions
        }
      } else {
        selectedOption = this.options.find(opt => opt === this.value)
        if (selectedOption) {
          const filteredOptions = this.options.filter(opt => opt !== this.value)
          filteredOptions.unshift(selectedOption)
          sortedOptions = filteredOptions
        }
      }

      return sortedOptions
    }
  },
  created () {
    this.uuid = this.id ? this.id : uuidv4()
  },
  methods: {
    emitInput (value) {
      this.$emit("input", value)
      this.$emit("update:value", value)
    },
    updateKey () {
      this.dropdownKey++
    }
  }
}
</script>
