<template>
  <div class="popup popup-with-search">
    <base-input
      v-model:value="searchValue"
      :active="searchActive"
      icon="search"
      :max-width="maxWidth"
      :min-width="minWidth"
      :padding-x="30"
      placeholder="Search"
      type="text"
      @input="(val) => shouldDebounceSearch ? debouncedSearch(val) : search(val)"
    />
    <slot :data="searchResult"></slot>
  </div>
</template>
<script>
import BaseInput from "./form/BaseInput"
import debounce from "lodash/debounce"
import Utils from "../../utils"

export default {
  components: {
    BaseInput
  },
  props: {
    minWidth: {
      type: Number,
      default: 260
    },
    maxWidth: {
      type: Number,
      default: null
    },
    endpoint: {
      type: String,
      required: true
    },
    queryParams: {
      type: Array,
      default: () => []
    },
    shouldDebounceSearch: {
      type: Boolean,
      default: false
    }
  },
  emits: ["search"],
  data () {
    return {
      searchValue: "",
      searchActive: false,
      searchResult: []
    }
  },
  mounted () {
    this.search("")
  },
  methods: {
    debouncedSearch: debounce(function (value) {
      this.search(value)
    }, 500),
    search (value) {
      const url = this.endpoint

      const queryParams = Utils.cloneDeep(this.queryParams)
      queryParams.push("term=" + this.searchValue)

      this.$emit("search", value)
      return this.$apiClient.get(url + "?" + queryParams.join("&")).then((resp) => {
        this.searchResult = resp.data
        return resp
      })
    }
  }
}
</script>
