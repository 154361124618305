<template>
  <form
    v-if="currentUser"
    class="profile-personal-info"
  >
    <card>
      <card-body>
        <div class="row">
          <div class="col">
            <div
              class="avatar-preview"
              @click="avatarClick"
            >
              <avatar
                :alt="[currentUser.firstname, currentUser.lastname].join(' ')"
                :avatar="customSrc ? null : form.avatar"
                :custom-src="customSrc"
                :size="100"
                :title="[currentUser.firstname, currentUser.lastname].join(' ')"
                :tooltip="false"
                :use-thumb="false"
                :user-id="currentUser.uuid"
              />
              <input
                ref="avatar-file-upload"
                accept="image/*"
                style="display: none;"
                type="file"
                @change="uploadedPhoto"
              />
              <icon name="photo-upload" />
              <context-dropdown
                v-if="form.avatar"
                :margin="[-15,-50,0,0]"
                :min-width="182"
                :options="getRowActions()"
                :placement="'bottom-end'"
                :visible="dropdownActive"
                @click="toggleDropdown"
                @hide="hideDropdown"
              />
            </div>
          </div>
          <div class="col">
            <div class="theme-switch">
              <!--              <mode-switch :dark-mode="currentUser.dark_mode"></mode-switch>-->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <!--            <div class="data-row">-->
            <!--              <span class="label">Achievement:</span>-->
            <!--              <span class="value"><inline-svg :src="require('../../../images/achievement.svg')" /></span>-->
            <!--            </div>-->
            <div
              v-if="currentUser.role"
              class="data-row"
            >
              <span class="label">Role:</span>
              <div class="value">
                <badge :role="currentUser.role" />
              </div>
            </div>
            <div class="data-row">
              <span class="label">Company:</span>
              <span class="value">{{ currentUser.active_company ? currentUser.active_company.name : null }}</span>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col">
            <text-input
              id="firstname"
              v-model:value="firstname"
              :errors="form.errors"
              label="First name"
              :required="true"
            />
          </div>
          <div class="col">
            <text-input
              id="lastname"
              v-model:value="lastname"
              :errors="form.errors"
              label="Last name"
              :required="true"
            />
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col">
            <text-input
              id="timeformat"
              :disabled="true"
              :errors="form.errors"
              :form="form"
              label="Time format"
              :required="true"
              :value="form.timeformat"
            />
          </div>
          <div class="col">
            <text-input
              id="dateformat"
              :disabled="true"
              :errors="form.errors"
              :form="form"
              label="Date format"
              :required="true"
              :value="form.dateformat"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input-dropdown
              v-model:value="country"
              :enable-selection="false"
              label="Country"
              :options="availableCountries"
            />
          </div>
          <div class="col">
            <input-dropdown
              v-model:value="language"
              :enable-selection="false"
              label="Language"
              :options="availableLanguages"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="actions">
              <default-button
                v-if="anyChangesApplied"
                size="xl"
                @click.prevent="cancel"
              >
                Cancel
              </default-button>
              <primary-button
                size="xl"
                @click.prevent="submit"
              >
                Save
              </primary-button>
            </div>
          </div>
        </div>
      </card-body>
    </card>
  </form>
</template>
<script>
import { mapActions, mapState } from "vuex"
import Utils from "../../utils"
import { Form } from "@/classes/form"
import dayjs from "dayjs"
import InputDropdown from "../common/dropdown/InputDropdown"
import Language from "../../i18n/en"
import ContextDropdown from "../common/dropdown/ContextDropdown"
import { UserRoutes } from "../../api/routes"

export default {
  name: "PersonalInfo",
  components: {
    ContextDropdown,
    InputDropdown
  },
  data () {
    return {
      customSrc: null,
      dropdownActive: false,
      anyChangesApplied: false,
      form: new Form({
        avatar: null,
        firstname: null,
        lastname: null,
        dateformat: Utils.parseDate(dayjs(), false),
        timeformat: "24 hr",
        country: "DE",
        language: "en"
      }, true),
      initialAvatar: null
    }
  },
  computed: {
    ...mapState(["currentUser", "availableCountries", "availableLanguages"]),
    country: {
      get () {
        return this.availableCountries.find((row) => row.value === this.form.country)
      },
      set (row) {
        this.form.country = row.value
        this.anyChangesApplied = true
      }
    },
    language: {
      get () {
        return this.availableLanguages.find((row) => row.value === this.form.language)
      },
      set (row) {
        this.form.language = row.value
        this.anyChangesApplied = true
      }
    },
    firstname: {
      get () {
        return this.form.firstname === null ? this.currentUser.firstname : this.form.firstname
      },
      set (val) {
        this.form.firstname = val
        this.anyChangesApplied = true
      }
    },
    lastname: {
      get () {
        return this.form.lastname === null ? this.currentUser.lastname : this.form.lastname
      },
      set (val) {
        this.form.lastname = val
        this.anyChangesApplied = true
      }
    },
    Utils () {
      return Utils
    }
  },
  mounted () {
    this.loadCurrentUser().then(() => {
      this.form.avatar = this.currentUser.avatar
    })
  },
  methods: {
    toggleDropdown () {
      this.dropdownActive = !this.dropdownActive
    },
    hideDropdown () {
      this.dropdownActive = false
    },
    avatarClick () {
      if (this.form.avatar === null) {
        this.uploadPhoto()
      } else {
        this.dropdownActive = !this.dropdownActive
      }
    },
    getRowActions () {
      return [
        {
          content: "Edit",
          icon: "edit",
          click: () => {
            this.dropdownActive = false
            this.uploadPhoto()
          }
        },
        {
          content: "Delete",
          icon: "delete",
          click: () => {
            this.dropdownActive = false
            this.deletePhoto()
          }
        }
      ]
    },
    deletePhoto () {
      this.customSrc = null
      this.form.avatar = null
      this.anyChangesApplied = true
    },
    uploadPhoto () {
      this.$refs["avatar-file-upload"].click()
    },
    uploadedPhoto (e) {
      const reader = new FileReader()
      reader.onload = () => {
        this.customSrc = reader.result
        this.form.avatar = e.target.files[0]
        this.anyChangesApplied = true
      }
      reader.readAsDataURL(e.target.files[0])
    },
    cancel () {
      this.country = { value: "DE" }
      this.language = { value: "en" }
      this.firstname = this.currentUser.firstname
      this.lastname = this.currentUser.lastname
      this.form.avatar = this.currentUser.avatar
      this.customSrc = null
      this.anyChangesApplied = false
    },
    submit () {
      if (this.form.firstname === null) {
        this.form.firstname = this.currentUser.firstname
      }

      if (this.form.lastname === null) {
        this.form.lastname = this.currentUser.lastname
      }

      if (this.form.firstname.length === 0 || this.form.lastname.length === 0) {
        return
      }

      this.form.firstname = this.firstname
      this.form.lastname = this.lastname

      const formData = new FormData()

      for (const key in this.form.data()) {
        formData.append(key, this.form.data()[key])
      }

      return this.$apiClient.post(UserRoutes.updateUserData(this.currentUser.uuid), formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(this.onSuccess)
        .catch(this.onError)
    },
    onSuccess () {
      this.$toasted.success(Language.profile.success.update)
      this.loadCurrentUser()
      this.anyChangesApplied = false
    },
    onError () {
      this.$toasted.error(Language.profile.error.update)
    },
    ...mapActions(["loadCurrentUser"])
  }
}
</script>
