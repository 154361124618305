<template>
  <transition name="fade">
    <div
      v-if="loadingStatus"
      class="loading-status"
    >
      <lottie-animation
        height="300px"
        :loop="true"
        width="300px"
      />
    </div>
  </transition>
</template>
<script>
import { mapState } from "vuex"
import { defineAsyncComponent } from "vue"

export default {
  components: {
    LottieAnimation: defineAsyncComponent(() => import("./LottieAnimation"))
  },
  computed: {
    ...mapState(["loadingStatus"])
  }
}
</script>
