<template>
  <div class="view-container">
    <sidebar />
    <div
      class="app-container"
      @click="$eventHub.$emit('hide-search', $event)"
    >
      <main-header />
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-12 content-inner">
              <user-profile-wrapper />
            </div>
          </div>
        </div>
        <loading-status />
      </div>
    </div>
  </div>
</template>
<script>
import UserProfileWrapper from "../../components/user/ProfileWrapper"

export default {
  components: {
    UserProfileWrapper
  }
}
</script>
