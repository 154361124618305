<template>
  <div class="box-selection">
    <single-box
      v-for="option in options"
      :key="'single-box-' + option.id"
      :active="modelValue === option.id"
      :disabled="option.disabled"
      :icon="option.icon"
      :title="option.title"
      @click="selectedAction(option.id)"
    />
  </div>
</template>
<script>
import SingleBox from "./SingleBox"

export default {
  components: {
    SingleBox
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: String,
      default: null
    }
  },
  emits: ["input", "update:modelValue"],
  methods: {
    selectedAction (optionId) {
      this.$emit("update:modelValue", optionId)
      this.$emit("input", optionId)
    }
  }
}
</script>
