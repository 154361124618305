<template>
  <span v-if="text">
    <span
      v-if="text.length >= length"
      v-tippy="{ maxWidth: tooltipMaxWidth, placement:tooltipPlacement}"
      :content="text"
      style="overflow: hidden; text-overflow: ellipsis; display: block; white-space: nowrap;"
    >{{ text.substr(0, length) + (text.length > length ? '...' : '') }}</span>
    <span v-if="text.length < length">{{ text }}</span>
  </span>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    },
    length: {
      type: Number,
      default: 16
    },
    tooltipMaxWidth: {
      type: Number,
      default: 350
    },
    tooltipPlacement: {
      type: String,
      default: "top"
    }
  }
}
</script>
