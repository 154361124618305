<template>
  <div
    class="selectable-circle"
    :class="active ? 'active' : ''"
    @click="clicked"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"],
  methods: {
    clicked (e) {
      this.$emit("click", e)
    }
  }
}
</script>
