// import Vue from "vue"
import { createRouter, createWebHistory } from "vue-router"
import routes from "./routes"
import Utils from "../utils"
import { useEventHub } from "@/composables/useEventHub"
import { useStore } from "@/composables/useStore"
import DomainDetection from "@/router/middleware/domainDetection"
import { SecurityRoutes } from "@/api/routes"
import { useApiClient } from "@/composables/useApiClient"
import { darkPresets } from "@/classes/showroomConfigManager"

const $eventHub = useEventHub()
const $apiClient = useApiClient()
const store = useStore()

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(DomainDetection)

router.beforeEach((to, from, next) => {
  if (to.path === "/404") {
    return next()
  }

  if (["invitation", "password-reset", "password-recovery"].includes(to.name)) {
    try {
      const selectedCompany = Utils.getCookie("company-id")
      if (selectedCompany) {
        $apiClient.post(SecurityRoutes.logout()).then()
      }
    } catch (e) {
      console.error("Couldn't log out the user.")
    }
  }

  if (to.name !== "showroom") {
    Utils.setShowroomCustomColors(darkPresets)
  }

  if (["login", "invitation", "logout", "user.edit", "campaign.adtags", "showroom", "password-reset", "password-recovery"].indexOf(to.name) !== -1) {
    return next()
  }
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  }

  const selectedCompany = Utils.getCookie("company-id")
  if (!selectedCompany && to.name !== "company") {

    next("/company")
    return
  }

  if (to.name === "designs.edit") {
    store.state.hideEditor = Boolean(to.query?.livingAds || to.query?.vast)
    window.localStorage.setItem("sidebar_collapsed", "true")
  }
  if (from.name === "designs.edit") {
    if (
      store.state.skipNavigationGuard === false &&
      store.state.lastSavedStateHash !== store.getters.getCurrentStateHash
    ) {
      $eventHub.$emit("display-leave-dialog", to)
      next(false)
      return
    }

    store.commit("setNavigationGuardSkip", false)
  }

  if (location.hostname.match(/wagawin\.(io|local)/i)) {
    document.title = "Wagawin"
  }

  next()
})

export default router
export const registerRouter = (app) => {
  app.use(router)
}
