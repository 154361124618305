<template>
  <section class="manager-dashboard">
    <live-campaigns />
    <traffic />
    <recent-campaigns />
  </section>
</template>

<script>
import LiveCampaigns from "./sections/LiveCampaigns"
import Traffic from "./sections/Traffic"
import RecentCampaigns from "./sections/RecentCampaigns"

export default {
  name: "ManagerDashboard",
  components: {
    RecentCampaigns,
    LiveCampaigns,
    Traffic
  }
}
</script>
