<template>
  <div class="device-orientation">
    <h3>Device orientation</h3>
    <div class="icons">
      <div
        class="icon-box"
        :class="{active: modelValue === 'portrait'}"
      >
        <icon
          height="40"
          name="preview-type-portrait"
          width="40"
          @click="changeOrientation('portrait')"
        />
      </div>
      <div
        class="icon-box"
        :class="{active: modelValue === 'landscape'}"
      >
        <icon
          height="40"
          name="preview-type-landscape"
          width="40"
          @click="changeOrientation('landscape')"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "portrait"
    }
  },
  emits: ["update:modelValue"],
  methods: {
    changeOrientation (orientation) {
      this.$emit("update:modelValue", orientation)
    }
  }
}
</script>
