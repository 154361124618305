<template>
  <div :class="statCardContainerClasses">
    <stat-card
      v-for="statCard in availableStatCards"
      :key="statCard.title + '-' + statCard.count"
      :brand="campaign.brand"
      :count="statCard.count"
      :show-bar="showBar"
      :title="statCard.title"
    />
  </div>
</template>
<script>
import StatCard from "./StatCard"
import { mapState } from "vuex"

export default {
  components: {
    StatCard
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    showBar: {
      type: Boolean,
      default: true
    },
    analytics: {
      type: [Object],
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      availableFeatures: state => state.availableFeatures
    }),
    availableStatCards () {
      return [
        {
          title: "Impressions",
          count: this.displays,
          icon: "analytics-impressions"
        },
        {
          title: "Clicks",
          count: this.clicks,
          icon: "analytics-clicks"
        },
        {
          title: "Clickrate",
          count: this.clickrate,
          icon: "analytics-clickrate"
        },
        ...(this.proConfigEnabled
          ? [{
              title: "Avg. time",
              count: this.averageTime
            }]
          : []),
        {
          title: "Eng. rate",
          count: this.engagementRate
        }
      ]
    },
    proConfigEnabled () {
      return this.availableFeatures.includes("fe:adtag.pro_tab")
    },
    statCardContainerClasses () {
      return this.proConfigEnabled ? ["five-children"] : [""]
    },
    displays () {
      return this.analytics ? (this.analytics.displays || 0) : 0
    },
    clicks () {
      return this.analytics ? (this.analytics.clicks || 0) : 0
    },
    clickrate () {
      return this.analytics ? `${this.parseClickrate(this.analytics.clickrate)}%` : "0%"
    },
    engagementRate () {
      return this.analytics ? `${this.parseEngagementRate(this.analytics.engagementRate * 100)}%` : ""
    },
    averageTime () {
      return this.analytics ? this.parseAverageTime(this.analytics.avg_time) : 0
    }
  },
  methods: {
    parseAverageTime (averageTime) {
      return parseInt(averageTime) + "s"
    },
    parseEngagementRate (engagementRate) {
      const engagementRateToFloat = parseFloat(engagementRate)
      const engagementRateToFixedPositioning = engagementRateToFloat.toFixed(2)
      return parseFloat(engagementRateToFixedPositioning).toLocaleString("de-DE")
    },
    parseClickrate (clickrate) {
      const clickrateToFloat = parseFloat(clickrate)
      const clickrateToFixedPositioning = clickrateToFloat.toFixed(2)
      return parseFloat(clickrateToFixedPositioning).toLocaleString("de-DE")
    }
  }
}
</script>
