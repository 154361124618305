<template>
  <div
    class="section-empty-state"
    @click.prevent="handleClick"
  >
    <inline-svg :src="icon" />
    <h2>
      <slot>
        No data yet
      </slot>
    </h2>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg"

export default {
  name: "EmptySection",
  components: {
    InlineSvg
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    link: {
      type: String,
      default: null
    }
  },
  emits: ["click"],
  methods: {
    handleClick (e) {
      this.$emit("click", e)
      if (this.link) {
        window.location.href = this.link
      }
    }
  }
}
</script>
