export class Routes {
  // DELETE
  static deleteTag = (companyUuid) => `/api/company/${companyUuid}`
  static deleteCompany = (companyUuid) => `/api/company/${companyUuid}`
  static deleteBrand = (companyUuid) => `/api/company/brand/${companyUuid}`

  // GET
  static getCompanyList = () => "/api/company"
  static getCompanyResource = (companyUuid) => `/api/company/${companyUuid}`
  static selectCompany = () => "/api/company/select"
  static getLatestComments = () => "/api/company/latest-comment"
  static getRecentCampaign = () => "/api/company/recent-campaign"
  static getRecentCreatives = () => "/api/company/recent-creative"
  static getCompanyUsers = () => "/api/company/user"
  static getCompanyTags = () => "/api/company/tag"
  static getCompanySettings = (companyUuid) => `/api/company/settings/${companyUuid}`
  static checkIfAnyCampaigns = () => "/api/company/campaign/is-any"
  static getShowroomSettings = ()=> "/api/company/showroom/settings"

  // POST
  static createCompany = () => "/api/company"
  static createBrand = () => "/api/company/brand"
  static updateBrand = (brandUuid) => `/api/company/brand/${brandUuid}`
  static createCompanyTag = () => "/api/company/tags"

  // PUT
  static updateCompany = (companyUuid) => `/api/company/${companyUuid}`
  static updateCompanyDetails = (companyUuid) => `/api/company/${companyUuid}/details`
  static archiveCompany = (companyUuid) => `/api/company/archive/${companyUuid}`
  static unarchiveCompany = (companyUuid) => `/api/company/unarchive/${companyUuid}`
  static updateCompanySettings = (companyUuid) => `/api/company/settings/${companyUuid}`
  static updateShowroomSettings = ()=> "/api/company/showroom/settings"

}
