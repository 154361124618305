<template>
  <base-modal
    ref="modal"
    class="modal-auto-height text-left user-email-modal"
    :show-close-button="false"
    size="extra-small"
    :title="title"
    @submit="changeEmail"
  >
    <template #content>
      <div class="container">
        <div class="row">
          <div class="col">
            <text-input
              v-model:value="form.email"
              :error="form.errors && form.errors.email && form.errors.email.length > 0"
              label="New email"
              placeholder="john@company.com"
              type="email"
              @input="mailInput"
            />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col">
            <text-input
              v-model:value="emailConfirmation"
              :error="confirmationError"
              label="Confirm new email"
              placeholder="john@company.com"
              type="email"
            />
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <base-button
        size="xl"
        @click.prevent="modal.close"
      >
        {{ cancelButton }}
      </base-button>
      <primary-button
        :disabled="confirmationError"
        size="xl"
        @click.prevent="changeEmail"
      >
        {{ actionButton }}
      </primary-button>
    </template>
  </base-modal>
</template>

<script>
import Language from "../../../i18n/en"
import { Form } from "@/classes/form"
import { UserRoutes } from "../../../api/routes"

export default {
  props: {
    title: {
      type: String,
      default: "Change Email"
    },
    cancelButton: {
      type: String,
      default: "Cancel"
    },
    actionButton: {
      type: String,
      default: "Save"
    },
    endpoint: {
      type: String,
      default: UserRoutes.requestEmailChange()
    },
    successMessage: {
      type: String,
      default: Language.profile.success.email
    },
    errorMessage: {
      type: String,
      default: Language.profile.error.email
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      },
      emailConfirmation: "",
      form: new Form({
        email: ""
      }, true)
    }
  },
  computed: {
    confirmationError () {
      return this.emailConfirmation !== this.form.email
    }
  },
  mounted () {
    this.modal = this.$refs.modal
  },
  methods: {
    mailInput () {
      this.form.errors.email = []
    },
    changeEmail () {
      if (!this.confirmationError) {
        return this.form
          .post(this.endpoint)
          .then(this.onSuccess)
          .catch(() => {
            this.modal.close()
            this.$toasted.error(this.errorMessage)
          })
      }
    },
    onSuccess () {
      this.modal.close()
      this.$toasted.success(this.successMessage)
    }
  }
}
</script>
