<template>
  <div class="latest-comments">
    <card>
      <card-header>
        <h2 class="comments-header">
          Comments
        </h2>
      </card-header>
      <card-body v-if="comments">
        <template v-if="comments.length > 0">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                v-for="(comment, index) in comments"
                :key="index"
                class="swiper-slide"
              >
                <div class="comment">
                  <div class="comment-meta">
                    <avatar
                      :alt="comment.author ? [comment.author.firstname, comment.author.lastname].join(' ') : 'Anonymous user'"
                      :avatar="comment.author ? comment.author.avatar : null"
                      :size="38"
                      :title="comment.author ? [comment.author.firstname, comment.author.lastname].join(' ') : 'Anonymous user'"
                      :tooltip="true"
                      :user-id="comment.author ? comment.author.uuid : null"
                    />
                    <div class="comment-meta__user">
                      <span class="comment-meta__username">
                        {{
                          comment.author ? comment.author.firstname + ' ' + comment.author.lastname : 'Anonymous user'
                        }}
                      </span>
                      commented on
                      <a
                        class="comment-meta__design-link"
                        :href="getShowroomHref(comment)"
                      >
                        {{ comment.design_name }}
                      </a>
                      &nbsp;
                      <span
                        v-tippy
                        class="comment-meta__time"
                        :content="parseTime(comment.createdAt)"
                      >{{
                        Utils.timeAgoFormatter(comment.createdAt)
                      }}</span>
                    </div>
                  </div>
                  <div class="comment__content">
                    {{ truncate(comment.content) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-button-prev swiper-button-disabled">
            <icon name="arrow_down_comments" />
          </div>
          <div class="swiper-button-next swiper-button-disabled">
            <icon name="arrow_down_comments" />
          </div>
          <div
            v-if="comments.length > 3"
            class="overlay"
          ></div>
        </template>
        <empty-section
          v-if="comments.length === 0"
          :icon="require('../../../../images/dashboard/designer/comments-empty-state.svg')"
        >
          Looks like there are no open feedbacks.
        </empty-section>
      </card-body>
    </card>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
import Utils from "../../../utils"
import Swiper from "swiper/bundle"
import EmptySection from "../components/EmptySection"
import WagawinUtils from "@/wagawinUtils"

export default {
  name: "LatestComments",
  components: {
    EmptySection
  },
  data () {
    return {
      swiper: null,
      virtualData: {
        slides: []
      }
    }
  },
  computed: {
    slideStyles () {
      return `left: ${this.virtualData.offset}px;`
    },
    Utils () {
      return Utils
    },
    ...mapState(["comments", "availableFeatures"])
  },
  created () {
    window.addEventListener("intercomTourChanged", this.loadData)
  },
  beforeUnmount () {
    window.removeEventListener("intercomTourChanged", this.loadData)
  },
  mounted () {
    this.loadData()
  },
  methods: {
    getShowroomHref ({design_unique_id: designUniqueId, design_token: designToken}) {
      if (this.availableFeatures.includes("gr:wagawinFeatures")) {
        const adtronUrl = `${window.location.origin}/showroom/${designUniqueId}?token=${designToken}`
        return WagawinUtils.replaceAdtronUrlToWagawin(adtronUrl)
      }
      return `/showroom/${designUniqueId}?token=${designToken}`
    },
    loadData () {
      this.loadLatestComments().then(() => {
        this.$nextTick(() => {
          this.initSwiper()
        })
      })
    },
    truncate (content) {
      const words = content.split(" ").filter(t => t.length > 0)
      const maxWords = 20
      const maxLength = 60

      if (content.trim().length > maxLength) {
        return content.trim().substr(0, maxLength).trim() + "..."
      }

      if (words.length > maxWords) {
        return content.substring(0, content.indexOf(words[maxWords], maxWords - 2)).trim() + "..."
      }

      return content
    },
    initSwiper () {
      if (this.comments.length > 3) {
        const slideLimit = 3
        this.swiper = new Swiper(".latest-comments .swiper-container", {
          speed: 400,
          spaceBetween: 12,
          slidesPerView: slideLimit,
          allowTouchMove: false,
          direction: "vertical",
          autoHeight: true,
          virtual: {
            slides: this.comments,
            renderExternal (data) {
              this.virtualData = data
            }
          },
          navigation: {
            nextEl: ".latest-comments .swiper-button-next",
            prevEl: ".latest-comments .swiper-button-prev"
          }
        })
      }
    },
    parseTime (time) {
      return Utils.parseDate(time)
    },
    ...mapActions(["loadLatestComments"])
  }
}
</script>
