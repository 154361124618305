import {
  GestureModuleType,
  TypoModuleType
} from "./components/designer/module_types/types"
import { ModuleDataFields as BaseModuleDataFields } from "./moduleDataFields"

const ModuleDataFields = {
  ...BaseModuleDataFields,
  [GestureModuleType]: [
    {
      type: "gesture",
      value: ""
    },
    {
      type: "gestureColor",
      value: "#fff"
    },
    {
      type: "gestureType",
      value: "mouseFilledIcon"
    },
    {
      type: "playInitialAnimation",
      value: 1
    },
    {
      type: "initialAnimationDuration",
      value: 3000
    },
    {
      type: "initialAnimationDelay",
      value: 0
    },
    {
      type: "initialAnimationLoop",
      value: false
    },
    {
      type: "initialAnimationLoopDelay",
      value: 0
    },
    {
      type: "image",
      value: ""
    },
    {
      type: "gestureAnimation",
      value: "tap"
    },
    {
      type: "gestureBackground",
      value: 0
    },
    {
      type: "gestureBackgroundColor",
      value: "#00000080"
    },
    {
      type: "constraints",
      value: []
    }
  ],

  [TypoModuleType]: [
    {
      type: "typoText",
      value: null
    }, {
      type: "fontFamily",
      value: "Open Sans"
    }, {
      type: "fontStyle",
      value: "regular"
    }, {
      type: "fontSize",
      value: 16
    }, {
      type: "letterSpacing",
      value: 0
    }, {
      type: "lineHeight",
      value: 1.2
    }, {
      type: "color",
      value: "#000"
    }, {
      type: "borderStyle",
      value: "none"
    }, {
      type: "clickout",
      value: ""
    }, {
      type: "allowedBaskets",
      value: []
    }, {
      type: "textTransform",
      value: "normal"
    }, {
      type: "textDecoration",
      value: "none"
    }, {
      type: "backgroundColor",
      value: "#00000000"
    }, {
      type: "textAlign",
      value: "center"
    }, {
      type: "textAlignVertical",
      value: "center"
    }, {
      type: "dataBindingId",
      value: null
    }, {
      type: "fontUnit",
      value: "px"
    }, {
      type: "typerEffect",
      value: 0
    }, {
      type: "constraints",
      value: []
    },
    {
      name: "Is custom font selected",
      type: "isCustomFontSelected",
      value: 0
    }
  ]
}

export { ModuleDataFields }
