<template>
  <base-button
    :href="href"
    :icon="icon"
    :size="size"
    :target="target"
    variant="default"
    @click="$emit('click', $event)"
  >
    <slot />
  </base-button>
</template>

<script>
import BaseButton from "./BaseButton"

export default {
  components: {
    BaseButton
  },
  props: {
    size: {
      type: String,
      default: "m",
      validator: (value) => {
        return ["s", "m", "l", "xl"].indexOf(value) !== -1
      }
    },
    target: {
      type: String,
      default: "_self"
    },
    icon: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    }
  },
  emits: ["click"]
}
</script>
