import { plugin as VueTippy } from "vue-tippy/dist/vue-tippy.esm-browser"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import { useToast } from "@/composables/useToast"
import { useStore } from "@/composables/useStore"
import { useEventHub } from "@/composables/useEventHub"
import { useApiClient } from "@/composables/useApiClient"

const store = useStore()
export const registerGlobalPlugins = (app) => {
  // Global event bus to emit payload between components
  app.config.globalProperties.$eventHub = useEventHub()

  // Global API Client
  app.config.globalProperties.$apiClient = useApiClient()

  app.use(VueTippy, {
    defaultProps: {
      zIndex: 99999
    },
    directive: "tippy",
    component: "tippy",
    popperOptions: {
      modifiers: {
        preventOverflow: {
          enabled: false
        },
        hide: {
          enabled: false
        }
      }
    }
  })

  app.use(Toast, {
    position: "top-center",
    timeout: 3000,
    hideProgressBar: true,
    icon: false,
    class: "toasts-container"
  })

  app.config.globalProperties.$toasted = useToast()

  app.config.globalProperties.$modal = {
    show: (view, options, actions) => {
      store.commit("openModal", {view, options, actions})
    },
    hide: () => {
      store.commit("closeModal")
    }
  }
}
