import {
  AssetGroupModuleType,
  AssetModuleType,
  BackgroundModuleType,
  BasketModuleType,
  CalendarModuleType,
  Click2MapsModuleType,
  CountdownModuleType,
  DestinationModuleType,
  GestureModuleType,
  HotOrNotModuleType,
  LightweightSwiperGroupModuleType,
  OfferistaAssetModuleType,
  OfferistaBannerModuleType,
  OfferistaModuleType,
  PanoModuleType,
  ParticleWipeAdModuleType,
  PollModuleType,
  PollSliderModuleType,
  PopupModuleType,
  ShakeModuleType,
  SlidebarModuleType,
  StoryModuleType,
  SurveyModuleType,
  SurveySliderModuleType,
  SwiperGroupModuleType,
  TypoModuleType,
  VideoControlsModuleType,
  VideoModuleType,
  VideoStoryModuleType,
  WagawinVideoPollModuleType,
  VastVideoModuleType,
  WhatsappModuleType,
  WipeAdModuleType,
  GoogleMapsModuleType,
  TimerModuleType,
  PuzzleModuleType,
  MemoryModuleType,
  SliderModuleType,
  ImageSequenceModuleType,
  MatchModuleType, CounterModuleType
} from "./components/designer/module_types/types"
import { EventManager } from "./classes/eventManager"
import { v4 as uuidv4 } from "uuid"
import { PRODUCT_DOOH, PRODUCT_VAST } from "./constants"

export const getTrackingFromKeys = (keys, moduleUuid) => {
  if (!keys) return []

  return keys.map((key) => {
    return {
      trigger: key,
      target: moduleUuid,
      action: {
        [EventManager.ACTION_TRACK]: {
          trackingUrl: key
        }
      }
    }
  })
}

/**
 * returns init events for a given module type
 * @returns {event[]} events
 * @param {String} moduleType
 * @param productType
 * @param {String} moduleUuid
 */
export const getModuleInitEvents = ({ moduleType, moduleUuid, productType = "rich_media" }) =>{
  let result = []
  let keys = []
  switch (moduleType) {
    case AssetGroupModuleType:
      result = []
      break
    case BackgroundModuleType:
      keys = [
        EventManager.TRIGGER_SWIPE_LEFT,
        EventManager.TRIGGER_SWIPE_RIGHT,
        EventManager.TRIGGER_SWIPE_UP,
        EventManager.TRIGGER_SWIPE_DOWN,
        EventManager.TRIGGER_LONGPRESS
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      result.push(
        {
          trigger: EventManager.TRIGGER_CLICK,
          target: moduleUuid,
          action: {
            [EventManager.ACTION_TRACK]: {
              trackingUrl: EventManager.CLICKOUT
            }
          }
        }
      )
      break
    case BasketModuleType:
      keys = [
        EventManager.BASKET_FULL,
        EventManager.BASKET_TIMEOUT,
        EventManager.BASKET_MISSED,
        EventManager.BASKET_DROP,
        EventManager.BASKET_DRAG,
        EventManager.BASKET_DRAG_ONCE,
        EventManager.BASKET_DROP_X
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case MatchModuleType:
      keys = [
        EventManager.MATCH_CARD_YES_X,
        EventManager.MATCH_CARD_X,
        EventManager.MATCH_CARD_NO_X,
        EventManager.MATCH_USER_INTERACTION,
        EventManager.MATCH_TIMEOUT,
        EventManager.MATCH_FINISHED
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case CalendarModuleType:
      keys = [
        EventManager.CALENDAR_MRAID_ADD,
        EventManager.CALENDAR_ICS_ADD
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case Click2MapsModuleType:
      keys = [
        EventManager.MAPS_DEFAULT
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case DestinationModuleType:
      result = []
      break
    case GestureModuleType:
      result = []
      break
    case PanoModuleType:
      keys = [
        EventManager.PANO_ENGAGEMENT_RIGHT,
        EventManager.PANO_ENGAGEMENT_LEFT,
        EventManager.PANO_ENGAGEMENT_CENTER,
        EventManager.PANO_ENGAGEMENT_TOP,
        EventManager.PANO_ENGAGEMENT_MIDDLE,
        EventManager.PANO_ENGAGEMENT_BOTTOM,
        EventManager.PANO_USER_INTERACTION,
        EventManager.PANO_READY,
        EventManager.VIDEOPANO_VTR_0,
        EventManager.VIDEOPANO_VTR_25,
        EventManager.VIDEOPANO_VTR_50,
        EventManager.VIDEOPANO_VTR_75,
        EventManager.VIDEOPANO_VTR_100,
        EventManager.VIDEOPANO_FINISHED,
        EventManager.VIDEOPANO_PAUSE,
        EventManager.VIDEOPANO_PLAY,
        EventManager.VIDEOPANO_START,
        EventManager.VIDEOPANO_RESUME,
        EventManager.VIDEOPANO_REPLAY,
        EventManager.VIDEOPANO_MUTE,
        EventManager.VIDEOPANO_UNMUTE
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case ParticleWipeAdModuleType:
      keys = [
        EventManager.WIPE_START,
        EventManager.WIPE_FINISHED,
        EventManager.WIPE_TIMEOUT
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case PopupModuleType:
      keys = [
        EventManager.POPUP_SHOWN,
        EventManager.POPUP_HIDDEN
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case StoryModuleType:
      keys = [
        EventManager.STORY_VIEW,
        EventManager.STORY_ON_SKIP_LEFT,
        EventManager.STORY_ON_SKIP_RIGHT,
        EventManager.STORY_FINISHED,
        EventManager.getEventForStoryStep("%")
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case VideoStoryModuleType:
      keys = [
        EventManager.VIDEO_STORY_VIEW,
        EventManager.VIDEO_STORY_ON_SKIP_LEFT,
        EventManager.VIDEO_STORY_ON_SKIP_RIGHT,
        EventManager.VIDEO_STORY_FINISHED,
        EventManager.VIDEO_STORY_STEP_VTR,
        EventManager.getEventForVideoStoryStep("%")
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case ShakeModuleType:
      keys = [
        EventManager.SHAKE_SHAKED,
        EventManager.SHAKE_ONE,
        EventManager.SHAKE_TWO,
        EventManager.SHAKE_THREE,
        EventManager.SHAKE_FOUR,
        EventManager.SHAKE_FINISHED,
        EventManager.SHAKE_TIMEOUT
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case SlidebarModuleType:
      keys = [
        EventManager.SLIDEBAR_FIRST_INTERACTION,
        EventManager.SLIDEBAR_USER_SLIDE_ONCE,
        EventManager.SLIDEBAR_LEFT_OPEN,
        EventManager.SLIDEBAR_RIGHT_OPEN,
        EventManager.SLIDEBAR_TIMEOUT,
        EventManager.SLIDEBAR_DRAG_IN_RANGE_MIN_MAX,
        EventManager.SLIDEBAR_RELEASED_IN_RANGE_MIN_MAX
      ]
      result = getTrackingFromKeys(keys, moduleUuid)

      if (productType === PRODUCT_DOOH) {
        const openLeftOnLoadEvent = {
          action: {
            slidebarOpenLeft: {
              moduleId: moduleUuid
            }
          },
          conditions: [],
          delay: null,
          design_variant_uuid: null,
          target: moduleUuid,
          trigger: "target_onload"
        }
        result.push(openLeftOnLoadEvent)
      }
      break
    case SwiperGroupModuleType:
      keys = [
        EventManager.GALLERY_USER_INTERACTION_ONCE,
        EventManager.GALLERY_SLIDE,
        EventManager.GALLERY_SLIDE_ONCE,
        EventManager.GALLERY_USER_SLIDE,
        EventManager.GALLERY_USER_SLIDE_ONCE,
        EventManager.GALLERY_USER_SLIDE_CHANGE
      ]

      if (productType === PRODUCT_VAST) {
        keys = [
          EventManager.CSV_GALLERY_VAST_VIDEO_START,
          EventManager.CSV_GALLERY_VAST_VIDEO_FIRST_QUARTILE,
          EventManager.CSV_GALLERY_VAST_VIDEO_MIDPOINT,
          EventManager.CSV_GALLERY_VAST_VIDEO_THIRD_QUARTILE,
          EventManager.CSV_GALLERY_VAST_VIDEO_COMPLETE,
          EventManager.CSV_GALLERY_VAST_VIDEO_MUTE,
          EventManager.CSV_GALLERY_VAST_VIDEO_UNMUTE,
          EventManager.CSV_GALLERY_VAST_VIDEO_PAUSE,
          EventManager.CSV_GALLERY_VAST_VIDEO_FULLSCREEN
        ]
      }
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case LightweightSwiperGroupModuleType:
      keys = [
        EventManager.GALLERY_USER_INTERACTION_ONCE,
        EventManager.GALLERY_SLIDE,
        EventManager.GALLERY_SLIDE_ONCE,
        EventManager.GALLERY_USER_SLIDE,
        EventManager.GALLERY_USER_SLIDE_ONCE,
        EventManager.GALLERY_USER_SLIDE_CHANGE
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case VideoModuleType:
      keys = [
        EventManager.VIDEO_VTR_0,
        EventManager.VIDEO_VTR_25,
        EventManager.VIDEO_VTR_50,
        EventManager.VIDEO_VTR_75,
        EventManager.VIDEO_VTR_100,
        EventManager.VIDEO_FINISHED,
        EventManager.VIDEO_PAUSE,
        EventManager.VIDEO_PLAY,
        EventManager.VIDEO_START,
        EventManager.VIDEO_RESUME,
        EventManager.VIDEO_REPLAY,
        EventManager.VIDEO_RESET,
        EventManager.VIDEO_MUTE,
        EventManager.VIDEO_UNMUTE,
        EventManager.VIDEO_PLAYER_EXPAND,
        EventManager.VIDEO_PLAYER_COLLAPSE,
        EventManager.TRIGGER_SWIPE_LEFT,
        EventManager.TRIGGER_SWIPE_RIGHT,
        EventManager.TRIGGER_SWIPE_UP,
        EventManager.TRIGGER_SWIPE_DOWN,
        EventManager.TRIGGER_LONGPRESS
      ]

      result = getTrackingFromKeys(keys, moduleUuid)

      result.push(
        {
          trigger: EventManager.TRIGGER_CLICK,
          target: moduleUuid,
          action: {
            [EventManager.ACTION_TRACK]: {
              trackingUrl: EventManager.CLICKOUT
            }
          }
        }
      )
      break
    case VideoControlsModuleType:
      result = []
      break
    case VastVideoModuleType:
      keys = [
        EventManager.VAST_VIDEO_START,
        EventManager.VAST_VIDEO_FIRST_QUARTILE,
        EventManager.VAST_VIDEO_MIDPOINT,
        EventManager.VAST_VIDEO_THIRD_QUARTILE,
        EventManager.VAST_VIDEO_COMPLETE,
        EventManager.VAST_VIDEO_MUTE,
        EventManager.VAST_VIDEO_UNMUTE,
        EventManager.VAST_VIDEO_PAUSE,
        EventManager.VAST_VIDEO_FULLSCREEN
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case WhatsappModuleType:
      keys = [
        EventManager.WHATSAPP_SHARE
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case WipeAdModuleType:
      keys = [
        EventManager.WIPE_START,
        EventManager.WIPE_FINISHED,
        EventManager.WIPE_TIMEOUT
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case CounterModuleType:
      keys = [
        EventManager.COUNTER_ON_VALUE_CHANGE_TRACK
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case AssetModuleType:
      keys = [
        EventManager.TRIGGER_SWIPE_LEFT,
        EventManager.TRIGGER_SWIPE_RIGHT,
        EventManager.TRIGGER_SWIPE_UP,
        EventManager.TRIGGER_SWIPE_DOWN,
        EventManager.TRIGGER_LONGPRESS
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      result.push(
        {
          trigger: EventManager.TRIGGER_CLICK,
          target: moduleUuid,
          action: {
            [EventManager.ACTION_TRACK]: {
              trackingUrl: EventManager.CLICKOUT
            }
          }
        }
      )
      break
    case TypoModuleType:
      keys = [
        EventManager.TRIGGER_SWIPE_LEFT,
        EventManager.TRIGGER_SWIPE_RIGHT,
        EventManager.TRIGGER_SWIPE_UP,
        EventManager.TRIGGER_SWIPE_DOWN,
        EventManager.TRIGGER_LONGPRESS
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      result.push(
        {
          trigger: EventManager.TRIGGER_CLICK,
          target: moduleUuid,
          action: {
            [EventManager.ACTION_TRACK]: {
              trackingUrl: EventManager.CLICKOUT
            }
          }
        }
      )
      break
    case PollModuleType:
      keys = [
        EventManager.ACTION_POLL_RELEASED,
        EventManager.ACTION_POLL_LEFT,
        EventManager.ACTION_POLL_RIGHT,
        EventManager.ACTION_POLL_TIMEOUT,
        EventManager.ACTION_POLL_SLIDING_LEFT,
        EventManager.ACTION_POLL_SLIDING_RIGHT
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case PollSliderModuleType:
      result = []
      break
    case SurveyModuleType:
      keys = [
        EventManager.ACTION_SURVEY_VOTE_LEFT,
        EventManager.ACTION_SURVEY_VOTE_CENTER,
        EventManager.ACTION_SURVEY_VOTE_RIGHT,
        EventManager.ACTION_SURVEY_REVOTE,
        EventManager.ACTION_SURVEY_VOTE_SAVED
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case SurveySliderModuleType:
      result = []
      break
    case SliderModuleType:
      keys = [
        EventManager.SLIDER_USER_SLIDE,
        EventManager.SLIDER_USER_SLIDE_ONCE,
        EventManager.SLIDER_RELEASED_0_50,
        EventManager.SLIDER_RELEASED_50_100,
        EventManager.SLIDER_DRAG_TO_THE_START,
        EventManager.SLIDER_DRAG_TO_THE_END,
        EventManager.SLIDER_DRAG_IN_RANGE_MIN_MAX,
        EventManager.SLIDER_RELEASED_IN_RANGE_MIN_MAX
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case OfferistaModuleType:
      result = []
      break
    case OfferistaAssetModuleType:
      result = []
      break
    case OfferistaBannerModuleType:
      result = [
        {
          trigger: EventManager.TRIGGER_CLICK,
          target: moduleUuid,
          action: {
            [EventManager.ACTION_TRACK]: {
              trackingUrl: EventManager.CLICKOUT
            }
          }
        }
      ]
      break
    case CountdownModuleType:
      keys = [
        EventManager.ACTION_COUNTDOWN_FINISHED
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case HotOrNotModuleType:
      keys = EventManager.HOT_OR_NOT_ACTIONS
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case WagawinVideoPollModuleType:
      keys = EventManager.VIDEO_POLL_ACTIONS
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case GoogleMapsModuleType:
      keys = [
        EventManager.ACTION_MAP_ACTIVATED
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case TimerModuleType:
      keys = [
        EventManager.TRACK_GOAL_TIMER_START,
        EventManager.TIMER_STEP_25,
        EventManager.TIMER_STEP_50,
        EventManager.TIMER_STEP_75,
        EventManager.TIMER_FINISHED,
        EventManager.TIMER_TIMESTAMP_TRACK
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case PuzzleModuleType:
      keys = [
        EventManager.PUZZLE_USER_INTERACTION,
        EventManager.PUZZLE_SWAPPED,
        EventManager.PUZZLE_COMPLETE,
        EventManager.PUZZLE_TIMEOUT
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case MemoryModuleType:
      keys = [
        EventManager.MEMORY_WIN,
        EventManager.MEMORY_MATCH,
        EventManager.MEMORY_NO_MATCH,
        EventManager.MEMORY_RESET,
        EventManager.MEMORY_USER_INTERACTION,
        EventManager.MEMORY_USER_INTERACTION,
        EventManager.MEMORY_TIMEOUT
      ]
      result = getTrackingFromKeys(keys, moduleUuid)
      break
    case ImageSequenceModuleType:
      keys = EventManager.IMAGE_SEQUENCE_TRACKING
      result = getTrackingFromKeys(keys, moduleUuid)
      break
  }

  return result.map((res) => {
    res.uuid = uuidv4()
    return res
  })
}
