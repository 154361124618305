export class Routes {
  // delete
  static deleteUser = (userUuid) => `/api/admin/user/${userUuid}`
  static deleteGroup = () => "/api/groups"

  // get
  static getAvailableRoles = () => "/api/admin/roles"
  static getUsersResources = () => "/api/admin/user"
  static getGroup = () => "/api/groups"
  static getBuildNodeStatus = () => "/api/admin/build-node-status"

  // post
  static createUser = () => "/api/admin/user"
  static resendUserInvitation = (userUuid) => `/api/admin/user/${userUuid}/reinvite`
  static sendUserInvitation = () => "/api/admin/user/invite/email"
  static createGroup = () => "/api/groups"

  // put
  static updateUser = (userUuid) => `/api/admin/user/${userUuid}`
  static updateGroup = () => "/api/groups"
}
