<template>
  <div class="campaign-list__header">
    <div class="avatar-row"></div>
    <div class="campaign">
      <span class="column-name">campaign</span>
    </div>
    <div class="duration">
      <span class="column-name">duration</span>
    </div>
    <div class="impressions">
      <span class="column-name">target</span>
    </div>
    <div class="actions"></div>
  </div>
</template>

<script>
export default {
  name: "CampaignListHeader"
}
</script>

