<template>
  <div class="common-box single-box">
    <div class="common-box-actions">
      <context-dropdown
        :margin="[-18,-10,0,0]"
        :min-width="182"
        :options="getRowActions()"
        :placement="'bottom-end'"
        :visible="dropdownActive"
        @hide="hideDropdown"
      />
    </div>
    <div class="common-box-details">
      <brand
        :brand="brand"
        :size="52"
        :tooltip="false"
      />
      <p>
        <tooltip-too-long
          :length="16"
          :text="brand.name"
        />
      </p>
    </div>
  </div>
</template>
<script>
import ContextDropdown from "../common/dropdown/ContextDropdown"
import BrandModal from "../common/modal/BrandModal"
import DeleteModal from "../common/modal/DeleteModal"
import Language from "../../i18n/en"
import { CompanyRoutes } from "../../api/routes"

export default {
  components: {
    ContextDropdown
  },
  props: {
    brand: {
      type: Object,
      required: true
    }
  },
  emits: ["reload"],
  data () {
    return {
      dropdownActive: false
    }
  },
  methods: {
    toggleDropdown () {
      this.dropdownActive = !this.dropdownActive
    },
    hideDropdown () {
      this.dropdownActive = false
    },
    getRowActions () {
      const actions = []
      actions.push({
        content: "Edit",
        icon: "edit",
        click: this.edit
      })
      actions.push({
        content: "Delete",
        icon: "delete",
        click: this.destroy
      })
      return actions
    },
    edit () {
      this.dropdownActive = false
      this.$modal.show(BrandModal, {
        brand: this.brand,
        title: "Edit brand",
        action: () => this.$emit("reload")
      })
    },
    destroy () {
      this.$modal.show(DeleteModal, {
        endpoint: CompanyRoutes.deleteBrand(this.brand.uuid),
        title: `Delete Brand ${this.brand.name}`,
        successMessage: Language.brands.success.delete,
        errorMessage: Language.brands.error.delete,
        afterDelete: () => {
          this.$emit("reload")
        }
      })
    }
  }
}
</script>
