import { addClassOnHoverDirective, clickoutsideDirective, highlightjsDirective } from "."

/**
 *  Register directives globally
 * */
const directives = {
  "click-outside": clickoutsideDirective,
  highlightjs: highlightjsDirective,
  "add-class-on-hover": addClassOnHoverDirective
}

export const registerGlobalDirectives = (app) => {
  Object.keys(directives).forEach(key => app.directive(key, directives[key]))
}
