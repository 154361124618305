<template>
  <base-modal
    ref="modal"
    class="modal-auto-height delete-modal text-left"
    :show-close-button="false"
    size="extra-small"
    :title="title"
    @submit="confirmAction"
  >
    <template #content>
      <p>{{ description }}</p>
    </template>
    <template #actions>
      <base-button
        :min-width="94"
        size="m"
        @click.prevent="cancelAction"
      >
        {{ cancelButton }}
      </base-button>
      <danger-button
        v-if="isWarning"
        :min-width="114"
        size="m"
        @click.prevent="confirmAction"
      >
        {{ actionButton }}
      </danger-button>
      <primary-button
        v-if="!isWarning"
        :min-width="114"
        size="m"
        @click.prevent="confirmAction"
      >
        {{ actionButton }}
      </primary-button>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "./BaseModal"
import DangerButton from "../controls/DangerButton"

export default {
  components: {
    BaseModal,
    DangerButton
  },
  props: {
    title: {
      type: String,
      default: "Confirm dialog"
    },
    description: {
      type: String,
      default: "Are You sure you want to perform this action"
    },
    cancelButton: {
      type: String,
      default: "Cancel"
    },
    actionButton: {
      type: String,
      default: "Reject"
    },
    isWarning: {
      type: Boolean,
      default: false
    },
    confirm: {
      type: Function,
      default: () => {}
    },
    cancel: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      }
    }
  },
  mounted () {
    this.modal = this.$refs.modal
  },
  methods: {
    confirmAction (e) {
      this.modal.close()
      this.confirm(e)
    },
    cancelAction (e) {
      this.modal.close()
      this.cancel(e)
    }
  }
}
</script>
