<template>
  <div
    class="color-box"
    :class="{active}"
    @click="$emit('click', $event)"
  >
    <div
      class="color"
      :style="{background: color}"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#ffffff"
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"]
}
</script>
