import TextUtils from "@/utils"

export const SLIDE = "slide"
export const SLIDE_IN = "slideIn"
export const SLIDE_OUT = "slideOut"
export const FLIP = "flip"
export const FLIP_IN = "flipIn"
export const FLIP_OUT = "flipOut"
export const FADE = "fade"
export const FADE_IN = "fadeIn"
export const FADE_OUT = "fadeOut"
export const SWAP = "swap"
export const SWAP_IN = "swapIn"
export const SWAP_OUT = "swapOut"
export const NONE = "none"
export const sceneTransitions = [SLIDE, FLIP, FADE, SWAP, NONE]
export const sceneInTransitions = [SLIDE_IN, FLIP_IN, FADE_IN, SWAP_IN, NONE]
export const sceneOutTransitions = [SLIDE_OUT, FLIP_OUT, FADE_OUT, SWAP_OUT, NONE]
export const sceneTransitionsOptions = sceneTransitions.map((t) => {
  return { name: TextUtils.ucfirst(t), value: t }
})
export const sceneInTransitionsOptions = sceneInTransitions.map((t) => {
  return { name: TextUtils.ucfirst(t), value: t }
})
export const sceneOutTransitionsOptions = sceneOutTransitions.map((t) => {
  return { name: TextUtils.ucfirst(t), value: t }
})
