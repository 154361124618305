import Utils from "../utils.js"
import { PanoModuleType } from "@/components/designer/module_types/types"

export class AnimationManager {
  static get ANIMATION_ON_HOVER_PRESETS_NAME () {
    return {
      [AnimationManager.PULSE]: "Pulse",
      [AnimationManager.FADE_OUT]: "Fade out",
      [AnimationManager.FLOAT]: "Float"
    }
  }

  static get ANIMATION_PRESETS_NAME () {
    return {
      // Attention seekers
      [AnimationManager.BOUNCE]: "Bounce",
      [AnimationManager.FLASH]: "Flash",
      [AnimationManager.PULSE]: "Pulse",
      [AnimationManager.RUBBER_BAND]: "Rubber band",
      [AnimationManager.SHAKE]: "Shake",
      [AnimationManager.SHAKE_Y]: "ShakeY",
      [AnimationManager.SWING]: "Swing",
      [AnimationManager.TADA]: "Tada",
      [AnimationManager.WOBBLE]: "Wobble",
      [AnimationManager.JELLO]: "Jello",
      [AnimationManager.HEARTBEAT]: "Heartbeat",
      [AnimationManager.SHINE_LEFT]: "Shine (left)",
      [AnimationManager.SHINE_RIGHT]: "Shine (right)",
      [AnimationManager.SHINE_UP]: "Shine (up)",
      [AnimationManager.SHINE_DOWN]: "Shine (down)",
      [AnimationManager.FLOAT]: "Float",

      // Back exists
      [AnimationManager.BACK_IN_DOWN]: "Back in (down)",
      [AnimationManager.BACK_IN_LEFT]: "Back in (left)",
      [AnimationManager.BACK_IN_RIGHT]: "Back in (right)",
      [AnimationManager.BACK_IN_UP]: "Back in (up)",

      // Back entrances
      [AnimationManager.BACK_OUT_DOWN]: "Back out (down)",
      [AnimationManager.BACK_OUT_LEFT]: "Back out (left)",
      [AnimationManager.BACK_OUT_RIGHT]: "Back out (right)",
      [AnimationManager.BACK_OUT_UP]: "Back out (up)",

      // Bounce in
      [AnimationManager.BOUNCE_IN]: "Bounce in",
      [AnimationManager.BOUNCE_IN_LEFT]: "Bounce in (left)",
      [AnimationManager.BOUNCE_IN_RIGHT]: "Bounce in (right)",
      [AnimationManager.BOUNCE_IN_UP]: "Bounce in (up)",
      [AnimationManager.BOUNCE_IN_DOWN]: "Bounce in (down)",

      // Bounce out
      [AnimationManager.BOUNCE_OUT]: "Bounce out",
      [AnimationManager.BOUNCE_OUT_LEFT]: "Bounce out (left)",
      [AnimationManager.BOUNCE_OUT_RIGHT]: "Bounce out (right)",
      [AnimationManager.BOUNCE_OUT_UP]: "Bounce out (up)",
      [AnimationManager.BOUNCE_OUT_DOWN]: "Bounce out (down)",

      // Scale in down
      [AnimationManager.SCALE_IN_DOWN]: "Scale in down",

      // Fade in
      [AnimationManager.FADE_IN]: "Fade in",
      [AnimationManager.FADE_IN_LEFT]: "Fade in (left)",
      [AnimationManager.FADE_IN_RIGHT]: "Fade in (right)",
      [AnimationManager.FADE_IN_UP]: "Fade in (up)",
      [AnimationManager.FADE_IN_DOWN]: "Fade in (down)",

      // Fade in big
      [AnimationManager.FADE_IN_LEFT_BIG]: "Fade in big (left)",
      [AnimationManager.FADE_IN_RIGHT_BIG]: "Fade in big (right)",
      [AnimationManager.FADE_IN_UP_BIG]: "Fade in big (up)",
      [AnimationManager.FADE_IN_DOWN_BIG]: "Fade in big (down)",

      // Fade out
      [AnimationManager.FADE_OUT]: "Fade out",
      [AnimationManager.FADE_OUT_LEFT]: "Fade out (left)",
      [AnimationManager.FADE_OUT_RIGHT]: "Fade out (right)",
      [AnimationManager.FADE_OUT_UP]: "Fade out (up)",
      [AnimationManager.FADE_OUT_DOWN]: "Fade out (down)",

      // Fade out big
      [AnimationManager.FADE_OUT_LEFT_BIG]: "Fade out big (left)",
      [AnimationManager.FADE_OUT_RIGHT_BIG]: "Fade out big (right)",
      [AnimationManager.FADE_OUT_UP_BIG]: "Fade out big (up)",
      [AnimationManager.FADE_OUT_DOWN_BIG]: "Fade out big (down)",

      // Flip
      [AnimationManager.FLIP_X]: "Flip x",
      [AnimationManager.FLIP]: "Flip y",
      [AnimationManager.FLIP_IN_X]: "Flip in x",
      [AnimationManager.FLIP_IN_Y]: "Flip in y",
      [AnimationManager.FLIP_OUT_X]: "Flip out x",
      [AnimationManager.FLIP_OUT_Y]: "Flip out y",

      // Light speed
      [AnimationManager.LIGHT_SPEED_IN]: "Light speed in (right)",
      [AnimationManager.LIGHT_SPEED_OUT]: "Light speed out (right)",
      [AnimationManager.LIGHT_SPEED_IN_LEFT]: "Light speed in (left)",
      [AnimationManager.LIGHT_SPEED_OUT_LEFT]: "Light speed out (left)",

      // Rotate in
      [AnimationManager.ROTATE_IN]: "Rotate in",

      // Rotate out
      [AnimationManager.ROTATE_OUT]: "Rotate out",

      // Rotators
      [AnimationManager.ROTATE_LEFT]: "Rotate left",
      [AnimationManager.ROTATE_RIGHT]: "Rotate right",

      // Slide in
      [AnimationManager.SLIDE_IN_LEFT]: "Slide in (left)",
      [AnimationManager.SLIDE_IN_RIGHT]: "Slide in (right)",
      [AnimationManager.SLIDE_IN_UP]: "Slide in (up)",
      [AnimationManager.SLIDE_IN_DOWN]: "Slide in (down)",

      // Slide out
      [AnimationManager.SLIDE_OUT_LEFT]: "Slide out (left)",
      [AnimationManager.SLIDE_OUT_RIGHT]: "Slide out (right)",
      [AnimationManager.SLIDE_OUT_UP]: "Slide out (up)",
      [AnimationManager.SLIDE_OUT_DOWN]: "Slide out (down)",

      // Zoom in
      [AnimationManager.ZOOM_IN]: "Zoom in",
      [AnimationManager.ZOOM_IN_LEFT]: "Zoom in (left)",
      [AnimationManager.ZOOM_IN_RIGHT]: "Zoom in (right)",
      [AnimationManager.ZOOM_IN_UP]: "Zoom in (up)",
      [AnimationManager.ZOOM_IN_DOWN]: "Zoom in (down)",
      [AnimationManager.ZOOM_IN_SMOOTH]: "Smooth zoom in",

      // Zoom out
      [AnimationManager.ZOOM_OUT]: "Zoom out",
      [AnimationManager.ZOOM_OUT_LEFT]: "Zoom out (left)",
      [AnimationManager.ZOOM_OUT_RIGHT]: "Zoom out (right)",
      [AnimationManager.ZOOM_OUT_UP]: "Zoom out (up)",
      [AnimationManager.ZOOM_OUT_DOWN]: "Zoom out (down)",
      [AnimationManager.ZOOM_OUT_SMOOTH]: "Smooth zoom out",

      // Specials
      [AnimationManager.HINGE]: "Hinge",
      [AnimationManager.JACK_IN_THE_BOX]: "Jack in the box",
      [AnimationManager.ROLL_IN]: "Roll in",
      [AnimationManager.ROLL_OUT]: "Roll out",

      [AnimationManager.BLUR_IN]: "Blur in",
      [AnimationManager.BLUR_OUT]: "Blur out",

      [AnimationManager.REVEAL_IN_RIGHT]: "Reveal in right",
      [AnimationManager.REVEAL_IN_LEFT]: "Reveal in left",
      [AnimationManager.REVEAL_IN_UP]: "Reveal in up",
      [AnimationManager.REVEAL_IN_DOWN]: "Reveal in down",

      [AnimationManager.REVEAL_OUT_RIGHT]: "Reveal out right",
      [AnimationManager.REVEAL_OUT_LEFT]: "Reveal out left",
      [AnimationManager.REVEAL_OUT_UP]: "Reveal out up",
      [AnimationManager.REVEAL_OUT_DOWN]: "Reveal out down"
    }
  }

  static get ANIMATION_OUT_EFFECTS () {
    return [
      // Bounce out
      AnimationManager.BOUNCE_OUT,
      AnimationManager.BOUNCE_OUT_LEFT,
      AnimationManager.BOUNCE_OUT_RIGHT,
      AnimationManager.BOUNCE_OUT_UP,
      AnimationManager.BOUNCE_OUT_DOWN,

      // Fade out
      AnimationManager.FADE_OUT,
      AnimationManager.FADE_OUT_LEFT,
      AnimationManager.FADE_OUT_RIGHT,
      AnimationManager.FADE_OUT_UP,
      AnimationManager.FADE_OUT_DOWN,

      // Back exits
      AnimationManager.BACK_OUT_UP,
      AnimationManager.BACK_OUT_LEFT,
      AnimationManager.BACK_OUT_RIGHT,
      AnimationManager.BACK_OUT_DOWN,

      // Light speed
      AnimationManager.LIGHT_SPEED_OUT,
      AnimationManager.LIGHT_SPEED_OUT_LEFT,

      // Rotate out
      AnimationManager.ROTATE_OUT,

      // Slide out
      AnimationManager.SLIDE_OUT_LEFT,
      AnimationManager.SLIDE_OUT_RIGHT,
      AnimationManager.SLIDE_OUT_UP,
      AnimationManager.SLIDE_OUT_DOWN,

      // Zoom out
      AnimationManager.ZOOM_OUT,
      AnimationManager.ZOOM_OUT_LEFT,
      AnimationManager.ZOOM_OUT_RIGHT,
      AnimationManager.ZOOM_OUT_UP,
      AnimationManager.ZOOM_OUT_DOWN,
      AnimationManager.ZOOM_OUT_SMOOTH,

      // Specials
      AnimationManager.HINGE,
      AnimationManager.ROLL_OUT,
      AnimationManager.ZOOM_OUT_SMOOTH,
      AnimationManager.BLUR_OUT,

      // Reveal
      AnimationManager.REVEAL_OUT_RIGHT,
      AnimationManager.REVEAL_OUT_LEFT,
      AnimationManager.REVEAL_OUT_UP,
      AnimationManager.REVEAL_OUT_DOWN,

      AnimationManager.PROPS_MIXED
    ]
  }

  static get ANIMATION_PRESETS_DURATION () {
    return {
      // Attention seekers
      [AnimationManager.BOUNCE]: 1000,
      [AnimationManager.FLASH]: 1000,
      [AnimationManager.PULSE]: 1000,
      [AnimationManager.RUBBER_BAND]: 1000,
      [AnimationManager.SHAKE]: 1000,
      [AnimationManager.SHAKE_Y]: 1000,
      [AnimationManager.SWING]: 1000,
      [AnimationManager.TADA]: 1000,
      [AnimationManager.WOBBLE]: 1000,
      [AnimationManager.JELLO]: 1000,
      [AnimationManager.SHINE_LEFT]: 1000,
      [AnimationManager.SHINE_RIGHT]: 1000,
      [AnimationManager.SHINE_UP]: 1000,
      [AnimationManager.SHINE_DOWN]: 1000,
      [AnimationManager.FLOAT]: 1000,
      [AnimationManager.HEARTBEAT]: 1000,

      // Back entrances
      [AnimationManager.BACK_IN_DOWN]: 1000,
      [AnimationManager.BACK_IN_LEFT]: 1000,
      [AnimationManager.BACK_IN_RIGHT]: 1000,
      [AnimationManager.BACK_IN_UP]: 1000,

      // Back exists
      [AnimationManager.BACK_OUT_DOWN]: 1000,
      [AnimationManager.BACK_OUT_LEFT]: 1000,
      [AnimationManager.BACK_OUT_RIGHT]: 1000,
      [AnimationManager.BACK_OUT_UP]: 1000,

      // Bounce in
      [AnimationManager.BOUNCE_IN]: 1000,
      [AnimationManager.BOUNCE_IN_LEFT]: 1000,
      [AnimationManager.BOUNCE_IN_RIGHT]: 1000,
      [AnimationManager.BOUNCE_IN_UP]: 1000,
      [AnimationManager.BOUNCE_IN_DOWN]: 1000,

      // Bounce out
      [AnimationManager.BOUNCE_OUT]: 1000,
      [AnimationManager.BOUNCE_OUT_LEFT]: 1000,
      [AnimationManager.BOUNCE_OUT_RIGHT]: 1000,
      [AnimationManager.BOUNCE_OUT_UP]: 1000,
      [AnimationManager.BOUNCE_OUT_DOWN]: 1000,

      // Scale in
      [AnimationManager.SCALE_IN_DOWN]: 1000,

      // Fade in
      [AnimationManager.FADE_IN]: 1000,
      [AnimationManager.FADE_IN_LEFT]: 1000,
      [AnimationManager.FADE_IN_RIGHT]: 1000,
      [AnimationManager.FADE_IN_UP]: 1000,
      [AnimationManager.FADE_IN_DOWN]: 1000,

      // Fade in big
      [AnimationManager.FADE_IN_LEFT_BIG]: 1000,
      [AnimationManager.FADE_IN_RIGHT_BIG]: 1000,
      [AnimationManager.FADE_IN_UP_BIG]: 1000,
      [AnimationManager.FADE_IN_DOWN_BIG]: 1000,

      // Fade out
      [AnimationManager.FADE_OUT]: 1000,
      [AnimationManager.FADE_OUT_LEFT]: 1000,
      [AnimationManager.FADE_OUT_RIGHT]: 1000,
      [AnimationManager.FADE_OUT_UP]: 1000,
      [AnimationManager.FADE_OUT_DOWN]: 1000,

      // Fade out big
      [AnimationManager.FADE_OUT_LEFT_BIG]: 1000,
      [AnimationManager.FADE_OUT_RIGHT_BIG]: 1000,
      [AnimationManager.FADE_OUT_UP_BIG]: 1000,
      [AnimationManager.FADE_OUT_DOWN_BIG]: 1000,

      // Flip
      [AnimationManager.FLIP]: 1000,
      [AnimationManager.FLIP_X]: 1000,
      [AnimationManager.FLIP_IN_X]: 1000,
      [AnimationManager.FLIP_IN_Y]: 1000,
      [AnimationManager.FLIP_OUT_X]: 1000,
      [AnimationManager.FLIP_OUT_Y]: 1000,

      // Light speed
      [AnimationManager.LIGHT_SPEED_IN]: 1000,
      [AnimationManager.LIGHT_SPEED_OUT]: 1000,
      [AnimationManager.LIGHT_SPEED_IN_LEFT]: 1000,
      [AnimationManager.LIGHT_SPEED_OUT_LEFT]: 1000,

      // Rotate in
      [AnimationManager.ROTATE_IN]: 1000,
      // Rotate out
      [AnimationManager.ROTATE_OUT]: 1000,

      // Rotators
      [AnimationManager.ROTATE_LEFT]: 1000,
      [AnimationManager.ROTATE_RIGHT]: 1000,

      // Slide in
      [AnimationManager.SLIDE_IN_LEFT]: 1000,
      [AnimationManager.SLIDE_IN_RIGHT]: 1000,
      [AnimationManager.SLIDE_IN_UP]: 1000,
      [AnimationManager.SLIDE_IN_DOWN]: 1000,

      // Slide out
      [AnimationManager.SLIDE_OUT_LEFT]: 1000,
      [AnimationManager.SLIDE_OUT_RIGHT]: 1000,
      [AnimationManager.SLIDE_OUT_UP]: 1000,
      [AnimationManager.SLIDE_OUT_DOWN]: 1000,

      // Zoom in
      [AnimationManager.ZOOM_IN]: 1000,
      [AnimationManager.ZOOM_IN_LEFT]: 1000,
      [AnimationManager.ZOOM_IN_RIGHT]: 1000,
      [AnimationManager.ZOOM_IN_UP]: 1000,
      [AnimationManager.ZOOM_IN_DOWN]: 1000,
      [AnimationManager.ZOOM_IN_SMOOTH]: 1000,

      // Zoom out
      [AnimationManager.ZOOM_OUT]: 1000,
      [AnimationManager.ZOOM_OUT_LEFT]: 1000,
      [AnimationManager.ZOOM_OUT_RIGHT]: 1000,
      [AnimationManager.ZOOM_OUT_UP]: 1000,
      [AnimationManager.ZOOM_OUT_DOWN]: 1000,
      [AnimationManager.ZOOM_OUT_SMOOTH]: 1000,

      // Specials
      [AnimationManager.HINGE]: 1000,
      [AnimationManager.JACK_IN_THE_BOX]: 1000,
      [AnimationManager.ROLL_IN]: 1000,
      [AnimationManager.ROLL_OUT]: 1000,
      [AnimationManager.BLUR_IN]: 1000,
      [AnimationManager.BLUR_OUT]: 1000,
      [AnimationManager.REVEAL_IN_LEFT]: 1000,
      [AnimationManager.REVEAL_IN_RIGHT]: 1000,
      [AnimationManager.REVEAL_IN_UP]: 1000,
      [AnimationManager.REVEAL_IN_DOWN]: 1000,
      [AnimationManager.REVEAL_OUT_LEFT]: 1000,
      [AnimationManager.REVEAL_OUT_RIGHT]: 1000,
      [AnimationManager.REVEAL_OUT_UP]: 1000,
      [AnimationManager.REVEAL_OUT_DOWN]: 1000
    }
  }

  static get ANIMATION_PRESETS_ORIGIN () {
    return {
      // Attention seekers
      [AnimationManager.BOUNCE]: null,
      [AnimationManager.FLASH]: null,
      [AnimationManager.PULSE]: null,
      [AnimationManager.RUBBER_BAND]: null,
      [AnimationManager.SHAKE]: null,
      [AnimationManager.SHAKE_Y]: null,
      [AnimationManager.SWING]: null,
      [AnimationManager.TADA]: null,
      [AnimationManager.WOBBLE]: null,
      [AnimationManager.JELLO]: null,
      [AnimationManager.HEARTBEAT]: null,
      [AnimationManager.SHINE_LEFT]: null,
      [AnimationManager.SHINE_RIGHT]: null,
      [AnimationManager.SHINE_UP]: null,
      [AnimationManager.SHINE_DOWN]: null,
      [AnimationManager.FLOAT]: null,

      // Back entrances
      [AnimationManager.BACK_IN_DOWN]: null,
      [AnimationManager.BACK_IN_LEFT]: null,
      [AnimationManager.BACK_IN_RIGHT]: null,
      [AnimationManager.BACK_IN_UP]: null,

      // Back exists
      [AnimationManager.BACK_OUT_DOWN]: null,
      [AnimationManager.BACK_OUT_LEFT]: null,
      [AnimationManager.BACK_OUT_RIGHT]: null,
      [AnimationManager.BACK_OUT_UP]: null,

      // Bounce in
      [AnimationManager.BOUNCE_IN]: null,
      [AnimationManager.BOUNCE_IN_LEFT]: null,
      [AnimationManager.BOUNCE_IN_RIGHT]: null,
      [AnimationManager.BOUNCE_IN_UP]: null,
      [AnimationManager.BOUNCE_IN_DOWN]: null,

      // Bounce out
      [AnimationManager.BOUNCE_OUT]: null,
      [AnimationManager.BOUNCE_OUT_LEFT]: null,
      [AnimationManager.BOUNCE_OUT_RIGHT]: null,
      [AnimationManager.BOUNCE_OUT_UP]: null,
      [AnimationManager.BOUNCE_OUT_DOWN]: null,

      // Scale out
      [AnimationManager.SCALE_IN_DOWN]: null,

      // Fade in
      [AnimationManager.FADE_IN]: null,
      [AnimationManager.FADE_IN_LEFT]: null,
      [AnimationManager.FADE_IN_RIGHT]: null,
      [AnimationManager.FADE_IN_UP]: null,
      [AnimationManager.FADE_IN_DOWN]: null,

      // Fade in big
      [AnimationManager.FADE_IN_LEFT_BIG]: null,
      [AnimationManager.FADE_IN_RIGHT_BIG]: null,
      [AnimationManager.FADE_IN_UP_BIG]: null,
      [AnimationManager.FADE_IN_DOWN_BIG]: null,

      // Fade out
      [AnimationManager.FADE_OUT]: null,
      [AnimationManager.FADE_OUT_LEFT]: null,
      [AnimationManager.FADE_OUT_RIGHT]: null,
      [AnimationManager.FADE_OUT_UP]: null,
      [AnimationManager.FADE_OUT_DOWN]: null,

      // Fade out big
      [AnimationManager.FADE_OUT_LEFT_BIG]: null,
      [AnimationManager.FADE_OUT_RIGHT_BIG]: null,
      [AnimationManager.FADE_OUT_UP_BIG]: null,
      [AnimationManager.FADE_OUT_DOWN_BIG]: null,

      // Flip
      [AnimationManager.FLIP]: null,
      [AnimationManager.FLIP_X]: null,
      [AnimationManager.FLIP_IN_X]: null,
      [AnimationManager.FLIP_IN_Y]: null,
      [AnimationManager.FLIP_OUT_X]: null,
      [AnimationManager.FLIP_OUT_Y]: null,

      // Light speed
      [AnimationManager.LIGHT_SPEED_IN]: null,
      [AnimationManager.LIGHT_SPEED_OUT]: null,
      [AnimationManager.LIGHT_SPEED_IN_LEFT]: null,
      [AnimationManager.LIGHT_SPEED_OUT_LEFT]: null,

      // Rotate in
      [AnimationManager.ROTATE_IN]: null,
      // Rotate out
      [AnimationManager.ROTATE_OUT]: null,

      // Rotators
      [AnimationManager.ROTATE_LEFT]: null,
      [AnimationManager.ROTATE_RIGHT]: null,

      // Slide in
      [AnimationManager.SLIDE_IN_LEFT]: null,
      [AnimationManager.SLIDE_IN_RIGHT]: null,
      [AnimationManager.SLIDE_IN_UP]: null,
      [AnimationManager.SLIDE_IN_DOWN]: null,

      // Slide out
      [AnimationManager.SLIDE_OUT_LEFT]: null,
      [AnimationManager.SLIDE_OUT_RIGHT]: null,
      [AnimationManager.SLIDE_OUT_UP]: null,
      [AnimationManager.SLIDE_OUT_DOWN]: null,

      // Zoom in
      [AnimationManager.ZOOM_IN]: null,
      [AnimationManager.ZOOM_IN_LEFT]: null,
      [AnimationManager.ZOOM_IN_RIGHT]: null,
      [AnimationManager.ZOOM_IN_UP]: null,
      [AnimationManager.ZOOM_IN_DOWN]: null,
      [AnimationManager.ZOOM_IN_SMOOTH]: null,

      // Zoom out
      [AnimationManager.ZOOM_OUT]: null,
      [AnimationManager.ZOOM_OUT_LEFT]: "left center",
      [AnimationManager.ZOOM_OUT_RIGHT]: "right center",
      [AnimationManager.ZOOM_OUT_UP]: "center bottom",
      [AnimationManager.ZOOM_OUT_DOWN]: "center top",
      [AnimationManager.ZOOM_OUT_SMOOTH]: null,

      // Specials
      [AnimationManager.HINGE]: "top left",
      [AnimationManager.JACK_IN_THE_BOX]: "center bottom",
      [AnimationManager.ROLL_IN]: null,
      [AnimationManager.ROLL_OUT]: null,
      [AnimationManager.BLUR_IN]: null,
      [AnimationManager.BLUR_OUT]: null,
      [AnimationManager.REVEAL_IN_RIGHT]: null,
      [AnimationManager.REVEAL_IN_LEFT]: null,
      [AnimationManager.REVEAL_IN_UP]: null,
      [AnimationManager.REVEAL_IN_DOWN]: null,
      [AnimationManager.REVEAL_OUT_RIGHT]: null,
      [AnimationManager.REVEAL_OUT_LEFT]: null,
      [AnimationManager.REVEAL_OUT_UP]: null,
      [AnimationManager.REVEAL_OUT_DOWN]: null
    }
  }

  // Null stands for easing transition
  static get ANIMATION_EASINGS () {
    return {
      [AnimationManager.PROPS_POSITION]: null,
      [AnimationManager.PROPS_OPACITY]: null,
      [AnimationManager.PROPS_EVENT_ANIMATION]: null,

      // Attention seekers
      [AnimationManager.BOUNCE]: null,
      [AnimationManager.FLASH]: null,
      [AnimationManager.PULSE]: null,
      [AnimationManager.RUBBER_BAND]: null,
      [AnimationManager.SHAKE]: null,
      [AnimationManager.SHAKE_Y]: null,
      [AnimationManager.SWING]: null,
      [AnimationManager.TADA]: null,
      [AnimationManager.WOBBLE]: null,
      [AnimationManager.JELLO]: null,
      [AnimationManager.HEARTBEAT]: null,
      [AnimationManager.SHINE_LEFT]: null,
      [AnimationManager.SHINE_RIGHT]: null,
      [AnimationManager.SHINE_UP]: null,
      [AnimationManager.SHINE_DOWN]: null,
      [AnimationManager.FLOAT]: AnimationManager.EASING_LINEAR(AnimationManager.EASING_IN_OUT).type,

      // Back entrances
      [AnimationManager.BACK_IN_DOWN]: null,
      [AnimationManager.BACK_IN_LEFT]: null,
      [AnimationManager.BACK_IN_RIGHT]: null,
      [AnimationManager.BACK_IN_UP]: null,

      // Back exists
      [AnimationManager.BACK_OUT_DOWN]: null,
      [AnimationManager.BACK_OUT_LEFT]: null,
      [AnimationManager.BACK_OUT_RIGHT]: null,
      [AnimationManager.BACK_OUT_UP]: null,

      // Bounce in
      [AnimationManager.BOUNCE_IN]: null,
      [AnimationManager.BOUNCE_IN_LEFT]: null,
      [AnimationManager.BOUNCE_IN_RIGHT]: null,
      [AnimationManager.BOUNCE_IN_UP]: null,
      [AnimationManager.BOUNCE_IN_DOWN]: null,

      // Bounce out
      [AnimationManager.BOUNCE_OUT]: null,
      [AnimationManager.BOUNCE_OUT_LEFT]: null,
      [AnimationManager.BOUNCE_OUT_RIGHT]: null,
      [AnimationManager.BOUNCE_OUT_UP]: null,
      [AnimationManager.BOUNCE_OUT_DOWN]: null,

      // Scale in
      [AnimationManager.SCALE_IN_DOWN]: AnimationManager.EASING_POWER1(AnimationManager.EASING_OUT).type,

      // Fade in
      [AnimationManager.FADE_IN]: AnimationManager.EASING_POWER1(AnimationManager.EASING_OUT).type,
      [AnimationManager.FADE_IN_LEFT]: AnimationManager.EASING_POWER1(AnimationManager.EASING_OUT).type,
      [AnimationManager.FADE_IN_RIGHT]: AnimationManager.EASING_POWER1(AnimationManager.EASING_OUT).type,
      [AnimationManager.FADE_IN_UP]: AnimationManager.EASING_POWER1(AnimationManager.EASING_OUT).type,
      [AnimationManager.FADE_IN_DOWN]: AnimationManager.EASING_POWER1(AnimationManager.EASING_OUT).type,

      // Fade in big
      [AnimationManager.FADE_IN_LEFT_BIG]: AnimationManager.EASING_POWER1(AnimationManager.EASING_OUT).type,
      [AnimationManager.FADE_IN_RIGHT_BIG]: AnimationManager.EASING_POWER1(AnimationManager.EASING_OUT).type,
      [AnimationManager.FADE_IN_UP_BIG]: AnimationManager.EASING_POWER1(AnimationManager.EASING_OUT).type,
      [AnimationManager.FADE_IN_DOWN_BIG]: AnimationManager.EASING_POWER1(AnimationManager.EASING_OUT).type,

      // Fade out
      [AnimationManager.FADE_OUT]: null,
      [AnimationManager.FADE_OUT_LEFT]: null,
      [AnimationManager.FADE_OUT_RIGHT]: null,
      [AnimationManager.FADE_OUT_UP]: null,
      [AnimationManager.FADE_OUT_DOWN]: null,

      // Fade out big
      [AnimationManager.FADE_OUT_LEFT_BIG]: null,
      [AnimationManager.FADE_OUT_RIGHT_BIG]: null,
      [AnimationManager.FADE_OUT_UP_BIG]: null,
      [AnimationManager.FADE_OUT_DOWN_BIG]: null,

      // Flip
      [AnimationManager.FLIP]: null,
      [AnimationManager.FLIP_X]: null,
      [AnimationManager.FLIP_IN_X]: null,
      [AnimationManager.FLIP_IN_Y]: null,
      [AnimationManager.FLIP_OUT_X]: null,
      [AnimationManager.FLIP_OUT_Y]: null,

      // Light speed
      [AnimationManager.LIGHT_SPEED_IN]: AnimationManager.EASING_LINEAR(AnimationManager.EASING_OUT).type,
      [AnimationManager.LIGHT_SPEED_OUT]: null,
      [AnimationManager.LIGHT_SPEED_IN_LEFT]: AnimationManager.EASING_LINEAR(AnimationManager.EASING_OUT).type,
      [AnimationManager.LIGHT_SPEED_OUT_LEFT]: null,

      // Rotate in
      [AnimationManager.ROTATE_IN]: null,
      // Rotate out
      [AnimationManager.ROTATE_OUT]: null,

      // Rotators
      [AnimationManager.ROTATE_LEFT]: null,
      [AnimationManager.ROTATE_RIGHT]: null,

      // Slide in
      [AnimationManager.SLIDE_IN_LEFT]: null,
      [AnimationManager.SLIDE_IN_RIGHT]: null,
      [AnimationManager.SLIDE_IN_UP]: null,
      [AnimationManager.SLIDE_IN_DOWN]: null,

      // Slide out
      [AnimationManager.SLIDE_OUT_LEFT]: null,
      [AnimationManager.SLIDE_OUT_RIGHT]: null,
      [AnimationManager.SLIDE_OUT_UP]: null,
      [AnimationManager.SLIDE_OUT_DOWN]: null,

      // Zoom in
      [AnimationManager.ZOOM_IN]: null,
      [AnimationManager.ZOOM_IN_LEFT]: null,
      [AnimationManager.ZOOM_IN_RIGHT]: null,
      [AnimationManager.ZOOM_IN_UP]: null,
      [AnimationManager.ZOOM_IN_DOWN]: null,
      [AnimationManager.ZOOM_IN_SMOOTH]: null,

      // Zoom out
      [AnimationManager.ZOOM_OUT]: null,
      [AnimationManager.ZOOM_OUT_LEFT]: null,
      [AnimationManager.ZOOM_OUT_RIGHT]: null,
      [AnimationManager.ZOOM_OUT_UP]: null,
      [AnimationManager.ZOOM_OUT_DOWN]: null,
      [AnimationManager.ZOOM_OUT_SMOOTH]: null,

      // Specials
      [AnimationManager.HINGE]: AnimationManager.EASING_POWER1(AnimationManager.EASING_IN_OUT).type,
      [AnimationManager.JACK_IN_THE_BOX]: AnimationManager.EASING_POWER1(AnimationManager.EASING_IN_OUT).type,
      [AnimationManager.ROLL_IN]: null,
      [AnimationManager.ROLL_OUT]: null,
      [AnimationManager.BLUR_IN]: null,
      [AnimationManager.BLUR_OUT]: null,
      [AnimationManager.REVEAL_IN_RIGHT]: null,
      [AnimationManager.REVEAL_IN_LEFT]: null,
      [AnimationManager.REVEAL_IN_UP]: null,
      [AnimationManager.REVEAL_IN_DOWN]: null,
      [AnimationManager.REVEAL_OUT_RIGHT]: null,
      [AnimationManager.REVEAL_OUT_LEFT]: null,
      [AnimationManager.REVEAL_OUT_UP]: null,
      [AnimationManager.REVEAL_OUT_DOWN]: null
    }
  }

  static get ANIMATIONS_GROUPED_BY_FOLDER () {
    return [
      {
        name: "Attention seekers",
        animations: [
          AnimationManager.BOUNCE,
          AnimationManager.FLASH,
          AnimationManager.PULSE,
          AnimationManager.RUBBER_BAND,
          AnimationManager.SHAKE,
          AnimationManager.SHAKE_Y,
          AnimationManager.SWING,
          AnimationManager.TADA,
          AnimationManager.WOBBLE,
          AnimationManager.JELLO,
          AnimationManager.HEARTBEAT,
          AnimationManager.SHINE_LEFT,
          AnimationManager.SHINE_RIGHT,
          AnimationManager.SHINE_UP,
          AnimationManager.SHINE_DOWN,
          AnimationManager.FLOAT
        ]
      },
      {
        name: "Back entrances",
        animations: [
          AnimationManager.BACK_IN_DOWN,
          AnimationManager.BACK_IN_LEFT,
          AnimationManager.BACK_IN_RIGHT,
          AnimationManager.BACK_IN_UP
        ]
      },
      {
        name: "Back exits",
        animations: [
          AnimationManager.BACK_OUT_DOWN,
          AnimationManager.BACK_OUT_LEFT,
          AnimationManager.BACK_OUT_RIGHT,
          AnimationManager.BACK_OUT_UP
        ]
      },
      {
        name: "Bounce in",
        animations: [
          AnimationManager.BOUNCE_IN,
          AnimationManager.BOUNCE_IN_LEFT,
          AnimationManager.BOUNCE_IN_RIGHT,
          AnimationManager.BOUNCE_IN_UP,
          AnimationManager.BOUNCE_IN_DOWN
        ]
      },
      {
        name: "Bounce out",
        animations: [
          AnimationManager.BOUNCE_OUT,
          AnimationManager.BOUNCE_OUT_LEFT,
          AnimationManager.BOUNCE_OUT_RIGHT,
          AnimationManager.BOUNCE_OUT_UP,
          AnimationManager.BOUNCE_OUT_DOWN
        ]
      },
      {
        name: "Fading entrances",
        animations: [
          AnimationManager.FADE_IN,
          AnimationManager.FADE_IN_LEFT,
          AnimationManager.FADE_IN_RIGHT,
          AnimationManager.FADE_IN_UP,
          AnimationManager.FADE_IN_DOWN,
          AnimationManager.FADE_IN_LEFT_BIG,
          AnimationManager.FADE_IN_RIGHT_BIG,
          AnimationManager.FADE_IN_UP_BIG,
          AnimationManager.FADE_IN_DOWN_BIG
        ]
      },
      {
        name: "Fading exits",
        animations: [
          AnimationManager.FADE_OUT,
          AnimationManager.FADE_OUT_LEFT,
          AnimationManager.FADE_OUT_RIGHT,
          AnimationManager.FADE_OUT_UP,
          AnimationManager.FADE_OUT_DOWN,
          AnimationManager.FADE_OUT_LEFT_BIG,
          AnimationManager.FADE_OUT_RIGHT_BIG,
          AnimationManager.FADE_OUT_UP_BIG,
          AnimationManager.FADE_OUT_DOWN_BIG
        ]
      },
      {
        name: "Flippers",
        animations: [
          AnimationManager.FLIP,
          AnimationManager.FLIP_X,
          AnimationManager.FLIP_IN_X,
          AnimationManager.FLIP_IN_Y,
          AnimationManager.FLIP_OUT_X,
          AnimationManager.FLIP_OUT_Y
        ]
      },
      {
        name: "Light speed",
        animations: [
          AnimationManager.LIGHT_SPEED_IN,
          AnimationManager.LIGHT_SPEED_OUT,
          AnimationManager.LIGHT_SPEED_IN_LEFT,
          AnimationManager.LIGHT_SPEED_OUT_LEFT
        ]
      },
      {
        name: "Rotating entrances",
        animations: [
          AnimationManager.ROTATE_IN
        ]
      },
      {
        name: "Rotating exits",
        animations: [
          AnimationManager.ROTATE_OUT
        ]
      },
      {
        name: "Rotators",
        animations: [
          AnimationManager.ROTATE_LEFT,
          AnimationManager.ROTATE_RIGHT
        ]
      },
      {
        name: "Specials",
        animations: [
          AnimationManager.HINGE,
          AnimationManager.JACK_IN_THE_BOX,
          AnimationManager.ROLL_IN,
          AnimationManager.ROLL_OUT,
          AnimationManager.BLUR_IN,
          AnimationManager.BLUR_OUT,
          AnimationManager.REVEAL_IN_RIGHT,
          AnimationManager.REVEAL_IN_LEFT,
          AnimationManager.REVEAL_IN_UP,
          AnimationManager.REVEAL_IN_DOWN,
          AnimationManager.REVEAL_OUT_RIGHT,
          AnimationManager.REVEAL_OUT_LEFT,
          AnimationManager.REVEAL_OUT_UP,
          AnimationManager.REVEAL_OUT_DOWN
        ]
      },
      {
        name: "Zooming entrances",
        animations: [
          AnimationManager.ZOOM_IN,
          AnimationManager.ZOOM_IN_LEFT,
          AnimationManager.ZOOM_IN_RIGHT,
          AnimationManager.ZOOM_IN_UP,
          AnimationManager.ZOOM_IN_DOWN,
          AnimationManager.ZOOM_IN_SMOOTH,
          AnimationManager.SCALE_IN_DOWN
        ]
      },
      {
        name: "Zooming exits",
        animations: [
          AnimationManager.ZOOM_OUT,
          AnimationManager.ZOOM_OUT_LEFT,
          AnimationManager.ZOOM_OUT_RIGHT,
          AnimationManager.ZOOM_OUT_UP,
          AnimationManager.ZOOM_OUT_DOWN,
          AnimationManager.ZOOM_OUT_SMOOTH
        ]
      },
      {
        name: "Sliding entrances",
        animations: [
          AnimationManager.SLIDE_IN_LEFT,
          AnimationManager.SLIDE_IN_RIGHT,
          AnimationManager.SLIDE_IN_UP,
          AnimationManager.SLIDE_IN_DOWN
        ]
      },
      {
        name: "Sliding exits",
        animations: [
          AnimationManager.SLIDE_OUT_LEFT,
          AnimationManager.SLIDE_OUT_RIGHT,
          AnimationManager.SLIDE_OUT_UP,
          AnimationManager.SLIDE_OUT_DOWN
        ]
      }
    ]
  }

  static get BOUNCE () {
    return "bounce"
  }

  static get FLASH () {
    return "flash"
  }

  static get PULSE () {
    return "pulse"
  }

  static get RUBBER_BAND () {
    return "rubber_band"
  }

  static get SHAKE () {
    return "shake"
  }

  static get SHAKE_Y () {
    return "shake_y"
  }

  static get SWING () {
    return "swing"
  }

  static get TADA () {
    return "tada"
  }

  static get WOBBLE () {
    return "wobble"
  }

  static get JELLO () {
    return "jello"
  }

  static get HEARTBEAT () {
    return "heartbeat"
  }

  static get SHINE () {
    return "shine"
  }

  static get SHINE_RIGHT () {
    return "shine_right"
  }

  static get SHINE_LEFT () {
    return "shine_left"
  }

  static get SHINE_UP () {
    return "shine_up"
  }

  static get SHINE_DOWN () {
    return "shine_down"
  }

  static get FLOAT () {
    return "float"
  }

  // Bounce in

  static get BOUNCE_IN () {
    return "bounce_in"
  }

  static get BOUNCE_IN_LEFT () {
    return "bounce_in_left"
  }

  static get BOUNCE_IN_RIGHT () {
    return "bounce_in_right"
  }

  static get BOUNCE_IN_UP () {
    return "bounce_in_up"
  }

  static get BOUNCE_IN_DOWN () {
    return "bounce_in_down"
  }

  // Bounce out

  static get BOUNCE_OUT () {
    return "bounce_out"
  }

  static get BOUNCE_OUT_LEFT () {
    return "bounce_out_left"
  }

  static get BOUNCE_OUT_RIGHT () {
    return "bounce_out_right"
  }

  static get BOUNCE_OUT_UP () {
    return "bounce_out_up"
  }

  static get BOUNCE_OUT_DOWN () {
    return "bounce_out_down"
  }

  // Back entrances

  static get BACK_IN_DOWN () {
    return "back_in_down"
  }

  static get BACK_IN_LEFT () {
    return "back_in_left"
  }

  static get BACK_IN_RIGHT () {
    return "back_in_right"
  }

  static get BACK_IN_UP () {
    return "back_in_up"
  }

  // Back exits

  static get BACK_OUT_DOWN () {
    return "back_out_down"
  }

  static get BACK_OUT_LEFT () {
    return "back_out_left"
  }

  static get BACK_OUT_RIGHT () {
    return "back_out_right"
  }

  static get BACK_OUT_UP () {
    return "back_out_up"
  }

  // Scale in
  static get SCALE_IN_DOWN () {
    return "scale_in_down"
  }

  // Fade in

  static get FADE_IN () {
    return "fade_in"
  }

  static get FADE_IN_LEFT () {
    return "fade_in_left"
  }

  static get FADE_IN_RIGHT () {
    return "fade_in_right"
  }

  static get FADE_IN_UP () {
    return "fade_in_up"
  }

  static get FADE_IN_DOWN () {
    return "fade_in_down"
  }

  // Fade in big

  static get FADE_IN_LEFT_BIG () {
    return "fade_in_left_big"
  }

  static get FADE_IN_RIGHT_BIG () {
    return "fade_in_right_big"
  }

  static get FADE_IN_UP_BIG () {
    return "fade_in_up_big"
  }

  static get FADE_IN_DOWN_BIG () {
    return "fade_in_down_big"
  }

  // Fade out

  static get FADE_OUT () {
    return "fade_out"
  }

  static get FADE_OUT_LEFT () {
    return "fade_out_left"
  }

  static get FADE_OUT_RIGHT () {
    return "fade_out_right"
  }

  static get FADE_OUT_UP () {
    return "fade_out_up"
  }

  static get FADE_OUT_DOWN () {
    return "fade_out_down"
  }

  // Fade out big

  static get FADE_OUT_LEFT_BIG () {
    return "fade_out_left_big"
  }

  static get FADE_OUT_RIGHT_BIG () {
    return "fade_out_right_big"
  }

  static get FADE_OUT_UP_BIG () {
    return "fade_out_up_big"
  }

  static get FADE_OUT_DOWN_BIG () {
    return "fade_out_down_big"
  }

  // Flip

  static get FLIP () {
    return "flip"
  }

  static get FLIP_X () {
    return "flip_x"
  }

  static get FLIP_IN_X () {
    return "flip_in_x"
  }

  static get FLIP_IN_Y () {
    return "flip_in_y"
  }

  static get FLIP_OUT_X () {
    return "flip_out_x"
  }

  static get FLIP_OUT_Y () {
    return "flip_out_y"
  }

  // Light speed

  static get LIGHT_SPEED_IN () {
    return "light_speed_in"
  }

  static get LIGHT_SPEED_OUT () {
    return "light_speed_out"
  }

  static get LIGHT_SPEED_IN_LEFT () {
    return "light_speed_in_left"
  }

  static get LIGHT_SPEED_OUT_LEFT () {
    return "light_speed_out_left"
  }

  // Rotate in

  static get ROTATE_IN () {
    return "rotate_in"
  }

  // Rotate out

  static get ROTATE_OUT () {
    return "rotate_out"
  }

  // Rotators

  static get ROTATE_LEFT () {
    return "rotate_left"
  }

  static get ROTATE_RIGHT () {
    return "rotate_right"
  }

  // Slide in

  static get SLIDE_IN_LEFT () {
    return "slide_in_left"
  }

  static get SLIDE_IN_RIGHT () {
    return "slide_in_right"
  }

  static get SLIDE_IN_UP () {
    return "slide_in_up"
  }

  static get SLIDE_IN_DOWN () {
    return "slide_in_down"
  }

  // Slide out

  static get SLIDE_OUT_LEFT () {
    return "slide_out_left"
  }

  static get SLIDE_OUT_RIGHT () {
    return "slide_out_right"
  }

  static get SLIDE_OUT_UP () {
    return "slide_out_up"
  }

  static get SLIDE_OUT_DOWN () {
    return "slide_out_down"
  }

  // Zoom in

  static get ZOOM_IN () {
    return "zoom_in"
  }

  static get ZOOM_IN_LEFT () {
    return "zoom_in_left"
  }

  static get ZOOM_IN_RIGHT () {
    return "zoom_in_right"
  }

  static get ZOOM_IN_UP () {
    return "zoom_in_up"
  }

  static get ZOOM_IN_DOWN () {
    return "zoom_in_down"
  }

  static get ZOOM_IN_SMOOTH () {
    return "zoom_in_smooth"
  }

  // Zoom out

  static get ZOOM_OUT () {
    return "zoom_out"
  }

  static get ZOOM_OUT_LEFT () {
    return "zoom_out_left"
  }

  static get ZOOM_OUT_RIGHT () {
    return "zoom_out_right"
  }

  static get ZOOM_OUT_UP () {
    return "zoom_out_up"
  }

  static get ZOOM_OUT_DOWN () {
    return "zoom_out_down"
  }

  static get ZOOM_OUT_SMOOTH () {
    return "zoom_out_smooth"
  }

  // Specials

  static get HINGE () {
    return "hinge"
  }

  static get JACK_IN_THE_BOX () {
    return "jack_in_the_box"
  }

  static get ROLL_IN () {
    return "roll_in"
  }

  static get REVEAL_IN_RIGHT () {
    return "reveal_in_right"
  }

  static get REVEAL_IN_LEFT () {
    return "reveal_in_left"
  }

  static get REVEAL_IN_UP () {
    return "reveal_in_up"
  }

  static get REVEAL_IN_DOWN () {
    return "reveal_in_down"
  }

  static get REVEAL_OUT_RIGHT () {
    return "reveal_out_right"
  }

  static get REVEAL_OUT_LEFT () {
    return "reveal_out_left"
  }

  static get REVEAL_OUT_UP () {
    return "reveal_out_up"
  }

  static get REVEAL_OUT_DOWN () {
    return "reveal_out_down"
  }

  static get ROLL_OUT () {
    return "roll_out"
  }

  static get BLUR_IN () {
    return "blur_in"
  }

  static get BLUR_OUT () {
    return "blur_out"
  }

  static get EASINGS () {
    const variants = [AnimationManager.EASING_IN, AnimationManager.EASING_OUT, AnimationManager.EASING_IN_OUT]
    const easingsArray = [
      AnimationManager.EASING_LINEAR,
      AnimationManager.EASING_POWER1,
      AnimationManager.EASING_POWER2,
      AnimationManager.EASING_POWER3,
      AnimationManager.EASING_POWER4,
      AnimationManager.EASING_BACK,
      AnimationManager.EASING_ELASTIC,
      AnimationManager.EASING_BOUNCE,
      AnimationManager.EASING_CIRCLE,
      AnimationManager.EASING_EXPO,
      AnimationManager.EASING_SINE
    ]

    let results = {}

    easingsArray.forEach((easingTypeFunctionCombiner) => {
      results = Object.assign({}, results, ...(variants.map(easingTypeFunctionCombiner).map(easing => ({ [easing.type]: (x) => easing.function(x, easing.type.split(".")[1]) }))))
    })

    return results
  }

  static get EASINGS_BASE () {
    return [
      {
        name: "Linear",
        function: AnimationManager.EASING_LINEAR
      },
      {
        name: "Power1",
        function: AnimationManager.EASING_POWER1
      },
      {
        name: "Power2",
        function: AnimationManager.EASING_POWER2
      },
      {
        name: "Power3",
        function: AnimationManager.EASING_POWER3
      },
      {
        name: "Power4",
        function: AnimationManager.EASING_POWER4
      },
      {
        name: "Back",
        function: AnimationManager.EASING_BACK
      },
      {
        name: "Elastic",
        function: AnimationManager.EASING_ELASTIC
      },
      {
        name: "Bounce",
        function: AnimationManager.EASING_BOUNCE
      },
      {
        name: "Circle",
        function: AnimationManager.EASING_CIRCLE
      },
      {
        name: "Expo",
        function: AnimationManager.EASING_EXPO
      },
      {
        name: "Sine",
        function: AnimationManager.EASING_SINE
      }
    ]
  }

  static get EASINGS_WITH_NAMES () {
    const variants = [AnimationManager.EASING_IN, AnimationManager.EASING_OUT, AnimationManager.EASING_IN_OUT]
    const easingsArray = [
      AnimationManager.EASING_LINEAR,
      AnimationManager.EASING_POWER1,
      AnimationManager.EASING_POWER2,
      AnimationManager.EASING_POWER3,
      AnimationManager.EASING_POWER4,
      AnimationManager.EASING_BACK,
      AnimationManager.EASING_ELASTIC,
      AnimationManager.EASING_BOUNCE,
      AnimationManager.EASING_CIRCLE,
      AnimationManager.EASING_EXPO,
      AnimationManager.EASING_SINE
    ]

    return [].concat(...easingsArray.map((easingTypeFunctionCombiner) => {
      return variants.map(easingTypeFunctionCombiner).map((easing, index) => {
        let func = easing.function.bind({})
        let name = easing.name
        switch (index) {
          case 0:
            func = (x) => easing.function(x, AnimationManager.EASING_IN)
            name += " (in)"
            break
          case 1:
            func = (x) => easing.function(x, AnimationManager.EASING_OUT)
            name += " (out)"
            break
          case 2:
            func = (x) => easing.function(x, AnimationManager.EASING_IN_OUT)
            name += " (in-out)"
            break
        }
        return Object.assign({}, easing, { name, function: func })
      })
    }))
  }

  // Easing type variants (ease(In/Out/InOut)

  static get EASING_IN () {
    return "in"
  }

  static get EASING_OUT () {
    return "out"
  }

  static get EASING_IN_OUT () {
    return "inOut"
  }

  // Easing types

  static get EASING_LINEAR () {
    return variant => ({
      type: ["linear", variant].join("."),
      name: "Linear",
      function: AnimationManager.EASING_LINEAR_FUNCTION
    })
  }

  static get EASING_POWER1 () {
    return variant => ({
      type: ["power1", variant].join("."),
      name: "Power1",
      function: AnimationManager.EASING_POWER1_FUNCTION
    })
  }

  static get EASING_POWER2 () {
    return variant => ({
      type: ["power2", variant].join("."),
      name: "Power2",
      function: AnimationManager.EASING_POWER2_FUNCTION
    })
  }

  static get EASING_POWER3 () {
    return variant => ({
      type: ["power3", variant].join("."),
      name: "Power3",
      function: AnimationManager.EASING_POWER3_FUNCTION
    })
  }

  static get EASING_POWER4 () {
    return variant => ({
      type: ["power4", variant].join("."),
      name: "Power4",
      function: AnimationManager.EASING_POWER4_FUNCTION
    })
  }

  static get EASING_BACK () {
    return variant => ({
      type: ["back", variant].join("."),
      name: "Back",
      function: AnimationManager.EASING_BACK_FUNCTION
    })
  }

  static get EASING_ELASTIC () {
    return variant => ({
      type: ["elastic", variant].join("."),
      name: "Elastic",
      function: AnimationManager.EASING_ELASTIC_FUNCTION
    })
  }

  static get EASING_BOUNCE () {
    return variant => ({
      type: ["bounce", variant].join("."),
      name: "Bounce",
      function: AnimationManager.EASING_BOUNCE_FUNCTION
    })
  }

  static get EASING_CIRCLE () {
    return variant => ({
      type: ["circ", variant].join("."),
      name: "Circ",
      function: AnimationManager.EASING_CIRCLE_FUNCTION
    })
  }

  static get EASING_EXPO () {
    return variant => ({
      type: ["expo", variant].join("."),
      name: "Expo",
      function: AnimationManager.EASING_EXPO_FUNCTION
    })
  }

  static get EASING_SINE () {
    return variant => ({
      type: ["sine", variant].join("."),
      name: "Sine",
      function: AnimationManager.EASING_SINE_FUNCTION
    })
  }

  // Easing functions

  static get EASING_LINEAR_FUNCTION () {
    return (x) => {
      return x
    }
  }

  static get EASING_POWER1_FUNCTION () {
    return (x, variant) => {
      switch (variant) {
        case this.EASING_IN:
          return x * x
        case this.EASING_OUT:
          return 1 - (1 - x) * (1 - x)
        case this.EASING_IN_OUT:
          return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2
      }
    }
  }

  static get EASING_POWER2_FUNCTION () {
    return (x, variant) => {
      switch (variant) {
        case this.EASING_IN:
          return Math.pow(x, 3)
        case this.EASING_OUT:
          return 1 - Math.pow(1 - x, 3)
        case this.EASING_IN_OUT:
          return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
      }
    }
  }

  static get EASING_POWER3_FUNCTION () {
    return (x, variant) => {
      switch (variant) {
        case this.EASING_IN:
          return Math.pow(x, 4)
        case this.EASING_OUT:
          return 1 - Math.pow(1 - x, 4)
        case this.EASING_IN_OUT:
          return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2
      }
    }
  }

  static get EASING_POWER4_FUNCTION () {
    return (x, variant) => {
      switch (variant) {
        case this.EASING_IN:
          return Math.pow(x, 5)
        case this.EASING_OUT:
          return 1 - Math.pow(1 - x, 5)
        case this.EASING_IN_OUT:
          return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2
      }
    }
  }

  static get EASING_SINE_FUNCTION () {
    return (x, variant) => {
      switch (variant) {
        case this.EASING_IN:
          return 1 - Math.cos((x * Math.PI) / 2)
        case this.EASING_OUT:
          return Math.sin((x * Math.PI) / 2)
        case this.EASING_IN_OUT:
          return -(Math.cos(Math.PI * x) - 1) / 2
      }
    }
  }

  static get EASING_BACK_FUNCTION () {
    return (x, variant) => {
      const c1 = 1.70158
      const c2 = c1 * 1.525
      const c3 = c1 + 1
      switch (variant) {
        case this.EASING_IN:
          return c3 * x * x * x - c1 * x * x
        case this.EASING_OUT:
          return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2)
        case this.EASING_IN_OUT:
          return x < 0.5
            ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
            : (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2
      }
    }
  }

  static get EASING_ELASTIC_FUNCTION () {
    return (x, variant) => {
      const c4 = (2 * Math.PI) / 3
      const c5 = (2 * Math.PI) / 4.5

      switch (variant) {
        case this.EASING_IN:
          return x === 0 ? 0 : x === 1 ? 1 : -Math.pow(2, 10 * x - 10) * Math.sin((x * 10 - 10.75) * c4)
        case this.EASING_OUT:
          return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1
        case this.EASING_IN_OUT:
          return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ? -(Math.pow(2, 20 * x - 10) * Math.sin((20 * x - 11.125) * c5)) / 2 : (Math.pow(2, -20 * x + 10) * Math.sin((20 * x - 11.125) * c5)) / 2 + 1
      }
    }
  }

  static get EASING_BOUNCE_FUNCTION () {
    return (x, variant) => {
      const n1 = 7.5625
      const d1 = 2.75

      switch (variant) {
        case this.EASING_IN:
          return 1 - this.EASING_BOUNCE_FUNCTION(1 - x, this.EASING_OUT)
        case this.EASING_OUT:
          if (x < 1 / d1) {
            return n1 * x * x
          } else if (x < 2 / d1) {
            return n1 * (x -= 1.5 / d1) * x + 0.75
          } else if (x < 2.5 / d1) {
            return n1 * (x -= 2.25 / d1) * x + 0.9375
          } else {
            return n1 * (x -= 2.625 / d1) * x + 0.984375
          }
        case this.EASING_IN_OUT:
          return x < 0.5 ? ((1 - this.EASING_BOUNCE_FUNCTION(1 - 2 * x, this.EASING_OUT)) / 2) : ((1 + this.EASING_BOUNCE_FUNCTION(2 * x - 1, this.EASING_OUT)) / 2)
      }
    }
  }

  static get EASING_CIRCLE_FUNCTION () {
    return (x, variant) => {
      switch (variant) {
        case this.EASING_IN:
          return 1 - Math.sqrt(1 - Math.pow(x, 2))
        case this.EASING_OUT:
          return Math.sqrt(1 - Math.pow(x - 1, 2))
        case this.EASING_IN_OUT:
          return x < 0.5 ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2 : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2
      }
    }
  }

  static get EASING_EXPO_FUNCTION () {
    return (x, variant) => {
      switch (variant) {
        case this.EASING_IN:
          return x === 0 ? 0 : Math.pow(2, 10 * x - 10)
        case this.EASING_OUT:
          return x === 1 ? 1 : 1 - Math.pow(2, -10 * x)
        case this.EASING_IN_OUT:
          return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ? Math.pow(2, 20 * x - 10) / 2 : (2 - Math.pow(2, -20 * x + 10)) / 2
      }
    }
  }

  static getAnimationPresetsName () {
    return AnimationManager.ANIMATION_PRESETS_NAME
  }

  static getAnimationOnHoverPresetsName () {
    return AnimationManager.ANIMATION_ON_HOVER_PRESETS_NAME
  }

  static getAnimationPresetsDuration () {
    return AnimationManager.ANIMATION_PRESETS_DURATION
  }

  // Timeline-related animations

  // Position props key (used in timeline block payload)
  static get PROPS_POSITION () {
    return "position"
  }

  // Mixed props key (pos, opacity, rotation, size)
  static get PROPS_MIXED () {
    return "mixed"
  }

  static get PROPS_EVENT_ANIMATION () {
    return "event_animation"
  }

  static get PROPS_DRAFT_ANIMATION () {
    return "draft_animation"
  }

  static get PROPS_GESTURE_ANIMATION () {
    return "gesture_animation"
  }

  static get PROPS_MODULE_INITIAL_ANIMATION () {
    return "module_initial_animation"
  }

  static get PROPS_OPACITY () {
    return "opacity"
  }

  static get PROPS_BORDER_RADIUS () {
    return "borderRadius"
  }

  static get PROPS () {
    return {
      [AnimationManager.PROPS_POSITION]: "Position change",
      [AnimationManager.PROPS_MIXED]: "Custom animation",
      [AnimationManager.PROPS_OPACITY]: "Opacity change",
      [AnimationManager.PROPS_EVENT_ANIMATION]: "[Event]"
    }
  }

  static getPropsName () {
    return AnimationManager.PROPS
  }

  /**
   * Returns getTransformPropsByAnimationBlock
   * @param {props} props
   */
  static getTransformPropsByAnimationBlock (props) {
    const transform = {}
    const newProperties = {}
    const customAnimationTransform = {}

    if (props.hasOwnProperty("left")) {
      transform.translateX = Math.floor(props.left) + "px"
    }
    if (props.hasOwnProperty("top")) {
      transform.translateY = Math.floor(props.top) + "px"
    }
    if (props.hasOwnProperty("leftCustom")) {
      customAnimationTransform.translateX = Math.floor(props.leftCustom) + "px"
    }
    if (props.hasOwnProperty("topCustom")) {
      customAnimationTransform.translateY = Math.floor(props.topCustom) + "px"
    }

    if (props.hasOwnProperty("rotateCustom")) {
      customAnimationTransform.rotate = props.rotateCustom
    }

    if (props.hasOwnProperty("rotate")) {
      transform.rotate = Math.floor(props.rotate) + "deg"
    }

    if (props.hasOwnProperty("translate3d")) {
      const translateProps = props.translate3d.split(",")

      for (let i = 0; i < translateProps.length; i++) {
        if (parseInt(translateProps[i]) !== 0) {
          transform[["translateX", "translateY", "translateZ"][i]] = (translateProps[i] > 0) ? "+" + translateProps[i] : translateProps[i]
        }
      }
    }

    if (props.hasOwnProperty("scale3d")) {
      transform.scaleX = props.scale3d.split(",")[0]
      transform.scaleY = props.scale3d.split(",")[1]
      transform.scaleZ = props.scale3d.split(",")[2]
    }

    if (props.hasOwnProperty("blur")) {
      newProperties.blur = props.blur
    }

    if (props.hasOwnProperty("skewX")) {
      transform.skewX = props.skewX
    }

    if (props.hasOwnProperty("skewY")) {
      transform.skewY = props.skewY
    }

    if (props.hasOwnProperty("rotate3d")) {
      transform.rotate3d = props.rotate3d
    }

    if (props.hasOwnProperty("perspective")) {
      transform.perspective = props.perspective
    }

    newProperties.transform = Utils.encodeTransform(transform)

    if (props.hasOwnProperty("animation-timing-function")) {
      newProperties["animation-timing-function"] = props["animation-timing-function"]
    }

    if (props.hasOwnProperty("transform-origin")) {
      newProperties["transform-origin"] = props["transform-origin"]
    }

    if (props.hasOwnProperty("opacity")) {
      newProperties.opacity = props.opacity
    }

    if (props.hasOwnProperty("borderRadius")) {
      newProperties.borderRadius = props.borderRadius
    }

    if (props.hasOwnProperty("width")) {
      newProperties.width = props.width
    }

    if (props.hasOwnProperty("height")) {
      newProperties.height = props.height
    }

    if (props.hasOwnProperty("animationClass")) {
      newProperties.animationClass = props.animationClass
    }

    const result = {
      blurAnimation: newProperties.blur,
      transformAnimation: newProperties.transform,
      widthAnimation: newProperties.width,
      heightAnimation: newProperties.height,
      opacityAnimation: newProperties.opacity,
      borderRadiusAnimation: newProperties.borderRadius,
      animationTimingFunction: newProperties["animation-timing-function"],
      transformOrigin: newProperties["transform-origin"] || "center center",
      animationClass: newProperties.animationClass || ""
    }

    if (props.hasOwnProperty("flipX")) {
      result.flipX = props.flipX
    }

    if (props.hasOwnProperty("flipY")) {
      result.flipY = props.flipY
    }

    if (props.hasOwnProperty("widthCustom")) {
      result.percentWidth = props.widthCustom
    }

    if (props.hasOwnProperty("heightCustom")) {
      result.percentHeight = props.heightCustom
    }

    if (props.hasOwnProperty("opacityCustom")) {
      result.opacity = props.opacityCustom
    }

    if (props.hasOwnProperty("borderRadiusCustom")) {
      result.borderRadius = props.borderRadiusCustom
    }

    if (Object.values(customAnimationTransform).length > 0) {
      result.transform = Utils.encodeTransform(customAnimationTransform)
    }

    if (props.hasOwnProperty("clip-path")) {
      result["clip-path"] = props["clip-path"]
    }

    return result
  }

  /**
   * Returns animation color class
   * @param {string} animationType
   */
  static getAnimationColorClass (animationType) {
    let type = animationType
    if (animationType === AnimationManager.PROPS_MODULE_INITIAL_ANIMATION) {
      type = AnimationManager.FADE_IN
    }

    const animationCategory = AnimationManager.ANIMATIONS_GROUPED_BY_FOLDER.find(f => f.animations.includes(type))
    if (animationCategory) {
      return animationCategory.name.toLowerCase().split(" ").join("-")
    }

    return "timeline-color"
  }

  /**
   * Returns animation duration percentage
   * @param {int} time
   * @param {object} block
   * @returns {float} animation duration percentage
   */
  static calculateAnimationDurationPercentage (time, block) {
    let animationDurationPercentage = (time - block.start) / block.duration
    if (block.looped && time > block.start + (block.duration + block.loopDelay)) {
      const durationWithLoopDelay = block.duration + block.loopDelay
      const numberOfBlockToRepeatWithLoopDelay = parseInt((time - block.start) / durationWithLoopDelay) + 1

      const start = block.start + ((block.duration) * (numberOfBlockToRepeatWithLoopDelay - 1))
      const end = block.start + ((block.duration) * numberOfBlockToRepeatWithLoopDelay)
      animationDurationPercentage = (time - start) / (end - start)

      if (block.loopDelay > 0) {
        const delayDurationWithLoopDelayRatio = (block.loopDelay / (durationWithLoopDelay))
        const durationToDelayRatio = (block.duration / block.loopDelay)

        const startWithDelay = block.start + (durationWithLoopDelay * (numberOfBlockToRepeatWithLoopDelay - 1))
        const endWithDelay = block.start + (durationWithLoopDelay * numberOfBlockToRepeatWithLoopDelay)
        const animationDurationWithDelayPercentage = (time - startWithDelay) / (endWithDelay - startWithDelay)
        animationDurationPercentage = animationDurationWithDelayPercentage / delayDurationWithLoopDelayRatio / durationToDelayRatio
      }

      if (block.type === "mixed" && numberOfBlockToRepeatWithLoopDelay % 2 === 0) {
        animationDurationPercentage = 1 - animationDurationPercentage
      }
    }
    animationDurationPercentage = Utils.clamp(0, Math.round(animationDurationPercentage * 100) / 100, 1)

    if (block.easing) {
      animationDurationPercentage = AnimationManager.EASINGS[block.easing](animationDurationPercentage)
    }

    return animationDurationPercentage
  }

  /**
   * Returns calculated changes
   * @param {float} currentTime
   * @param {object} block
   * @param {module} module
   * @param {design} design
   * @param {module[]} modules
   */
  static getCalculatedChanges (currentTime, block, module, design, modules) {
    const props = {}
    const time = parseInt(currentTime)
    const animationDurationPercentage = this.calculateAnimationDurationPercentage(time, block)

    const vectorValue = 50 // The vector variable used for X and Y axis transformations

    const calculatePercentValue = (from, to, timeStart, timeEnd) => {
      const localPercentage = (animationDurationPercentage - timeStart) / (timeEnd - timeStart)

      let change = (Math.abs(from - to)) * localPercentage

      if (from > to) {
        change = -change
      }

      return (from + change).toFixed(2)
    }

    const scale = (from, to, timeStart, timeEnd) => {
      const vector = calculatePercentValue(from, to, timeStart, timeEnd)
      return [vector, vector, vector]
    }
    const translateVectors = (from, to, timeStart, timeEnd, vectors) => {
      if (!vectors) {
        vectors = [true, true, true]
      }
      const vector = calculatePercentValue(from, to, timeStart, timeEnd)
      return [vectors[0] ? vector : 0, vectors[1] ? vector : 0, vectors[2] ? vector : 0]
    }

    const opacity = (from, to, timeStart, timeEnd) => {
      return calculatePercentValue(from, to, timeStart, timeEnd)
    }

    switch (block.type) {
      // [x1,y1] -> [x2,y2]
      case AnimationManager.PROPS_POSITION:
        props.left =
          block.from[0] +
          (block.to[0] - block.from[0]) * animationDurationPercentage
        props.top =
          block.from[1] +
          (block.to[1] - block.from[1]) * animationDurationPercentage
        break
      case AnimationManager.PROPS_MIXED:
        if (block.from.hasOwnProperty("x") && block.to.hasOwnProperty("x")) {
          let percentage = animationDurationPercentage
          if (block.from.x > block.to.x) {
            percentage = (1 - animationDurationPercentage)
          }
          const from = Math.min(block.from.x, block.to.x)
          const to = Math.max(block.from.x, block.to.x)
          props.leftCustom = from + ((to - from) * percentage)
        }

        if (block.from.hasOwnProperty("y") && block.to.hasOwnProperty("y")) {
          let percentage = animationDurationPercentage
          if (block.from.y > block.to.y) {
            percentage = (1 - animationDurationPercentage)
          }
          const from = Math.min(block.from.y, block.to.y)
          const to = Math.max(block.from.y, block.to.y)
          props.topCustom = from + ((to - from) * percentage)
        }

        if (block.from.hasOwnProperty("opacity") && block.to.hasOwnProperty("opacity")) {
          props.opacityCustom =
            block.from.opacity +
            (block.to.opacity - block.from.opacity) * animationDurationPercentage
        }

        if (block.from.hasOwnProperty("borderRadius") && block.to.hasOwnProperty("borderRadius")) {
          props.borderRadiusCustom =
            block.from.borderRadius +
            (block.to.borderRadius - block.from.borderRadius) * animationDurationPercentage
        }

        if (block.from.hasOwnProperty("rotation") && block.to.hasOwnProperty("rotation")) {
          props.rotateCustom = calculatePercentValue(block.from.rotation, block.to.rotation, 0, 1) + "deg"
        }

        if (block.from.hasOwnProperty("flipX") && block.to.hasOwnProperty("flipX")) {
          props.flipX = calculatePercentValue(
            (block.from.flipX === false || block.from.flipX === undefined || block.from.flipX === "1.00") ? 1 : -1,
            (block.to.flipX === false || block.to.flipX === undefined || block.to.flipX === "1.00") ? 1 : -1,
            0,
            1
          )
        }

        if (block.from.hasOwnProperty("flipY") && block.to.hasOwnProperty("flipY")) {
          props.flipY = calculatePercentValue(
            (block.from.flipY === false || block.from.flipY === undefined || block.from.flipY === "1.00") ? 1 : -1,
            (block.to.flipY === false || block.to.flipY === undefined || block.to.flipY === "1.00") ? 1 : -1,
            0,
            1
          )
        }

        if (block.from.hasOwnProperty("width") && block.to.hasOwnProperty("width")) {
          let percentage = animationDurationPercentage
          if (block.from.width > block.to.width) {
            percentage = (1 - animationDurationPercentage)
          }
          const from = Math.min(block.from.width, block.to.width)
          const to = Math.max(block.from.width, block.to.width)
          props.widthCustom = from + ((to - from) * percentage)
        }

        if (block.from.hasOwnProperty("height") && block.to.hasOwnProperty("height")) {
          let percentage = animationDurationPercentage
          if (block.from.height > block.to.height) {
            percentage = (1 - animationDurationPercentage)
          }
          const from = Math.min(block.from.height, block.to.height)
          const to = Math.max(block.from.height, block.to.height)
          props.heightCustom = from + ((to - from) * percentage)
        }

        break
      case AnimationManager.PROPS_GESTURE_ANIMATION:
        const transitionTime = 500 // The same value as in Gesture's `transition` property
        const transitionTimePrecentage = transitionTime / block.duration
        if (animationDurationPercentage < transitionTimePrecentage) {
          props.opacity = Math.min(1, Math.max(0, time - block.start) / transitionTime) // Percentage of opacity in range 0s -> 0.5s
        } else if (animationDurationPercentage >= (transitionTimePrecentage) && animationDurationPercentage <= (1 - transitionTimePrecentage)) {
          props.opacity = 1
        } else if (animationDurationPercentage > (1 - transitionTimePrecentage)) {
          props.opacity = Math.max(0, (block.duration - (time - block.start)) / transitionTime) // // Percentage of opacity in range <BLOCK-DURATION> - 0.5s -> <BLOCK-DURATION>s
        }
        break
      case AnimationManager.PROPS_OPACITY:
        // animationDurationPercentage is the same format as opacity; Check if opacity decreases, if yes, get reversed percentage
        props.opacity = (block.from[0] - block.to[0]) < 0 ? 1 - animationDurationPercentage : animationDurationPercentage
        break

      // Animation presets
      case AnimationManager.BOUNCE:
        let finalVectors = [0, 0, 0]
        if (animationDurationPercentage < 0.2) {
          finalVectors = translateVectors(0, 0, 0, 0.2, [false, true, false])
        }
        if (
          animationDurationPercentage >= 0.2 &&
          animationDurationPercentage <= 0.4
        ) {
          finalVectors = translateVectors(0, -30, 0.2, 0.4, [false, true, false])
        }
        if (animationDurationPercentage > 0.4 &&
          animationDurationPercentage <= 0.43
        ) {
          // standby
          finalVectors = [0, -30, 0]
        }
        if (animationDurationPercentage > 0.43 && animationDurationPercentage <= 0.53) {
          finalVectors = translateVectors(-30, 0, 0.43, 0.53, [false, true, false])
        }
        if (animationDurationPercentage > 0.53 && animationDurationPercentage <= 0.7) {
          finalVectors = translateVectors(0, -14, 0.53, 0.7, [false, true, false])
        }
        if (animationDurationPercentage > 0.7 && animationDurationPercentage <= 0.8) {
          finalVectors = translateVectors(-14, 0, 0.7, 0.8, [false, true, false])
        }
        if (animationDurationPercentage > 0.8 && animationDurationPercentage <= 0.9) {
          finalVectors = translateVectors(0, -4, 0.8, 0.9, [false, true, false])
        }
        if (animationDurationPercentage > 0.9 && animationDurationPercentage <= 1) {
          finalVectors = translateVectors(-4, 0, 0.9, 1, [false, true, false])
        }
        props.translate3d = finalVectors.map(e => e + "px").join(",")
        break
      case AnimationManager.FLASH:
        if (animationDurationPercentage <= 0.25) {
          props.opacity = opacity(1, 0, 0, 0.25)
        }
        if (
          animationDurationPercentage > 0.25 &&
          animationDurationPercentage <= 0.5
        ) {
          props.opacity = opacity(0, 1, 0.25, 0.5)
        }
        if (
          animationDurationPercentage > 0.5 &&
          animationDurationPercentage <= 0.75
        ) {
          props.opacity = opacity(1, 0, 0.5, 0.75)
        }
        if (
          animationDurationPercentage > 0.75 &&
          animationDurationPercentage <= 1
        ) {
          props.opacity = opacity(0, 1, 0.75, 1)
        }
        break
      case AnimationManager.PULSE:
        let finalScaleVectors = [1, 1, 1]

        if (animationDurationPercentage < 0.5) {
          finalScaleVectors = scale(1, 1.05, 0, 0.5)
        }
        if (
          animationDurationPercentage >= 0.5 &&
          animationDurationPercentage <= 1
        ) {
          finalScaleVectors = scale(1.05, 1, 0.5, 1)
        }

        props.scale3d = finalScaleVectors.join(",")
        break
      case AnimationManager.RUBBER_BAND:
        if (animationDurationPercentage < 0.3) {
          props.scale3d = [
            calculatePercentValue(1, 1.25, 0, 0.3),
            calculatePercentValue(1, 0.75, 0, 0.3),
            1
          ].join(",")
        }
        if (animationDurationPercentage >= 0.3 && animationDurationPercentage < 0.4) {
          props.scale3d = [
            calculatePercentValue(1.25, 0.75, 0.3, 0.4),
            calculatePercentValue(0.75, 1.25, 0.3, 0.4),
            1
          ].join(",")
        }
        if (animationDurationPercentage >= 0.4 && animationDurationPercentage < 0.5) {
          props.scale3d = [
            calculatePercentValue(0.75, 1.15, 0.4, 0.5),
            calculatePercentValue(1.25, 0.85, 0.4, 0.5),
            1
          ].join(",")
        }
        if (animationDurationPercentage >= 0.5 && animationDurationPercentage < 0.65) {
          props.scale3d = [
            calculatePercentValue(1.15, 0.95, 0.5, 0.65),
            calculatePercentValue(0.85, 1.05, 0.5, 0.65),
            1
          ].join(",")
        }
        if (animationDurationPercentage >= 0.65 && animationDurationPercentage < 0.75) {
          props.scale3d = [
            calculatePercentValue(0.95, 1.05, 0.65, 0.75),
            calculatePercentValue(1.05, 0.95, 0.65, 0.75),
            1
          ].join(",")
        }
        if (animationDurationPercentage >= 0.75 && animationDurationPercentage <= 1) {
          props.scale3d = [
            calculatePercentValue(1.05, 1, 0.75, 1),
            calculatePercentValue(0.95, 1, 0.75, 1),
            1
          ].join(",")
        }
        break
      case AnimationManager.SHAKE:
        if (animationDurationPercentage < 0.1) {
          props.translate3d = translateVectors(0, -10, 0, 0.1, [true, false, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.1 && animationDurationPercentage < 0.2) {
          props.translate3d = translateVectors(-10, 10, 0.1, 0.2, [true, false, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.2 && animationDurationPercentage < 0.3) {
          props.translate3d = translateVectors(10, -10, 0.2, 0.3, [true, false, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.3 && animationDurationPercentage < 0.4) {
          props.translate3d = translateVectors(-10, 10, 0.3, 0.4, [true, false, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.4 && animationDurationPercentage < 0.5) {
          props.translate3d = translateVectors(10, -10, 0.4, 0.5, [true, false, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.5 && animationDurationPercentage < 0.6) {
          props.translate3d = translateVectors(-10, 10, 0.5, 0.6, [true, false, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.6 && animationDurationPercentage < 0.7) {
          props.translate3d = translateVectors(10, -10, 0.6, 0.7, [true, false, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.7 && animationDurationPercentage < 0.8) {
          props.translate3d = translateVectors(-10, 10, 0.7, 0.8, [true, false, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.8 && animationDurationPercentage < 0.9) {
          props.translate3d = translateVectors(10, -10, 0.8, 0.9, [true, false, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.9 && animationDurationPercentage <= 1) {
          props.translate3d = translateVectors(-10, 0, 0.9, 1, [true, false, false])
            .map(e => e + "px")
            .join(",")
        }
        break
      case AnimationManager.SHAKE_Y:
        if (animationDurationPercentage < 0.1) {
          props.translate3d = translateVectors(0, -10, 0, 0.1, [false, true, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.1 && animationDurationPercentage < 0.2) {
          props.translate3d = translateVectors(-10, 10, 0.1, 0.2, [false, true, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.2 && animationDurationPercentage < 0.3) {
          props.translate3d = translateVectors(10, -10, 0.2, 0.3, [false, true, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.3 && animationDurationPercentage < 0.4) {
          props.translate3d = translateVectors(-10, 10, 0.3, 0.4, [false, true, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.4 && animationDurationPercentage < 0.5) {
          props.translate3d = translateVectors(10, -10, 0.4, 0.5, [false, true, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.5 && animationDurationPercentage < 0.6) {
          props.translate3d = translateVectors(-10, 10, 0.5, 0.6, [false, true, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.6 && animationDurationPercentage < 0.7) {
          props.translate3d = translateVectors(10, -10, 0.6, 0.7, [false, true, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.7 && animationDurationPercentage < 0.8) {
          props.translate3d = translateVectors(-10, 10, 0.7, 0.8, [false, true, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.8 && animationDurationPercentage < 0.9) {
          props.translate3d = translateVectors(10, -10, 0.8, 0.9, [false, true, false])
            .map(e => e + "px")
            .join(",")
        }
        if (animationDurationPercentage >= 0.9 && animationDurationPercentage <= 1) {
          props.translate3d = translateVectors(-10, 0, 0.9, 1, [false, true, false])
            .map(e => e + "px")
            .join(",")
        }
        break
      case AnimationManager.SWING:
        if (animationDurationPercentage < 0.2) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(0, 15, 0, 0.2) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.2 && animationDurationPercentage < 0.4) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(15, -10, 0.2, 0.4) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.4 && animationDurationPercentage < 0.6) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(-10, 5, 0.4, 0.6) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.6 && animationDurationPercentage < 0.8) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(5, -5, 0.6, 0.8) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.8 && animationDurationPercentage <= 1) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(-5, 0, 0.8, 1) + "deg"].join(",")
        }
        break
      case AnimationManager.TADA:
        if (animationDurationPercentage < 0.1) {
          props.scale3d = scale(1, 0.9, 0, 0.1).join(",")
          props.rotate3d = [0, 0, 1, calculatePercentValue(0, -3, 0, 0.1) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.1 && animationDurationPercentage < 0.2) {
          props.scale3d = [0.9, 0.9, 0.9].join(",")
          props.rotate3d = [0, 0, 1, -3 + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.2 && animationDurationPercentage < 0.3) {
          props.scale3d = scale(0.9, 1, 0.2, 0.3).join(",")
          props.rotate3d = [0, 0, 1, calculatePercentValue(-3, 3, 0.2, 0.3) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.3 && animationDurationPercentage < 0.4) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(3, -3, 0.3, 0.4) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.4 && animationDurationPercentage < 0.5) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(-3, 3, 0.4, 0.5) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.5 && animationDurationPercentage < 0.6) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(3, -3, 0.5, 0.6) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.6 && animationDurationPercentage < 0.7) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(-3, 3, 0.6, 0.7) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.7 && animationDurationPercentage < 0.8) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(3, -3, 0.7, 0.8) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.8 && animationDurationPercentage < 0.9) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(-3, 3, 0.8, 0.9) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.9 && animationDurationPercentage <= 1) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(3, 0, 0.9, 1) + "deg"].join(",")
        }
        break
      case AnimationManager.WOBBLE:
        if (animationDurationPercentage < 0.15) {
          props.translate3d = [calculatePercentValue(0, -25, 0, 0.15) + "%", 0, 0].join(",")
          props.rotate3d = [0, 0, 1, calculatePercentValue(0, -5, 0, 0.15) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.15 && animationDurationPercentage < 0.3) {
          props.translate3d = [calculatePercentValue(-25, 20, 0.15, 0.3) + "%", 0, 0].join(",")
          props.rotate3d = [0, 0, 1, calculatePercentValue(-5, 3, 0.15, 0.3) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.3 && animationDurationPercentage < 0.45) {
          props.translate3d = [calculatePercentValue(20, -15, 0.3, 0.45) + "%", 0, 0].join(",")
          props.rotate3d = [0, 0, 1, calculatePercentValue(3, -3, 0.3, 0.45) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.45 && animationDurationPercentage < 0.6) {
          props.translate3d = [calculatePercentValue(-15, 10, 0.45, 0.6) + "%", 0, 0].join(",")
          props.rotate3d = [0, 0, 1, calculatePercentValue(-3, 2, 0.45, 0.6) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.6 && animationDurationPercentage < 0.75) {
          props.translate3d = [calculatePercentValue(10, -5, 0.6, 0.75) + "%", 0, 0].join(",")
          props.rotate3d = [0, 0, 1, calculatePercentValue(2, -1, 0.6, 0.75) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.75 && animationDurationPercentage <= 1) {
          props.translate3d = [calculatePercentValue(-5, 0, 0.75, 1) + "%", 0, 0].join(",")
          props.rotate3d = [0, 0, 1, calculatePercentValue(-1, 0, 0.75, 1) + "deg"].join(",")
        }
        break
      case AnimationManager.JELLO:
        if (animationDurationPercentage < 0.111 || (animationDurationPercentage > 0.888 && animationDurationPercentage <= 1)) {
          props.translate3d = [0, 0, 0].join(",")
        }
        if (animationDurationPercentage >= 0.111 && animationDurationPercentage < 0.222) {
          props.skewX = calculatePercentValue(0, -12.5, 0.111, 0.222) + "deg"
          props.skewY = calculatePercentValue(0, -12.5, 0.111, 0.222) + "deg"
        }
        if (animationDurationPercentage >= 0.222 && animationDurationPercentage < 0.333) {
          props.skewX = calculatePercentValue(-12.5, 6.25, 0.222, 0.333) + "deg"
          props.skewY = calculatePercentValue(-12.5, 6.25, 0.222, 0.333) + "deg"
        }
        if (animationDurationPercentage >= 0.333 && animationDurationPercentage < 0.444) {
          props.skewX = calculatePercentValue(6.25, -3.125, 0.333, 0.444) + "deg"
          props.skewY = calculatePercentValue(6.25, -3.125, 0.333, 0.444) + "deg"
        }
        if (animationDurationPercentage >= 0.444 && animationDurationPercentage < 0.555) {
          props.skewX = calculatePercentValue(-3.125, 1.5625, 0.444, 0.555) + "deg"
          props.skewY = calculatePercentValue(-3.125, 1.5625, 0.444, 0.555) + "deg"
        }
        if (animationDurationPercentage >= 0.555 && animationDurationPercentage < 0.666) {
          props.skewX = calculatePercentValue(1.5625, -0.78125, 0.555, 0.666) + "deg"
          props.skewY = calculatePercentValue(1.5625, -0.78125, 0.555, 0.666) + "deg"
        }
        if (animationDurationPercentage >= 0.666 && animationDurationPercentage < 0.777) {
          props.skewX = calculatePercentValue(-0.78125, 0.390625, 0.666, 0.777) + "deg"
          props.skewY = calculatePercentValue(-0.78125, 0.390625, 0.666, 0.777) + "deg"
        }
        if (animationDurationPercentage >= 0.777 && animationDurationPercentage < 0.888) {
          props.skewX = calculatePercentValue(0.390625, -0.1953125, 0.777, 0.888) + "deg"
          props.skewY = calculatePercentValue(0.390625, -0.1953125, 0.777, 0.888) + "deg"
        }
        if (animationDurationPercentage >= 0.888 && animationDurationPercentage <= 1) {
          props.skewX = calculatePercentValue(-0.1953125, 0, 0.888, 1) + "deg"
          props.skewY = calculatePercentValue(-0.1953125, 0, 0.888, 1) + "deg"
        }
        break
      case AnimationManager.HEARTBEAT:
        if (animationDurationPercentage < 0.14) {
          props.scale3d = scale(1, 1.3, 0, 0.14).join(",")
        }
        if (animationDurationPercentage >= 0.14 && animationDurationPercentage < 0.28) {
          props.scale3d = scale(1.3, 1, 0.14, 0.28).join(",")
        }
        if (animationDurationPercentage >= 0.28 && animationDurationPercentage < 0.42) {
          props.scale3d = scale(1, 1.3, 0.28, 0.42).join(",")
        }
        if (animationDurationPercentage >= 0.42 && animationDurationPercentage < 0.7) {
          props.scale3d = scale(1.3, 1, 0.42, 0.7).join(",")
        }
        if (animationDurationPercentage >= 0.7 && animationDurationPercentage <= 1) {
          props.scale3d = [1, 1, 1].join(",")
        }
        break
      case AnimationManager.SHINE_LEFT:
        if (animationDurationPercentage > 0 && animationDurationPercentage < 1) {
          props.animationClass = AnimationManager.SHINE_LEFT
        } else {
          props.animationClass = ""
        }
        break
      case AnimationManager.SHINE_RIGHT:
        if (animationDurationPercentage > 0 && animationDurationPercentage < 1) {
          props.animationClass = AnimationManager.SHINE_RIGHT
        } else {
          props.animationClass = ""
        }
        break
      case AnimationManager.SHINE_UP:
        if (animationDurationPercentage > 0 && animationDurationPercentage < 1) {
          props.animationClass = AnimationManager.SHINE_UP
        } else {
          props.animationClass = ""
        }
        break
      case AnimationManager.SHINE_DOWN:
        if (animationDurationPercentage > 0 && animationDurationPercentage < 1) {
          props.animationClass = AnimationManager.SHINE_DOWN
        } else {
          props.animationClass = ""
        }
        break
      // Bounce in
      case AnimationManager.BOUNCE_IN:
        let finalBounceInVectors = [0.3, 0.3, 0.3]

        props["animation-timing-function"] = "cubic-bezier(0.215, 0.61, 0.355, 1)"
        props.opacity = opacity(0, 1, 0, 0.2)

        if (animationDurationPercentage < 0.4) {
          finalBounceInVectors = scale(0.3, 1.1, 0.2, 0.4)
        }

        if (animationDurationPercentage >= 0.4 && animationDurationPercentage < 0.6) {
          finalBounceInVectors = scale(1.1, 1.03, 0.4, 0.6)
        }

        if (animationDurationPercentage >= 0.6 && animationDurationPercentage < 0.8) {
          finalBounceInVectors = scale(1.03, 0.97, 0.6, 0.8)
        }

        if (animationDurationPercentage >= 0.8 && animationDurationPercentage <= 1) {
          finalBounceInVectors = scale(0.97, 1, 0.8, 1)
        }

        props.scale3d = finalBounceInVectors.join(",")
        break
      case AnimationManager.BOUNCE_IN_LEFT:
        let finalBounceInLeftVectors = [-3000, 0, 0]

        props["animation-timing-function"] = "cubic-bezier(0.215, 0.61, 0.355, 1)"

        if (animationDurationPercentage < 0.6) {
          finalBounceInLeftVectors = translateVectors(-3000, 25, 0, 0.6, [true, false, false])
        }

        if (animationDurationPercentage >= 0.6 && animationDurationPercentage < 0.75) {
          finalBounceInLeftVectors = translateVectors(25, -10, 0.6, 0.75, [true, false, false])
        }

        if (animationDurationPercentage >= 0.75 && animationDurationPercentage < 0.9) {
          finalBounceInLeftVectors = translateVectors(-10, 5, 0.75, 0.9, [true, false, false])
        }

        if (animationDurationPercentage >= 0.9 && animationDurationPercentage <= 1) {
          finalBounceInLeftVectors = translateVectors(5, 0, 0.9, 1, [true, false, false])
        }

        props.translate3d = finalBounceInLeftVectors.map(e => e + "px").join(",")
        break
      case AnimationManager.BOUNCE_IN_RIGHT:
        let finalBounceInRightVectors = [-3000, 0, 0]

        props["animation-timing-function"] = "cubic-bezier(0.215, 0.61, 0.355, 1)"

        if (animationDurationPercentage < 0.6) {
          finalBounceInRightVectors = translateVectors(3000, -25, 0, 0.6, [true, false, false])
        }

        if (animationDurationPercentage >= 0.6 && animationDurationPercentage < 0.75) {
          finalBounceInRightVectors = translateVectors(-25, 10, 0.6, 0.75, [true, false, false])
        }

        if (animationDurationPercentage >= 0.75 && animationDurationPercentage < 0.9) {
          finalBounceInRightVectors = translateVectors(10, -5, 0.75, 0.9, [true, false, false])
        }

        if (animationDurationPercentage >= 0.9 && animationDurationPercentage <= 1) {
          finalBounceInRightVectors = translateVectors(-5, 0, 0.9, 1, [true, false, false])
        }

        props.translate3d = finalBounceInRightVectors.map(e => e + "px").join(",")
        break
      case AnimationManager.BOUNCE_IN_DOWN:
        let finalBounceInUpVectors = [0, -3000, 0]

        props["animation-timing-function"] = "cubic-bezier(0.215, 0.61, 0.355, 1)"

        if (animationDurationPercentage < 0.6) {
          finalBounceInUpVectors = translateVectors(3000, -25, 0, 0.6, [false, true, false])
        }

        if (animationDurationPercentage >= 0.6 && animationDurationPercentage < 0.75) {
          finalBounceInUpVectors = translateVectors(-25, 10, 0.6, 0.75, [false, true, false])
        }

        if (animationDurationPercentage >= 0.75 && animationDurationPercentage < 0.9) {
          finalBounceInUpVectors = translateVectors(10, -5, 0.75, 0.9, [false, true, false])
        }

        if (animationDurationPercentage >= 0.9 && animationDurationPercentage <= 1) {
          finalBounceInUpVectors = translateVectors(-5, 0, 0.9, 1, [false, true, false])
        }

        props.translate3d = finalBounceInUpVectors.map(e => e + "px").join(",")
        break
      case AnimationManager.BOUNCE_IN_UP:
        let finalBounceInDownVectors = [0, -3000, 0]

        props["animation-timing-function"] = "cubic-bezier(0.215, 0.61, 0.355, 1)"

        if (animationDurationPercentage < 0.6) {
          finalBounceInDownVectors = translateVectors(-3000, 25, 0, 0.6, [false, true, false])
        }

        if (animationDurationPercentage >= 0.6 && animationDurationPercentage < 0.75) {
          finalBounceInDownVectors = translateVectors(25, -10, 0.6, 0.75, [false, true, false])
        }

        if (animationDurationPercentage >= 0.75 && animationDurationPercentage < 0.9) {
          finalBounceInDownVectors = translateVectors(-10, 5, 0.75, 0.9, [false, true, false])
        }

        if (animationDurationPercentage >= 0.9 && animationDurationPercentage <= 1) {
          finalBounceInDownVectors = translateVectors(5, 0, 0.9, 1, [false, true, false])
        }

        props.translate3d = finalBounceInDownVectors.map(e => e + "px").join(",")
        break

      // Bounce out
      case AnimationManager.BOUNCE_OUT:
        let finalBounceOutVectors = [1, 1, 1]

        props["animation-timing-function"] = "cubic-bezier(0.215, 0.61, 0.355, 1)"
        props.opacity = opacity(1, 0, 0.5, 1)

        if (animationDurationPercentage < 0.2) {
          finalBounceOutVectors = scale(1, 0.9, 0, 0.2)
        }

        if (animationDurationPercentage >= 0.2 && animationDurationPercentage < 0.5) {
          finalBounceOutVectors = scale(0.9, 1.1, 0.2, 0.5)
        }

        if (animationDurationPercentage >= 0.5 && animationDurationPercentage < 0.55) {
          finalBounceOutVectors = [1.1, 1.1, 1.1]
        }

        if (animationDurationPercentage >= 0.55 && animationDurationPercentage < 0.97) {
          finalBounceOutVectors = scale(1.1, 0.3, 0.55, 0.97)
        }

        if (animationDurationPercentage >= 0.97 && animationDurationPercentage <= 1) {
          finalBounceOutVectors = [0, 0, 0]
        }

        props.scale3d = finalBounceOutVectors.join(",")
        break
      case AnimationManager.BOUNCE_OUT_LEFT:
        let finalBounceOutLeftVectors = [0, 0, 0]

        if (animationDurationPercentage < 0.2) {
          finalBounceOutLeftVectors = translateVectors(0, 20, 0, 0.2, [true, false, false])
        }

        if (animationDurationPercentage >= 0.2 && animationDurationPercentage <= 1) {
          props.opacity = opacity(1, 0, 0.2, 1)
          finalBounceOutLeftVectors = translateVectors(20, -2000, 0.2, 1, [true, false, false])
        }

        props.translate3d = finalBounceOutLeftVectors.map(e => e + "px").join(",")
        break
      case AnimationManager.BOUNCE_OUT_RIGHT:
        let finalBounceOutRightVectors = [0, 0, 0]

        if (animationDurationPercentage < 0.2) {
          finalBounceOutRightVectors = translateVectors(0, -20, 0, 0.2, [true, false, false])
        }

        if (animationDurationPercentage >= 0.2 && animationDurationPercentage <= 1) {
          props.opacity = opacity(1, 0, 0.2, 1)
          finalBounceOutRightVectors = translateVectors(-20, 2000, 0.2, 1, [true, false, false])
        }

        props.translate3d = finalBounceOutRightVectors.map(e => e + "px").join(",")
        break
      case AnimationManager.BOUNCE_OUT_UP:
        let finalBounceOutUpVectors = [0, 0, 0]

        if (animationDurationPercentage < 0.2) {
          finalBounceOutUpVectors = translateVectors(0, -10, 0, 0.2, [false, true, false])
        }

        if (animationDurationPercentage >= 0.2 && animationDurationPercentage < 0.4) {
          finalBounceOutUpVectors = translateVectors(-10, 20, 0.2, 0.4, [false, true, false])
        }

        if (animationDurationPercentage >= 0.4 && animationDurationPercentage < 0.45) {
          // standby
          finalBounceOutUpVectors = [0, 20, 0]
        }

        if (animationDurationPercentage >= 0.45 && animationDurationPercentage <= 1) {
          props.opacity = opacity(1, 0, 0.45, 1)
          finalBounceOutUpVectors = translateVectors(20, -2000, 0.45, 1, [false, true, false])
        }

        props.translate3d = finalBounceOutUpVectors.map(e => e + "px").join(",")
        break
      case AnimationManager.BOUNCE_OUT_DOWN:
        let finalBounceOutDownVectors = [0, 0, 0]

        if (animationDurationPercentage < 0.2) {
          finalBounceOutDownVectors = translateVectors(0, 10, 0, 0.2, [false, true, false])
        }

        if (animationDurationPercentage >= 0.2 && animationDurationPercentage < 0.4) {
          finalBounceOutDownVectors = translateVectors(10, -20, 0.2, 0.4, [false, true, false])
        }

        if (animationDurationPercentage >= 0.4 && animationDurationPercentage < 0.45) {
          // standby
          finalBounceOutDownVectors = [0, 20, 0]
        }

        if (animationDurationPercentage >= 0.45 && animationDurationPercentage <= 1) {
          props.opacity = opacity(1, 0, 0.45, 1)
          finalBounceOutDownVectors = translateVectors(-20, 2000, 0.45, 1, [false, true, false])
        }

        props.translate3d = finalBounceOutDownVectors.map(e => e + "px").join(",")
        break

      // Back entrances
      case AnimationManager.BACK_IN_DOWN:
        props.scale3d = [0.7, 0.7, 0.7].join(",")

        if (animationDurationPercentage < 0.8) {
          props.translate3d = [0, calculatePercentValue(-1200, 0, 0, 0.8) + "px", 0].join(",")
        }

        if (animationDurationPercentage >= 0.8) {
          props.scale3d = scale(0.7, 1, 0.8, 1).join(",")
        }
        break
      case AnimationManager.BACK_IN_LEFT:
        props.scale3d = [0.7, 0.7, 0.7].join(",")

        if (animationDurationPercentage < 0.8) {
          props.translate3d = [calculatePercentValue(-2000, 0, 0, 0.8) + "px", 0, 0].join(",")
        }

        if (animationDurationPercentage >= 0.8) {
          props.scale3d = scale(0.7, 1, 0.8, 1).join(",")
        }
        break
      case AnimationManager.BACK_IN_RIGHT:
        props.scale3d = [0.7, 0.7, 0.7].join(",")

        if (animationDurationPercentage < 0.8) {
          props.translate3d = [calculatePercentValue(2000, 0, 0, 0.8) + "px", 0, 0].join(",")
        }

        if (animationDurationPercentage >= 0.8) {
          props.scale3d = scale(0.7, 1, 0.8, 1).join(",")
        }
        break
      case AnimationManager.BACK_IN_UP:
        props.scale3d = [0.7, 0.7, 0.7].join(",")

        if (animationDurationPercentage < 0.8) {
          props.translate3d = [0, calculatePercentValue(1200, 0, 0, 0.8) + "px", 0].join(",")
        }

        if (animationDurationPercentage >= 0.8) {
          props.scale3d = scale(0.7, 1, 0.8, 1).join(",")
        }
        break

      // Back exits
      case AnimationManager.BACK_OUT_DOWN:
        if (animationDurationPercentage < 0.2) {
          props.scale3d = scale(1, 0.7, 0, 0.2).join(",")
          props.opacity = opacity(1, 0.7, 0, 0.2)
        }

        if (animationDurationPercentage >= 0.2) {
          props.scale3d = [0.7, 0.7, 0.7].join(",")
          props.opacity = 0.7
          props.translate3d = [0, calculatePercentValue(0, 700, 0.2, 1) + "px", 0].join(",")
        }
        break
      case AnimationManager.BACK_OUT_LEFT:
        if (animationDurationPercentage < 0.2) {
          props.scale3d = scale(1, 0.7, 0, 0.2).join(",")
          props.opacity = opacity(1, 0.7, 0, 0.2)
        }

        if (animationDurationPercentage >= 0.2) {
          props.scale3d = [0.7, 0.7, 0.7].join(",")
          props.opacity = 0.7
          props.translate3d = [calculatePercentValue(0, -2000, 0.2, 1) + "px", 0, 0].join(",")
        }
        break
      case AnimationManager.BACK_OUT_RIGHT:
        if (animationDurationPercentage < 0.2) {
          props.scale3d = scale(1, 0.7, 0, 0.2).join(",")
          props.opacity = opacity(1, 0.7, 0, 0.2)
        }

        if (animationDurationPercentage >= 0.2) {
          props.scale3d = [0.7, 0.7, 0.7].join(",")
          props.opacity = 0.7
          props.translate3d = [calculatePercentValue(0, 2000, 0.2, 1) + "px", 0, 0].join(",")
        }
        break
      case AnimationManager.BACK_OUT_UP:
        if (animationDurationPercentage < 0.2) {
          props.scale3d = scale(1, 0.7, 0, 0.2).join(",")
          props.opacity = opacity(1, 0.7, 0, 0.2)
        }

        if (animationDurationPercentage >= 0.2) {
          props.scale3d = [0.7, 0.7, 0.7].join(",")
          props.opacity = 0.7
          props.translate3d = [0, calculatePercentValue(0, -700, 0.2, 1) + "px", 0].join(",")
        }
        break
      case AnimationManager.FLOAT:
        if (animationDurationPercentage < 0.5) {
          props.translate3d = [0, calculatePercentValue(0, -20, 0, 0.5) + "px", 0].join(",")
        } else {
          props.translate3d = [0, calculatePercentValue(-20, 0, 0.5, 1) + "px", 0].join(",")
        }
        break
      case AnimationManager.SCALE_IN_DOWN:
        props.top = "-" + (15 * (1 - animationDurationPercentage))
        props.scale3d = scale(1.1, 1.0, 0, 1).join(",")
        props.opacity = animationDurationPercentage
        break
      // Fade in
      case AnimationManager.FADE_IN:
        props["animation-timing-function"] = "ease-out"
        props.opacity = animationDurationPercentage
        break
      case AnimationManager.FADE_IN_LEFT:
        props["animation-timing-function"] = "ease-out"
        props.opacity = animationDurationPercentage
        props.left = "-" + (15 * (1 - animationDurationPercentage))
        break
      case AnimationManager.FADE_IN_RIGHT:
        props["animation-timing-function"] = "ease-out"
        props.opacity = animationDurationPercentage
        props.left = "+" + (15 * (1 - animationDurationPercentage))
        break
      case AnimationManager.FADE_IN_UP:
        props["animation-timing-function"] = "ease-out"
        props.opacity = animationDurationPercentage
        props.top = "+" + (15 * (1 - animationDurationPercentage)) // It's done automatically, don't even ask how
        break
      case AnimationManager.FADE_IN_DOWN:
        props["animation-timing-function"] = "ease-out"
        props.opacity = animationDurationPercentage
        props.top = "-" + (15 * (1 - animationDurationPercentage))
        break

      // Fade in big
      case AnimationManager.FADE_IN_LEFT_BIG:
        props.opacity = animationDurationPercentage
        props.left = "-" + (2000 * (1 - animationDurationPercentage))
        break
      case AnimationManager.FADE_IN_RIGHT_BIG:
        props.opacity = animationDurationPercentage
        props.left = "+" + (2000 * (1 - animationDurationPercentage))
        break
      case AnimationManager.FADE_IN_UP_BIG:
        props.opacity = animationDurationPercentage
        props.top = "+" + (2000 * (1 - animationDurationPercentage)) // It's done automatically, don't even ask how
        break
      case AnimationManager.FADE_IN_DOWN_BIG:
        props.opacity = animationDurationPercentage
        props.top = "-" + (2000 * (1 - animationDurationPercentage))
        break

      // Fade out
      case AnimationManager.FADE_OUT:
        props.opacity = (1 - animationDurationPercentage)
        break
      case AnimationManager.FADE_OUT_LEFT:
        props.opacity = (1 - animationDurationPercentage)
        props.left = "-" + (vectorValue * animationDurationPercentage)
        break
      case AnimationManager.FADE_OUT_RIGHT:
        props.opacity = (1 - animationDurationPercentage)
        props.left = "+" + (vectorValue * animationDurationPercentage)
        break
      case AnimationManager.FADE_OUT_UP:
        props.opacity = (1 - animationDurationPercentage)
        props.top = "-" + (vectorValue * animationDurationPercentage)
        break
      case AnimationManager.FADE_OUT_DOWN:
        props.opacity = (1 - animationDurationPercentage)
        props.top = "+" + (vectorValue * animationDurationPercentage)
        break

      // Fade out big
      case AnimationManager.FADE_OUT_LEFT_BIG:
        props.opacity = (1 - animationDurationPercentage)
        props.left = "-" + (2000 * animationDurationPercentage)
        break
      case AnimationManager.FADE_OUT_RIGHT_BIG:
        props.opacity = (1 - animationDurationPercentage)
        props.left = "+" + (2000 * animationDurationPercentage)
        break
      case AnimationManager.FADE_OUT_UP_BIG:
        props.opacity = (1 - animationDurationPercentage)
        props.top = "-" + (2000 * animationDurationPercentage)
        break
      case AnimationManager.FADE_OUT_DOWN_BIG:
        props.opacity = (1 - animationDurationPercentage)
        props.top = "+" + (2000 * animationDurationPercentage)
        break

      case AnimationManager.FLIP:
        if (animationDurationPercentage < 0.4) {
          props.perspective = 400 + "px"
          props.scale3d = [1, 1, 1].join(",")
          props.translate3d = [0, 0, calculatePercentValue(0, 150, 0, 0.4) + "px"].join(",")
          props.rotate3d = [0, 1, 0, calculatePercentValue(-360, -190, 0, 0.4) + "deg"].join(",")
          props["animation-timing-function"] = "ease-out"
        }

        if (animationDurationPercentage >= 0.4 && animationDurationPercentage < 0.5) {
          props.perspective = 400 + "px"
          props.scale3d = [1, 1, 1].join(",")
          props.translate3d = [0, 0, 150 + "px"].join(",")
          props.rotate3d = [0, 1, 0, calculatePercentValue(-190, -170, 0.4, 0.5) + "deg"].join(",")
          props["animation-timing-function"] = "ease-out"
        }

        if (animationDurationPercentage >= 0.5 && animationDurationPercentage < 0.8) {
          props.perspective = 400 + "px"
          props.scale3d = scale(1, 0.95, 0.5, 0.8).join(",")
          props.translate3d = [0, 0, calculatePercentValue(0, 150, 0.5, 0.8) + "px"].join(",")
          props.rotate3d = [0, 1, 0, calculatePercentValue(-170, 0, 0.5, 0.8) + "deg"].join(",")
          props["animation-timing-function"] = "ease-in"
        }

        if (animationDurationPercentage >= 0.8 && animationDurationPercentage <= 1) {
          props.perspective = 400 + "px"
          props.scale3d = scale(0.95, 1, 0.8, 1).join(",")
          props.translate3d = [0, 0, 0].join(",")
          props.rotate3d = [0, 1, 0, 0 + "deg"].join(",")
          props["animation-timing-function"] = "ease-in"
        }
        break
      case AnimationManager.FLIP_X:
        props.perspective = 400 + "px"

        if (animationDurationPercentage < 0.5) {
          props.rotate3d = [1, 0, 0, calculatePercentValue(0, 180, 0, 0.5) + "deg"].join(",")
        }

        if (animationDurationPercentage >= 0.5 && animationDurationPercentage <= 1) {
          props.rotate3d = [1, 0, 0, calculatePercentValue(180, 360, 0.5, 1) + "deg"].join(",")
        }
        break
      case AnimationManager.FLIP_IN_X:
        props.perspective = 400 + "px"
        props.opacity = calculatePercentValue(0, 1, 0, 0.6)

        if (animationDurationPercentage < 0.5) {
          props.rotate3d = [1, 0, 0, calculatePercentValue(0, 180, 0, 0.5) + "deg"].join(",")
        }

        if (animationDurationPercentage >= 0.5 && animationDurationPercentage <= 1) {
          props.rotate3d = [1, 0, 0, calculatePercentValue(180, 360, 0.5, 1) + "deg"].join(",")
        }
        break
      case AnimationManager.FLIP_IN_Y:
        props.perspective = 400 + "px"
        props.opacity = calculatePercentValue(0, 1, 0, 0.6)

        if (animationDurationPercentage < 0.5) {
          props.rotate3d = [0, 1, 0, calculatePercentValue(0, 180, 0, 0.5) + "deg"].join(",")
        }

        if (animationDurationPercentage >= 0.3 && animationDurationPercentage <= 1) {
          props.rotate3d = [0, 1, 0, calculatePercentValue(180, 360, 0.5, 1) + "deg"].join(",")
        }
        break
      case AnimationManager.FLIP_OUT_X:
        props.perspective = 400 + "px"
        props.opacity = calculatePercentValue(1, 0, 0, 1)

        if (animationDurationPercentage < 0.5) {
          props.rotate3d = [1, 0, 0, calculatePercentValue(0, 180, 0, 0.5) + "deg"].join(",")
        }

        if (animationDurationPercentage >= 0.5 && animationDurationPercentage <= 1) {
          props.rotate3d = [1, 0, 0, calculatePercentValue(180, 360, 0.5, 1) + "deg"].join(",")
        }
        break
      case AnimationManager.FLIP_OUT_Y:
        props.perspective = 400 + "px"
        props.opacity = calculatePercentValue(1, 0, 0, 1)

        if (animationDurationPercentage < 0.5) {
          props.rotate3d = [0, 1, 0, calculatePercentValue(0, 180, 0, 0.5) + "deg"].join(",")
        }

        if (animationDurationPercentage >= 0.3 && animationDurationPercentage <= 1) {
          props.rotate3d = [0, 1, 0, calculatePercentValue(180, 360, 0.5, 1) + "deg"].join(",")
        }
        break
      case AnimationManager.LIGHT_SPEED_IN:
        props.opacity = calculatePercentValue(0, 1, 0, 0.6)
        props.translate3d = [calculatePercentValue(100, 0, 0, 1) + "%", 0, 0].join(",")

        if (animationDurationPercentage < 0.6) {
          props.skewX = calculatePercentValue(-30, 20, 0, 0.6) + "deg"
        }

        if (animationDurationPercentage >= 0.6 && animationDurationPercentage < 0.8) {
          props.skewX = calculatePercentValue(20, -5, 0.6, 0.8) + "deg"
        }

        if (animationDurationPercentage >= 0.8 && animationDurationPercentage <= 1) {
          props.skewX = calculatePercentValue(-5, 0, 0.8, 1) + "deg"
        }
        break
      case AnimationManager.LIGHT_SPEED_IN_LEFT:
        props.opacity = calculatePercentValue(0, 1, 0, 0.6)
        props.translate3d = [calculatePercentValue(-100, 0, 0, 1) + "%", 0, 0].join(",")

        if (animationDurationPercentage < 0.6) {
          props.skewX = calculatePercentValue(30, -20, 0, 0.6) + "deg"
        }

        if (animationDurationPercentage >= 0.6 && animationDurationPercentage < 0.8) {
          props.skewX = calculatePercentValue(-20, 5, 0.6, 0.8) + "deg"
        }

        if (animationDurationPercentage >= 0.8 && animationDurationPercentage <= 1) {
          props.skewX = calculatePercentValue(5, 0, 0.8, 1) + "deg"
        }
        break
      case AnimationManager.LIGHT_SPEED_OUT:
        props.translate3d = [calculatePercentValue(0, 100, 0, 1) + "%", 0, 0].join(",")
        props.opacity = calculatePercentValue(1, 0, 0, 1)
        props.skewX = calculatePercentValue(0, 30, 0, 1) + "deg"
        break
      case AnimationManager.LIGHT_SPEED_OUT_LEFT:
        props.translate3d = [calculatePercentValue(0, -100, 0, 1) + "%", 0, 0].join(",")
        props.opacity = calculatePercentValue(1, 0, 0, 1)
        props.skewX = calculatePercentValue(0, -30, 0, 1) + "deg"
        break
      case AnimationManager.ROTATE_IN:
        props.rotate3d = [0, 0, calculatePercentValue(1, 0, 0, 1), calculatePercentValue(-200, 0, 0, 1) + "deg"].join(",")
        props.opacity = calculatePercentValue(0, 1, 0, 1)
        break
      case AnimationManager.ROTATE_OUT:
        props.rotate3d = [0, 0, calculatePercentValue(0, 1, 0, 1), calculatePercentValue(0, -200, 0, 1) + "deg"].join(",")
        props.opacity = calculatePercentValue(1, 0, 0, 1)
        break
      case AnimationManager.ROTATE_LEFT:
        props.rotate3d = [0, 0, calculatePercentValue(1, 0, 0, 1), calculatePercentValue(360, 0, 0, 1) + "deg"].join(",")
        break
      case AnimationManager.ROTATE_RIGHT:
        props.rotate3d = [0, 0, calculatePercentValue(0, 1, 0, 1), calculatePercentValue(0, 360, 0, 1) + "deg"].join(",")
        break
      case AnimationManager.SLIDE_IN_LEFT:
        if (design) {
          const dim = Utils.getDimensions(design)
          if (module.parentModuleId) {
            const parentModule = modules.find(m => m.uuid === module.parentModuleId)
            if (parentModule.type === PanoModuleType) {
              dim.width *= 3
            }
          }
          const moduleTransform = Utils.parseTransform(module.preview.transform)
          const leftMax = -(dim.width + parseInt(moduleTransform.translateX))
          props.translate3d = [calculatePercentValue(leftMax, 0, 0, 1) + "px", 0, 0].join(",")
        } else {
          props.translate3d = [calculatePercentValue(-100, 0, 0, 1) + "vh", 0, 0].join(",")
        }
        break
      case AnimationManager.SLIDE_IN_RIGHT:
        if (design) {
          const dim = Utils.getDimensions(design)
          if (module.parentModuleId) {
            const parentModule = modules.find(m => m.uuid === module.parentModuleId)
            if (parentModule.type === PanoModuleType) {
              dim.width *= 3
            }
          }
          const moduleTransform = Utils.parseTransform(module.preview.transform)
          const leftMax = (dim.width + parseInt(moduleTransform.translateX))
          props.translate3d = [calculatePercentValue(leftMax, 0, 0, 1) + "px", 0, 0].join(",")
        } else {
          props.translate3d = [calculatePercentValue(100, 0, 0, 1) + "vh", 0, 0].join(",")
        }
        break
      case AnimationManager.SLIDE_IN_UP:
        if (design) {
          const dim = Utils.getDimensions(design)
          const moduleTransform = Utils.parseTransform(module.preview.transform)
          const topMax = (dim.height + parseInt(moduleTransform.translateY))
          props.translate3d = [0, calculatePercentValue(topMax, 0, 0, 1) + "px", 0].join(",")
        } else {
          props.translate3d = [0, calculatePercentValue(100, 0, 0, 1) + "vh", 0].join(",")
        }
        break
      case AnimationManager.SLIDE_IN_DOWN:
        if (design) {
          const dim = Utils.getDimensions(design)
          const moduleTransform = Utils.parseTransform(module.preview.transform)
          const topMax = -(dim.height + parseInt(moduleTransform.translateY))
          props.translate3d = [0, calculatePercentValue(topMax, 0, 0, 1) + "px", 0].join(",")
        } else {
          props.translate3d = [0, calculatePercentValue(-100, 0, 0, 1) + "vh", 0].join(",")
        }
        break
      case AnimationManager.SLIDE_OUT_LEFT:
        if (design) {
          const dim = Utils.getDimensions(design)
          if (module.parentModuleId) {
            const parentModule = modules.find(m => m.uuid === module.parentModuleId)
            if (parentModule.type === PanoModuleType) {
              dim.width *= 3
            }
          }
          const moduleTransform = Utils.parseTransform(module.preview.transform)
          const leftMax = -(dim.width + parseInt(moduleTransform.translateX))
          props.translate3d = [calculatePercentValue(0, leftMax, 0, 1) + "px", 0, 0].join(",")
        } else {
          props.translate3d = [calculatePercentValue(0, -100, 0, 1) + "vh", 0, 0].join(",")
        }
        break
      case AnimationManager.SLIDE_OUT_RIGHT:
        if (design) {
          const dim = Utils.getDimensions(design)
          if (module.parentModuleId) {
            const parentModule = modules.find(m => m.uuid === module.parentModuleId)
            if (parentModule.type === PanoModuleType) {
              dim.width *= 3
            }
          }
          const moduleTransform = Utils.parseTransform(module.preview.transform)
          const leftMax = (dim.width + parseInt(moduleTransform.translateX))
          props.translate3d = [calculatePercentValue(0, leftMax, 0, 1) + "px", 0, 0].join(",")
        } else {
          props.translate3d = [calculatePercentValue(0, 100, 0, 1) + "vh", 0, 0].join(",")
        }
        break
      case AnimationManager.SLIDE_OUT_UP:
        if (design) {
          const dim = Utils.getDimensions(design)
          const moduleTransform = Utils.parseTransform(module.preview.transform)
          const topMax = -(dim.height + parseInt(moduleTransform.translateY))
          props.translate3d = [0, calculatePercentValue(0, topMax, 0, 1) + "px", 0].join(",")
        } else {
          props.translate3d = [0, calculatePercentValue(0, -100, 0, 1) + "vh", 0].join(",")
        }
        break
      case AnimationManager.SLIDE_OUT_DOWN:
        if (design) {
          const dim = Utils.getDimensions(design)
          const moduleTransform = Utils.parseTransform(module.preview.transform)
          const topMax = (dim.height + parseInt(moduleTransform.translateY))
          props.translate3d = [0, calculatePercentValue(0, topMax, 0, 1) + "px", 0].join(",")
        } else {
          props.translate3d = [0, calculatePercentValue(0, 100, 0, 1) + "vh", 0].join(",")
        }
        break
      case AnimationManager.ZOOM_IN:
        props.opacity = calculatePercentValue(0, 1, 0, 0.5)
        props.scale3d = scale(0.3, 1, 0, 1).join(",")
        break
      case AnimationManager.ZOOM_IN_LEFT:
        props.opacity = calculatePercentValue(0, 1, 0, 0.6)

        if (animationDurationPercentage < 0.6) {
          props["animation-timing-function"] = "cubic-bezier(0.55, 0.055, 0.675, 0.19)"
          props.translate3d = [calculatePercentValue(-1000, 10, 0, 0.6) + "%", 0, 0].join(",")
          props.scale3d = scale(0.1, 0.475, 0, 0.6).join(",")
        }

        if (animationDurationPercentage >= 0.6 && animationDurationPercentage <= 1) {
          props["animation-timing-function"] = "cubic-bezier(0.175, 0.885, 0.32, 1);"
          props.translate3d = [calculatePercentValue(10, 0, 0.6, 1) + "%", 0, 0].join(",")
          props.scale3d = scale(0.475, 1, 0.6, 1).join(",")
        }
        break
      case AnimationManager.ZOOM_IN_RIGHT:
        props.opacity = calculatePercentValue(0, 1, 0, 0.6)

        if (animationDurationPercentage < 0.6) {
          props["animation-timing-function"] = "cubic-bezier(0.55, 0.055, 0.675, 0.19)"
          props.translate3d = [calculatePercentValue(1000, -10, 0, 0.6) + "%", 0, 0].join(",")
          props.scale3d = scale(0.1, 0.475, 0, 0.6).join(",")
        }

        if (animationDurationPercentage >= 0.6 && animationDurationPercentage <= 1) {
          props["animation-timing-function"] = "cubic-bezier(0.175, 0.885, 0.32, 1);"
          props.translate3d = [calculatePercentValue(-10, 0, 0.6, 1) + "%", 0, 0].join(",")
          props.scale3d = scale(0.475, 1, 0.6, 1).join(",")
        }
        break
      case AnimationManager.ZOOM_IN_UP:
        props.opacity = calculatePercentValue(0, 1, 0, 0.6)

        if (animationDurationPercentage < 0.6) {
          props["animation-timing-function"] = "cubic-bezier(0.55, 0.055, 0.675, 0.19)"
          props.translate3d = [0, calculatePercentValue(1000, -60, 0, 0.6) + "%", 0].join(",")
          props.scale3d = scale(0.1, 0.475, 0, 0.6).join(",")
        }

        if (animationDurationPercentage >= 0.6 && animationDurationPercentage <= 1) {
          props["animation-timing-function"] = "cubic-bezier(0.175, 0.885, 0.32, 1);"
          props.translate3d = [0, calculatePercentValue(-60, 0, 0.6, 1) + "%", 0].join(",")
          props.scale3d = scale(0.475, 1, 0.6, 1).join(",")
        }
        break
      case AnimationManager.ZOOM_IN_DOWN:
        props.opacity = calculatePercentValue(0, 1, 0, 0.6)

        if (animationDurationPercentage < 0.6) {
          props["animation-timing-function"] = "cubic-bezier(0.55, 0.055, 0.675, 0.19)"
          props.translate3d = [0, calculatePercentValue(-1000, 60, 0, 0.6) + "%", 0].join(",")
          props.scale3d = scale(0.1, 0.475, 0, 0.6).join(",")
        }

        if (animationDurationPercentage >= 0.6 && animationDurationPercentage <= 1) {
          props["animation-timing-function"] = "cubic-bezier(0.175, 0.885, 0.32, 1);"
          props.translate3d = [0, calculatePercentValue(60, 0, 0.6, 1) + "%", 0].join(",")
          props.scale3d = scale(0.475, 1, 0.6, 1).join(",")
        }
        break
      case AnimationManager.ZOOM_IN_SMOOTH:
        props.scale3d = scale(1, 1.064, 0, 1).join(",")
        break
      case this.ZOOM_OUT:
        props.opacity = calculatePercentValue(1, 0, 0, 0.5)
        props.scale3d = scale(1, 0.3, 0, 0.5).join(",")
        break
      case AnimationManager.ZOOM_OUT_LEFT:
        props.opacity = calculatePercentValue(1, 0, 0.4, 1)

        if (animationDurationPercentage < 0.4) {
          props.translate3d = [calculatePercentValue(0, 42, 0, 0.4) + "px", 0, 0].join(",")
          props.scale3d = scale(1, 0.475, 0, 0.4).join(",")
        }

        if (animationDurationPercentage >= 0.4 && animationDurationPercentage <= 1) {
          props.translate3d = [calculatePercentValue(42, -2000, 0.4, 1) + "px", 0, 0].join(",")
          props.scale3d = scale(0.475, 0.1, 0.4, 1).join(",")
        }
        break
      case AnimationManager.ZOOM_OUT_RIGHT:
        props.opacity = calculatePercentValue(1, 0, 0.4, 1)

        if (animationDurationPercentage < 0.4) {
          props.translate3d = [calculatePercentValue(0, -42, 0, 0.4) + "px", 0, 0].join(",")
          props.scale3d = scale(1, 0.475, 0, 0.4).join(",")
        }

        if (animationDurationPercentage >= 0.4 && animationDurationPercentage <= 1) {
          props.translate3d = [calculatePercentValue(-42, 2000, 0.4, 1) + "px", 0, 0].join(",")
          props.scale3d = scale(0.475, 0.1, 0.4, 1).join(",")
        }
        break
      case AnimationManager.ZOOM_OUT_UP:
        props.opacity = calculatePercentValue(1, 0, 0.4, 1)

        if (animationDurationPercentage < 0.4) {
          props.translate3d = [0, calculatePercentValue(0, 60, 0, 0.4) + "px", 0].join(",")
          props.scale3d = scale(1, 0.475, 0, 0.4).join(",")
        }

        if (animationDurationPercentage >= 0.4 && animationDurationPercentage <= 1) {
          props.translate3d = [0, calculatePercentValue(60, -2000, 0.4, 1) + "px", 0].join(",")
          props.scale3d = scale(0.475, 0.1, 0.4, 1).join(",")
        }
        break
      case AnimationManager.ZOOM_OUT_DOWN:
        props.opacity = calculatePercentValue(1, 0, 0.4, 1)

        if (animationDurationPercentage < 0.4) {
          props.translate3d = [0, calculatePercentValue(0, -60, 0, 0.4) + "px", 0].join(",")
          props.scale3d = scale(1, 0.475, 0, 0.4).join(",")
        }

        if (animationDurationPercentage >= 0.4 && animationDurationPercentage <= 1) {
          props.translate3d = [0, calculatePercentValue(-60, 2000, 0.4, 1) + "px", 0].join(",")
          props.scale3d = scale(0.475, 0.1, 0.4, 1).join(",")
        }
        break
      case AnimationManager.ZOOM_OUT_SMOOTH:
        props.scale3d = scale(1.064, 1, 0, 1).join(",")
        break
      case AnimationManager.HINGE:
        props.opacity = calculatePercentValue(1, 0, 0.8, 1)

        if (animationDurationPercentage < 0.2) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(0, 80, 0, 0.2) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.2 && animationDurationPercentage < 0.4) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(80, 60, 0.2, 0.4) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.4 && animationDurationPercentage < 0.6) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(60, 80, 0.4, 0.6) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.6 && animationDurationPercentage < 0.8) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(80, 60, 0.6, 0.8) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.8 && animationDurationPercentage <= 1) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(60, 80, 0.8, 1) + "deg"].join(",")
          props.translate3d = [0, calculatePercentValue(0, 700, 0.8, 1) + "px", 0].join(",")
        }
        break
      case AnimationManager.JACK_IN_THE_BOX:
        props.scale3d = scale(0.1, 1, 0, 1).join(",")
        props.opacity = calculatePercentValue(0, 1, 0, 1)

        if (animationDurationPercentage < 0.5) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(30, -10, 0, 0.5) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.5 && animationDurationPercentage < 0.7) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(-10, 3, 0.5, 0.7) + "deg"].join(",")
        }
        if (animationDurationPercentage >= 0.7 && animationDurationPercentage <= 1) {
          props.rotate3d = [0, 0, 1, calculatePercentValue(3, 0, 0.7, 1) + "deg"].join(",")
        }
        break
      case AnimationManager.ROLL_IN:
        props.rotate3d = [0, 0, 1, calculatePercentValue(-120, 0, 0, 1) + "deg"].join(",")
        props.opacity = calculatePercentValue(0, 1, 0, 1)
        props.translate3d = [calculatePercentValue(-100, 0, 0, 1) + "%", 0, 0].join(",")
        break
      case AnimationManager.ROLL_OUT:
        props.rotate3d = [0, 0, 1, calculatePercentValue(0, 120, 0, 1) + "deg"].join(",")
        props.opacity = calculatePercentValue(1, 0, 0, 1)
        props.translate3d = [calculatePercentValue(0, 100, 0, 1) + "%", 0, 0].join(",")
        break
      case AnimationManager.BLUR_IN:
        props.blur = (1 - animationDurationPercentage) * 5 + "px"
        break
      case AnimationManager.BLUR_OUT:
        props.blur = animationDurationPercentage * 100 + "px"
        props.opacity = calculatePercentValue(1, 0, 0.4, 1)
        break
      case AnimationManager.REVEAL_IN_RIGHT:
        props["clip-path"] = `polygon(${calculatePercentValue(100, 0, 0, 1)}% 0%, 100% 0%, 100% 100%, ${calculatePercentValue(100, 0, 0, 1)}% 100%)`
        break
      case AnimationManager.REVEAL_IN_LEFT:
        props["clip-path"] = `polygon(0% 0%, ${calculatePercentValue(0, 100, 0, 1)}% 0%, ${calculatePercentValue(0, 100, 0, 1)}% 100%, 0% 100%)`
        break
      case AnimationManager.REVEAL_IN_UP:
        props["clip-path"] = `polygon(0% 0%, 100% 0%, 100% ${calculatePercentValue(0, 100, 0, 1)}%, 0% ${calculatePercentValue(0, 100, 0, 1)}%)`
        break
      case AnimationManager.REVEAL_IN_DOWN:
        props["clip-path"] = `polygon(0% ${calculatePercentValue(100, 0, 0, 1)}%, 100% ${calculatePercentValue(100, 0, 0, 1)}%, 100% 100%, 0% 100%)`
        break
      case AnimationManager.REVEAL_OUT_RIGHT:
        props["clip-path"] = `polygon(${calculatePercentValue(0, 100, 0, 1)}% 0%, 100% 0%, 100% 100%, ${calculatePercentValue(0, 100, 0, 1)}% 100%)`
        break
      case AnimationManager.REVEAL_OUT_LEFT:
        props["clip-path"] = `polygon(0% 0%, ${calculatePercentValue(100, 0, 0, 1)}% 0%, ${calculatePercentValue(100, 0, 0, 1)}% 100%, 0% 100%)`
        break
      case AnimationManager.REVEAL_OUT_UP:
        props["clip-path"] = `polygon(0% 0%, 100% 0%, 100% ${calculatePercentValue(100, 0, 0, 1)}%, 0% ${calculatePercentValue(100, 0, 0, 1)}%)`
        break
      case AnimationManager.REVEAL_OUT_DOWN:
        props["clip-path"] = `polygon(0% ${calculatePercentValue(0, 100, 0, 1)}%, 100% ${calculatePercentValue(0, 100, 0, 1)}%, 100% 100%, 0% 100%)`
        break
    }

    if (props.opacity && animationDurationPercentage > 0) {
      const currentOpacity = typeof module.preview.opacity !== "undefined" ? module.preview.opacity : 1

      props.opacity = props.opacity * currentOpacity
    }

    if (props.borderRadius) {
      const currentBorderRadius = typeof module.preview.borderRadius !== "undefined" ? module.preview.borderRadius : 0

      props.borderRadius = props.borderRadius * currentBorderRadius
    }

    return props
  }
}
