<template>
  <div class="popup">
    <slot />
  </div>
</template>
<script>
export default {
  name: "Popup"
}
</script>
