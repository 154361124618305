<template>
  <popup class="notifications-popup">
    <card class="card-sm">
      <card-body>
        <h1>Notifications</h1>
        <hr class="sep" />
        <div class="notifications-scroll">
          <div
            v-for="(notification, index) in filteredNotifications"
            :key="'notification-' + index"
            class="row"
            @click="notificationClick(notification, $event)"
          >
            <avatar
              :alt="getAuthorName(notification)"
              :avatar="getAuthorAvatar(notification)"
              :size="38"
              :title="getAuthorName(notification)"
              :user-id="getAuthorUuid(notification)"
            />
            <div class="notification-content">
              <span v-html="Utils.parseNotification(notification)"></span>
              <span class="timestamp">{{ Utils.timeAgoFormatter(notification.timestamp * 1000) }}</span>
            </div>
          </div>
        </div>
        <template v-if="filteredNotifications.length === 0">
          <div class="notification-empty">
            <icon name="notification-empty" />
            <h4>
              There are no Notifications to show,
              lets check again later.
            </h4>
          </div>
        </template>
      </card-body>
    </card>
  </popup>
</template>

<script>
import Utils from "../../../utils"
import InvalidNotificationException from "../../../errors/InvalidNotificationException"

export default {
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    filteredNotifications () {
      return this.notifications.filter((n) => {
        try {
          Utils.parseNotification(n)
        } catch (e) {
          if (e instanceof InvalidNotificationException) {
            return false
          }
        }

        return true
      })
    },
    Utils () {
      return Utils
    }
  },
  methods: {
    notificationClick (notification) {
      if (notification.link) {
        window.location.href = notification.link
      }
    },
    getAuthorName (notification) {
      if (notification.author) {
        return notification.author.firstname + " " + notification.author.lastname
      }
      return "Anonymous user"
    },
    getAuthorAvatar (notification) {
      if (notification.author) {
        return notification.author.avatar
      }
      return null
    },
    getAuthorUuid (notification) {
      if (notification.author) {
        return notification.author.uuid
      }
      return null
    }
  }
}
</script>
