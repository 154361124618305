<template>
  <div :class="`tabs`">
    <div class="tabs-wrapper">
      <ul
        v-if="tabs.length > 0"
        class="nav nav-tabs"
      >
        <li
          v-for="(tab, tabIndex) in tabs"
          :key="tabIndex"
          class="nav-item"
          :class="{ active: tab.isActive }"
        >
          <a
            class="nav-link"
            :class="{ active: tab.isActive, disabled: tab.disabled }"
            href="#"
            @click="setActiveTab($event, tab.title)"
          >
            <icon
              v-if="tab.icon"
              :name="tab.icon"
            />
            {{ getTabTitle(tab) }}
          </a>
        </li>
      </ul>
      <slot name="header"></slot>
    </div>
    <slot name="content" />
  </div>
</template>

<script>
import Icon from "../common/Icon"

export default {
  name: "Tabs",
  components: { Icon },
  props: {
    initActiveTab: {
      type: String,
      default: ""
    },
    showTitleOnlyForActiveTab: {
      type: Boolean,
      default: false
    }
  },
  emits: ["change-tab"],
  data () {
    return {
      tabs: []
    }
  },
  watch: {
    initActiveTab () {
      if (typeof this.$slots.content === "function") {
        this.tabs = this.getAvailableTabs()
      }

      if (this.initActiveTab.length > 0) {
        const newActiveTab = this.tabs.find((tab) => tab.title.toLowerCase() === this.initActiveTab)
        if (newActiveTab) {
          this.setActiveTab(null, newActiveTab.title)
        }
      }
    }
  },
  mounted () {
    if (typeof this.$slots.content === "function") {
      this.tabs = this.getAvailableTabs()
    }

    if (this.initActiveTab.length > 0) {
      const newActiveTab = this.tabs.find((tab) => tab.title.toLowerCase() === this.initActiveTab)
      if (newActiveTab) {
        this.setActiveTab(null, newActiveTab.title)
      }
    }
  },
  methods: {
    getAvailableTabs () {
      const _tabItems = []

      this.$slots.content().forEach((component) => {
        if (component.type.name && component.type.name === "Tab") {
          _tabItems.push(component.props)
        }
      })

      return _tabItems
    },
    getTabTitle (tab) {
      if (!this.showTitleOnlyForActiveTab) {
        return tab.title
      }

      return tab.isActive ? tab.title : ""
    },
    setActiveTab ($e, title) {
      if ($e) {
        $e.preventDefault()
      }
      this.tabs.forEach(tab => {
        tab.isActive = (tab.title === title)
        if (tab.isActive) {
          this.$emit("change-tab", tab)
        }
      })
    }
  }
}
</script>
