<template>
  <div class="cropper mb-2">
    <vue-cropper
      ref="cropper"
      :auto-crop-area="0.5"
      :src="cropImgSrc"
      :view-mode="2"
      :zoom-on-touch="false"
      :zoom-on-wheel="false"
      alt="Source Image"
      class="cropper__canvas"
      drag-mode="move"
    />

    <div
      v-if="ratios.length > 1"
      class="toolbar d-flex flex-row justify-content-center mb-2"
    >
      <button
        v-for="ratio in ratios.filter((e) => e !== false)"
        :key="ratio"
        :class="{ active: activeAspectRatio === ratio }"
        class="btn btn-sm"
        @click="setRatio(ratio)"
      >
        {{ ratio }}
      </button>
      <button
        v-if="ratios.indexOf(false) !== -1"
        :class="{ active: activeAspectRatio === null }"
        class="btn btn-sm"
        @click="setRatio(null)"
      >
        Free
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
import Language from "../../../i18n/en"
import VueCropper from "vue-cropperjs"
import "cropperjs/dist/cropper.css"

export default {
  name: "Cropper",
  components: {
    VueCropper
  },
  title: "Cropper",
  type: "cropper",
  props: {
    ratios: {
      type: Array,
      default: () => ["16:9", "9:16", "4:3", "1:1", false]
    },
    zoomRatio: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      activeAspectRatio: null,
      activeZoom: 0
    }
  },
  computed: {
    ...mapState(["cropImgData"]),
    cropImgSrc () {
      return this.cropImgData.src
    }
  },
  watch: {
    zoomRatio () {
      this.setZoom(this.zoomRatio)
    }
  },
  created () {
    this.$eventHub.$on("crop-cancel-action", this.cropCancel)
    this.$eventHub.$on("crop-image-action", this.cropImage)
  },
  mounted () {
    if (this.ratios.length === 1) {
      this.setRatio(this.ratios[0])
    }
  },
  beforeUnmount () {
    this.$eventHub.$off("crop-cancel-action")
    this.$eventHub.$off("crop-image-action")
  },
  methods: {
    setRatio (ratio) {
      if (this.$refs.cropper) {
        this.$refs.cropper.cropper.setAspectRatio(
          ratio ? ratio.split(":")[0] / ratio.split(":")[1] : false
        )
        this.activeAspectRatio = ratio
      }
    },
    setZoom (zoom) {
      if (this.$refs.cropper) {
        this.$refs.cropper.cropper.zoom(0.1 * (zoom - this.activeZoom))
        this.activeZoom = zoom
      }
    },
    cropCancel () {
      this.$eventHub.$emit("crop-cancel")
      this.$store.commit("setCropData", { src: "" })
    },
    cropImage () {
      const data = this.$refs.cropper.getCroppedCanvas().toDataURL()

      this.saveCroppedImage({
        id: this.cropImgData.id,
        data,
        cropImgData: this.cropImgData,
        suffix: "cropped"
      }).then(
        (response) => this.$eventHub.$emit("crop-finished", response.data),
        (err) => {
          if ([419].includes(err.response.status)) {
            return
          }

          this.$toasted.error(`${Language.designs.error.cropped}<br/> ${err}`)
        }
      )
    },
    ...mapActions(["saveCroppedImage"])
  }
}
</script>
