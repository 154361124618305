<template>
  <div class="invite-modal">
    <base-modal
      ref="modal"
      :image="require('../../../../images/modal/invite-user-infographics.svg')"
      :title="'Invite User'"
      @submit="sendInvite"
    >
      <template #content>
        <div class="container">
          <div class="row d-flex">
            <div class="col">
              <text-input
                v-model:value="firstname"
                :error="form.errors.errors.firstname && form.errors.errors.firstname.length > 0"
                label="User name"
                :max-width="250"
                :min-width="250"
                placeholder="First name"
                @input="clearErrors('firstname')"
              />
            </div>
            <div class="col">
              <text-input
                v-model:value="lastname"
                :error="form.errors.errors.lastname && form.errors.errors.lastname.length > 0"
                :max-width="250"
                :min-width="250"
                placeholder="Second name"
                @input="clearErrors('lastname')"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col">
              <text-input
                v-model:value="email"
                :error="form.errors.errors.email && form.errors.errors.email.length > 0"
                label="User email"
                placeholder="johndoe@abc.com"
                type="email"
                @input="clearErrors('email')"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col">
              <input-dropdown
                v-model:value="activeRole"
                :error="form.errors.errors.role && form.errors.errors.role.length > 0"
                label="Choose role"
                :max-width="250"
                :min-width="250"
                :options="roles"
                :placement="'bottom-end'"
                :show-arrow="true"
                @input="clearErrors('role')"
              />
            </div>
            <div class="col">
              <input-dropdown
                v-model:value="activeGroup"
                label="Choose group"
                :max-width="250"
                :min-width="250"
                :optional="true"
                :options="groups"
                :placement="'bottom-end'"
                :show-arrow="true"
              />
            </div>
          </div>
        </div>
      </template>
      <template #actions>
        <base-button
          size="xl"
          @click.prevent="modal.close"
        >
          Cancel
        </base-button>
        <primary-button
          :disabled="!isFormValidated"
          size="xl"
          @click="sendInvite"
        >
          Invite
        </primary-button>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { Form } from "@/classes/form"
import Language from "../../../i18n/en"
import { AdminRoutes } from "../../../api/routes"
import Utils from "@/utils"

export default {
  props: {
    success: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      },
      firstname: "",
      lastname: "",
      email: "",
      activeRole: { title: "Designer", value: "user" },
      activeGroup: null,
      form: new Form({
        email: "",
        firstname: "",
        lastname: "",
        group: "",
        role: ""
      })
    }
  },
  computed: {
    isFormValidated () {
      return this.firstname.length > 0 && this.lastname.length > 0 && Utils.validateEmail(this.email) && this.activeRole
    },
    roles () {
      return (this.availableRoles || []).filter((r) => {
        return (this.currentUser ? this.currentUser.roles.super_admin : false) ? true : r.value !== "super_admin"
      }).map((r) => {
        return {
          value: r.value,
          title: r.name
        }
      })
    },
    groups () {
      return (this.availableGroups || []).map((g) => {
        return {
          value: g,
          title: g.name
        }
      })
    },
    ...mapState({
      availableRoles: "availableRoles",
      availableGroups: "groups",
      currentUser: "currentUser"
    })
  },
  mounted () {
    this.modal = this.$refs.modal

    this.loadGroups()
  },
  methods: {
    clearErrors (name) {
      this.form.errors.errors[name] = []
    },
    sendInvite () {
      this.form.email = this.email
      this.form.firstname = this.firstname
      this.form.lastname = this.lastname
      this.form.role = this.activeRole ? this.activeRole.value : null
      this.form.group = this.activeGroup ? this.activeGroup.value.id : null

      return this.form
        .post(AdminRoutes.sendUserInvitation())
        .then(this.onSuccess)
        .catch(e => {
          const errors = Object.values(e)

          if (errors.length > 0) {
            errors.forEach(e => this.$toasted.error(e))
          } else {
            this.$toasted.error(Language.users.error.invitation)
          }
        })
    },
    onSuccess () {
      this.modal.close()
      this.$toasted.success(Language.users.success.invitation)
      this.success()
    },
    ...mapActions(["loadGroups"])
  }
}
</script>
