<template>
  <base-modal
    id="packageBuildModal"
    ref="modal"
    class="modal-auto-height delete-modal text-left"
    :show-close-button="true"
    size="extra-small"
    title="Create a package"
  >
    <template #content>
      <div>
        <div class="form-group">
          <input-dropdown
            id="job"
            v-model:value="job"
            label="Job type"
            name="job"
            :options="options"
          />
        </div>
        <div class="form-group">
          <text-input
            id="externalId"
            v-model:value="externalId"
            label="External id (folder name)"
            name="externalId"
            type="text"
            @keydown.enter.prevent="$event.target && $event.target.blur"
          />
        </div>
      </div>
    </template>
    <template #actions>
      <base-button
        :min-width="114"
        size="m"
        @click.prevent="modal.close"
      >
        Cancel
      </base-button>
      <primary-button
        :disabled="externalId.length <= 0"
        :min-width="114"
        size="m"
        @click.prevent="queuePack"
      >
        Create package
      </primary-button>
    </template>
  </base-modal>
</template>
<script>
import Language from "../../i18n/en"

export default {
  name: "PackageBuildModal",
  props: {
    designUuid: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      },
      job: { name: "Permodo", title: "Permodo", value: "permodo" },
      externalId: ""
    }
  },
  computed: {
    options () {
      return [
        { name: "Default", title: "Default", value: "adtron.package" },
        { name: "Permodo", title: "Permodo", value: "permodo" }
      ]
    }
  },
  mounted () {
    this.modal = this.$refs.modal
  },
  methods: {
    queuePack () {
      this.$toasted.success(Language.designs.success.pack_started)
      this.$eventHub.$emit("pack-started")
      const promise = this.$store.dispatch("pack", { uuid: this.designUuid, job: this.job.value, externalId: this.externalId }).then(
        () => { },
        err => {
          if ([419].includes(err.response.status)) return
          if (err.response.status === 400) {
            this.$toasted.error(err.response.data.message)
          } else {
            this.$toasted.error(Language.designs.error.pack_started)
          }
        }
      )
      this.modal.close()

      return promise
    }
  }
}
</script>
