<template>
  <base-modal
    id="packageDownloadModal"
    ref="modal"
    class="modal-auto-height delete-modal text-left"
    :show-close-button="true"
    size="small"
    title="Download a package"
  >
    <template #content>
      <div>
        <div class="form-group">
          <input-dropdown
            id="packages"
            v-model:value="packageLink"
            label="Package"
            name="job"
            :options="options"
            track-by="value"
          />
        </div>

        <div class="form-group">
          <div>
            <label class="input-label">
              <span>
                Share link
              </span>
            </label>
            <base-input
              id="shareLink"
              ref="shareLink"
              placeholder="http://example.com"
              :readonly="true"
              :value="packageLinkValue"
              @click="() => $refs['shareLink'].$el.querySelector('input').select()"
            />
            <div class="copy-icon">
              <icon
                name="copy_alt"
                @click="copyLink('shareLink')"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <base-button
        :min-width="114"
        size="m"
        @click.prevent="modal.close"
      >
        Cancel
      </base-button>
      <primary-button
        :disabled="!packageLinkValue || !packageLinkValue.length"
        :min-width="114"
        size="m"
        @click.prevent="download"
      >
        Download
      </primary-button>
    </template>
  </base-modal>
</template>
<script>
import { mapState } from "vuex"
import Language from "../../i18n/en"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

export default {
  props: {
    links: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      },
      packageLink: null
    }
  },
  computed: {
    packageLinkValue () {
      return this.packageLink ? this.packageLink.value : ""
    },
    options () {
      return (this.links && this.links.length ? this.links : this.activeDesign?.download_links || []).map((link) => {
        return {
          name: this.extractLastLinkPart(link),
          value: link
        }
      })
    },
    ...mapState(["activeDesign"])
  },
  created () {
    dayjs.extend(utc)
  },
  mounted () {
    this.modal = this.$refs.modal
  },
  methods: {
    extractLastLinkPart (link) {
      const splitLink = link.split("/")

      return this.parseDateInLink(splitLink[splitLink.length - 1].replace(".zip", ""))
    },
    formatDownloadPackageDate (date) {
      return dayjs.utc(date, "YYYY MM DD HH mm ss").local().format("YYYY-MM-DD HH:mm:ss")
    },
    parseDateInLink (link) {
      const splitLink = link.split("_")
      const datePart = splitLink.splice(-6, 6)
      const dateString = datePart.join(" ")

      return splitLink.join("_") + " (" + this.formatDownloadPackageDate(dateString) + ")"
    },
    copyLink (ref) {
      const node = this.$refs[ref].$el.querySelector("input")
      node.select()
      document.execCommand("copy")
      node.selectionStart = 0
      node.selectionEnd = 0
      node.blur()
      this.$toasted.success(Language.clipboard.success.copy_link)
    },
    download () {
      window.open(this.packageLink.value)
      this.modal.close()
    }
  }
}
</script>
