<template>
  <section class="live-campaigns">
    <div class="d-flex justify-content-between align-content-center">
      <h1>Live Campaigns</h1>
      <primary-button @click="showComprehensiveReportModal">
        Comprehensive report
      </primary-button>
    </div>

    <section
      v-if="campaigns"
      class="live-campaigns-list"
    >
      <template v-if="campaigns.length">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="(campaign, index) in campaigns"
              :key="'live-campaigns-box-swiper' + index"
              class="swiper-slide"
              :style="slideStyles"
            >
              <card>
                <card-body>
                  <div class="brand">
                    <brand
                      :brand="campaign.brand"
                      class="campaign-brand"
                      :size="32"
                    />
                    <tooltip-too-long
                      :length="32"
                      :text="campaign.brand ? campaign.brand.name : 'Default brand'"
                    />
                  </div>
                  <router-link :to="`/campaign/${campaign.unique_id}/details`">
                    <h2 class="campaign-title">
                      <tooltip-too-long
                        :length="32"
                        :text="campaign.name"
                      />
                    </h2>
                  </router-link>
                </card-body>
                <card-footer>
                  <div class="impressions">
                    <div class="impressions-text">
                      <span class="impression-text__count">
                        {{ getDeliveredTargetValue(campaign) }}
                      </span>
                      &nbsp;{{ campaign.target }}
                    </div>
                    <div class="impressions-chart">
                      <span class="impressions-chart__count">{{
                        getDeliveredTargetPercent(campaign)
                      }}</span>%
                      <div
                        v-tippy="{arrow: true, allowHTML:true}"
                        class="impressions-chart__chart"
                        :content="getTooltipContent(campaign)"
                      >
                        <circle-chart
                          :max="Math.max(0, campaign.targetedNumber)"
                          :size="40"
                          :progress-color="getCampaignCircleColor(campaign.trackTargetType)"
                          :value="getCampaignDeliveredTarget(campaign)"
                        />
                      </div>
                    </div>
                  </div>
                </card-footer>
              </card>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev swiper-button-disabled">
          <icon name="slide-arrow-left" />
        </div>
        <div class="swiper-button-next swiper-button-disabled">
          <icon name="slide-arrow" />
        </div>
      </template>
      <empty-section
        v-if="campaigns.length === 0"
        :icon="require('../../../../images/dashboard/manager/live-campaigns-empty-state.svg')"
      >
        Are u ready to create an awesome live campaign?
      </empty-section>
    </section>
  </section>
</template>
<script>
import { mapActions } from "vuex"
import Card from "../../common/card/Card"
import CardBody from "../../common/card/CardBody"
import CardFooter from "../../common/card/CardFooter"
import Swiper from "swiper/bundle"
import Utils from "../../../utils"
import PrimaryButton from "@/components/common/controls/PrimaryButton.vue"
import ComprehensiveReportModal from "@/components/common/modal/ComprehensiveReportModal.vue"
import EmptySection from "@/components/dashboard/components/EmptySection.vue"

export default {
  name: "LiveCampaigns",
  components: {
    PrimaryButton,
    EmptySection,
    CardFooter,
    CardBody,
    Card
  },
  data () {
    return {
      swiper: null,
      campaigns: []
    }
  },
  computed: {
    slideStyles () {
      return {
        width: "242px !important"
      }
    },
    Utils () {
      return Utils
    }
  },
  beforeUnmount () {
    window.removeEventListener("intercomTourChanged", this.updateCampaigns)
  },
  created () {
    window.addEventListener("intercomTourChanged", this.updateCampaigns)
  },
  mounted () {
    this.updateCampaigns()
  },
  methods: {
    ...mapActions(["loadLiveCampaigns"]),
    getCampaignDeliveredTarget (campaign) {
      return campaign.trackTargetType === "impressions" ? campaign.deliveredImpressions : campaign.deliveredClicks
    },
    getCampaignCircleColor (target) {
      return target === "impressions" ? "#a2c100" : "#5d3eff"
    },
    getDeliveredTargetPercent (campaign) {
      if (campaign.trackTargetType === "impressions") {
        return Math.min(Utils.getImpressionsPercent(campaign.deliveredImpressions, campaign.targetedNumber), 100)
      }

      return Math.min(Utils.getImpressionsPercent(campaign.deliveredClicks, campaign.targetedNumber), 100)
    },
    getDeliveredTargetValue (campaign) {
      const deliveredTargetValue = campaign.target === "impressions" ? campaign.deliveredImpressions : campaign.deliveredClicks
      return Utils.parseImpressionCount(deliveredTargetValue)
    },
    initSwiper () {
      this.swiper = new Swiper(".live-campaigns .swiper-container", {
        breakpointsBase: "container",
        spaceBetween: 20,
        speed: 400,
        slidesPerView: "auto",
        allowTouchMove: false,
        preventClicks: false,
        preventClicksPropagation: false,
        centeredSlides: false,
        navigation: {
          nextEl: ".live-campaigns .swiper-button-next",
          prevEl: ".live-campaigns .swiper-button-prev"
        }
      })
    },
    getTooltipContent (campaign) {
      return Utils.getRichTooltip(`Target ${campaign.trackTargetType}`, null, Utils.getCampaignLiveDate(campaign), Utils.parseNumber(campaign.targetedNumber))
    },
    updateCampaigns () {
      this.loadLiveCampaigns().then((res) => {
        this.campaigns = res.data.data
        this.$nextTick(() => {
          this.initSwiper()
        })
      })
    },
    showComprehensiveReportModal () {
      this.$modal.show(ComprehensiveReportModal)
    }
  }
}
</script>
