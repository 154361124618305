<template>
  <img
    v-tippy="{arrow: true}"
    :alt="name"
    class="brand"
    :content="name"
    :src="`${imgSrc}`"
    :style="getImageStyle()"
    :title="name"
    @error="error"
    @show="showTooltip"
  />
</template>
<script>
import Utils from "../../utils"
import { BRAND_SIZE, ThumbnailResolver } from "@/thumbnailResolver"

export default {
  name: "Brand",
  props: {
    brand: {
      type: Object,
      default: null
    },
    tooltip: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
      default: 16
    },
    radius: {
      type: Number,
      default: 8
    }
  },
  computed: {
    imgSrc () {
      return this.brand?.file ? this.parseThumbUrl(this.brand) : this.fallbackImage()
    },
    content () {
      return this.name
    },
    name () {
      return this.brand ? this.brand.name : "Default brand"
    },
    color () {
      return this.brand ? this.brand.color : "#d6dae0"
    }
  },
  methods: {
    showTooltip () {
      return this.tooltip === true
    },
    parseThumbUrl (brand) {
      return ThumbnailResolver.isEnabled() ? ThumbnailResolver.resolve(
        brand.file.filename.replace(/^brands\//i, ""),
        BRAND_SIZE
      ) : brand.file.relativepath
    },
    getImageStyle () {
      return {
        width: this.size + "px",
        height: this.size + "px",
        borderRadius: this.radius + "px"
      }
    },
    error (e) {
      e.target.src = this.fallbackImage()
    },
    fallbackImage () {
      return Utils.brandAvatar(this.name, false, this.color)
    }
  }
}
</script>
