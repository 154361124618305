<template>
  <div class="campaign-list-filters">
    <search popup-component="user-list-popup" />
    <tag-list class="selected-filters">
      <tag
        v-for="(filter, index) in mappedSelectedFilters"
        :key="index"
      >
        {{ filter.name }} <a
          href="#"
          @click.prevent="removeFilter(filter.type, filter.value)"
        ><icon name="close" /></a>
      </tag>
    </tag-list>
  </div>
</template>

<script>
import Search from "../../common/search/Search"
import { mapState } from "vuex"

export default {
  name: "UserListFilters",
  components: {
    Search
  },
  computed: {
    ...mapState(["selectedFilters"]),
    mappedSelectedFilters () {
      const filters = []
      this.selectedFilters.role.forEach((role) => filters.push({ type: "role", value: role.value, name: role.name }))
      this.selectedFilters.user.forEach((user) => filters.push({ type: "user", value: user.value, name: user.name }))

      return filters
    }
  },
  methods: {
    removeFilter (type, value) {
      this.$store.commit("deleteFilter", { type, value })
    }
  }
}
</script>
