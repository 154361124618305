<template>
  <form class="profile-account-settings">
    <card>
      <card-body>
        <div class="row">
          <div class="col">
            <div class="data-row">
              <span class="label">Your Email</span>
              <span class="value">{{ currentUser.email }}</span>
            </div>
          </div>
          <div class="col">
            <div class="action-link">
              <a
                href
                @click.prevent="changeEmail"
              >Change email</a>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col">
            <div class="data-row">
              <span class="label">Password</span>
              <span class="value">An email to reset your password will be sent to your mail</span>
            </div>
          </div>
          <div class="col">
            <div class="action-link">
              <a
                href
                @click.prevent="resetPassword"
              >Reset password</a>
            </div>
          </div>
        </div>
      </card-body>
    </card>
  </form>
</template>
<script>
import { mapState } from "vuex"
import Utils from "../../utils"
import EmailModal from "../common/modal/EmailModal"
import Language from "../../i18n/en"
import { Form } from "@/classes/form"
import { SecurityRoutes } from "../../api/routes"

export default {
  name: "AccountSettings",
  computed: {
    ...mapState(["currentUser"]),
    Utils () {
      return Utils
    }
  },
  methods: {
    changeEmail () {
      this.$modal.show(EmailModal)
    },
    resetPassword () {
      let form = new Form({
        email: this.currentUser.email
      }, true)

      return form
        .post(SecurityRoutes.sendRecoverPasswordEmail())
        .then(this.onSuccess)
        .catch(this.onError)
        .finally(() => {
          form = null
        })
    },
    onSuccess () {
      this.$toasted.success(Language.password_reset.success.instructions_sent_profile)
    },
    onError () {
      this.$toasted.error(Language.password_reset.error.internal)
    }
  }
}
</script>
