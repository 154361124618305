<template>
  <base-modal
    ref="modal"
    class="campaign-wizard"
    :class="'step-' + currentStep"
    :disable-action-line="currentStep === 0 || currentStep === steps.length - 1"
    :image="currentStepImage"
    size="big"
    @submit="nextStep"
  >
    <template #modal-side-content>
      <div class="side-title-name">
        <h3 v-if="!campaignObject.uuid">
          Create
        </h3>
        <h3 v-if="campaignObject.uuid">
          Edit
        </h3>
        <h2>
          Campaign.
        </h2>
      </div>
      <template v-if="currentStep > 0">
        <div class="wizard-steps">
          <ol>
            <li
              v-for="(stepVal, stepKey) in steps.filter(s => s.title !== null)"
              :key="'wizardStepCampaign' + stepKey"
              class="step"
              :class="{current: currentStep === stepKey, finished: currentStep > stepKey}"
              @click.prevent="changeStep(stepKey)"
            >
              <icon name="wizard-step-finished" />
              <div class="list-item-bg"></div>
              <span>
                {{ stepVal.title }}
              </span>
            </li>
          </ol>
        </div>
      </template>
    </template>
    <template #modal-header>
&nbsp;
    </template>
    <template #modal-close>
&nbsp;
    </template>
    <template #content>
      <div
        v-if="currentStep > 0 && currentStep < steps.length - 1 && form.name.length > 0"
        class="campaign-name"
        :style="{color: activeBrand && activeBrand.value ? activeBrand.value.color : false, background: activeBrand && activeBrand.value ? backgroundColorBrand(activeBrand.value.color) + '56' : false}"
      >
        {{ form.name }}
      </div>
      <div class="step-0-content">
        <h1>Your Campaign Wizard</h1>
        <h4>
          Enlight your Campaign
          Create your Campaign in few steps with adtron and built awesome creatives that stand out. Lets get it started!
        </h4>
        <div class="container">
          <div class="row d-flex">
            <div class="col">
              <text-input
                v-model:value="form.name"
                :error="controlsInvalid.campaignName"
                :information-tooltip="Utils.getRichTooltip(null, 'Type in the name you want to choose for your upcoming campaign! You can later on use this name in the search field to find your campaign easier.')"
                label="Campaign name"
                :max-width="645"
                :maxlength="60"
                :min-width="645"
                placeholder="name your campaign"
                :required="true"
                @input="clearControlsInvalidCampaignName"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col">
              <rich-input-dropdown
                v-model:value="selectedUsersAndGroups"
                :is-dropdown="true"
                label="Select User or Group"
                :max-displayed="maxDisplay"
                :information-tooltip="Utils.getRichTooltip(null, 'Select the Users or Groups who should have access to this campaign. If this campaign should be visible for everyone just leave it at &quot;Global&quot;')"
                :max-width="315"
                :min-width="315"
                :options="availableUsersAndGroups"
                placeholder="Search for User/Group"
                :placement="'bottom-end'"
                :searchable="false"
                :show-arrow="true"
                track-by="id"
              >
                <template #option-preview="data">
                  <template v-if="data.option.value.type === 'group'">
                    <div class="group-stack">
                      <div class="avatars-stacked">
                        <template v-for="(author, index) in data.option.value.users.slice(0,5)">
                          <div
                            v-if="author"
                            :key="'author-' + index"
                            class="avatar-wrapper"
                          >
                            <avatar
                              :alt="[author.firstname, author.lastname].join(' ')"
                              :avatar="author.avatar"
                              :size="26"
                              :title="[author.firstname, author.lastname].join(' ')"
                              :tooltip="true"
                              :user-id="author.uuid"
                            />
                          </div>
                        </template>
                        <div
                          v-if="data.option.value.users.length > 4"
                          class="avatar-wrapper"
                        >
                          <span
                            v-tippy="{animateFill: false}"
                            class="avatar avatar-more"
                            :content="getMoreAuthorsTooltip(data.option.value.users)"
                            style="width: 26px; height: 26px;"
                          >
                            <span class="users-more">
                              +{{ data.option.value.users.length - 4 }}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="data.option.value.type === 'user'">
                    <avatar
                      :alt="data.option.title"
                      :avatar="data.option.value.avatar"
                      :size="22"
                      :title="data.option.title"
                      :tooltip="false"
                      :user-id="data.option.value.uuid"
                    />
                  </template>
                </template>
                <template #option-row="data">
                  <template v-if="data.option.value.type === 'group'">
                    <div class="group-stack">
                      <div class="avatars-stacked">
                        <template v-for="(author, index) in data.option.value.users.slice(0,5)">
                          <div
                            v-if="author"
                            :key="'author-' + index"
                            class="avatar-wrapper"
                          >
                            <avatar
                              :alt="[author.firstname, author.lastname].join(' ')"
                              :avatar="author.avatar"
                              :size="26"
                              :title="[author.firstname, author.lastname].join(' ')"
                              :tooltip="true"
                              :user-id="author.uuid"
                            />
                          </div>
                        </template>
                        <div
                          v-if="data.option.value.users.length > 4"
                          class="avatar-wrapper"
                        >
                          <span
                            v-tippy="{animateFill: false}"
                            class="avatar avatar-more"
                            :content="getMoreAuthorsTooltip(data.option.value.users)"
                            style="width: 26px; height: 26px;"
                          >
                            <span class="users-more">
                              +{{ data.option.value.users.length - 4 }}
                            </span>
                          </span>
                        </div>
                      </div>
                      <span class="group-name">
                        <tooltip-too-long
                          :length="10"
                          :text="data.option.title"
                        />
                      </span>
                    </div>
                  </template>
                  <template v-if="data.option.value.type === 'user'">
                    <avatar
                      :alt="data.option.title"
                      :avatar="data.option.value.avatar"
                      :size="28"
                      style="margin-right: 15px"
                      :title="data.option.title"
                      :tooltip="false"
                      :user-id="data.option.value.uuid"
                    />
                    <span class="user-name">
                      <tooltip-too-long
                        :length="14"
                        :text="data.option.title"
                      />
                    </span>
                  </template>
                </template>
                <template #placeholder>
                  <div class="d-flex align-items-center">
                    <icon name="global" />
                    <span class="ml-2">Global</span>
                  </div>
                </template>
              </rich-input-dropdown>
            </div>
            <div class="col">
              <input-dropdown
                v-model:value="activeBrand"
                :information-tooltip="Utils.getRichTooltip(null, 'For a better Overview, you can set brands for your campaigns! Add and edit brands in the Preference Section. If none is needed you can leave this here empty.')"
                label="Select brand"
                :max-width="315"
                :min-width="315"
                :options="availableBrands"
                placeholder="None"
                :placement="'bottom-end'"
              >
                <template #single-option="option">
                  <div style="margin: -2px; display: flex; align-items: center">
                    <brand
                      :brand="option.option.value"
                      :size="24"
                      style="margin-right: 10px"
                      :tooltip="false"
                    />
                    <tooltip-too-long
                      :length="24"
                      :text="option.option.title"
                    />
                  </div>
                </template>
                <template #option-row="option">
                  <brand
                    :brand="option.option.value"
                    :size="24"
                    style="margin-right: 10px"
                    :tooltip="false"
                  />
                  <tooltip-too-long
                    :length="24"
                    :text="option.option.title"
                  />
                </template>
              </input-dropdown>
            </div>
          </div>
          <choose-your-tags
            :tags="tags"
            @input="e => tags = e"
          />
        </div>
      </div>
      <div class="step-1-content">
        <h2>Target & Duration</h2>
        <h4>
          Planing is everything. If you already know how much Impressions/Clicks are targeted and the Duration of your Campaigns, you should type it in here. This will give you a better overview later in your Campaign Manager.
        </h4>
        <div class="container">
          <div class="row pt-3">
            <div class="col">
              <input-dropdown
                style="width: 315px"
                label="Target"
                class="without-highlight"
                :allow-empty="false"
                :options="availableCampaignTrackTargetTypes"
                placeholder="Select target"
                :preselect-first="true"
                :searchable="false"
                :show-labels="false"
                :min-width="315"
                :track-by="null"
                :max-width="315"
                :value="availableCampaignTrackTargetTypes.find((v) => v.value === form.trackTargetType)"
                @input="updateCampaignTrackTargetType"
              >
                <template #single-option="{ option }">
                  {{ option.name }}
                </template>
                <template #option-row="{ option }">
                  {{ option.name }}
                </template>
              </input-dropdown>
            </div>
            <div class="col">
              <text-input
                :key="computedTargetValueTooltip.length"
                v-model:value="form.targetedNumber"
                :label="computedTargetValueLabel"
                :max="9999999999999998"
                :max-width="315"
                :information-tooltip="computedTargetValueTooltip"
                :min="0"
                :min-width="315"
                :placeholder="computedTargetValuePlaceholder"
                :separators="true"
                type="number"
              />
            </div>
          </div>
          <div class="row pt-3">
            <div class="col">
              <date-range-picker
                :date-end="form.durationEnd"
                :date-start="form.durationStart"
                :information-tooltip="Utils.getRichTooltip(null, 'Here you can set the start and end point of your Campaign. If you don\'t know yet when the Campaign will start or end, you can leave that out here and edit it later when needed.')"
                :is-typeable="true"
                label="Duration"
                :max-width="645"
                :min-width="645"
                @change="changeTimeRange"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="step-2-content">
        <h1>Campaign Created Successfully!</h1>
        <h4 class="pt-2">
          Your Campaign <span class="text-campaign-name">{{ campaign ? campaign.name : form.name }}</span> has been created!
        </h4>
        <h4 class="pt-2">
          Congratulation you are all set! You can now add Creatives to your Campaign, start building kick ass Ads.
        </h4>
        <div class="wizard-actions">
          <div class="d-flex">
            <primary-button
              :disabled="campaignObject === null"
              icon="add"
              size="l"
              @click="createDesign"
            >
              Add Creative
            </primary-button>
            <secondary-button
              v-if="!campaignObject.uuid"
              class="ml-2"
              size="l"
              @click="modal.close"
            >
              I'm Done
            </secondary-button>
            <secondary-button
              v-if="campaignObject.uuid"
              class="ml-2"
              size="l"
              @click="modal.close"
            >
              Save
            </secondary-button>
          </div>
        </div>
      </div>
    </template>
    <template
      v-if="currentStep > 0"
      #actions
    >
      <base-button
        size="xl"
        @click.prevent="previousStep"
      >
        Previous
      </base-button>
      <primary-button
        v-if="currentStep < steps.length - 1"
        :disabled="isDisabledNextStepButton(currentStep)"
        size="xl"
        @click="nextStep"
      >
        <span v-if="currentStep >= 1 && !campaignObject.uuid">Create</span>
        <span v-if="currentStep >= 1 && campaignObject.uuid">Save</span>
        <span v-if="currentStep < 1">Next</span>
      </primary-button>
    </template>
    <template
      v-else
      #actions
    >
      <hr>
      <primary-button
        :disabled="form.name.length === 0"
        size="xl"
        @click="getStarted"
      >
        {{ saveCampaignButtonContent }}
      </primary-button>
    </template>
  </base-modal>
</template>

<script>
import { mapActions } from "vuex"
import { Form } from "@/classes/form"
import Language from "../../../../i18n/en"
import Utils from "../../../../utils"
import DateRangePicker from "../../DateRangePicker"
import RichInputDropdown from "../../dropdown/RichInputDropdown"
import dayjs from "dayjs"
import DesignModal from "../DesignModal"
import customParseFormat from "dayjs/plugin/customParseFormat"
import duration from "dayjs/plugin/duration"
import ChooseYourTags from "./ChooseYourTags"
import { CampaignRoutes } from "../../../../api/routes"
import InputDropdown from "@/components/common/dropdown/InputDropdown.vue"

dayjs.extend(customParseFormat)
dayjs.extend(duration)

export default {
  components: {
    InputDropdown,
    ChooseYourTags,
    RichInputDropdown,
    DateRangePicker
  },
  props: {
    activeStep: {
      type: Number,
      default: 0
    },
    campaign: {
      type: Object,
      default: null
    },
    openCampaign: {
      type: Function,
      default: () => {
      }
    },
    editCampaign: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modal: {
        close: ()=>{}
      },
      currentStep: 0,
      campaignObject: {},
      steps: [
        {
          title: "Campaign name",
          image: require("../../../../../images/modal/campaign/campaign-step-0.svg")
        },
        {
          title: "Impressions & duration",
          image: require("../../../../../images/modal/campaign/campaign-step-1.svg")
        },
        {
          title: null,
          image: require("../../../../../images/modal/campaign/campaign-step-2.svg")
        }
      ],
      selectedUsersAndGroups: [],
      activeBrand: { value: null, title: "None" },
      form: new Form({
        name: "",
        trackTargetType: "impressions",
        targetedNumber: "1000000",
        tags: [],
        usersAndGroups: [],
        brand: null,
        durationStart: dayjs().format("DD/MM/YYYY"),
        durationEnd: dayjs().add(dayjs.duration({ week: 2 })).format("DD/MM/YYYY")
      }),
      controlsInvalid: {
        campaignName: false
      },
      tags: [],
      formSubmitted: false,
      campaigns: [],
      brands: [],
      users: [],
      groups: []
    }
  },
  computed: {
    saveCampaignButtonContent () {
      return this.campaignObject?.uuid ? "Save changes" : "Get started"
    },
    computedTargetValueTooltip () {
      if (this.form.trackTargetType === "impressions") {
        return Utils.getRichTooltip(null, "Enter the planned amount of impressions for your campaign. If you don't know how many impressions will be delivered, you can leave it out and edit it at a later point.")
      }

      return Utils.getRichTooltip(null, "Enter the planned amount of clicks for your campaign. If you don't know how many clicks will be delivered, you can leave it out and edit it at a later point.")
    },
    computedTargetValueLabel () {
      if (this.form.trackTargetType === "impressions") {
        return "Target impressions"
      }

      return "Target clicks"
    },
    computedTargetValuePlaceholder () {
      if (this.form.trackTargetType === "impressions") {
        return "Enter your impressions"
      }

      return "Enter your clicks"
    },
    availableCampaignTrackTargetTypes () {
      return [
        { name: "Impressions", value: "impressions" },
        { name: "Clicks", value: "clicks" }
      ]
    },
    currentStepImage () {
      return this.steps[this.currentStep].image
    },
    maxDisplay () {
      const groups = this.selectedUsersAndGroups.filter(e => e.value.type === "group").length

      if (groups > 0) {
        return 2
      }

      return 3
    },
    availableUsersAndGroups () {
      return [
        ...(this.groups || []).map((r) => {
          r.type = "group"
          return {
            id: r.uuid,
            value: r,
            title: r.name
          }
        }),
        ...(this.users || []).map((r) => {
          r.type = "user"
          return {
            id: r.uuid,
            value: r,
            title: [r.firstname, r.lastname].join(" ")
          }
        })]
    },
    availableBrands () {
      return [...(this.brands || []).map((r) => {
        return {
          value: r,
          title: r.name
        }
      })]
    },
    Utils () {
      return Utils
    }
  },
  beforeMount () {
    if (this.campaign) {
      this.campaignObject = this.campaign
      this.prepareCampaignData()
    }
  },
  mounted () {
    this.loadUsers({performCommit: false}).then((res) => {
      this.users = res.data.data
    })
    this.loadGroups({performCommit: false}).then((res) => {
      this.groups = res.data.data
    })
    this.loadBrands({
      performCommit: false,
      limit: 100,
      page: 1,
      orderBy: "name",
      order: "ASC",
      append: false
    }).then((res) => {
      this.brands = res.data.data
    })
    this.currentStep = this.activeStep
    this.modal = this.$refs.modal
  },
  methods: {
    updateCampaignTrackTargetType ({ value }) {
      this.form.trackTargetType = value
    },
    clearControlsInvalidCampaignName () {
      this.controlsInvalid.campaignName = false
    },
    backgroundColorBrand (color) {
      return {
        "#55D8FF": "#376171",
        "#4892FF": "#334C71",
        "#6B0FE5": "#3D2569",
        "#BD74FF": "#564371",
        "#FF7E83": "#6A464B",
        "#F87335": "#674334",
        "#E59B0F": "#624F29",
        "#ffc351": "#6A5B3C",
        "#34D17E": "#2D5F4A",
        "#2CD344": "#2A5F38"
      }[color]
    },
    getMoreAuthorsTooltip (users) {
      let text = ""
      users.forEach((row) => {
        text += `<p>${row.name}</p>`
      })

      return `<div class="group-users-tooltip">${text}</div>`
    },
    prepareCampaignData () {
      this.form.name = this.campaignObject.name
      if (this.campaignObject.brand) {
        this.activeBrand = {
          title: this.campaignObject.brand.name,
          value: this.campaignObject.brand
        }
      }

      this.form.trackTargetType = this.campaignObject.trackTargetType
      this.form.targetedNumber = this.campaignObject.targetedNumber
      this.selectedUsersAndGroups = [
        ...this.campaignObject.allowedUsers.map((u) => {
          u.type = "user"
          return {
            id: u.uuid,
            title: [u.firstname, u.lastname].join(" "),
            value: u
          }
        }),
        ...this.campaignObject.allowedGroups.map((g) => {
          g.type = "group"
          return {
            id: g.uuid,
            title: g.name,
            value: g
          }
        })
      ]
      this.tags = this.campaignObject.tags.map(t => t.name)
      if (this.campaignObject.campaign_start) {
        this.form.durationStart = dayjs(this.campaignObject.campaign_start).format("DD/MM/YYYY")
      }

      if (this.campaignObject.campaign_end) {
        this.form.durationEnd = dayjs(this.campaignObject.campaign_end).format("DD/MM/YYYY")
      }
    },
    isDisabledNextStepButton (currentStep) {
      if (currentStep === 0 && this.checkCampaignName() === false) {
        return true
      }

      if (currentStep === 2 && (!this.form.durationStart || !this.form.durationEnd || !this.form.targetedNumber)) {
        return true
      }

      return false
    },
    createDesign () {
      this.modal.close()
      this.$modal.show(DesignModal, {
        campaign: this.campaignObject,
        showBackButton: true,
        openCampaign: this.openCampaign
      })
    },
    changeTimeRange (d) {
      if (d[0] > 0) {
        this.form.durationStart = d[0].toLocaleDateString("en-DE")
      }
      if (d[1] > 0) {
        this.form.durationEnd = d[1].toLocaleDateString("en-DE")
      }
    },
    changeStep (stepIndex) {
      if (stepIndex === this.currentStep) {
        return
      }
      this.formSubmitted = false
      this.currentStep = stepIndex
    },
    checkCampaignName () {
      this.controlsInvalid.campaignName = this.form.name.trim().length === 0
      return this.controlsInvalid.campaignName === false
    },
    getStarted () {
      if (this.checkCampaignName()) {
        this.nextStep()
      }
    },
    nextStep () {
      if (this.isDisabledNextStepButton(this.currentStep)) {
        return
      }

      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++
      }
      if (this.formSubmitted === false) {
        this.submit()
      }
    },
    previousStep () {
      this.formSubmitted = false
      if (this.currentStep > 0) {
        this.currentStep--
      }
    },
    submit () {
      if (this.currentStep === this.steps.length - 1) {
        this.form.usersAndGroups = this.selectedUsersAndGroups.map(e => e.id)
        this.form.tags = this.tags
        this.campaignObject = Object.assign({}, this.campaign, this.campaignObject, { name: this.form.name })

        const url = this.campaignObject.uuid ? CampaignRoutes.updateCampaign(this.campaignObject.uuid) : CampaignRoutes.createCampaign()

        if (this.activeBrand && this.activeBrand.value) {
          this.form.brand = this.activeBrand.value.uuid
        }

        this.form.targetedNumber = this.form.targetedNumber.toString()

        if (this.campaignObject.uuid) {
          return this.form
            .put(url)
            .then(this.onSuccess)
            .catch(this.onError)
            .finally(() => {
              if (!this.editCampaign) {
                this.$eventHub.$emit("load-campaigns")
              } else {
                this.$eventHub.$emit("campaign-updated", this.campaignObject)
              }
            })
        }

        return this.form
          .post(url)
          .then(this.onSuccess)
          .catch(this.onError)
          .finally(() => {
            if (!this.editCampaign) {
              this.$eventHub.$emit("load-campaigns")
            } else {
              this.$eventHub.$emit("campaign-updated", this.campaignObject)
            }
          })
      }
    },
    onSuccess (res) {
      const message = this.campaignObject && this.campaignObject.uuid ? Language.campaign.success.edit : Language.campaign.success.create
      this.campaignObject = res.data
      this.prepareCampaignData()
      this.form.name = this.campaignObject.name
      this.$toasted.success(message)
      this.formSubmitted = true
      if (!this.editCampaign) {
        this.$eventHub.$emit("new-campaign-added")
      }
    },
    onError () {
      const message = this.campaignObject && this.campaignObject.uuid ? Language.campaign.error.edit : Language.campaign.error.create
      this.$toasted.error(message)
    },
    ...mapActions(["loadGroups", "loadUsers", "loadBrands"])
  }
}
</script>
