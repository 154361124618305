<template>
  <base-input
    :border-radius="19"
    :font-size="15"
    :height="38"
    icon="search"
    :min-width="300"
    :padding-x="30"
    :padding-y="10"
    placeholder="Search..."
    :value="value"
    @active="(val, e) => $emit('active', val, e)"
    @change="(val, e) => $emit('change', val, e)"
    @input="(val, e) => $emit('input', val, e)"
  />
</template>

<script>

import BaseInput from "../form/BaseInput"

export default {
  components: {
    BaseInput
  },
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  emits: ["input", "change", "active"]
}
</script>
