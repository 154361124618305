export default {
  mounted (el, binding) {
    const { value = "hover" } = binding
    el.addEventListener("mouseenter", () => {
      el.classList.add(value)
    })
    el.addEventListener("mouseleave", () => {
      el.classList.remove(value)
    })
  },
  unmounted (el) {
    el.removeEventListener("mouseenter", null)
    el.removeEventListener("mouseleave", null)
  }
}
