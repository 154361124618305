<template>
  <div
    v-show="isActive"
    class="card card-pane tab-content p-0"
    :class="titleClass"
  >
    <div class="card-body">
      <slot />
    </div>
  </div>
</template>

<script>
import kebabCase from "lodash/kebabCase"

export default {
  name: "Tab",
  props: {
    title: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    icon: {
      type: String,
      default: "",
      required: false
    },
    // eslint-disable-next-line vue/no-unused-properties
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    titleClass () {
      return "tab-" + kebabCase(this.title) + "-content"
    }
  }
}
</script>
