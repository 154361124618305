import actions from "./actions"
import getters from "./getters"
import mutations from "./mutations"
import state from "./state"
import { undoRedoPlugin } from "./plugins/state"
import { createStore } from "vuex"

const store = createStore({
  plugins: [undoRedoPlugin],
  state,
  actions,
  getters,
  mutations,
  strict: false
})

export default store
export const registerStore = (app) => {
  app.use(store)
}
