export const AssetModuleType = "assetModule"
export const VideoControlsModuleType = "videoControlsModule"
export const ShakeModuleType = "shakeModule"
export const BasketModuleType = "basketModule"
export const BackgroundModuleType = "backgroundModule"
export const SlidebarModuleType = "slidebarModule"
export const TypoModuleType = "typoModule"
export const PopupModuleType = "popupModule"
export const GestureModuleType = "gestureModule"
export const VideoModuleType = "videoModule"
export const SwiperGroupModuleType = "swiperGroupModule"
export const LightweightSwiperGroupModuleType = "lightweightSwiperGroupModule"
export const PanoModuleType = "panoModule"
export const DestinationModuleType = "destinationModule"
export const CalendarModuleType = "calendarModule"
export const AssetGroupModuleType = "assetGroupModule"
export const WipeAdModuleType = "wipeAdModule"
export const WipeAdBrushModuleType = "wipeAdBrushModule"
export const ParticleWipeAdModuleType = "particleWipeAdModule"
export const WhatsappModuleType = "whatsappModule"
export const Click2MapsModuleType = "click2MapsModule"
export const SurveyModuleType = "surveyModule"
export const SurveySliderModuleType = "surveySliderModule"
export const PollModuleType = "pollModule"
export const PollSliderModuleType = "pollSliderModule"
export const OfferistaModuleType = "offeristaModule"
export const OfferistaAssetModuleType = "offeristaAssetModule"
export const OfferistaBannerModuleType = "offeristaBannerModule"
export const VideoStoryModuleType = "videoStoryModule"
export const StoryModuleType = "storyModule"
export const CountdownModuleType = "countdownModule"
export const HotOrNotModuleType = "hotOrNotModule"
export const WagawinPrivacyModuleType = "wagawinPrivacyModule"
export const WagawinVideoPollModuleType = "wagawinVideoPollModule"
export const GoogleMapsModuleType = "googleMapsModule"
export const TimerModuleType = "timerModule"
export const PuzzleModuleType = "puzzleModule"
export const MemoryModuleType = "memoryModule"
export const SliderModuleType = "sliderModule"
export const ImageSequenceModuleType = "imageSequenceModule"
export const MatchModuleType = "matchModule"
export const VastVideoModuleType = "vastVideoModule"
export const OfferistaFlyerAssetModuleType = "offeristaFlyerAssetModule"
export const CounterModuleType = "counterModule"
