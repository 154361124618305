export class Routes {
  // GET
  static getAvailableProducts = (dataBindingUuid) => `/api/offerista/product/${dataBindingUuid}`
  static getAvailableCategories = (dataBindingUuid) => `/api/offerista/category/${dataBindingUuid}`
  static getAvailableBanners = (dataBindingUuid) => `/api/offerista/banner/${dataBindingUuid}`
  static updateDataFetching = (designUuid) => `/api/offerista/data-fetching/${designUuid}`
  static fetchOfferistaBanners = (bannerDataBindingId) => `/api/offerista/banner/${bannerDataBindingId}`

  // POST
  static queueOfferistaDataFetchingJob = (designUuid) => `/api/offerista/${designUuid}/fetch`
  static saveOfferistaCredentials = (companyUuid) => `/api/offerista/credential/${companyUuid}`
}
