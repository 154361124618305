<template>
  <div class="search">
    <search-input
      :value="searchQuery"
      @active="changeDisplayStatus"
      @input="(value, e) => updateSearchQuery(value, e)"
    />
    <component
      :is="popupComponent"
      v-if="displaySearchResults && searchQuery.length"
      :data="searchResult"
      :query="searchQuery"
    />
  </div>
</template>

<script>
import Input from "./Input"
import { mapState } from "vuex"

export default {
  components: {
    "search-input": Input
  },
  props: {
    popupComponent: {
      type: String,
      required: true
    }
  },
  emits: ["change"],
  data () {
    return {
      data: [],
      searchQuery: "",
      displaySearchResults: false
    }
  },
  computed: {
    ...mapState(["searchResult"])
  },
  created () {
    this.$eventHub.$on("hide-search", () => {
      this.displaySearchResults = false
    })
  },
  methods: {
    changeDisplayStatus () {
      this.displaySearchResults = this.searchQuery.length > 0
    },
    updateSearchQuery (value, e) {
      this.searchQuery = value
      this.displaySearchResults = this.searchQuery.length > 0
      this.$emit("change", e.target.value)
    }
  }
}
</script>
