<template>
  <div class="profile-wrapper">
    <h1>Settings</h1>
    <tabs
      v-if="isUserLoaded"
      class="tabs--aligned"
      :init-active-tab="activeTab"
      @change-tab="changeTab"
    >
      <template #content>
        <tab
          :is-active="activeTab==='personal info'"
          title="Personal Info"
        >
          <personal-info />
        </tab>
        <tab
          :is-active="activeTab==='account settings'"
          title="Account Settings"
        >
          <account-settings />
        </tab>
        <tab
          :is-active="activeTab==='notifications'"
          title="Notifications"
        >
          <notification-settings ref="notificationSettings" />
        </tab>
      </template>
    </tabs>
  </div>
</template>
<script>
import PersonalInfo from "./PersonalInfo"
import AccountSettings from "./AccountSettings"
import NotificationSettings from "./NotificationSettings"
import { mapState } from "vuex"

export default {
  name: "ProfileWrapper",
  components: {
    NotificationSettings,
    AccountSettings,
    PersonalInfo
  },
  data () {
    return {
      activeTab: "personal info",
      isUserLoaded: false
    }
  },
  computed: {
    ...mapState(["currentUser"])
  },
  created () {
    if (this.currentUser) {
      this.isUserLoaded = true
    } else {
      const unwatch = this.$watch(
        "currentUser",
        function () {
          this.isUserLoaded = true
          if (unwatch) {
            unwatch()
          }
        }
      )
    }
  },
  methods: {
    changeTab (tab) {
      const title = tab.title.toLowerCase()

      this.activeTab = title

      if (title === "notifications") {
        this.$refs.notificationSettings.initData()
      }
    }
  }
}
</script>
