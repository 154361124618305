<template>
  <div class="chart-date-picker">
    <a
      class="chart-date-picker__prev"
      :class="prevDisabled ? 'disabled' : ''"
      href="#"
      @click.prevent="!prevDisabled ? $emit('prev') : ''"
    >
      <icon name="arrow-left" />
    </a>
    <a
      class="chart-date-picker__current"
      href="#"
      @click.prevent="$emit('current')"
    >
      {{ date }}
    </a>
    <a
      class="chart-date-picker__next"
      :class="nextDisabled ? 'disabled' : ''"
      href="#"
      @click.prevent="!nextDisabled ? $emit('next') : ''"
    >
      <icon name="arrow-right" />
    </a>
  </div>
</template>
<script>
export default {
  name: "ChartDatePicker",
  props: {
    date: {
      type: String,
      required: true
    },
    prevDisabled: {
      type: Boolean,
      default: false
    },
    nextDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["prev", "current", "next"]
}
</script>
