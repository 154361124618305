import { v4 as uuidv4 } from "uuid"
import { addAsset } from "./common"

export const addCloseButton = () => {
  const closeButtonUuid = uuidv4()

  const preview = {
    alwaysOnTop: true
  }

  const styles = {
    "background-size": "contain"
  }

  const options = {
    clickable: true,
    preview,
    styles
  }

  addAsset(closeButtonUuid, "x_close.png", options)

  window.postMessage({
    type: "setModuleDimensions",
    params: {
      moduleId: closeButtonUuid,
      parentModuleId: null,
      w: 30,
      h: 30
    }
  }, "*")

  window.postMessage({
    type: "moveModule",
    params: {
      moduleId: closeButtonUuid,
      x: "290px",
      y: "0px"
    }
  }, "*")

  return closeButtonUuid
}

const changeScene = (idx) => {

  window.postMessage({
    type: "setCurrentScene",
    params: {
      sceneIdx: idx
    }
  }, "*")

  return null

}

export const ExpandableBanner = () => {
  window.postMessage({ type: "clear" }, "*")
  changeScene(1)
  addCloseButton()

  window.postMessage({ type: "run" }, "*")

}
