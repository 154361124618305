<template>
  <div class="device-sizings mobile">
    <sizing-entry
      v-for="entry in entries"
      :key="'adformat-mobile-' + entry.type"
      :active="modelValue && entry.type === modelValue.type"
      :sizing="getSizing(entry)"
      :title="entry.title"
      :type="entry.type"
      @click="chooseEntry(entry.type)"
    />
  </div>
</template>
<script>
import SizingEntry from "./SizingEntry"
import Utils from "../../../utils.js"
import { AD_FORMATS, EXPANDABLE_BANNER_FORMATS } from "@/constants"

export default {
  components: {
    SizingEntry
  },
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    availableFeatures: {
      type: Array,
      default: () => []
    }
  },
  emits: ["input", "update:modelValue"],
  computed: {
    entries () {
      const mobileSizings = Utils.getMobileSizings().filter((row) => row.type !== AD_FORMATS.interscroller)

      if (!this.availableFeatures.includes("fe:polishcompany")) {
        return mobileSizings.filter((row) => row.type !== AD_FORMATS.interstitial_320x568)
      }

      return mobileSizings
    }
  },
  methods: {
    getSizing (entry) {
      if (EXPANDABLE_BANNER_FORMATS.includes(entry.type)) {
        return entry.bannerSizing
      }

      return entry.sizing
    },
    chooseEntry (entryType) {
      this.$emit("input", this.entries.find(e => e.type === entryType))
      this.$emit("update:modelValue", this.entries.find(e => e.type === entryType))
    }
  }
}
</script>
