<template>
  <div class="device-sizings desktop">
    <sizing-entry
      v-for="entry in entries"
      :key="'adformat-desktop-' + entry.type"
      :active="modelValue && entry.type === modelValue.type"
      :sizing="entry.sizing"
      :title="entry.title"
      :type="entry.type"
      @click="chooseEntry(entry.type)"
    />
  </div>
</template>
<script>
import SizingEntry from "./SizingEntry"
import Utils from "../../../utils"
import { AD_FORMATS } from "@/constants"

export default {
  components: {
    SizingEntry
  },
  props: {
    modelValue: {
      type: Object,
      default: null
    }
  },
  emits: ["input", "update:modelValue"],
  computed: {
    entries () {
      const sizings = Utils.getDesktopSizings()

      return sizings.filter(s => s.type !== AD_FORMATS.desktop_dynamic_sidebar)
    }
  },
  methods: {
    chooseEntry (entryType) {
      this.$emit("input", this.entries.find(e => e.type === entryType))
      this.$emit("update:modelValue", this.entries.find(e => e.type === entryType))
    }
  }
}
</script>
