<template>
  <popup class="search-popup campaign-popup">
    <card>
      <card-body>
        <h3>Filter matching "{{ query }}"</h3>
        <div class="search-result">
          <a
            v-for="campaign in data.campaigns"
            :key="campaign.uuid"
            class="search-tag"
            href="#"
            @click.prevent.stop="selectFilter('campaign', campaign.name, campaign.uuid)"
          >
            <span :class="selectedFilters.campaign.filter(row => row.value === campaign.uuid).length ? 'active' : ''">{{
              campaign.name
            }}</span>
          </a>
          <hr
            v-if="data.tags && data.tags.length > 0"
            class="sep"
          />
          <a
            v-for="tag in data.tags"
            :key="tag.uuid"
            class="search-tag"
            href="#"
            @click.prevent.stop="selectFilter('tag', tag.name, tag.uuid)"
          >
            <span :class="selectedFilters.tag.filter(row => row.value === tag.uuid).length ? 'active' : ''">{{
              tag.name
            }}</span>
          </a>
          <hr
            v-if="availableStatuses.filter(s => s.name.toLowerCase().startsWith(query.toLowerCase())).length > 0"
            class="sep"
          />
          <div class="statuses">
            <div
              v-for="status in availableStatuses.filter(s => s.name.toLowerCase().startsWith(query.toLowerCase()))"
              :key="status.value"
              class="status-row"
            >
              <a
                class="search-tag"
                href="#"
                @click.prevent.stop="selectFilter('status', status.name, status.value)"
              >
                <design-status :status="status.value" />
              </a>
            </div>
          </div>
          <hr
            v-if="data.brands && data.brands.length > 0"
            class="sep"
          />
          <div class="brands">
            <div
              v-for="brand in data.brands"
              :key="brand.id"
            >
              <a
                class="search-tag"
                href="#"
                @click.prevent.stop="selectFilter('brand', brand.name, brand.uuid)"
              >
                <brand
                  :brand="brand"
                  class="campaign-brand"
                  :size="26"
                />
                <span :class="selectedFilters.brand.filter(row => row.value === brand.uuid).length ? 'active' : ''">{{
                  brand.name
                }}</span>
              </a>
            </div>
          </div>
        </div>
      </card-body>
    </card>
  </popup>
</template>

<script>
import Utils from "../../../../utils"
import { mapState } from "vuex"
import DesignStatus from "../../design/DesignStatus"
import {
  DESIGN_PROGRESS_DELIVERED,
  DESIGN_PROGRESS_DESIGN,
  DESIGN_PROGRESS_LIVE,
  DESIGN_PROGRESS_READY,
  DESIGN_PROGRESS_REVIEW
} from "@/constants"

export default {
  components: {
    DesignStatus
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    query: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      availableStatuses: [
        { name: this.capitalize(DESIGN_PROGRESS_DESIGN), value: DESIGN_PROGRESS_DESIGN },
        { name: this.capitalize(DESIGN_PROGRESS_REVIEW), value: DESIGN_PROGRESS_REVIEW },
        { name: this.capitalize(DESIGN_PROGRESS_READY), value: DESIGN_PROGRESS_READY },
        { name: this.capitalize(DESIGN_PROGRESS_LIVE), value: DESIGN_PROGRESS_LIVE },
        { name: this.capitalize(DESIGN_PROGRESS_DELIVERED), value: DESIGN_PROGRESS_DELIVERED }
      ]
    }
  },
  computed: {
    ...mapState(["selectedFilters"]),
    utils () {
      return Utils
    }
  },
  methods: {
    capitalize (s) {
      return s[0].toUpperCase() + s.slice(1)
    },
    getCreativeCount (campaign) {
      const count = campaign.creative_count
      if (count === 1) {
        return count + " creative"
      }

      return count + " creatives"
    },
    selectFilter (type, name, value) {
      if (this.selectedFilters[type].find((row) => row.value === value)) {
        this.$store.commit("deleteFilter", { type, value })
      } else {
        this.$store.commit("selectFilter", { name, type, value })
      }
    }
  }
}
</script>
