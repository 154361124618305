<template>
  <div class="d-flex align-items-center">
    <icon :name="icon" />
    <div
      class="content"
    >
      <h3>{{ title }}</h3>
      <p v-html="sanitizedContent"></p>
    </div>
  </div>
</template>

<script>
import Icon from "../Icon.vue"
import sanitizeHtml from "sanitize-html"

export default {
  name: "CommonToast",
  components: {
    Icon
  },
  props: {
    title: {
      type: String,
      default: "Success"
    },
    content: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: "success-notification"
    }
  },
  computed: {
    sanitizedContent () {
      return sanitizeHtml(this.content, {
        allowedTags: ["br"]
      })
    }
  }
}
</script>
