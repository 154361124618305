<template>
  <div
    class="impressions-chart"
    :style="{width: size + 'px', height: size + 'px'}"
  >
    <canvas
      ref="canvas"
      :height="size"
      :width="size"
    ></canvas>
  </div>
</template>
<script>
import Chart from "chart.js/auto"

export default {
  name: "CircleChart",
  props: {
    size: {
      type: Number,
      default: 200
    },
    value: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    fillColor: {
      type: String,
      default: "#373b3e"
    },
    progressColor: {
      type: String,
      default: "#a2c100"
    }
  },
  created () {
    this.$eventHub.$on("update-circle-charts", this.createCircleChart)
  },
  beforeUnmount () {
    this.$eventHub.$off("update-circle-charts", this.createCircleChart)
  },
  mounted () {
    this.createChart(this.$refs.canvas ? this.$refs.canvas.getContext("2d") : null)
  },
  methods: {
    createCircleChart () {
      this.createChart(this.$refs.canvas ? this.$refs.canvas.getContext("2d") : null)
    },
    buildDatasets () {
      return [{
        label: false,
        data: [
          this.value,
          this.value === 0 && this.max === 0 ? 100 : Math.max(0, this.max - this.value)
        ],
        backgroundColor: [
          this.progressColor,
          this.fillColor
        ],
        borderWidth: 0
      }]
    },
    createChart (ctx) {
      if (ctx) {
        // eslint-disable-next-line no-new
        new Chart(ctx, {
          type: "doughnut",
          data: {
            datasets: this.buildDatasets()
          },
          options: {
            cutout: "85%",
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                enabled: false
              }
            }
          }
        })
      }
    }
  }
}
</script>
