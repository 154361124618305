<template>
  <div class="user-list">
    <filters v-if="filtersToggled" />
    <advanced-table
      v-if="areThereAnyUsers"
      :columns="['User', 'Role', 'Created', 'Status', '']"
      :current-page="currentPage"
      :data="filteredUsers"
      :last-page="lastPage"
      @load-next-page="changePage(currentPage + 1, 20, true)"
    >
      <template #default="{row}">
        <td>
          <avatar
            :alt="[row.firstname, row.lastname].join(' ')"
            :avatar="row.avatar"
            :size="38"
            :title="[row.firstname, row.lastname].join(' ')"
            :tooltip="true"
            :user-id="row.uuid"
          />
          <span class="user-name">
            {{ row.firstname }} {{ row.lastname }}
          </span>
        </td>
        <td>
          <div
            v-if="row.role"
            class="user-badge"
          >
            {{ availableRoles.find((r) => r.value === row.role).name }}
          </div>
        </td>
        <td>
          <div class="user-date">
            <span
              v-tippy="{arrow: true}"
              :content="Utils.parseDate(row.created_at, false, 'D MMM YYYY')"
            >
              {{ Utils.timeAgoFormatter(row.created_at) }}
            </span>
            <span
              v-if="row.last_logged"
              class="user-date__last-logged"
            >
              last logged in {{ Utils.timeAgoFormatter(row.last_logged) }}
            </span>
          </div>
        </td>
        <td>
          <span
            v-if="row.active"
            class="user-status user-status--active"
          >Active</span>
          <span
            v-if="!row.active"
            class="user-status user-status--inactive"
          >Inactive</span>
        </td>
        <td class="action-row">
          <div
            class="dropdown-holder"
          >
            <context-dropdown
              :min-width="182"
              :options="getRowActions(row)"
              :placement="'bottom-end'"
            />
          </div>
        </td>
      </template>
    </advanced-table>
    <empty-section
      v-else
      :icon="require('../../../../images/modal/invite-user-infographics.svg')"
    >
      There are no Users yet. Start by creating new one!
    </empty-section>
  </div>
</template>
<script>
import { mapState } from "vuex"
import Utils from "../../../utils"
import ContextDropdown from "../../common/dropdown/ContextDropdown"
import Language from "../../../i18n/en"
import Filters from "./Filters"
import EditUserModal from "../../modals/EditUserModal"
import ConfirmModal from "../../common/modal/ConfirmModal"
import TransferModal from "../../common/modal/TransferModal"
import { AdminRoutes } from "../../../api/routes"
import EmptySection from "../../dashboard/components/EmptySection"

export default {
  name: "AdminUserList",
  components: {
    ContextDropdown,
    Filters,
    EmptySection
  },
  data () {
    return {
      currentPage: 1,
      lastPage: 1,
      dropdownActive: false,
      filtersToggled: false,
      currentSort: "id",
      currentSortDir: "DESC",
      users: []
    }
  },
  computed: {
    Utils () {
      return Utils
    },
    areThereAnyUsers () {
      return this.filteredUsers?.length
    },
    filteredUsers () {
      const uuids = this.selectedFilters.user.map((r) => r.value.uuid)
      return this.users.filter(u => uuids.length === 0 || uuids.includes(u.uuid))
    },
    ...mapState(["selectedFilters", "availableRoles", "currentUser"])
  },
  watch: {
    selectedFilters: {
      handler: "fetchUsers",
      deep: true
    }
  },
  created () {
    this.$eventHub.$on("toggle-filters", (section) => {
      if (section === "user-list-filters") {
        this.filtersToggled = !this.filtersToggled
      }
    })
  },
  mounted () {
    this.$eventHub.$on("load-users", this.fetchUsers)
    this.fetchUsers()
  },
  beforeUnmount () {
    this.$eventHub.$off("load-users", this.fetchUsers)
  },
  methods: {
    setDropdownActive (uuid) {
      this.dropdownActive = this.dropdownActive ? null : uuid
    },
    hideDropdown () {
      this.dropdownActive = null
    },
    changePage (page = 1, limit = 20, append = false) {
      this.currentPage = page
      this.fetchUsers(page, limit, this.currentSort, this.currentSortDir, append)
    },
    fetchUsers (page = 1, limit = 20, sortBy = "id", sortWay = "DESC", append = false) {
      const filters = {
        role: this.selectedFilters && this.selectedFilters.role ? this.selectedFilters.role.map((row) => row.value) : []
      }
      return this.$store.dispatch("loadUsers", {
        limit,
        page,
        orderBy: sortBy,
        order: sortWay,
        filters,
        performCommit: false
      }).then((resp) => {
        this.users = append ? this.users.concat(resp.data.data) : resp.data.data
        this.currentPage = resp.data.meta?.current_page || page
        this.lastPage = resp.data.meta?.last_page || page
      })
    },
    deleteUser ($event, userUuid) {
      $event.preventDefault()
      this.$modal.show(TransferModal, {
        user: this.users.find(u => u.uuid === userUuid),
        users: this.users,
        afterDelete: () => {
          this.fetchUsers()
        }
      })
    },
    openEditUserModal ($event, userUuid) {
      $event.preventDefault()
      const user = this.users.find(user => {
        return user.uuid === userUuid
      })

      this.$store.dispatch("setUserEdit", user)
      this.$modal.show(EditUserModal)
    },
    toggleUserActivity ($event, row, active) {
      $event.preventDefault()
      const confirmAction = () => {
        return this.$store.dispatch("toggleUserActivity", {
          id: row.uuid,
          active
        })
          .then(() => {
            this.$toasted.success(Language.users.success.edit)
            this.fetchUsers()
          })
          .catch(() => {
            this.$toasted.error(Language.users.error.edit)
          })
      }

      const name = row.firstname + " " + row.lastname
      this.$modal.show(ConfirmModal, {
        actionButton: active ? "Activate" : "Deactivate",
        title: active ? "Activate User" : "Deactivate User",
        isWarning: !active,
        description: active ? `Are you sure that you want to Activate ${name}` : `Are you sure that you want to Deactivate ${name}`,
        confirm: confirmAction
      })
    },
    resendInvitation ($event, id) {
      $event.preventDefault()
      return this.$apiClient.post(AdminRoutes.resendUserInvitation(id)).then(() => {
        this.$toasted.success(Language.users.success.invitation)
      }).catch(() => {
        this.$toasted.error(Language.users.error.invitation)
      })
    },
    getRowActions (row) {
      const rolesHierarchy = {
        super_admin: 4,
        admin: 3,
        manager: 2,
        user: 1
      }
      const currentUserRoles = Object.entries(this.currentUser.roles).filter(e => e[1]).map(e => e[0])
      const rowUserRoles = Object.entries(row.roles).filter(e => e[1]).map(e => e[0])
      const currentUserHigherHierarchy = Math.max(...currentUserRoles.map(r => rolesHierarchy[r])) > Math.max(...rowUserRoles.map(r => rolesHierarchy[r]))
      const actions = []
      actions.push({
        content: "Edit",
        icon: "edit",
        href: "/admin/users/" + row.uuid,
        click: ($event) => this.openEditUserModal($event, row.uuid)
      })
      if ((this.currentUser.roles.super_admin || this.currentUser.roles.admin) && currentUserHigherHierarchy) {
        if (row.active) {
          actions.push({
            content: "Deactivate",
            icon: "deactivate",
            href: "/admin/users/" + row.uuid,
            click: ($event) => this.toggleUserActivity($event, row, false)
          })
        } else {
          actions.push({
            content: "Activate",
            icon: "deactivate",
            href: "/admin/users/" + row.uuid,
            click: ($event) => this.toggleUserActivity($event, row, true)
          })
        }
      }
      actions.push({
        content: "Resend invitation",
        icon: "resend-invitation",
        href: "/admin/users/" + row.uuid,
        click: ($event) => this.resendInvitation($event, row.uuid)
      })

      if (this.currentUser.roles.super_admin || currentUserHigherHierarchy) {
        actions.push({
          content: "Delete",
          icon: "delete",
          click: ($event) => this.deleteUser($event, row.uuid)
        })
      }

      return actions
    }
  }
}
</script>
