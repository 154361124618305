<template>
  <form
    enctype="multipart/form-data"
    method="post"
    @keydown="form.errors.clear($event.target.name)"
    @submit.prevent="onSubmit"
  >
    <div
      v-if="form.success"
      class="alert alert-success"
    >
      You've been successfully registered
    </div>
    <div class="panel-body">
      <div class="form-group">
        <div class="row">
          <div class="col">
            <label for="name"> Name<span class="required">*</span> </label>
            <input
              id="name"
              v-model="form.name"
              class="form-control"
              :class="{ 'is-invalid': form.errors.has('name') }"
              name="name"
              placeholder="Name"
              type="text"
            />
            <div
              v-if="form.errors.has('name')"
              class="invalid-feedback"
              v-text="form.errors.get('name')"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col">
            <label for="firstname">
              First name<span class="required">*</span>
            </label>
            <input
              id="firstname"
              v-model="form.firstname"
              class="form-control"
              :class="{ 'is-invalid': form.errors.has('firstname') }"
              name="firstname"
              placeholder="First name"
              type="text"
            />
            <div
              v-if="form.errors.has('firstname')"
              class="invalid-feedback"
              v-text="form.errors.get('firstname')"
            />
          </div>
          <div class="col">
            <label for="lastname">
              Last name<span class="required">*</span>
            </label>
            <input
              id="lastname"
              v-model="form.lastname"
              class="form-control"
              :class="{ 'is-invalid': form.errors.has('lastname') }"
              name="lastname"
              placeholder="Last name"
              type="text"
            />
            <div
              v-if="form.errors.has('lastname')"
              class="invalid-feedback"
              v-text="form.errors.get('lastname')"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="lastname"> Email<span class="required">*</span> </label>
        <input
          id="email"
          v-model="form.email"
          class="form-control"
          :class="{ 'is-invalid': form.errors.has('email') }"
          name="email"
          placeholder="Email"
          type="email"
        />
        <div
          v-if="form.errors.has('email')"
          class="invalid-feedback"
          v-text="form.errors.get('email')"
        />
      </div>
      <div class="form-group position-relative">
        <label for="lastname">
          Password<span class="required">*</span><i
            class="showPassword fas"
            :class="{
              'fa-eye-slash': !showPassword,
              'fa-eye': showPassword
            }"
            @click="toggleShowPassword"
          />
        </label>
        <input
          id="password"
          v-model="form.password"
          class="form-control"
          :class="{ 'is-invalid': form.errors.has('password') }"
          name="password"
          placeholder="Password"
          :type="showPassword ? 'text' : 'password'"
        />
        <div
          v-if="form.errors.has('password')"
          class="invalid-feedback"
          v-text="form.errors.get('password')"
        />
      </div>
      <div class="form-group position-relative">
        <label for="password_confirmation">
          Password confirmation<span class="required">*</span>
        </label>
        <input
          id="password_confirmation"
          v-model="form.password_confirmation"
          class="form-control"
          :class="{
            'is-invalid': form.errors.has('password_confirmation')
          }"
          name="password_confirmation"
          placeholder="Confirm password"
          :type="showPassword ? 'text' : 'password'"
        />
        <div
          v-if="form.errors.has('password_confirmation')"
          class="invalid-feedback"
          v-text="form.errors.get('password_confirmation')"
        />
      </div>
    </div>
    <input
      name="submitted"
      type="hidden"
      value="1"
    />
    <button
      class="btn btn-primary mt-4"
      :disabled="form.errors.any()"
      type="submit"
    >
      Create
    </button>
  </form>
</template>
<script>
import { Form } from "@/classes/form"
import Language from "../../i18n/en"

export default {
  name: "UserRegister",
  props: {
    invitation: {
      type: Object,
      default: Object.assign({})
    }
  },
  data () {
    return {
      availableRoles: [],
      form: new Form({
        name: "",
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        password_confirmation: "",
        role: "",
        invitation: ""
      }),
      showPassword: false
    }
  },
  mounted () {
    this.form.invitation = this.invitation
  },
  methods: {
    toggleShowPassword () {
      this.showPassword = !this.showPassword
    },
    onSubmit () {
      return this.form
        .post("/register")
        .then(this.onSuccess)
        .catch(() => {
          this.$toasted.error(Language.users.error.create)
        })
    },
    onSuccess () {
      window.location.href = "/"
      this.$toasted.success(Language.users.success.create)
    }
  }
}
</script>
