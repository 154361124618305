<template>
  <popup class="search-popup users-popup">
    <card>
      <card-body>
        <h3>Filter matching "{{ query }}"</h3>
        <div class="search-result ">
          <div
            v-for="role in matchingRoles"
            :key="'role_' + role.value"
          >
            <a
              class="search-tag roles"
              href="#"
              @click.prevent.stop="selectFilter(role.name, role.value, 'role')"
            >
              <span
                class="user-badge"
                :class="selectedFilters.role.filter(row => row.value === role.value).length ? 'active' : ''"
              >{{ role.name }}</span>
            </a>
          </div>
          <hr v-if="matchingUsers.length > 0">
          <div
            v-for="user in matchingUsers"
            :key="'user_search_' + user.uuid"
          >
            <a
              class="search-tag"
              href="#"
              @click.prevent.stop="selectFilter([user.firstname, user.lastname].join(' '), user, 'user')"
            >
              <span :class="selectedFilters.user.filter(row => row.value.uuid === user.uuid).length ? 'active' : ''">
                <avatar
                  v-if="user"
                  :alt="[user.firstname, user.lastname].join(' ')"
                  :avatar="user.avatar"
                  :size="26"
                  :title="[user.firstname, user.lastname].join(' ')"
                  :tooltip="true"
                  :user-id="user.uuid"
                />
                {{ [user.firstname, user.lastname].join(' ') }}
              </span>
            </a>
          </div>
        </div>
      </card-body>
    </card>
  </popup>
</template>

<script>
import Utils from "../../../../utils"
import { mapState } from "vuex"

export default {
  props: {
    query: {
      type: String,
      default: ""
    }
  },
  computed: {
    matchingUsers () {
      return this.users.filter(e => [e.firstname, e.lastname].join(" ").match(new RegExp("(.*?)" + this.query + "(.*?)", "i")))
    },
    matchingRoles () {
      return this.availableRoles.filter(role => role.name.match(new RegExp("(.*?)" + this.query + "(.*?)", "i")))
    },
    utils () {
      return Utils
    },
    ...mapState(["selectedFilters", "availableRoles", "users"])
  },
  methods: {
    selectFilter (name, value, type) {
      const deleteFilter = type === "role" ? this.selectedFilters.role.find((row) => row.value === value) : this.selectedFilters.user.find((row) => row.value.uuid === value.uuid)
      if (deleteFilter) {
        this.$store.commit("deleteFilter", { type, value })
      } else {
        this.$store.commit("selectFilter", { name, type, value })
      }
    }
  }
}
</script>
