<template>
  <base-modal
    ref="modal"
    class="comprehensive-report-modal"
    :image="require('../../../../images/modal/edit-features-graphics.svg')"
    :scrollable="true"
    :show-close-button="false"
    :title="title"
    @submit="generateReport"
  >
    <template #content>
      <div class="container">
        <div class="row d-flex">
          <div class="col">
            <div class="form-group">
              <date-range-picker
                ref="reporting-period-datepicker"
                :calendar-count="1"
                icon="calendar"
                label="Reporting period"
                :max-width="510"
                :min-width="510"
                type="month"
                @change="changeTimeRange"
              />
            </div>
          </div>
        </div>
        <div
          v-if="campaigns.length"
          class="row d-flex"
        >
          <div class="col">
            <div class="form-group">
              <rich-input-dropdown
                v-model:value="selectedCampaigns"
                :height="290"
                icon="search"
                :clear-on-select="false"
                label="Select campaign"
                :display-tags="false"
                :max-displayed="null"
                :max-height="235"
                :options="campaigns"
                placeholder="Search"
                :single="false"
                track-by="uuid"
                track-label="name"
                :should-add-select-all-option="true"
              >
                <template #single-option-preview></template>
                <template #option-row="data">
                  <span>{{ data.option.name === 'Select all' ? selectedCampaigns.length===campaigns.length ?'Hide all' : 'Select all' : data.option.name }}</span>
                </template>
              </rich-input-dropdown>
            </div>
          </div>
        </div>
        <empty-section
          v-else
          :icon="require('@images/dashboard/designer/campaigns-empty-state.svg')"
        >
          Select a reporting period.
        </empty-section>
      </div>
    </template>
    <template #actions>
      <div class="container">
        <div class="row d-flex justify-content-sm-between align-content-center">
          <div class="column d-flex  justify-content-center align-content-center">
          </div>
          <div class="column">
            <div class="row">
              <base-button
                size="xl"
                @click.prevent="modal.close"
              >
                Cancel
              </base-button>
              <primary-button
                size="xl"
                @click="generateReport"
              >
                Generate
              </primary-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions, mapMutations } from "vuex"
import Language from "../../../i18n/en"
import RichInputDropdown from "../dropdown/RichInputDropdown"
import Utils from "../../../utils"
import { AnalyticsRoutes } from "../../../api/routes"
import DateRangePicker from "@/components/common/DateRangePicker.vue"
import dayjs from "dayjs"
import EmptySection from "@/components/dashboard/components/EmptySection.vue"

export default {
  components: {
    EmptySection,
    DateRangePicker,
    RichInputDropdown
  },
  props: {
    title: {
      type: String,
      default: "Comprehensive report"
    },
    action: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      },
      campaigns: [],
      selectedCampaigns: [],
      startDate: null,
      endDate: null,
      count: 0,
      currentPage: 1,
      lastPage: 10
    }
  },
  mounted () {
    this.modal = this.$refs.modal
  },
  methods: {
    generateReport () {
      this.setLoadingStatus(true)

      const URL = AnalyticsRoutes.getComprehensiveReport()
      this.$apiClient.get(URL, {
        params: {
          campaignUuids: this.selectedCampaigns.map(c => c.uuid),
          start_date: this.startDate,
          end_date: this.endDate
        }
      })
        .then((resp) => {
          this.onSuccess()
          Utils.downloadFileFromResponse(resp)
        })
        .catch(()=>{
          this.onError()
        })
        .finally(() => {
          this.modal.close()
          this.setLoadingStatus(false)
        })
    },
    fetchData (page = 1, limit = 40, sortBy = "last_edit", sortWay = "DESC", concat = true) {
      const loadCampaigns = this.fetchCampaigns({
        limit,
        page,
        orderBy: sortBy,
        order: sortWay,
        filters: {
          start_date: this.startDate,
          end_date: this.endDate,
          hosted: true
        },
        concat
      })

      loadCampaigns.then((resp) => {
        this.count = resp.meta.total
        this.currentPage = resp.meta.current_page
        this.lastPage = resp.meta.last_page

        const campaigns = resp.data

        if (concat) {
          this.campaigns = Utils.removeDuplicatesByKey([...this.campaigns, ...campaigns], "uuid")
        } else {
          this.campaigns = campaigns
        }
      })
    },
    changeTimeRange (data) {
      if (!data.length) {
        return
      }

      const start = dayjs(data[0]).format("YYYY-MM-DD")
      const end = dayjs(data[1]).add(1, this.calendarType).subtract(1, "day").format("YYYY-MM-DD")

      this.startDate = start
      this.endDate = end

      this.$refs["reporting-period-datepicker"].closeCalendar()

      this.selectedCampaigns = []
      this.fetchData()
    },
    onSuccess () {
      this.$toasted.success(Language.analytics.success.automatic_report_saved)
      if (this.action) {
        this.action()
      }
    },
    onError () {
      this.$toasted.error(Language.analytics.error.automatic_report_saved)
    },
    ...mapActions(["fetchCampaigns"]),
    ...mapMutations(["setLoadingStatus"])
  }
}
</script>
