<template>
  <div class="preferences">
    <h2>Preferences</h2>
    <tabs
      :key="canEditShowroomSettings"
      class="clean no-bg tabs--aligned"
      :init-active-tab="activeTab"
      @change-tab="changeTab"
    >
      <template #header>
        <nav class="top-actions">
          <template v-if="activeTab === 'brands'">
            <primary-button @click.prevent="showCreateBrandModal">
              <icon name="add" />
              Create Brand
            </primary-button>
          </template>
          <template v-if="activeTab === 'templates'">
          </template>
          <template v-if="activeTab === 'showroom'">
          </template>
          <div v-if="activeTab==='showroom'">
            <rich-switch
              first-option="Basic"
              second-option="Advanced"
              :selected-option="selectedShowroomSettingsEditorMode"
              @switch="setSelectedShowroomSettingsEditorMode"
            />
          </div>
          <div
            v-else
            class="switch-view"
          >
            <div
              class="view-btn"
              :class="{'active-view': activeView === 'list'}"
              @click="changeActiveView('list')"
            >
              <icon name="list-view" />
            </div>
            <div
              class="view-btn"
              :class="{'active-view': activeView === 'grid'}"
              @click="changeActiveView('grid')"
            >
              <icon name="grid-view" />
            </div>
          </div>
        </nav>
      </template>
      <template #content>
        <tab
          :is-active="activeTab==='brands'"
          title="Brands"
        >
          <transition
            appear
            mode="out-in"
            name="fadeIn"
          >
            <brand-list
              ref="brandList"
              :view="activeView"
            />
          </transition>
        </tab>
        <tab
          :is-active="activeTab==='templates'"
          title="Templates"
        >
          <transition
            appear
            mode="out-in"
            name="fadeIn"
          >
            <template-list :view="activeView" />
          </transition>
        </tab>
        <tab
          v-if="canEditShowroomSettings"
          :is-active="activeTab==='showroom'"
          title="Showroom"
        >
          <transition
            appear
            mode="out-in"
            name="fadeIn"
          >
            <showroom-config />
          </transition>
        </tab>
      </template>
    </tabs>
  </div>
</template>
<script>
import Utils from "../../utils"
import BrandModal from "../common/modal/BrandModal"
import BrandList from "./BrandList"
import TemplateList from "./TemplateList"
import ShowroomConfig from "@/components/preferences/ShowroomConfig.vue"
import { mapMutations, mapState } from "vuex"

export default {
  name: "Preferences",
  components: {
    ShowroomConfig,
    BrandList,
    TemplateList
  },
  data () {
    return {
      activeTab: "brands",
      activeView: "list"
    }
  },
  computed: {
    ...mapState(["selectedShowroomSettingsEditorMode", "currentUser"]),
    Utils () {
      return Utils
    },
    canEditShowroomSettings () {
      if (this.currentUser) {
        return this.currentUser.roles.manager || this.currentUser.roles.admin || this.currentUser.roles.super_admin
      }
      return false
    }
  },
  methods: {
    ...mapMutations(["setSelectedShowroomSettingsEditorMode"]),
    changeActiveView (view) {
      this.activeView = view
      this.reloadBrands()
    },
    reloadBrands () {
      if (this.activeView === "grid") {
        if (this.$refs.brandList) {
          this.$refs.brandList.changePage(1, 20, false)
        }
      } else if (this.$refs.brandList) {
        this.$refs.brandList.changePage(1, 20, false)
      }
    },
    showCreateBrandModal () {
      this.$modal.show(BrandModal, {
        action: this.reloadBrands
      })
    },
    changeTab (tab) {
      this.activeTab = tab.title.toLowerCase()
    }
  }
}
</script>
