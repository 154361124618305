<template>
  <base-modal
    ref="modal"
    class="design-wizard"
    :class="'step-' + currentStep"
    :disable-action-line="currentStep === steps.length - 1"
    :image="currentStepImage"
    size="big"
    @submit="nextStep"
  >
    <template #modal-side-content>
      <div class="side-title-name">
        <h3 v-if="!design">
          Add
        </h3>
        <h3 v-if="design">
          Edit
        </h3>
        <h2>
          Creative.
        </h2>
      </div>
      <div class="wizard-steps">
        <ol>
          <li class="separated finished">
            <icon name="wizard-step-finished" />
            <span>
              {{ campaign.name }}
            </span>
          </li>
          <li
            v-for="(stepVal, stepKey) in steps.filter(s => s.title !== null)"
            :key="'wizard-step' + stepKey"
            class="step"
            :class="{current: currentStep === stepKey, finished: currentStep > stepKey}"
            @click.prevent="changeStep(stepKey)"
          >
            <icon name="wizard-step-finished" />
            <div class="list-item-bg"></div>
            <span>
              {{ stepVal.title }}
            </span>
          </li>
        </ol>
      </div>
    </template>
    <template #modal-header>
&nbsp;
    </template>
    <template #modal-close>
&nbsp;
    </template>
    <template #content>
      <div class="step-0-content">
        <h1>Choose your Product</h1>
        <h4>
          Let's find the right Creatives to guarantee the maximum amount of success for your Campaign! First, choose your Product.
        </h4>
        <div class="container">
          <div class="row pt-3">
            <box-selection
              v-model="productType"
              :options="availableProducts"
              @input="setStepsAccordingToDesign"
            />
          </div>
        </div>
      </div>
      <div
        v-if="productType === PRODUCT_DOOH"
        class="step-1-content"
      >
        <h2>Set Ad Format</h2>
        <h4>
          Define a Format for your Creative. You can choose one of the pre-defined ones.
        </h4>
        <div class="container">
          <div class="row">
            <div class="col">
              <dooh-sizings
                v-model="adFormat"
                @input="setStepsAccordingToDesign"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="productType === PRODUCT_LIVING_ADS"
        class="step-1-content"
      >
        <h2>Set Ad Format</h2>
        <h4>
          Define a Format for your Creative. You can choose one of the pre-defined ones.
        </h4>
        <div class="container">
          <living-ads-sizings
            v-model="adFormat"
            @input="setStepsAccordingToDesign"
          />
        </div>
      </div>
      <div
        v-else-if="productType === PRODUCT_VAST"
        class="step-1-content"
      >
        <h2>Set Ad Format</h2>
        <h4>
          Define a Format for your Creative. You can choose one of the pre-defined ones.
        </h4>
        <div class="container">
          <vast-ads-sizings
            v-model="adFormat"
            @input="setStepsAccordingToDesign"
          />
        </div>
      </div>
      <div
        v-else
        class="step-1-content"
      >
        <h2>Set Creative Format</h2>
        <h4>
          Define a Format for your Creative. You can choose the pre-defined ones from “Mobile” and “Desktop” or define your own size in the “Custom” section.
        </h4>
        <div class="container">
          <div class="row">
            <div class="col">
              <tabs
                class="tabs--aligned"
                :init-active-tab="activeTab"
                @change-tab="changeTab"
              >
                <template #content>
                  <tab
                    :is-active="activeTab==='mobile'"
                    title="Mobile"
                  >
                    <mobile-sizings
                      v-model="adFormat"
                      :available-features="availableFeatures"
                      @input="setStepsAccordingToDesign"
                    />
                    <device-orientation
                      v-model="deviceOrientation"
                    />
                  </tab>
                  <tab
                    :is-active="activeTab==='desktop'"
                    title="Desktop"
                  >
                    <desktop-sizings
                      v-model="adFormat"
                      @input="setStepsAccordingToDesign"
                    />
                  </tab>
                  <tab
                    class="custom-sizing-tab"
                    :is-active="activeTab==='custom'"
                    title="Custom"
                  >
                    <div class="container">
                      <div class="row d-flex">
                        <div class="col">
                          <text-input
                            v-model:value="customWidth"
                            label="Ad width"
                            :max="1920"
                            :max-width="300"
                            :min="50"
                            :min-width="300"
                            placeholder="0"
                            suffix="px"
                            type="number"
                          />
                        </div>
                      </div>
                      <div class="row d-flex pt-3">
                        <div class="col">
                          <text-input
                            v-model:value="customHeight"
                            label="Ad height"
                            :max="1080"
                            :max-width="300"
                            :min="50"
                            :min-width="300"
                            placeholder="0"
                            suffix="px"
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="shouldShowCustomAdFormatResponsiveUnitsCheckbox"
                      class="row d-flex pt-3 pl-2"
                    >
                      <div class="col">
                        <checkbox
                          id="responsive_units"
                          label="Use responsive units"
                          :value="customAdFormatValue"
                          @input="updateCustomAdFormat"
                        />
                      </div>
                    </div>
                    <sizing-entry type="desktop_custom" />
                  </tab>
                </template>
              </tabs>
            </div>
          </div>
        </div>
      </div>

      <!--       Hide custom templates tab when editing design-->
      <div
        v-if="isTemplateStepAvailable"
        class="step-2-content"
      >
        <h2>Set Creative Template</h2>
        <h4>
          At your Service! Our hand picked Templates will boost your Campaign. You can also set up your own Templates from previous creatives. To do so save your Creative as a Template inside of the editor.
        </h4>
        <div class="container">
          <div class="row">
            <div class="col">
              <tabs
                class="tabs--aligned"
                :init-active-tab="activeTemplateTab"
                @change-tab="changeActiveTemplateTab"
              >
                <template #content>
                  <tab
                    :is-active="activeTemplateTab==='default'"
                    title="Default"
                  >
                    <div class="template-preset">
                      <div
                        v-for="preset in filteredTemplatePresets"
                        :key="preset.type"
                        class="template-entry"
                        :class="{active: (preset.type === 'start-from-scratch' && activePreset === null) || activePreset === preset.type }"
                        @click="changeActivePresetType(preset.type)"
                      >
                        <inline-svg :src="require('../../../../images/templates/' + preset.type + '.svg')" />
                        <p class="template-title">
                          {{ preset.title }}
                        </p>
                      </div>
                    </div>
                    <!-- No templates prepared yet -->
                  </tab>
                  <tab
                    :is-active="activeTemplateTab==='custom'"
                    title="Custom"
                  >
                    <div
                      v-if="isTemplateStepAvailable && currentStep === 2"
                      class="templates-list"
                    >
                      <div class="swiper-container">
                        <div
                          ref="swiper-wrapper"
                          class="swiper-wrapper"
                          @scroll="templateLoadCheck"
                        >
                          <div
                            v-for="template in templates"
                            :key="'template-' + template.name"
                            class="swiper-slide"
                            :class="{active: activeTemplate && activeTemplate.id === template.id}"
                            @click="selectTemplate(template)"
                          >
                            <design-box
                              :design="template"
                              :display-edit="false"
                              :display-meta="false"
                              :display-showroom="false"
                              :display-sizing="false"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab>
                </template>
              </tabs>
            </div>
          </div>
        </div>
      </div>
      <div :class="[!isTemplateStepAvailable ? 'step-2-content' : 'step-3-content']">
        <h1>You are all Set!</h1>
        <h4 class="pt-2">
          Good Job! Choose a name for your Creative and jump directly into the Editor to unleash your creativity.
        </h4>
        <div class="container">
          <div class="row d-flex py-3">
            <div class="col">
              <text-input
                v-model:value="form.name"
                :information-tooltip="Utils.getRichTooltip('Enter Creative Name', 'No Idea what to type here? Maybe the Format of your Creative could be a recognizable name then!')"
                label="Enter Creative Name"
                :max-width="645"
                :maxlength="95"
                :min-width="645"
                placeholder="Name Your Creative"
                :required="true"
              />
            </div>
          </div>
          <div class="row d-flex pt-2">
            <div class="col">
              <primary-button
                :disabled="form.name.length === 0 || wasSubmitClicked"
                size="l"
                @click="submit"
              >
                <span v-if="!design">Start Creating</span>
                <span v-if="design">Save</span>
              </primary-button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions-container>
      <div
        class="modal-actions"
        :class="currentStep === steps.length - 1 ? 'actions-left' : ''"
      >
        <template v-if="(currentStep === 0 && showBackButton) || currentStep !== steps.length - 1">
          <base-button
            v-if="(currentStep === 0 && showBackButton) || currentStep > 0"
            size="xl"
            @click.prevent="previousStep"
          >
            Previous
          </base-button>
          <primary-button
            :disabled="nextStepAvailable === false"
            size="xl"
            @click="nextStep"
          >
            Next
          </primary-button>
        </template>
        <template v-if="currentStep === steps.length - 1">
          <div class="actions-left">
            <base-button
              size="xl"
              @click.prevent="previousStep"
            >
              Previous
            </base-button>
          </div>
        </template>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { Form } from "@/classes/form"
import {
  AD_FORMATS,
  DESKTOP_FORMATS,
  DOOH_AD_FORMATS,
  EXPANDABLE_BANNER_FORMATS,
  LIVING_ADS_FORMAT,
  MOBILE_FORMATS,
  PRODUCT_DOOH,
  PRODUCT_LIVING_ADS,
  PRODUCT_RICH_MEDIA,
  RICH_MEDIA_AD_FORMATS,
  PRODUCT_VAST, VAST_ADS_FORMAT
} from "@/constants"
import Language from "../../../i18n/en"
import Utils from "../../../utils"
import BoxSelection from "../box/BoxSelection"
import DeviceOrientation from "../sizing/DeviceOrientation"
import MobileSizings from "../sizing/MobileSizings"
import DesktopSizings from "../sizing/DesktopSizings"
import DesignBox from "../design/DesignBox"
import Swiper from "swiper/bundle"
import InlineSvg from "vue-inline-svg"
import DoohSizings from "../sizing/DoohSizings"
import SizingEntry from "../../common/sizing/SizingEntry"
import { DesignRoutes } from "../../../api/routes"
import throttle from "lodash/throttle"
import LivingAdsSizings from "@/components/common/sizing/LivingAdsSizings.vue"
import VastAdsSizings from "@/components/common/sizing/VastAdsSizings.vue"

export default {
  components: {
    VastAdsSizings,
    LivingAdsSizings,
    MobileSizings,
    DoohSizings,
    DeviceOrientation,
    DesktopSizings,
    BoxSelection,
    SizingEntry,
    DesignBox,
    InlineSvg
  },
  props: {
    showBackButton: {
      type: Boolean,
      default: false
    },
    campaign: {
      type: Object,
      required: true
    },
    design: {
      type: Object,
      default: null
    },
    openCampaign: {
      type: Function,
      default: () => () => {}
    },
    updateCallback: {
      type: Function,
      default: () => () => {}
    }
  },
  data () {
    return {
      page: 1,
      hasMoreTemplates: false,
      modal: {
        close: () => {}
      },
      templatePresets: [
        {
          type: "start-from-scratch",
          title: "Start from \n Scratch"
        },
        {
          type: "ProductVideoGallery",
          title: "Video & Product Gallery"
        },
        {
          type: "Pano",
          title: "Pano Ad"
        },
        {
          type: "Slidebar",
          title: "Slidebar"
        }
      ],
      currentStep: 0,
      allSteps: [
        {
          title: "Choose product",
          image: require("../../../../images/modal/design/design-step-0.svg")
        },
        {
          title: "Choose format",
          image: require("../../../../images/modal/design/design-step-1.svg")
        },
        {
          title: "Choose template",
          image: require("../../../../images/modal/design/design-step-2.svg")
        },
        {
          title: null,
          image: require("../../../../images/modal/design/design-step-3.svg")
        }
      ],
      steps: [],
      activeTab: "mobile",
      activeTemplateTab: "default",
      productType: "rich-media",
      customWidthVal: 50,
      customHeightVal: 50,
      deviceOrientation: "portrait",
      adFormat: {
        title: "interstitial",
        sizing: [320, 480],
        type: "interstitial"
      },
      activeTemplate: null,
      activePreset: null,
      form: new Form({
        campaignUuid: "",
        adWidth: null,
        adHeight: null,
        adProduct: "",
        deviceType: null,
        template: null,
        type: null,
        name: "",
        preset: null
      }),
      swiper: null,
      virtualData: {
        slides: []
      },
      wasSubmitClicked: false
    }
  },
  computed: {
    ...mapState({
      brands: "availableBrands",
      users: "users",
      groups: "groups",
      templates: "templates",
      availableFeatures: "availableFeatures"
    }),
    shouldShowCustomAdFormatResponsiveUnitsCheckbox () {
      return this.availableFeatures.includes("fe:custom_responsive_format")
    },
    customAdFormatValue () {
      if (this.adFormat.type === AD_FORMATS.custom_responsive) {
        return true
      } else {
        return false
      }
    },
    deviceType () {
      if (this.productType === PRODUCT_DOOH) {
        return PRODUCT_DOOH
      }

      if (this.productType === PRODUCT_VAST) {
        return PRODUCT_VAST
      }

      return this.activeTab === "mobile" ? "phone" : "desktop"
    },
    isTemplateStepAvailable () {
      if ([AD_FORMATS.vast_csv_gallery, AD_FORMATS.vast_interstitial].includes(this.adFormat?.type)) {
        return true
      }
      return !this.design && this.deviceType === "phone" && [PRODUCT_LIVING_ADS].includes(this.productType) === false
    },
    filteredTemplatePresets () {
      if (
        [this.PRODUCT_DOOH, this.PRODUCT_LIVING_ADS].includes(this.productType) ||
        [...EXPANDABLE_BANNER_FORMATS, AD_FORMATS.vast_csv_gallery, AD_FORMATS.vast_interstitial].includes(this.selectedAdFormat.type)
      ) {
        return [{
          type: "start-from-scratch",
          title: "Start from \n Scratch"
        }]
      }

      return this.templatePresets
    },
    availableProducts () {
      const availableProducts = [
        {
          title: "Rich Media",
          icon: "rich-media",
          id: "rich-media",
          disabled: this.design && this.design.ad_product !== PRODUCT_RICH_MEDIA
        },
        {
          title: "DOOH",
          icon: "dooh",
          id: "dooh",
          disabled: this.availableFeatures.includes("fe:adproducts.dooh") === false || (this.design && this.design.ad_product !== PRODUCT_DOOH)
        },
        {
          title: "VAST",
          icon: "vast",
          id: "vast",
          disabled: false
        }

      ]

      if (this.availableFeatures.includes("gr:wagawinFeatures")) {
        availableProducts.push({
          title: "LivingAds",
          icon: "wagawin_wini_white",
          id: "living-ads",
          disabled: (this.design && this.design?.ad_product !== PRODUCT_LIVING_ADS)
        })
      }

      return availableProducts
    },
    customHeight: {
      get () {
        return this.customHeightVal
      },
      set (val) {
        this.customHeightVal = val

        const mockupHeight = 92
        const ratio = 92 / 1080

        document.body.style.setProperty("--custom-height", parseInt(this.customHeightVal) * ratio + "px")
        document.body.style.setProperty("--custom-y", mockupHeight - (parseInt(this.customHeightVal) * ratio) + "px")
      }
    },
    customWidth: {
      get () {
        return this.customWidthVal
      },
      set (val) {
        this.customWidthVal = val

        const mockupWidth = 168
        const ratio = mockupWidth / 1920

        document.body.style.setProperty("--custom-width", parseInt(this.customWidthVal) * ratio + "px")
        document.body.style.setProperty("--custom-x", mockupWidth - (parseInt(this.customWidthVal) * ratio) + "px")
      }
    },
    nextStepAvailable () {
      switch (this.currentStep) {
        case 0: // Product type
          return this.productType !== null
        case 1: // Format
          return this.selectedAdFormat !== null
        case 2: // Templates
        default:
          return true
      }
    },
    selectedAdFormat () {
      return this.activeTab === "custom" ? {
        ...this.adFormat,
        sizing: [this.customWidth, this.customHeight]
      } : this.adFormat
    },
    currentStepImage () {
      return this.steps[this.currentStep].image
    },
    availableBrands () {
      return [...(this.brands || []).map((r) => {
        return {
          value: r,
          title: r.name
        }
      }), { value: null, title: "None" }]
    },
    Utils () {
      return Utils
    },
    PRODUCT_DOOH () {
      return PRODUCT_DOOH
    },
    PRODUCT_LIVING_ADS () {
      return PRODUCT_LIVING_ADS
    },
    PRODUCT_VAST () {
      return PRODUCT_VAST
    }
  },
  created () {
    // Hide custom templates tab when editing design or having a desktop design
    this.setStepsAccordingToDesign()
    this.loadingTemplates = false
  },
  beforeMount () {
    this.form.campaignUuid = this.campaign.uuid
  },
  mounted () {
    this.modal = this.$refs.modal

    this.initSwiper()
    this.loadCompanyUsers()
    this.loadGroups()
    this.loadBrands()

    this.form.campaignUuid = this.campaign.uuid

    if (this.design) {
      this.form.name = this.design.name

      this.deviceOrientation = this.design.deviceDimensions.pw > this.design.deviceDimensions.ph && this.design.deviceType === "phone" && this.design.deviceSizing !== "fixed" ? "landscape" : "portrait"

      const sizingKeys = ["pw", "ph"]
      const selectedSizingKeys = this.deviceOrientation === "landscape" ? sizingKeys.reverse() : sizingKeys
      const desktopSizing = this.design.deviceType === "desktop" ? Utils.getDesktopSizings().find(e => e.sizing[0] === this.design.deviceDimensions[selectedSizingKeys[0]] && e.sizing[1] === this.design.deviceDimensions[selectedSizingKeys[1]]) : null
      const mobileSizing = this.design.deviceType === "phone" ? Utils.getMobileSizings().find(e => e.sizing[0] === this.design.deviceDimensions[selectedSizingKeys[0]] && e.sizing[1] === this.design.deviceDimensions[selectedSizingKeys[1]]) : null

      this.productType = this.design.ad_product
      this.activeTab = this.productType === PRODUCT_DOOH ? PRODUCT_DOOH : (desktopSizing ? "desktop" : mobileSizing ? "mobile" : "custom")

      if (![AD_FORMATS.custom_responsive, AD_FORMATS.custom_fixed].includes(this.design.format)) {

        if (this.productType === PRODUCT_DOOH) {
          this.adFormat = Utils.getDoohSizings().find(s => s.type === this.design.format)
        } else if (this.productType === PRODUCT_VAST) {
          this.adFormat = Utils.getVastSizings().find(s => s.type === this.design.format)
        } else {
          this.adFormat = this.productType === PRODUCT_DOOH
            ? Utils.getDoohSizings().find(s => s.type === this.design.format)
            : (this.design.deviceType === "phone"
                ? Utils.getMobileSizings().find(s => s.type === this.design.format)
                : Utils.getDesktopSizings().find(s => s.type === this.design.format)
              )
        }

      } else {
        this.customWidth = this.design.deviceDimensions.pw
        this.customHeight = this.design.deviceDimensions.ph
        this.updateCustomAdFormat(this.design.format === AD_FORMATS.custom_responsive)
      }
      this.form.adWidth = this.selectedAdFormat.sizing[0] || 320
      this.form.adHeight = this.selectedAdFormat.sizing[1] || 480
    }
  },
  methods: {
    async loadFreshTemplates () {
      this.page = 1
      if (this.isTemplateStepAvailable) {
        await this.loadTemplates({ page: this.page, limit: 50, designFormat: this.adFormat.type}).then((resp) => {
          this.hasMoreTemplates = resp.data.meta.last_page > resp.data.meta.current_page
          if (this.hasMoreTemplates) {
            this.templateLoadCheck()
          }
        })
      }
    },
    updateCustomAdFormat (val) {
      if (val) {
        this.adFormat = {
          title: "Custom responsive",
          sizing: [this.customWidth, this.customHeight],
          type: "custom_responsive"
        }
      } else {
        this.adFormat = {
          title: "Custom fixed",
          sizing: [this.customWidth, this.customHeight],
          type: "custom_fixed"
        }
      }
    },
    templateLoadCheck () {
      return throttle(() => {
        if (this.hasMoreTemplates && !this.loadingTemplates) {
          const targetEl = this.$refs["swiper-wrapper"]
          if (!targetEl) {
            return
          }
          const scrollPercent = targetEl.scrollLeft / targetEl.scrollWidth
          if (scrollPercent > 0.7 || (targetEl.scrollLeft === 0 && targetEl.scrollWidth === 0)) {
            this.page++
            this.loadTemplates({ page: this.page, append: true, designFormat: this.adFormat.type}).then((resp) => {
              this.hasMoreTemplates = resp.data.meta.last_page > resp.data.meta.current_page
              this.loadingTemplates = false

              if (this.hasMoreTemplates) {
                this.templateLoadCheck()
              }
            })
            this.loadingTemplates = true
          }
        }
      }, 200)()
    },
    selectTemplate (template) {
      this.activeTemplate = this.activeTemplate && this.activeTemplate.id === template.id ? this.activeTemplate = null : template
    },
    changeActivePresetType (type) {
      this.activePreset = type === "start-from-scratch" ? null : type
    },
    changeActiveTemplateTab (tab) {
      this.activeTemplateTab = tab.title.toLowerCase()
    },
    changeTab (tab) {
      this.activeTab = tab.title.toLowerCase()
      this.setStepsAccordingToDesign()
      this.adjustAdFormatAfterCreativeFormatTabChange()
    },
    setStepsAccordingToDesign () {
      if (this.productType === PRODUCT_VAST) {
        this.steps = this.allSteps
      } else if (this.design || this.deviceType !== "phone") {
        this.steps = [
          {
            title: "Choose product",
            image: require("../../../../images/modal/design/design-step-0.svg")
          },
          {
            title: "Choose format",
            image: require("../../../../images/modal/design/design-step-1.svg")
          },
          {
            title: null,
            image: require("../../../../images/modal/design/design-step-3.svg")
          }
        ]
      } else if (this.productType === PRODUCT_LIVING_ADS) {
        this.steps = [
          {
            title: "Choose product",
            image: require("../../../../images/modal/design/design-step-0.svg")
          },
          {
            title: "Choose format",
            image: require("../../../../images/modal/design/design-step-1.svg")
          },
          {
            title: null,
            image: require("../../../../images/modal/design/design-step-3.svg")
          }
        ]
      } else {
        this.steps = this.allSteps
      }

      // update active template tab
      this.activeTemplateTab = "default"
    },
    initSwiper () {
      this.swiper = new Swiper(".templates-list .swiper-container", {
        slidesPerView: 3,
        spaceBetween: 15,
        allowTouchMove: false
      })
    },
    changeTimeRange (d) {
      if (d.dateRange.start.length > 0) {
        this.form.durationStart = d.dateRange.start
      }
      if (d.dateRange.end.length > 0) {
        this.form.durationEnd = d.dateRange.end
      }
    },
    changeStep (stepIndex) {
      if (stepIndex > this.currentStep && this.nextStepAvailable === false) {
        return
      }
      this.currentStep = stepIndex
    },
    adjustAdFormatAfterProductChange () {
      switch (this.productType) {
        case PRODUCT_DOOH:
          if (DOOH_AD_FORMATS.includes(this.adFormat.type)) {
            break
          }

          this.adFormat = {
            title: Utils.getFriendlyDoohSizingTitle(AD_FORMATS.infoscreen),
            sizing: [1920, 1080],
            type: AD_FORMATS.infoscreen
          }
          break
        case PRODUCT_RICH_MEDIA:
          if (RICH_MEDIA_AD_FORMATS.includes(this.adFormat.type)) {
            break
          }

          this.adFormat = {
            title: Utils.snakeCaseToCapitalizedWords(AD_FORMATS.interstitial),
            sizing: [320, 480],
            type: AD_FORMATS.interstitial
          }
          break
        case PRODUCT_LIVING_ADS:
          if (LIVING_ADS_FORMAT.includes(this.adFormat.type)) {
            break
          }

          this.adFormat = {
            title: Utils.getFriendlyLivingAdsFormatTitle(AD_FORMATS.living_ads_hot_or_not),
            sizing: [320, 480],
            type: AD_FORMATS.living_ads_hot_or_not,
            icon: "wagawin_wini_white",
            id: AD_FORMATS.living_ads_hot_or_not,
            disabled: false
          }
          break
        case PRODUCT_VAST:
          if (VAST_ADS_FORMAT.includes(this.adFormat.type)) {
            break
          }

          this.adFormat = {
            title: "Interstitial",
            sizing: [1920, 1080],
            type: AD_FORMATS.vast_interstitial,
            icon: "wagawin_wini_white",
            id: AD_FORMATS.vast_interstitial,
            disabled: false
          }
          break
        default:
          break
      }
    },
    adjustAdFormatAfterCreativeFormatTabChange () {
      switch (this.activeTab) {
        case "custom":
          if ([AD_FORMATS.custom_fixed, AD_FORMATS.custom_responsive].includes(this.adFormat.type)) {
            break
          }

          this.adFormat = {
            title: Utils.snakeCaseToCapitalizedWords(AD_FORMATS.custom_fixed),
            sizing: [50, 50],
            type: AD_FORMATS.custom_fixed
          }

          this.customWidth = this.adFormat.sizing[0]
          this.customHeight = this.adFormat.sizing[1]
          break
        case "mobile":
          if (MOBILE_FORMATS.includes(this.adFormat.type)) {
            break
          }

          this.adFormat = {
            title: Utils.snakeCaseToCapitalizedWords(AD_FORMATS.interstitial),
            sizing: [320, 480],
            type: AD_FORMATS.interstitial
          }
          break
        case "desktop":
          if (DESKTOP_FORMATS.includes(this.adFormat.type)) {
            break
          }

          this.adFormat = {
            title: Utils.getFriendlyDesktopSizingTitle(AD_FORMATS.desktop_medium_banner),
            sizing: [300, 250],
            type: AD_FORMATS.desktop_medium_banner
          }
          break
        default:
          break
      }
    },
    nextStep () {
      if (this.nextStepAvailable && this.currentStep < this.steps.length - 1) {
        const nextStep = this.currentStep + 1
        if (nextStep === 1) {
          this.adjustAdFormatAfterProductChange()
        }
        const nextStepType = this.steps[nextStep].title
        if (nextStepType === "Choose template") {
          this.loadFreshTemplates()
        }
        this.currentStep = nextStep
      } else if (this.form.name.length > 0) {
        this.submit()
      }
    },
    previousStep () {
      if (this.currentStep === 0 && this.showBackButton) {
        this.modal.close()
        this.openCampaign({ campaign: this.campaign, activeStep: 3 })
      } else {
        this.currentStep--
      }
    },
    submit () {
      if (this.currentStep === this.steps.length - 1) {
        this.wasSubmitClicked = true
        this.form.campaignUuid = this.campaign.uuid

        if (this.activeTemplateTab === "default") {
          this.form.preset = this.activePreset
          this.form.adWidth = this.selectedAdFormat.sizing[0]
          this.form.adHeight = this.selectedAdFormat.sizing[1]
        } else if (this.activeTemplate) {
          this.form.adWidth = Utils.getDimensions(this.activeTemplate).width
          this.form.adHeight = Utils.getDimensions(this.activeTemplate).height
          this.form.template = this.activeTemplate.id
        } else {
          this.form.adWidth = this.selectedAdFormat.sizing[0]
          this.form.adHeight = this.selectedAdFormat.sizing[1]
        }

        if (this.deviceOrientation === "landscape" && this.deviceType === "phone") {
          const [width, height] = [this.form.adWidth, this.form.adHeight]
          this.form.adWidth = height
          this.form.adHeight = width
        }

        this.form.deviceType = this.deviceType
        this.form.type = this.selectedAdFormat.type
        this.form.adProduct = this.productType

        if (this.design) {
          return this.form
            .put(DesignRoutes.updateDesign(this.design.uuid))
            .then(this.onSuccess)
            .catch(this.onError)
        }

        return this.form
          .post(DesignRoutes.createDesign())
          .then(this.onSuccess)
          .catch(this.onError)
      }
    },
    onSuccess (response) {
      this.$toasted.success(Language.designs.success.create)
      this.wasSubmitClicked = false
      this.modal.close()
      if (!this.design) {
        const isLivingAds = this.productType === PRODUCT_LIVING_ADS
        const isVast = this.adFormat.type === AD_FORMATS.vast_interstitial
        if (isLivingAds) {
          this.$router.replace(`${response.redirect}?livingAds=${this.adFormat.type}`)
        } else if (isVast) {
          this.$router.replace(`${response.redirect}?vast=${this.adFormat.type}`)
        } else {
          this.$router.replace(response.redirect)
        }
      }
      this.updateCallback()
    },
    onError (errors) {
      const errorsMessage = errors ? Object.values(errors).join("<br>") : ""
      let message
      if (errorsMessage.length > 0) {
        message = errorsMessage
      } else {
        message = this.design ? Language.designs.error.edit : Language.designs.error.create
      }
      this.$toasted.error(message)
      this.wasSubmitClicked = false
    },
    ...mapActions(["loadGroups", "loadCompanyUsers", "loadBrands", "loadTemplates"])
  }
}
</script>
