<template>
  <div
    class="form-group space-between form-group-color-picker"
    :class="[placement]"
    @click="e=> {$emit('click',e)}"
  >
    <label
      v-if="label"
      :for="`color-picker-${cid}`"
    >{{ label }}</label>
    <span
      v-if="tooltip && tooltip.length"
      v-tippy
      class="info-icon"
      :content="tooltip"
      style="margin-left: 4px; margin-right: auto;"
    >
      <icon name="info" />
    </span>
    <div
      class="btn-color-picker-wrapper"
      :class="{active:highlight}"
    >
      <div
        class="btn btn-color-picker custom-control-element d-block"
        :data-target="cid"
        :style="`background-color:${value};background:${value}`"
        @click="toggleColorPickerVisibility"
      >
        <i
          v-if="showColorPicker"
          class="fac fac-close"
        ></i>
      </div>
    </div>
    <selected-color-picker
      v-if="showColorPicker"
      :id="`color-picker-${cid}`"
      ref="colorPicker"
      class="color-picker"
      :disable-alpha="!showAlpha"
      :model-value="value"
      :name="`color-picker-${cid}`"
      :preset-colors="presetColors"
      @update:model-value="updateColor($event.hex, $event.hex8)"
    />
  </div>
</template>
<script>
import Utils from "../../../utils"
import { mapGetters } from "vuex"
import { Sketch as SelectedColorPicker } from "@lk77/vue3-color"
import { v4 as uuidv4 } from "uuid"

export default {
  name: "ColorPicker",
  components: {
    SelectedColorPicker
  },
  props: {
    value: {
      type: String,
      default: "#000000"
    },
    label: {
      type: String,
      default: "Font color"
    },
    tooltip: {
      type: String,
      default: ""
    },
    showAlpha: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: "bottom"
    }
  },
  emits: ["click", "update-value", "update:value"],
  data () {
    return {
      cid: null,
      showColorPicker: false,
      initialColor: null
    }
  },
  computed: {
    ...mapGetters({
      presetColors: "getPresetColors"
    })
  },
  mounted () {
    this.cid = uuidv4()
    this.$eventHub.$on("clicked-body", this.hideColorPicker)
    this.$eventHub.$on("clicked-wagawin-modal-body", this.hideColorPicker)
    this.$eventHub.$on("clicked-top-bar", this.hideColorPicker)

    this.initialColor = this.value
  },
  beforeUnmount () {
    this.$eventHub.$off("clicked-body", this.hideColorPicker)
    this.$eventHub.$off("clicked-wagawin-modal-body", this.hideColorPicker)
    this.$eventHub.$off("clicked-top-bar", this.hideColorPicker)
  },
  methods: {
    hideColorPicker (ev) {
      if (this.showColorPicker === false) {
        return
      }

      const dataTarget = ev.target ? ev.target.getAttribute("data-target") : null
      const hideBoolean =
          ((!ev.target.classList.contains("btn-color-picker") &&
              !ev.target.classList.contains("color-picker") &&
              !Utils.parentHasClass(ev.target, "color-picker")) || (dataTarget !== this.cid)) &&
          Array.from(ev.target.classList).join(" ").includes("vc-") === false

      if (hideBoolean) {
        this.showColorPicker = false
        this.addColorToPalette()
      }
    },
    updateColor (color, color8) {
      this.$emit("update-value", this.showAlpha ? color8 : color)
      this.$emit("update:value", this.showAlpha ? color8 : color)
    },
    addColorToPalette () {
      if (this.initialColor !== this.value && !this.presetColors.includes(this.value)) {
        this.$store.commit("addColorToPalette", this.value)
      }
    },
    toggleColorPickerVisibility () {
      if (this.disabled) {
        return
      }
      this.showColorPicker = !this.showColorPicker

      if (this.showColorPicker) {
        this.initialColor = this.value
      } else {
        this.addColorToPalette()
      }

      this.$emit("click")
    }
  }
}
</script>
