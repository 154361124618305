export class Routes {
  // DELETE
  static deleteComment = (commentUuid) => `/api/comment/${commentUuid}`

  // GET
  static getComments = (designUuid) => `/api/design/${designUuid}/comment`

  // PATCH
  static updateCommentResolve = (commentUuid) => `/api/comment/${commentUuid}/resolve`
  static updateComment = (commentUuid) => `/api/comment/${commentUuid}`

  // POST
  static createComment = (designUuid) => `/api/design/${designUuid}/comment`
  static setCommentTyping = (commentUuid) => `/api/design/${commentUuid}/comment/typing`
}
