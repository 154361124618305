<template>
  <div class="box-list">
    <template v-if="areThereAnyBrandsAvailable">
      <div
        v-if="view === 'grid'"
        key="box-selection"
        class="box-selection"
      >
        <brand-box
          v-for="brand in availableBrands"
          :key="brand.id"
          :brand="brand"
          @reload="() => changePage(1)"
        />
        <div
          v-if="view === 'grid' && currentPage < lastPage"
          key="brand-list-load-more"
          style="display: flex; align-items: center; padding-bottom: 20px;"
        >
          <secondary-button @click="nextPage">
            <span>Load more</span>
          </secondary-button>
        </div>
      </div>
      <advanced-table
        v-else
        key="brand-list-array"
        :columns="[{title: 'Brands', name: 'name'}, '']"
        :current-page="currentPage"
        :data="availableBrands"
        :last-page="lastPage"
        @load-next-page="nextPage"
      >
        <template #default="{row}">
          <td>
            <brand
              :brand="row"
              class="campaign-brand"
              :radius="8"
              :size="42"
            />
            <span class="brand-name">
              {{ row.name }}
            </span>
          </td>
          <td class="action-row">
            <div
              class="dropdown-holder"
              @click.prevent.stop="toggleDropdown(row.uuid)"
            >
              <context-dropdown
                :min-width="182"
                :options="getRowActions(row)"
                :placement="'bottom-end'"
                :visible="dropdownActive === row.uuid"
                @hide="hideDropdown"
              />
            </div>
          </td>
        </template>
      </advanced-table>
    </template>
    <template v-else>
      <empty-section :icon="require('../../../images/modal/create-brand-infographics.svg')">
        There are no Brands yet. Start by creating new one!
      </empty-section>
    </template>
  </div>
</template>
<script>
import BrandBox from "./BrandBox"
import { mapActions } from "vuex"
import ContextDropdown from "../common/dropdown/ContextDropdown"
import BrandModal from "../common/modal/BrandModal"
import DeleteModal from "../common/modal/DeleteModal"
import Language from "../../i18n/en"
import { CompanyRoutes } from "../../api/routes"
import EmptySection from "../dashboard/components/EmptySection"

export default {
  components: {
    BrandBox,
    ContextDropdown,
    EmptySection
  },
  props: {
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dropdownActive: null,
      currentPage: 1,
      lastPage: 1,
      availableBrands: [],
      currentSort: "created_at",
      currentSortDir: "DESC"
    }
  },
  computed: {
    areThereAnyBrandsAvailable () {
      return this.availableBrands && this.availableBrands.length
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    ...mapActions(["loadBrands"]),
    toggleDropdown (uuid) {
      this.dropdownActive = this.dropdownActive ? null : uuid
    },
    hideDropdown () {
      this.dropdownActive = null
    },
    changePage (newPage, limit = 20, appendData = false) {
      this.currentPage = newPage
      this.fetchData(newPage, limit, this.currentSort, this.currentSortDir, appendData)
    },
    nextPage () {
      this.changePage(this.currentPage + 1, 20, true)
    },
    getRowActions (brand) {
      const actions = []
      actions.push({
        content: "Edit",
        icon: "edit",
        click: () => this.edit(brand)
      })
      actions.push({
        content: "Delete",
        icon: "delete",
        click: () => this.destroy(brand)
      })
      return actions
    },
    edit (brand) {
      this.dropdownActive = false
      this.$modal.show(BrandModal, {
        brand,
        title: "Edit brand",
        action: this.fetchData
      })
    },
    destroy (brand) {
      this.$modal.show(DeleteModal, {
        endpoint: CompanyRoutes.deleteBrand(brand.uuid),
        title: `Delete Brand ${brand.name}?`,
        successMessage: Language.brands.success.delete,
        errorMessage: Language.brands.error.delete,
        afterDelete: this.fetchData
      })
    },
    fetchData (page = 1, limit = 20, sortBy = this.currentSort, sortWay = this.currentSortDir, appendData = false) {
      const loadBrands = this.loadBrands({
        limit,
        page,
        orderBy: sortBy,
        order: sortWay,
        append: appendData,
        performCommit: false
      })

      if (loadBrands) {
        loadBrands.then((resp) => {
          this.availableBrands = appendData ? this.availableBrands.concat(resp.data.data) : resp.data.data
          this.currentPage = resp.data.meta?.current_page || page
          this.lastPage = resp.data.meta?.last_page || page
        })
      }

      return loadBrands
    }
  }
}
</script>
