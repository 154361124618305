<template>
  <base-modal
    ref="modal"
    class="modal-auto-height transfer-modal text-left"
    :show-close-button="false"
    size="small"
    :title="null"
    @submit="deleteEntity"
  >
    <template #content>
      <h2>
        Delete user
      </h2>
      <p v-html="description"></p>
      <rich-input-dropdown
        v-model:value="selectedUser"
        :height="290"
        icon="search"
        label="Select user"
        :max-displayed="null"
        :max-height="235"
        :options="availableUsersToTransfer"
        placeholder="Search"
        :single="true"
        track-by="id"
        track-label="name"
      >
        <template #single-option-preview="single">
          <base-input
            disabled
            placeholder="Select user"
            style="margin-bottom: 10px"
            :value="single.option ? [single.option.firstname, single.option.lastname].join(' ') : ''"
          />
        </template>
        <template #option-row="data">
          <avatar
            :alt="data.option.title"
            :avatar="data.option.avatar"
            :size="28"
            style="margin-right: 15px"
            :title="data.option.title"
            :tooltip="false"
            :user-id="data.option.uuid"
          />
          <span>{{ data.option.firstname }} {{ data.option.lastname }}</span>
        </template>
      </rich-input-dropdown>
    </template>
    <template #actions>
      <base-button
        :min-width="94"
        size="l"
        @click.prevent="modal.close"
      >
        {{ cancelButton }}
      </base-button>
      <danger-button
        :disabled="users.filter(u => u.uuid !== user.uuid).length > 0 && selectedUser === null"
        :min-width="114"
        size="l"
        @click.prevent="deleteEntity"
      >
        {{ actionButton }}
      </danger-button>
    </template>
  </base-modal>
</template>

<script>
import DangerButton from "../controls/DangerButton"
import Language from "../../../i18n/en"
import RichInputDropdown from "../dropdown/RichInputDropdown"
import { AdminRoutes } from "../../../api/routes"

export default {
  components: {
    DangerButton,
    RichInputDropdown
  },
  props: {
    description: {
      type: String,
      default: "Choose a User who gets assigned to the Campaigns & Creatives of the deleted User."
    },
    cancelButton: {
      type: String,
      default: "Cancel"
    },
    actionButton: {
      type: String,
      default: "Delete"
    },
    endpoint: {
      type: String,
      default: null
    },
    successMessage: {
      type: String,
      default: Language.users.success.delete
    },
    errorMessage: {
      type: String,
      default: Language.users.error.delete
    },
    afterDelete: {
      type: Function,
      default: () => {
      }
    },
    action: {
      type: Function,
      default: null,
      validator: (prop) => prop !== null
    },
    user: {
      type: Object,
      default: null
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      modal: {
        close: () => {}
      },
      selectedUser: null
    }
  },
  computed: {
    availableUsersToTransfer () {
      return this.users.filter(u => u.uuid !== this.user.uuid).map((u) => {
        return {
          ...u,
          name: `${u.firstname} ${u.lastname}`,
          title: u.name
        }
      })
    }
  },
  mounted () {
    this.modal = this.$refs.modal
  },
  methods: {
    deleteEntity () {
      if (this.selectedUser) {
        if (this.action) {
          return this.action(this.modal).then(this.onSuccess)
        } else {
          const url = this.endpoint ? this.endpoint : AdminRoutes.deleteUser(this.user.uuid)
          return this.$apiClient.delete(url, {
            params: {
              passTo: this.selectedUser.uuid
            }
          })
            .then(this.onSuccess)
            .catch(() => {
              if (this.errorMessage !== null) {
                this.$toasted.error(this.errorMessage)
              }
            })
        }
      }
    },
    onSuccess () {
      this.modal.close()
      if (this.successMessage !== null) {
        this.$toasted.success(this.successMessage)
      }
      this.afterDelete()
    }
  }
}
</script>
