<template>
  <form class="profile-notification-settings">
    <card>
      <card-body>
        <div class="d-flex setting-entry">
          <checkbox
            id="new-comments"
            :value="showNewComments"
            @input="changeOption('showNewComments', $event)"
          />
          <div class="data-row">
            <label
              class="label"
              for="new-comments"
            >Show new comments</label>
            <span class="value">Enable to get notified each time a creative you are involved in gets commented in the preview section.</span>
          </div>
        </div>
        <div class="d-flex setting-entry">
          <checkbox
            id="progress"
            :value="showChangedProgress"
            @input="changeOption('showChangedProgress', $event)"
          />
          <div class="data-row">
            <label
              class="label"
              for="progress"
            >Show changed progress</label>
            <span class="value">Enable to get notified each time a creatives status is changed which you are involved in.</span>
          </div>
        </div>
        <div class="d-flex setting-entry">
          <checkbox
            id="newsletter"
            :value="showNewsletter"
            @input="changeOption('showNewsletter', $event)"
          />
          <div class="data-row">
            <label
              class="label"
              for="newsletter"
            >Show newsletter</label>
            <span class="value">Enable to get the newest informations about adtron updates first via Mail.</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="actions">
              <primary-button
                size="xl"
                @click="submit"
              >
                Save
              </primary-button>
            </div>
          </div>
        </div>
      </card-body>
    </card>
  </form>
</template>
<script>
import { mapActions, mapState } from "vuex"
import Utils from "../../utils"
import { Form } from "@/classes/form"
import Language from "../../i18n/en"
import { UserRoutes } from "../../api/routes"

export default {
  name: "NotificationSettings",
  data () {
    return {
      form: new Form({
        settings: {}
      }, true),
      showNewComments: true,
      showChangedProgress: true,
      showNewsletter: true
    }
  },
  computed: {
    settings () {
      return Object.assign({}, {
        showNewComments: true,
        showChangedProgress: true,
        showNewsletter: true
      }, this.currentUser ? this.currentUser.settings : {})
    },
    Utils () {
      return Utils
    },
    ...mapState(["currentUser"])
  },
  created () {
    this.initData()
  },
  methods: {
    changeOption (name, val) {
      this[name] = Boolean(val)
    },
    submit () {
      this.form.settings = Object.assign((this.currentUser ? this.currentUser.settings : {}), {
        showNewComments: this.showNewComments,
        showChangedProgress: this.showChangedProgress,
        showNewsletter: this.showNewsletter
      })
      return this.form
        .post(UserRoutes.updateUserSettings(this.currentUser.uuid))
        .then(() => {
          this.$toasted.success(Language.profile.success.settings)
          this.loadCurrentUser()
        })
        .catch(() => {
          this.$toasted.error(Language.profile.error.settings)
        })
    },
    initData () {
      this.showNewComments = this.settings.showNewComments
      this.showChangedProgress = this.settings.showChangedProgress
      this.showNewsletter = this.settings.showNewsletter
    },
    ...mapActions(["loadCurrentUser"])
  }
}
</script>
