import javascript from "highlight.js/lib/languages/javascript"
import xml from "highlight.js/lib/languages/xml"
import "highlight.js/styles/darcula.css"
import hljs from "highlight.js/lib/core"

hljs.registerLanguage("javascript", javascript)
hljs.registerLanguage("xml", xml)
export default {
  deep: true,
  mounted (el, binding) {
    // on first bind, highlight all targets
    const targets = el.querySelectorAll("code")
    targets.forEach(target => {
      // if a value is directly assigned to the directive, use this
      // instead of the element content.
      if (binding.value) {
        target.textContent = binding.value
      }
      hljs.highlightElement(target)
    })
  },
  updated (el, binding) {
    // after an update, re-fill the content and then highlight
    const targets = el.querySelectorAll("code")
    targets.forEach(target => {
      if (binding.value) {
        target.textContent = binding.value
        hljs.highlightElement(target)
      }
    })
  }
}
