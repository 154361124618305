<template>
  <ul
    ref="macro-scroll-container"
    class="adt-dropdown macro"
  >
    <li
      v-for="(macro, i) in macros"
      :key="macro.replacement"
      :class="{active: activeIndex === i}"
      :data-macro="i"
      @click.capture.prevent="handleClick(macros[i])"
    >
      <a>
        {{ macro.replacement }}
      </a>
    </li>
  </ul>
</template>

<script>
import { v4 as uuidv4 } from "uuid"

export default {
  props: {
    minWidth: {
      type: Number,
      default: 182
    },
    activeIndex: {
      type: Number,
      default: null
    },
    macros: {
      type: Array,
      default: () => []
    },
    tip: {
      type: Object,
      default: null
    },
    chooseMacro: {
      type: Function,
      default: () => {}
    }
  },
  created () {
    this.uuid = uuidv4()
  },
  methods: {
    getDropdownStyle () {
      return {
        minWidth: this.minWidth + "px"
      }
    },
    handleClick (macro) {
      if (this.tip?.value) {
        this.tip.value._tippy.hide()
      }

      this.chooseMacro(macro)
    }
  }
}
</script>
